import { SxProps } from "@mui/material"

const contentWrapper: SxProps = {
    display: 'flex',
    gap: '0.5em',
    '@media(max-width: 64em)': {
        flexWrap: 'wrap',
        flexDirection: "column",
        p: 2
    }
}

const mainContentWrapperStyle : SxProps = {
    width: "44%",
    '@media(min-width: 1800px )': {
        width: "48%"
    },
    "@media screen and (max-width: 1399px) and (min-height: 780px) and (max-height: 900px)": {
        width: "38%"
    }
}

const mainContentStyle: SxProps = {
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    position: "relative",
    flexDirection: 'column',
    pt: "40px",
    '@media(max-width: 1399px)': {
        pt: "20px"
    },
    '& img': {
        marginLeft: "30px",
        maxWidth: '190px',
        '@media(max-width: 1299px)': {
            maxWidth: '140px',
        },
        '@media(max-width: 1399px)': {
            maxWidth: '160px',
        },
        '@media(max-width: 1599px)': {
            maxWidth: '180px',
        },
        '@media(min-width: 1699px)': {
            maxWidth: '240px',
        },
        '@media(min-width: 1799px)': {
            maxWidth: '260px',
        }
    },
    '@media(max-width: 80em)': {
        width: "100%",
        maxWidth: '100%',
    },
    "p.MuiTypography-root":{
        fontSize: "0.6em",
        '@media(min-width: 1024px)': {
            fontSize: "0.66em",
        },
        '@media(min-width: 1400px)': {
            fontSize: "0.8em",
        },
        '@media(min-width: 1600px)': {
            fontSize: "0.8em",
            lineHeight: "1.4em"
        },
        '@media(min-width: 1799px)': {
            fontSize: "1em",
            lineHeight: "1.6em"
        }
    }
}

const headingStyle: SxProps = {
    fontWeight: 'bold',
    fontSize: "1.8em",
    px: 6,
    '@media(max-width: 39.28em)': {
        fontSize: "1.1em",
    }
}

const contentImageStyle: SxProps = { 
    position: "absolute",
    top: "-7.4%",
    right: "15px",
    bottom: "0",
    left: "calc( 44% + 120px )",
    '@media(min-width: 1800px )': {
        left: "calc( 48% + 120px )",
    },
    "@media screen and (max-width: 1399px) and (min-height: 780px) and (max-height: 900px)": {
        left: "calc( 38% + 120px )",
    },
    '& img': {
        position: 'absolute',
        top: '0',
        // width: '100%',
        // height: "100%",
        width: "auto",
        height: "calc(100vh - 200px)",
        left: "0",
        right: '0',
        objectFit: 'contain',
        objectPosition: 'center top',
        '@media(max-width: 1599px )': {
            maxWidth: "100%"
        }
    },
    '@media(max-width: 64em)': {
        height: "100%",
    }
}

const contentDataImageStyle: SxProps = {
        display: 'flex',
        position: 'relative',
        marginTop: "-4%",
        "img": {
            maxWidth: "640px",
            '@media(max-width: 1699px)': {
                maxWidth: "520px",
            },
            '@media(max-width: 1399px)': {
                maxWidth: "420px",
            },
            '@media(max-width: 1299px)': {
                maxWidth: "320px",
            }
        },
}

export {
    mainContentStyle,
    headingStyle,
    contentImageStyle,
    contentDataImageStyle,
    contentWrapper,
    mainContentWrapperStyle
}