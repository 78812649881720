import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface Props {
    label: string;
    handleCheckboxChange?: () => void;
    checked?: boolean;
}

const CustomCheckbox = (props: Props) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.checked}
                        onChange={props.handleCheckboxChange} />
                }
                label={props.label}
                sx={{
                    '.MuiFormControlLabel-label': {
                        fontWeight: '400',
                    },
                }}
            />
        </FormGroup>
    );
};

export default CustomCheckbox;
