import { FormGroup, FormControlLabel, Radio } from '@mui/material';
import React from 'react'

interface Props {
    label: string;
    handleChange?: () => void;
    checked?: boolean;
}

const CustomRadioComponent = (props: Props) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Radio
                        checked={props.checked}
                        onChange={props.handleChange} />
                }
                label={props.label}
                sx={{
                    ".MuiFormControlLabel-label": {
                        fontWeight: "400",
                    },
                }} />
        </FormGroup>
    );
};

export default CustomRadioComponent;

