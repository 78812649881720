import Icon from "@mdi/react";
import { SxProps } from "@mui/material/styles";
import MDButton from "components/MDButton";

interface ButtonProps {
  iconPath?: string;
  [key: string]: any;
  sx?: SxProps;
  buttonType?: "text" | "outlined" | "contained" | "gradient";
}

const ButtonWithIcon = ({
  children,
  iconPath,
  fontSize,
  buttonType,
  fontWeight,
  textColor,
  sx,
  ...attributes
}: ButtonProps) => {
  return (
    <MDButton
      variant={buttonType ?? "outlined"}
      color="primary"
      sx={{
        height: "36px",
        borderColor: "#c7ccd0",
        fontSize: "14px",
        backgroundColor: buttonType ?? "#ffffff",
        ...sx,
      }}
      {...attributes}
    >
      {<Icon path={iconPath} size={0.8} />}
      &nbsp; {children}
    </MDButton>
  );
};

export default ButtonWithIcon;
