import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';

export const SentComponent = () => {
    return (
        <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" color="success" fontWeight="bold" pr={0.5}>
                SENT
            </MDTypography>
            <Icon path={mdiCheck} size={0.8} color="#4CAF50" />
        </MDBox>
    )
}
