import { Search } from "@mui/icons-material";
import { TextField, InputAdornment, List } from "@mui/material";
import CustomCheckbox from "pages/dashboard/components/CustomCheckBox";
import { useEffect, useState } from "react";

/// Model for each checkbox
interface CheckboxItem {
  label: string;
  checked: boolean;
}

interface Props {
  title?: string;
  /// Already added columns
  latestAddedItems?: string[];
  /// List to be loaded
  customSelectorList: string[];
  shouldShowDeletableChips: boolean;
  handleCheckBoxChange: (listOfSelectedItems: string[]) => void;
}

/// Styles applied for the list
export const listStyle = {
  maxHeight: "230px",
  overflowY: "auto",
  pl: 0.5,
  scrollbarWidth: "thin",
  scrollbarColor: "#9F9FA1 auto",
  "&::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#9F9FA1",
    with: "5px",
    borderRadius: "5px",
  },
  "label": {
    display: 'flex',
    minWidth: '0',
    maxWidth: '230px',
    "span.MuiFormControlLabel-label": {
      overflow :"hidden",
      lineHeight: "20px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  }
};

export const CustomSearchBarWithList = (props: Props) => {
  /// Holds the state of search field
  const [searchInput, setSearchInput] = useState<string>("");
  /// State to hold the checked items
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  useEffect(() => {
    setCheckedItems(props.latestAddedItems);
  }, [props.latestAddedItems]);

  /// Mapped the filteredList to CheckBox Item
  const checkBoxItems = props.customSelectorList.map((item) => {
    const checkboxItem: CheckboxItem = {
      label: item,
      checked: false,
    };
    return checkboxItem;
  });

  const checkboxesItemsList = checkBoxItems
    .filter((item) =>
      item.label.toLowerCase().includes(searchInput.trim().toLowerCase())
    )
    .map((item) => ({
      ...item,
      checked: checkedItems.includes(item.label),
    }));

  /// Handles the onChange of the checkbox selection
  const handleCheckboxChange = (selectedItem: CheckboxItem) => {
    const optionALL = "ALL";
    setSearchInput("");
    // If 'ALL' is selecteds
    if (selectedItem.label === optionALL) {
      // 'ALL' is being deselected
      if (checkedItems.includes(optionALL)) {
        setCheckedItems([]);
        props.handleCheckBoxChange([]);
      } else {
        // 'ALL' is being selected
        setCheckedItems(props.customSelectorList);
        props.handleCheckBoxChange(props.customSelectorList);
      }
    } else {
      // If an individual option is selected
      const isChecked = checkedItems.includes(selectedItem.label);
      const isAllOptionChecked = checkedItems.includes(optionALL);
      const newCheckedItems =
        isChecked &&
        isAllOptionChecked &&
        props.customSelectorList.includes("ALL")
          ? /// Correct here - I need the options other than the selected option and "ALL" option
            checkedItems.filter(
              (item) => item !== selectedItem.label && item !== optionALL
            )
          : isChecked
          ? checkedItems.filter((item) => item !== selectedItem.label)
          : [...checkedItems, selectedItem.label];
      // If all individual options are selected, selects the 'ALL' option
      if (
        newCheckedItems.length === props.customSelectorList.length - 1 &&
        props.customSelectorList.includes("ALL")
      ) {
        setCheckedItems([...newCheckedItems, optionALL]);
        props.handleCheckBoxChange([...newCheckedItems, optionALL]);
      } else {
        setCheckedItems(newCheckedItems);
        props.handleCheckBoxChange(newCheckedItems);
      }
    }
  };

  /// Handles the onChange of the search input
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value;
    setSearchInput(input);
  };

  return (
    <>
      <TextField
        fullWidth
        placeholder="Search"
        value={searchInput}
        InputLabelProps={{ shrink: true }}
        onChange={handleSearchInputChange}
        sx={{ py: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize="medium" sx={{ color: "#344767" }} />
            </InputAdornment>
          ),
        }}
      />
      <List sx={listStyle}>
        {checkboxesItemsList.map((item: CheckboxItem, index: number) => {
          return (
            <CustomCheckbox
              key={index}
              label={item.label}
              handleCheckboxChange={() => {
                handleCheckboxChange(item);
              }}
              checked={item.checked}
            />
          );
        })}
      </List>
    </>
  );
};
