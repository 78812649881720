import { DialogActions, DialogContent } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import { MDBox, MDButton } from "../../vue-grid/helpers/imports";

export const EscalateDialogContent: React.FC<{
  closeDialog: (hasEscalated: boolean, reason?: string) => void;
  label: string;
  characterLength: number;
}> = (props) => {
  /// This handles the disability of the "ESCALATE" button
  const [reason, setReason] = useState<string>("");

  /// Handles the onChange of "Reason" field
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    /// Max character length should be 71
    setReason(value);
  };

  /// Handles the onClick of "Yes, Cancel" button
  const handleEscalateButtonClick = () => {
    props.closeDialog(true, reason.trim());
  };

  return (
    <>
      <DialogContent>
        <MDBox sx={{ px: "4px", py: "20px" }}>
          <MDBox display={"flex"}>
            <MDTypography sx={{ fontSize: "14px", fontWeight: "500" }}>
              {props.label}
            </MDTypography>
          </MDBox>
          <MDInput
            value={reason}
            sx={{ pt: "32px" }}
            fullWidth
            multiline
            rows={5}
            inputProps={{
              maxLength: props.characterLength,
            }}
            onChange={handleInputChange}
          />
        </MDBox>
      </DialogContent>
      {/* Action buttons */}
      <DialogActions>
        <MDButton
          variant="outlined"
          color="info"
          size="medium"
          sx={{
            borderColor: "secondary.light",
            color: "dark.main",
            fontSize: "14px",
            "&:hover": {
              backgroundColor: "white.main",
              borderColor: "secondary.light",
              color: "dark.main",
            },
            "&:focus": { color: "dark.main" },
          }}
          onClick={() => {
            props.closeDialog(false);
          }}
        >
          CANCEL
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="medium"
          disabled={reason.isEmpty()}
          onClick={handleEscalateButtonClick}
        >
          ESCALATE
        </MDButton>
      </DialogActions>
    </>
  );
};
