import { Card, Grid, Divider } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DashboardVue } from "../utils/dashboard_interfaces";
import CompletedVueItem from "./CompletedVueItem";
import { PlaceholderComponent } from "./PlaceholderComponent";

const CompletedVueList = ({
  completedVues,
}: {
  completedVues: DashboardVue[];
}) => {
  return (
    <Card>
      <Grid container display="flex" px={2} pt={2} spacing={4}>
        <Grid item xs={3}>
          <MDTypography sx={{ fontSize: 14 }} fontWeight="bold" ml={1}>
            VUE TITLE
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          <MDTypography sx={{ fontSize: 14 }} fontWeight="bold">
            VUE ID
          </MDTypography>
        </Grid>
        <Grid item xs={3}>
          <MDTypography sx={{ fontSize: 14 }} fontWeight="bold">
            COMPLETED AT
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          <MDTypography sx={{ fontSize: 14 }} fontWeight="bold">
            ESCALATED
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          <MDTypography sx={{ fontSize: 14 }} fontWeight="bold">
            AGING
          </MDTypography>
        </Grid>
      </Grid>
      <Divider
        sx={{
          backgroundColor: "#a8b8d8",
          height: "1pt",
          borderWidth: 1,
          opacity: 1,
        }}
      />
      {/* Displays only the first 10 completed vues list */}
      {completedVues.length !== 0 ? (
        completedVues
          .filter((item, index) => index < 10)
          .map((vue, index) => {
            return <CompletedVueItem key={index} vueItem={vue} />;
          })
      ) : (
        <MDBox
          height="130px"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PlaceholderComponent label="There are no recently completed Vues" />
        </MDBox>
      )}
    </Card>
  );
};

export default CompletedVueList;
