import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

interface Props {
  label: string;
  placeholder: string;
  [key: string]: any;
}

const CustomInput = (props: Props) => {
  return (
    <>
      <MDTypography variant="button" fontWeight="bold" sx={{ fontSize: 14 }}>
        {props.label}
      </MDTypography>
      <RequiredFieldMarker />
      <MDInput
        type="text"
        fullWidth
        onChange={props.onChange}
        value={props.value}
        {...props}
        label="" // setting label as empty to override the default label
      />
    </>
  );
};

export default CustomInput;
