import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const NamedAvatar = ({ name, color = colors.secondary.main, }: { name: string; color?: string; }) => {
  const getInitials = () => {
    let initials;
    const nameSplit = name.trim().split(" ");
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
      initials =
        nameSplit[0].substring(0, 1) +
        nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
      initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
  };

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <MDBox
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        backgroundColor: color ?? getRandomColor(),
        borderRadius: "100%",
      }}
      alignItems="center"
      justifyContent="center"
    >
      <MDTypography
        alignItems="center"
        color="white"
        sx={{
          margin: "auto",
          fontSize: "inherit",
          letterSpacing: "inherit"
        }}
      >
        {getInitials()}
      </MDTypography>
    </MDBox>
  );
};

export default NamedAvatar;
