import HorizontalBarChart from "layouts/Charts/HorizontalBarChart";
import { CompletedVueCountType } from "../utils/dashboard_interfaces";
import { alphanumericSort } from "utils/helpers/extensions";

interface Types {
  labels: string[];
  datasets: {
    label: string;
    color:
      | "primary"
      | "secondary"
      | "info"
      | "success"
      | "warning"
      | "error"
      | "light"
      | "dark";
    data: number[];
  }[];
}

const defaultChartData: Types = {
  labels: [],
  datasets: [
    {
      label: "Type of Vues Completed",
      color: "info",
      data: [],
    },
  ],
};

function TypesOfVueBarChart({
  completedVueCountByType,
  onBarChartClick,
}: {
  completedVueCountByType: CompletedVueCountType[];
  onBarChartClick: (surveyType: string, status: string) => void;
}): JSX.Element {
  const getChartData = (): Types => {
    // If there is no data returns the default data
    if (completedVueCountByType.length <= 0) {
      return defaultChartData;
    }

    const sortedArray = completedVueCountByType.sort((a, b) =>
      alphanumericSort(a.type, b.type)
    );
    const dataLabels = sortedArray.map((vueType) => vueType.type.capitalizeWords());
    const dataValues = sortedArray.map((vueType) => vueType.count);

    return {
      labels: dataLabels,
      datasets: [
        {
          label: "Type of Vues Completed",
          color: "info",
          data: dataValues,
        },
      ],
    };
  };

  // Action called when the user clicks on barchart
  const onChartClick = (datasetIndex: number, index: number) => {
    const chartData = getChartData();
    const selectedBar = chartData.labels[index];
    // Pass the data to parent when clicking on the bar
    onBarChartClick(selectedBar, "Completed");
  };

  return (
    <HorizontalBarChart
      title="Type of Vues Completed"
      chart={getChartData()}
      onClick={onChartClick}
    />
  );
}

export default TypesOfVueBarChart;
