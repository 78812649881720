import MDSnackbar from "components/MDSnackbar";

export interface CustomSnackbarContent {
    title:string;
    message:string;
    isError:boolean;
  }

interface Props {
  snackbarContent: CustomSnackbarContent;
  onClose: () => void;
}

const CustomSnackbar = (props: Props) => {
  const { snackbarContent, onClose } = props;
  return (
    <MDSnackbar
      color={snackbarContent?.isError ? "error" : "info"}
      icon={snackbarContent?.isError ? "erroroutline" : "done"}
      title={snackbarContent?.title ?? ""}
      content={snackbarContent?.message ?? ""}
      dateTime=""
      open={snackbarContent ? true : false}
      close={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    />
  );
};

export default CustomSnackbar;
