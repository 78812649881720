import { DEFAULT_PAGE_SIZE } from "./../../../../constants";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";

export const defaultPaginationMetaData = {
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 0,
  totalPages: 0,
  totalElements: 0,
};

export enum UserRoles {
  noAccess = "no_access",
  superAdmin = "admin_user",
  admin = "company_admin",
  manager = "company_manager",
  user = "company_user",
}

export const ADMIN_ROLE =
  "Admin - Admin can create and manage system, settings, users, site list and Vues";
export const USER_ROLE = "User - Users can view, edit and escalate Vues.";
export const MANAGER_ROLE =
  "Manager - Manager can create Vues, manage site list and surveys";
export const NO_ACCESS = "No Access";
export const AVAILABLE_USER_ROLES = [NO_ACCESS, USER_ROLE, MANAGER_ROLE];

export interface PaginationMetaData {
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  totalElements: number;
}

interface CompanyRole {
  companyId: string;
  role: UserRoles;
}

export interface CompanyRolesByEmail {
  email: string;
  companyRoles: CompanyRole[];
}

export interface UserFromAPI {
  email: string;
  isActive: boolean;
  portalCount: number;
  lastSignInEstimate?: any;
}

export interface UserGridData {
  users: UserInfo;
  active_inactive: boolean;
  manageportals: number;
  createddate: string;
  lastsignin: string;
  isAdmin: boolean;
  roles: CompanyRoleResult;
}

// Interface used to identify the company role result
export interface CompanyRoleResult {
  email: string;
  companyRoles: CompanyRole[];
}

///////////// HELPERS /////////////

export const getRoleDescriptionFromRole = (role: UserRoles): string => {
  switch (role) {
    case UserRoles.user:
      return USER_ROLE;
    case UserRoles.superAdmin:
    case UserRoles.admin:
      return ADMIN_ROLE;
    case UserRoles.manager:
      return MANAGER_ROLE;
    default:
      return NO_ACCESS;
  }
};

export const getRoleFromDescription = (description: string): UserRoles => {
  switch (description.toLowerCase()) {
    case USER_ROLE.toLowerCase():
      return UserRoles.user;
    case ADMIN_ROLE.toLowerCase():
      return UserRoles.admin;
    case MANAGER_ROLE.toLowerCase():
      return UserRoles.manager;
    default:
      return UserRoles.noAccess;
  }
};

export const generateGridDataFromResponse = (
  userSummary: UserFromAPI[],
  detailedUserList: UserInfo[],
  userRoleList: CompanyRoleResult[]
) => {
  var gridData: UserGridData[] = [];
  detailedUserList.forEach((userDetail) => {
    const email = userDetail.email;
    // Finding user details with same email
    const summaryFromServer = userSummary.find((user) => user.email === email);
    // Finding role with same email
    const userRole = userRoleList.find((role) => role.email === email);

    const finalData = generateUserObjectForGrid(
      summaryFromServer,
      userDetail,
      userRole
    );
    gridData.push(finalData);
  });

  return gridData;
};

export const generateUserObjectForGrid = (
  userSummary: UserFromAPI,
  userDetail: UserInfo,
  allRoles: CompanyRoleResult
): UserGridData => {
  const lastSigIn = userSummary.lastSignInEstimate?.value ?? "";
  const isAdmin = isCompanyAdmin(userDetail, allRoles);
  return {
    users: userDetail,
    active_inactive: userSummary.isActive,
    manageportals: userSummary.portalCount,
    createddate: userDetail.createdAt,
    lastsignin: lastSigIn,
    roles: allRoles,
    isAdmin: isAdmin,
  };
};

// Checks whether role is admin or not
const isCompanyAdmin = (
  userDetail: UserInfo,
  allRoles: CompanyRoleResult
): boolean => {
  const { companyId } = userDetail;
  // Finding role that matches user's company id
  const role = allRoles.companyRoles.find(
    (company) => companyId === company.companyId
  );
  if (role) {
    // Checks whether role is admin or not
    return role.role === UserRoles.admin;
  }
  // Role is not available.
  return false;
};

// Generates a default user summary with given email and portals
export const getDefaultUserSummaryFromEmailAndPortals = (
  email: string,
  portals: number
): UserFromAPI => {
  return {
    email: email,
    isActive: true,
    portalCount: portals,
    lastSignInEstimate: null,
  };
};
