function configs(labels: any, datasets: any) {
  return {
    data: {
      labels,
      datasets: [...datasets],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context:any) {
              // Customising data to show in the tooltip
              const label = `${context.label} ${context.dataset.tooltipLabel}`;
              const value = context.dataset.data[context.dataIndex];
              return ` ${label}: ${value}`;
            },
          },
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          beginAtZero:true,
          border: { dash: [5, 5], display: false },
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "#e9eaed",
          },
          ticks: {
            precision: 0,
            display: true,
            padding: 10,
            color: "#344767",
            font: {
              size: 14,
              weight: 400,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          border: { dash: [5, 5], display: false },
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: true,
            borderDash: [5, 5],
            color: "#e9eaed",
          },
          ticks: {
            display: true,
            color: "#344767",
            padding: 10,
            font: {
              size: 14,
              weight: 400,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
  };
}

export default configs;
