import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

const SiteListBottomBar = ({totalSites}:{totalSites: number}) => {
  return (
    <Grid
      container
      display="flex"
      px={4}
      pb={1.1}
      pt={0.6}
      sx={{
        borderTop: "0.5px solid #A8B8D8",
      }}
    >
      <Grid item xs={2} display="flex" alignItems="center" pl={2}>
        <MDTypography variant="h5" fontWeight="bold">
          SUMMARY
        </MDTypography>
      </Grid>
      <Grid item xs={2}>
        <MDTypography variant="button" color="textLight" fontWeight="regular">
          Total Sites
        </MDTypography>
        <MDTypography variant="h5">{totalSites > 0 ? totalSites : '-'}</MDTypography>
      </Grid>
    </Grid>
  );
};

export default SiteListBottomBar;
