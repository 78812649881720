import DashboardContentLayout from "layouts/DashboardContentLayout";
import AnalyticsComingSoon from "./AnalyticsComingSoon";

const AnalyticsHome = () => {
    return (
        <DashboardContentLayout needCardBackground={false}>
            <AnalyticsComingSoon />
        </DashboardContentLayout>
    );
};

export default AnalyticsHome;
