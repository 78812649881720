import MDBox from 'components/MDBox';
import { vuerSingleUserStyle } from './ManageInternalVuerNetworkStyle';
import MDTypography from 'components/MDTypography';
import { FC } from 'react';

interface HeadProps{
    headers:string[]
}

const VuerTableHead:FC<HeadProps> = ({ headers }) => {
    return (
        <>
            <MDBox  display='flex' sx={{
                px:2,
                borderBottom: `1px solid #adb5bd`,
                position: "sticky",
                top: 0,
                backgroundColor: "inherit",
                zIndex: 10,
                overflowX:'auto',
                minWidth: "fit-content",
                boxSizing:'border-box',
                '&::-webkit-scrollbar':{
                    display:'none'
                }
            }}>
                {headers.map((item) => (
                    <MDBox key={item} sx={{
                        height:'2.8em',
                        ...vuerSingleUserStyle
                    }}>
                        <MDTypography
                            variant="body2"
                            sx={{
                                fontSize: '0.8em',
                                fontWeight: 'bold',
                                py: 2
                            }}>
                            {item}
                        </MDTypography>
                    </MDBox>
                ))}
            </MDBox>
        </>
    )
}

export default VuerTableHead