import { Tabs, Tab, AppBar } from "@mui/material";
import Icon from "@mui/material/Icon";
import { TabContext } from "App";
import breakpoints from "assets/theme/base/breakpoints";
import { useState, useEffect, useContext } from "react";

export enum ProfilePageTab {
  profile = 0,
  portal = 1,
}

const DashboardTabs: React.FC<{ getSelectedTab: (selectedTab: number) => void; }> = (props) => {
  /// Hooks used for tab orientation
  const [tabsOrientation, setTabsOrientation] = useState<"horizontal" | "vertical">("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const { setTabName } = useContext(TabContext)

  /// A function that sets the orientation state of the tabs.
  useEffect(() => {
    /// Set this because the default tab is Profile
    setTabName("Profile");
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsOrientation]);

  const handleSetTabValue = (event: any, newValue: any) => {
    setTabValue(newValue);
    setTabName(newValue === 0 ? 'Profile' : 'Portals')
    props.getSelectedTab(newValue);
  }

  return (
    <AppBar position="static">
      <Tabs
        orientation={tabsOrientation}
        value={tabValue}
        onChange={handleSetTabValue}>
        <Tab
          label="Profile"
          icon={<Icon fontSize="small">person</Icon>}
          sx={{ px: 2, fontSize: tabValue === 0 ? 14 : 16 }}
        />
        <Tab
          label="Portals"
          icon={<Icon fontSize="small">language</Icon>}
          sx={{ px: 2, fontSize: tabValue === 1 ? 14 : 16 }}
        />
      </Tabs>
    </AppBar>
  );
};

export default DashboardTabs;
