import { FC } from 'react';
import MDBox from 'components/MDBox';
import { VuerRequest, VuerData } from 'types/inter-network';
import { buttonStyle, tableFontStyle, vuerCardLazyStyle, vuerSingleUserStyle } from './ManageInternalVuerNetworkStyle';
import MDTypography from 'components/MDTypography';
import { Button } from '@mui/material';
import colors from 'assets/theme/base/colors';

interface RowProps {
    requester?: VuerRequest,
    vuerData: VuerData,
    handleModal: (data: VuerData | VuerRequest, modalName: string) => void,
    isRequester?: boolean
}

const VuerTableRow: FC<RowProps> = ({ requester, handleModal, vuerData, isRequester }) => {
    return (
        <>
            <MDBox display='flex' alignItems='center' sx={vuerCardLazyStyle}>
                <MDBox sx={vuerSingleUserStyle}>
                    <MDTypography sx={{
                        fontSize: '0.6em',
                        backgroundColor: '#e0e3e8',
                        color: colors.dark.main,
                        px: 2,
                        borderRadius: 2,
                        textTransform: 'capitalize',
                        fontWeight: 'bold',
                        width: 'fit-content'
                    }}>
                        #{vuerData.canonicalId}
                    </MDTypography>
                </MDBox>
                <MDBox sx={vuerSingleUserStyle}>
                    <MDTypography sx={tableFontStyle}>
                        {vuerData.name}
                    </MDTypography>
                </MDBox>
                <MDBox sx={vuerSingleUserStyle} >
                    <MDTypography sx={tableFontStyle}>
                        {vuerData.phoneNumber.formatPhoneNumber()}
                    </MDTypography>
                </MDBox>
                <MDBox sx={vuerSingleUserStyle} >
                    <MDTypography sx={tableFontStyle}>
                        {vuerData.email}
                    </MDTypography>
                </MDBox>
               <MDBox sx={vuerSingleUserStyle} >
                    <MDTypography sx={{
                        fontSize: '0.7em',
                        paddingLeft: 1,
                        color: colors.dark.main,
                        fontWeight:'bold'
                    }}>
                        { !isRequester && vuerData.completedVues}
                    </MDTypography>
                </MDBox>
                <MDBox sx={vuerSingleUserStyle}>
                    <MDBox display='flex' justifyContent={"flex-end"} alignItems='center' gap={1}>
                        {isRequester ? <>
                            <Button
                                onClick={() => handleModal(requester, 'approve')}
                                sx={{
                                    backgroundColor: '#e4f3e5',
                                    ...buttonStyle
                                }} >Approve</Button>
                            <Button
                                onClick={() => handleModal(requester, 'reject')}
                                sx={{
                                    backgroundColor: '#efd2ce',
                                    color: colors.error.main,
                                    ...buttonStyle
                                }} >Deny</Button>
                        </> : <Button
                            onClick={() => handleModal(vuerData, 'remove')}
                            sx={{
                                backgroundColor: '#efd2ce',
                                color: colors.error.main,
                                ...buttonStyle
                            }} >remove</Button>}
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    )
}

export default VuerTableRow