import { Close, Done, Remove } from "@mui/icons-material";
import classes from "./PasswordInfoContent.module.css";
import MDTypography from "components/MDTypography";


const PasswordInfoContent: React.FC<{
  passwordCriteria: StrongPasswordCriteria;
}> = ({ passwordCriteria }) => {
  const passedIcon = <Done color="info" />;
  const failedIcon = <Close sx={{ color: "red" }} />;
  const normalIcon = <Remove color="secondary" />;
  return (
    <div className={classes.panel}>
      <MDTypography className={classes.panelTitle} variant="button" fontWeight="medium" mb="20px">
        Password Requirements:
      </MDTypography>      
      <ul>
        <li>
          <MDTypography variant="button" fontWeight="regular">
            <span className={classes.icon}>{passwordCriteria.hasEightChars ? passedIcon : failedIcon}</span>
            Must be 8 characters long
          </MDTypography>
        </li>
        <li>
            <MDTypography variant="button" fontWeight="regular" className={classes.item}>
              <span className={classes.icon}>{passwordCriteria.satisfiesCritirea ? passedIcon : failedIcon}</span>
              Must contain at least 3 of the 4 characters:
            </MDTypography>
          <ul>
            <li>
              <MDTypography variant="button" fontWeight="regular" className={classes.item}>
              <span className={classes.icon}>{passwordCriteria.containsUppercase ? passedIcon : normalIcon}</span>
                1 Uppercase Letter
              </MDTypography>
            </li>
            <li>
              <MDTypography variant="button" fontWeight="regular" className={classes.item}>
                <span className={classes.icon}>{passwordCriteria.containsLowercase ? passedIcon : normalIcon}</span>
                1 Lowercase Letter
              </MDTypography>
            </li>
            <li>
              <MDTypography variant="button" fontWeight="regular" className={classes.item}>
                <span className={classes.icon}>{passwordCriteria.containsNumber ? passedIcon : normalIcon}</span>
                1 Number
              </MDTypography>
            </li>
            <li>
              <MDTypography variant="button" fontWeight="regular" className={classes.item}>
                <span className={classes.icon}>{passwordCriteria.containsSpecialChars ? passedIcon : normalIcon}{" "}</span>
                1 Special Character
              </MDTypography>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
export default PasswordInfoContent;