import { DialogActions, DialogContent } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useState } from "react";

interface Props {
  message: string;
  onCancelClick: () => void;
  onMakeUrgentClick: () => void;
}
const MakeUrgentDialog = (props: Props) => {

  const [processing, setProcessing] = useState(false);

  return (
    <MDBox px={1} py={1}>
      {/* Title */}
      <DialogContent>
        <MDTypography
          sx={{
            color: "#344767",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {props.message}
        </MDTypography>
      </DialogContent>

      {/* Action buttons */}
      <DialogActions>
        <MDButton
          variant="outlined"
          color="info"
          size="medium"
          sx={{
            borderColor: "secondary.light",
            color: "dark.main",
            fontSize: 14,
            "&:hover": {
              backgroundColor: "white.main",
              borderColor: "secondary.light",
              color: "dark.main",
            },
            "&:focus": { color: "dark.main" },
          }}
          onClick={props.onCancelClick}
          disabled={processing}
        >
          CANCEL
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="medium"
          onClick={()=>{
            setProcessing(true);
            props.onMakeUrgentClick();
          }}
          disabled={processing}
        >
          Make Urgent
        </MDButton>
      </DialogActions>
    </MDBox>
  );
};
export default MakeUrgentDialog;
