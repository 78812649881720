import { Button } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface HeaderProps {
  title: string;
  onClick: () => void;
}

const CustomHeader = ({ title, onClick }: HeaderProps) => {
  return (
    <MDBox display="flex" justifyContent="space-between">
      <MDTypography variant="h5" fontWeight="bold" sx={{ letterSpacing: 0 }}>
        {title}
      </MDTypography>
      <Button
        variant="outlined"
        size="small"
        onClick={onClick}
        sx={{
          borderColor: "success.main",
          bgcolor: "success.main",
          fontSize: 14,
          "&:hover": {
            bgcolor: "success.main",
            borderColor: "success.main",
            color: "white.main",
          },
          "&:focus": { color: "dark.main" },
        }}
      >
        {" "}
        VIEW MORE
      </Button>
    </MDBox>
  );
};

export default CustomHeader;
