import { ClickAwayListener, Popper, Card } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
  onClickAway: (event: MouseEvent | TouchEvent) => void;
  shouldOpen: boolean;
  anchorElement: HTMLButtonElement | HTMLDivElement;
  placement: any;
  id: string;
  children: ReactNode;
}

export const CustomPopper = (props: Props) => {
  return (
    <ClickAwayListener onClickAway={props.onClickAway}>
      <Popper
        open={props.shouldOpen}
        anchorEl={props.anchorElement}
        id={props.id}
        placement={props.placement}
        sx={{
          /// Added this inorder to display popper on the top of NavBar
          /// So gave a z-index greater than the z-index value of NavBar
          zIndex: 1124,
        }}>
        <Card>{props.children}</Card>
      </Popper>
    </ClickAwayListener>
  );
};
