import { FC } from 'react';
import MDBox from 'components/MDBox';
import colors from 'assets/theme/base/colors';
import VuerTableHead from './VuerTableHead';




interface VerTableProps {
    children: React.ReactNode,
    height?: number | string,
    headers?: string[],
}

const VuerTable: FC<VerTableProps> = ({ children, height, headers }) => {

    return (
        <MDBox sx={{
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            backgroundColor: colors.white.main,
            borderRadius: 2,
            overflow: 'auto',
            height: height ? height : 'auto',
            mt: height ? 2 : 4
        }}>
             <VuerTableHead headers={headers} />
            {children}
        </MDBox>
    )
}

export default VuerTable