import { UserInfo } from "pages/profile/utils/ProfileInterfaces";

export enum AddUserPageActionType {
  userDataLoaded = "USER_DATA_LOADED",
  firstNameChanged = "FIRST_NAME_CHANGED",
  lastNameChanged = "LAST_NAME_CHANGED",
  designationChanged = "DESIGNATION_CHANGED",
  emailChanged = "EMAIL_CHANGED",
}

export interface AddUserPayload {
  userDetail: UserInfo;
  contentModified: boolean;
}

export const defaultUserState: AddUserPayload = {
  userDetail: {
    id: "",
    canonicalId: "",
    email: "",
    createdAt: "",
    companyId: "",
    companies: [],
    companyGroups: [],
    valid: true,
    phoneNumber: "",
    profileImgId: "",
    designation: "",
    firstName: "",
    lastName: "",
    twoFormRequired: true,
    marketingEnabled: true,
  },
  contentModified: false, // Form Content modified or not
};

export interface UserInfoUpdateAction {
  type: AddUserPageActionType;
  payload: AddUserPayload;
}

export const addUserPageReducer = (
  state: AddUserPayload,
  action: UserInfoUpdateAction
) => {
  const { type, payload } = action;
  switch (type) {
    case AddUserPageActionType.userDataLoaded:
      return payload;
    case AddUserPageActionType.firstNameChanged:
    case AddUserPageActionType.lastNameChanged:
    case AddUserPageActionType.designationChanged:
    case AddUserPageActionType.emailChanged:
      return {
        ...state,
        userDetail: payload.userDetail,
        contentModified: true,
      };
    default:
      return state;
  }
};
