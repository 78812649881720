import { FC, useEffect, useState } from 'react';
import { VuerRequest, VuerData } from 'types/inter-network';
import { vuerSingleUser } from './services/VuerServices';
import { WebServiceStatus } from "utils/services/AppUrls"
import VuerTableRow from './VuerTableRow';

interface VuerCardProps {
    requester: VuerRequest,
    handleModal: (data: VuerData | VuerRequest, modalName: string) => void
}

const VuerCardLazy: FC<VuerCardProps> = ({ requester, handleModal }) => {
    const [vuerData, setVuerData] = useState<VuerData>();

    useEffect(() => {
        if (requester.requesterId) {
            (async () => {
                const response = await vuerSingleUser(requester.requesterId)
                if (response.status === WebServiceStatus.success) {
                    setVuerData(response.data)
                } else {
                    console.log('error', response);
                }
            })()
        }
    }, [requester])


    return (
        vuerData && <VuerTableRow isRequester={true} requester={requester} vuerData={vuerData} handleModal={handleModal} />
    )
}

export default VuerCardLazy