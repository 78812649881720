import {
    VuerAPIServices,
    WebServiceResponse,
} from "utils/services/AppUrls";
import {
    performGetRequest,
    performPostRequest
} from "utils/services/NetworkHandler";

///////// GET VUER API  ////////////

const getClientVuers = async (id: string, pageSize: number, pageNumber: number): Promise<WebServiceResponse> => {
    const url = `${VuerAPIServices.getVuers}/${id}?pagination.pageSize=${pageSize}&pagination.pageNumber=${pageNumber}`;
    return await performGetRequest(url);
};


///////// GET VUER REQUEST API  ////////////

const getClientVuerRequest = async (id: string): Promise<WebServiceResponse> => {
    const url = `${VuerAPIServices.getVuersRequest}/${id}`;
    return await performGetRequest(url);
};

///////// POST VUER REJECT API  ////////////

const vuerReject = async (id: string): Promise<WebServiceResponse> => {
    const url = `${VuerAPIServices.postVuerReject}`;
    return await performPostRequest(url, { id });
};
///////// POST VUER APPROVE API  ////////////

const vuerApprove = async (id: string): Promise<WebServiceResponse> => {
    const url = `${VuerAPIServices.postVuerApprove}`;
    return await performPostRequest(url, { id });
};
///////// POST VUER REMOVE API  ////////////

const vuerRemove = async (data: object): Promise<WebServiceResponse> => {
    const url = `${VuerAPIServices.postVuerRemove}`;
    return await performPostRequest(url, data);
};
///////// GET SINGLE VUER API  ////////////

const vuerSingleUser = async (id: string): Promise<WebServiceResponse> => {
    const url = `${VuerAPIServices.vuerSingleUser}/${id}`;
    return await performGetRequest(url);
};

export {
    vuerRemove,
    vuerApprove,
    vuerReject,
    getClientVuerRequest,
    getClientVuers,
    vuerSingleUser
}
