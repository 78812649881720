import axios, { AxiosError } from "axios";
import {
  WebServiceResponse,
  WebServiceStatus,
  DashboardAPIServices,
} from "utils/services/AppUrls";
import { getAuthenticationHeader } from "pages/authentication/services/AuthenticationServices";
import { GENERIC_ERROR_MESSAGE } from "../../../../../constants";

export const getVuesForDashboard = async (
  companyIds: string[],
  startTime: string,
  endTime: string
): Promise<WebServiceResponse> => {
  try {
    var url = DashboardAPIServices.getVuesForDashboard + "?companyIds=";
    const allIds = companyIds.join("&companyIds=");
    url += `${allIds}&createdAt.startTime=${startTime}&createdAt.endTime=${endTime}`;
    const response = await axios.get(url, {
      headers: getAuthenticationHeader(),
    });

    const { data, status } = response;
    if (status === 200) {
      if (data) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: responseData.message ?? GENERIC_ERROR_MESSAGE,
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

export const getSummaryFiguresForDashboard = async (
  companyIds: string[],
  startTime: string,
  endTime: string
): Promise<WebServiceResponse> => {
  try {
    var url =
      DashboardAPIServices.getSummaryFiguresForDashboard + "?companyIds=";
    const allIds = companyIds.join("&companyIds=");
    url += `${allIds}&createdAt.startTime=${startTime}&createdAt.endTime=${endTime}`;
    const response = await axios.get(url, {
      headers: getAuthenticationHeader(),
    });

    const { data, status } = response;
    if (status === 200) {
      if (data) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: responseData.message ?? GENERIC_ERROR_MESSAGE,
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

export const getVueHistoryForPortalIds = async (
  companyIds: string[]
): Promise<WebServiceResponse> => {
  try {
    var url = DashboardAPIServices.getVueHistoryByPortalId + "?ids=";
    const allIds = companyIds.join("&ids=");
    url += allIds;
    const response = await axios.get(url, {
      headers: getAuthenticationHeader(),
    });

    const { data, status } = response;
    if (status === 200) {
      if (data) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error:responseData.message ?? GENERIC_ERROR_MESSAGE,
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};
