import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import MDBox from 'components/MDBox';
import { useState } from 'react'
import { useAuth } from 'context/AuthProvider';

const CustomLogoutButton = () => {
    /// Handles the visibility of alert dialog
    const [openDialog, setOpenDialog] = useState(false);
    /// AuthContext
    const { logOut } = useAuth();

    /// Handles the onClick of "Logout" button in the Header Navbar
    const openLogoutPopup = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    /// Handles the onClick of "Logout" button in the Popup
    const handleLogoutButtonClick = () => {
        /// Invoking "logOut" in context
        logOut();
    }

    return (
        <>
            <MDBox>
                <Button
                    variant="outlined"
                    sx={{ textTransform: "none", fontWeight: "500" }}
                    color="inherit"
                    size="small"
                    onClick={openLogoutPopup}>
                    Logout
                </Button>
            </MDBox>
            <Dialog open={openDialog} onClose={null}>
                <DialogTitle sx={{ fontSize: 16 }}>{"Logout"}</DialogTitle>
                <Divider sx={{ backgroundColor: '#DEE2E8', marginY: 0, height: "1pt", borderWidth: 2 }} />
                <DialogContent>
                    <DialogContentText sx={{ color: 'dark.main', fontSize: 14 }}>Are you sure you want to logout?</DialogContentText>
                </DialogContent>
                <MDBox pl={10} pt={2}>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="info"
                            size="small"
                            sx={{
                                borderColor: 'secondary.light',
                                color: 'success.main',
                                "&:hover": { backgroundColor: "white.main", borderColor: 'secondary.light' }
                            }}
                            onClick={handleClose}>CANCEL
                        </Button>
                        <Button
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{
                                color: 'white.main',
                                backgroundColor: "success.main",
                                "&:hover": { backgroundColor: "success.main" }
                            }}
                            onClick={handleLogoutButtonClick}>LOGOUT
                        </Button>
                    </DialogActions>
                </MDBox>
            </Dialog>
        </>
    );
}

export default CustomLogoutButton;


