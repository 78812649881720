/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React TS Helper Functions
// import pxToRem from "assets/theme/functions/pxToRem";

const { transparent } = colors;

// types
type Types = any;

const select: Types = {
  styleOverrides: {
    select: {
      display: "grid",
      alignItems: "center",
      //padding: `0 ${pxToRem(12)} !important`, // Removed padding

      "& .Mui-selected": {
        backgroundColor: transparent.main,
      },
    },

    selectMenu: {
      background: "none",
      height: "none",
      minHeight: "none",
      overflow: "unset",
    },

    icon: {
      // display: "none",
      color: "transparent",
      width: "1.5em",
      height: "1.5em",
      top: `calc(50% - 0.75em)`,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3e%3c/svg%3e\")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: `center center`,
      // backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
    },
  },
};

export default select;
