import DashboardContentLayout from "layouts/DashboardContentLayout";
import SiteListDataGrid from "./components/SiteListDataGrid";

const SiteLists = () => {
  return (
    <DashboardContentLayout needCardBackground={false}>
      <SiteListDataGrid />
    </DashboardContentLayout>
  );
};

export default SiteLists;
