import { DialogContent, DialogActions, IconButton, InputAdornment } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useReducer, useState } from 'react';
import CustomPasswordInputs from 'pages/authentication/components/CustomPasswordInputs';
import { VisibilityOff, Visibility, InfoOutlined } from '@mui/icons-material';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import "utils/helpers/extensions";
import { handleUpdatePassword } from '../services/ProfileServices';
import { WebServiceStatus } from 'utils/services/AppUrls';
import { CustomIndicator } from 'pages/components/CustomIndicator';
import MessageAlert from 'pages/authentication/components/MessageAlert';
import RequiredFieldMarker from 'pages/components/RequiredFieldMarker';

enum PasswordActionType {
    setPassword = "SET_PASSWORD",
    setCurrentPassword = "SET_CURRENT_PASSWORD"
}

interface PasswordAction {
    type: PasswordActionType;
    payload: {
        value1: string;
        value2: string;
    };
}

interface PasswordState {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
    isFormValid: boolean;
    hasError: boolean;
    error: string;
}

const defaultPasswordState = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    isFormValid: false,
    hasError: false,
    error: "",
};

const PasswordReducer = (
    state: PasswordState,
    action: PasswordAction
) => {
    const { type, payload } = action;
    switch (type) {
        case PasswordActionType.setPassword: {
            const newPassword = payload.value1;
            const confirmPassword = payload.value2;
            const isFormValid = state.currentPassword.isNotEmpty() &&
                newPassword.getStrongPasswordStatus().isStrong &&
                confirmPassword === newPassword
            return {
                ...state,
                newPassword: newPassword,
                isFormValid: isFormValid,
                confirmPassword: confirmPassword,
            };
        }
        case PasswordActionType.setCurrentPassword: {
            const currentPassword = payload.value1;
            const isFormValid = currentPassword.isNotEmpty() &&
                state.newPassword.getStrongPasswordStatus().isStrong &&
                state.confirmPassword === state.newPassword;

            return {
                ...state,
                isFormValid: isFormValid,
                currentPassword: currentPassword,
            };
        }
        default:
            return state;
    }
};


export const UpdatePasswordContent: React.FC<{ closeDialog: () => void; }> = (props) => {

    // Handles the password text visibility
    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
    // Handles visibility of current password text
    const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
    const [passwordState, dispatchPasswordAction] = useReducer(PasswordReducer, { ...defaultPasswordState });
    const [error, setError] = useState<string>("Your Passwords do not match");
    /// State to show/hide progress indicator
    const [showLoader, setShowLoader] = useState<boolean>(false);
    /// Sets the error to be shown in message alert
    const [messageAlertError, setMessageAlertError] = useState<string>("");

    /******** METHODS ***********/

    /// Handles the continue button click and validates the inputs
    const handleUpdatePasswordButtonClick = () => {
        if (passwordState.currentPassword.isEmpty()) {
            setError("Please enter your current password");
        } else if (!passwordState.newPassword.getStrongPasswordStatus().isStrong) {
            setError("Please enter a strong password");
        } else if (passwordState.confirmPassword.isEmpty()) {
            setError("Please confirm your password");
        } else if (passwordState.confirmPassword !== passwordState.newPassword) {
            setError("Your Passwords do not match");
        } else {
            updatePassword();
        }
    };

    const updatePassword = async () => {
        setShowLoader(true);
        const updatePasswordParams = {
            oldPassword: passwordState.currentPassword,
            newPassword: passwordState.newPassword
        }
        const response = await handleUpdatePassword(updatePasswordParams);
        if (response.status === WebServiceStatus.success) {
            setShowLoader(false);
            props.closeDialog();
        } else {
            setShowLoader(false);
            setMessageAlertError(response.error);
        }
    }

    /// Getting the password and confirmPassword field values from "CustomPasswordInputs" component and storing it here locally
    const handlePasswordValues = (password: string, confirmPassword: string) => {
        dispatchPasswordAction({
            type: PasswordActionType.setPassword,
            payload: { value1: password, value2: confirmPassword },
        });
    };

    // Handles the change in the current password field
    const handleCurrentPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentPassword = event.target.value;
        dispatchPasswordAction({
            type: PasswordActionType.setCurrentPassword,
            payload: { value1: currentPassword, value2: "" },
        });
    }

    return (
        <MDBox>
            {showLoader && <CustomIndicator />}
            <DialogContent>
                <MDBox mx={2} my={3}>
                    {messageAlertError.isNotEmpty() &&
                        <MessageAlert
                            message={messageAlertError}
                            type="error"
                            icon={<InfoOutlined />} />}

                    <MDBox display={"flex"}>
                        <MDTypography
                            variant="button"
                            fontWeight="bold"
                            sx={{ fontSize: 14 }} >
                            Current Password
                        </MDTypography>
                        <RequiredFieldMarker />
                    </MDBox>

                    <MDInput
                        value={passwordState.currentPassword}
                        type={showCurrentPassword ? "text" : "password"}
                        placeholder="Current Password"
                        fullWidth
                        onChange={handleCurrentPasswordChange}
                        sx={{
                            'input[type=password]': {
                                '::-ms-reveal, ::-ms-clear': {
                                    display: 'none'
                                }
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Toggle password visibility"
                                        edge="end"
                                        onClick={handleClickShowCurrentPassword}>
                                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <MDBox pt={3} />
                    <CustomPasswordInputs
                        passwordTitle='New Password'
                        confirmTitle='Confirm New Password'
                        error={error}
                        onChangePasswordValue={handlePasswordValues}
                    />
                </MDBox>
            </DialogContent>
            <DialogActions>
                <MDButton
                    variant="outlined"
                    color="info"
                    size="medium"
                    sx={{
                        borderColor: 'secondary.light',
                        color: 'dark.main',
                        fontSize: 14,
                        "&:hover": { backgroundColor: "white.main", borderColor: 'secondary.light', color: 'dark.main', }
                    }}
                    onClick={props.closeDialog}>CANCEL
                </MDButton>
                <MDButton
                    variant="gradient"
                    color="info"
                    size="medium"
                    disabled={!passwordState.isFormValid}
                    onClick={handleUpdatePasswordButtonClick}>UPDATE PASSWORD
                </MDButton>
            </DialogActions>
        </MDBox>
    )
}

