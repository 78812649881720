import { Card, Link } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface Props {
  title: string;
  value: string;
  onClick: () => void;
}

function StatisticCard({ title, value, onClick }: Props): JSX.Element {
  return (
    <Card sx={{ flexGrow: "1", cursor: "pointer" }}>
      <Link onClick={onClick}>
        <MDBox p={2}>
          <MDBox lineHeight={1} mb={2}>
            <MDTypography
              variant="caption"
              fontWeight="medium"
              color="text"
              textTransform="capitalize"
              sx={{ fontSize: "14px" }}
            >
              {title}
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography variant="h4" fontWeight="bold" color="dark">
              {value}
            </MDTypography>
          </MDBox>
        </MDBox>
      </Link >
    </Card>
  );
}

export default StatisticCard;
