import { Icon } from "@mdi/react";
import { mdiClose } from "@mdi/js";
import rgba from "assets/theme/functions/rgba";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

interface Props {
  imgPath: string;
  onClose: () => void;
}

export const ImagePopUp = ({ imgPath, onClose }: Props) => {
  return (
    <MDBox
      role="presentation"
      sx={{
        position: "fixed",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        zIndex: "1340",
        display: "flex",
      }}
    >
      <MDBox
        aria-hidden="true"
        className="popUpBackdrop"
        onClick={onClose}
        sx={{
          position: "fixed",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          zIndex: "-1",
          background: rgba("black", 0.55),
        }}
      ></MDBox>
      <MDBox
        sx={{
          // backgroundImage: `url( ${imgPath} )`,
          margin: "auto",
          position: "relative",
          // width: "100%",
          // height: "100%",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "contain"
        }}
      >
        <MDButton
          variant="text"
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "5px",
            right: "5px",
            padding: "4px",
            minWidth: "auto",
            minHeight: "auto",
            color: "black",
            svg: {
              filter: "drop-shadow(0px 0px 2px rgb(0 0 0 / 0.65))",
            },
          }}
        >
          <Icon path={mdiClose} size={1.2} />
        </MDButton>
        <img
          src={imgPath}
          alt=""
          style={{
            width: "auto",
            height: "auto",
            maxWidth: "90vw",
            maxHeight: "92vh",
            boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.85)",
          }}
        />
      </MDBox>
    </MDBox>
  );
};
