import { Libraries } from "@react-google-maps/api";

export const DEFAULT_ZOOM_LEVEL: number = 19;
export const API_KEY = process.env.REACT_APP_GMAP_API_KEY;
export const LIBRARIES: Libraries = ["places"];
/// Columbia, OH, USA
export const DEFAULT_LATITUDE: number = 40.576098834228944;
export const DEFAULT_LONGITUDE: number = -81.50723618219902;

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export enum PERMISSION_STATUS {
  granted = "granted",
  prompt = "prompt",
  denied = "denied",
}
