// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import "utils/helpers/extensions";
// Images
import bgImage from "assets/images/authentication-cover.jpeg";
import { InfoOutlined } from "@mui/icons-material";
import { useState, useReducer } from "react";
import CoverLayout from "layouts/CoverLayout";
import MessageAlert from "../components/MessageAlert";
import CustomPasswordInputs from "../components/CustomPasswordInputs";
import { RoutePath } from "../../../constants";
import { handleChangePassword } from "pages/authentication/services/AuthenticationServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { CustomIndicator } from "pages/components/CustomIndicator";

function ResetPasswordCompletion(): JSX.Element {
  enum PasswordActionType {
    setPassword = "SET_PASSWORD",
  }

  interface PasswordAction {
    type: PasswordActionType;
    payload: {
      value1: string;
      value2: string;
    };
  }

  interface PasswordState {
    password: string;
    confirmPassword: string;
    isFormValid: boolean;
    hasError: boolean;
    error: string;
  }

  const defaultPasswordState = {
    password: "",
    confirmPassword: "",
    isFormValid: false,
    hasError: false,
    error: "",
  };

  const PasswordReducer = (
    state: PasswordState,
    action: PasswordAction
  ) => {
    const { type, payload } = action;
    switch (type) {
      case PasswordActionType.setPassword: {
        const password = payload.value1;
        const confirmPassword = payload.value2;
        const isFormValid =
          password.getStrongPasswordStatus().isStrong &&
          confirmPassword === password;
        return {
          ...state,
          password: password,
          isFormValid: isFormValid,
          confirmPassword: confirmPassword,
        };
      }
      default:
        return state;
    }
  };

  const [passwordState, dispatchPasswordAction] = useReducer(
    PasswordReducer,
    { ...defaultPasswordState }
  );

  const [hasError, setHasError] = useState<boolean>(false);
  const [error, setError] = useState<string>("Your passwords do not match");
  /// State to show/hide progress indicator
  const [showLoader, setShowLoader] = useState<boolean>(false);

  /// Hook used for navigation
  const navigateToSignInPage = useNavigate();
  /// Hook used for fetching the urlParams
  const { email, requestId, verificationCode } = useParams();

  ////// METHODS ///////

  /// Handles the continue button click and validates the inputs
  const handleUpdatePasswordButtonClick = () => {
    if (!passwordState.password.getStrongPasswordStatus().isStrong) {
      setHasError(true);
      setError("Please enter a strong password.");
    } else if (passwordState.confirmPassword.isEmpty()) {
      setHasError(true);
      setError("Please confirm your password.");
    } else if (passwordState.confirmPassword !== passwordState.password) {
      setHasError(true);
      setError("Your passwords do not match");
    } else {
      setHasError(false);
      proceedToChangePassword();
    }
  };

  /// This handles the password change
  const proceedToChangePassword = async () => {
    setShowLoader(true);
    try {
      const setPasswordParameters = {
        email: email.decodeParam(),
        password: passwordState.password,
        requestId: requestId,
        verificationCode: verificationCode,
      }
      const response = await handleChangePassword(setPasswordParameters);
      if (response.status === WebServiceStatus.success) {
        setShowLoader(false);
        navigateToSignInPage(RoutePath.root);
      } else {
        setShowLoader(false);
        setHasError(true);
        const error = response.error ?? "Unable to change your password.";
        setError(error);
      }
    } catch (error) {
      setShowLoader(false);
      setHasError(true);
      const errorMessage = "Unable to change your password.";
      setError(errorMessage);
    }
  }

  /// Getting the password and confirmPassword field values from "CustomPasswordInputs" component and storing it here locally
  const handlePasswordValues = (password: string, confirmPassword: string) => {
    dispatchPasswordAction({
      type: PasswordActionType.setPassword,
      payload: { value1: password, value2: confirmPassword },
    });
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              variant="h5"
              fontWeight="medium"
              color="white"
              mt={1}
              pb={1}
            >
              Create New Password
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Provide a new password for your account
            </MDTypography>
          </MDBox>
          {hasError && (
            <MDBox px={2}>
              <MessageAlert
                message={error}
                type="error"
                icon={<InfoOutlined />}
              />
            </MDBox>
          )}
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <CustomPasswordInputs
                error={error}
                onChangePasswordValue={handlePasswordValues}
              />
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleUpdatePasswordButtonClick}
                  disabled={!passwordState.isFormValid}
                >
                  UPDATE PASSWORD
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text" fontWeight="regular">
                  Already have an account?{" "}
                  <MDTypography
                    component={Link}
                    to={RoutePath.root}
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    </>
  );
}

export default ResetPasswordCompletion;
