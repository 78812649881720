export enum DataGridType {
  vues,
  sites,
  batches,
}

export const getGridNameFromType = (type: DataGridType): string => {
  switch (type) {
    case DataGridType.vues:
      return "vues";
    case DataGridType.sites:
      return "sites";
    case DataGridType.batches:
      return "batches";  
  }
};

export interface GridPreferences {
  columnPixelWidths:any;
  orderedVisibleColumnNames:string[];
  gridName:string;
}
