import { Grid, Divider } from "@mui/material";

const ProfileSectionDivider = () => {
  return (
    <Grid item xs={true}>
      <Divider
        sx={{
          backgroundColor: "#c7ccd0",
          height: "1px",
          opacity: "1",
          backgroundImage: "none !important",
        }}
      />
    </Grid>
  );
};

export default ProfileSectionDivider;
