import { VueBatchDataFromServer, VueBatchGridData } from "../utils/interfaces";
import { VuesBatchPageSummaryState } from "../utils/reducer";
import moment from "moment";

export const generateVuesBatchAPIParamter = (
  clientVuesBatchState: VuesBatchPageSummaryState,
  pageNumber: number,
  pageSize: number
): string => {
  var parameterString = `pageSize=${pageSize}&pageNumber=${pageNumber}`;
  // Appending startDate and endDate
  if (
    clientVuesBatchState.startDate?.isNotEmpty() &&
    clientVuesBatchState.endDate?.isNotEmpty()
  ) {
    parameterString += `&createdAtRange.startTime=${clientVuesBatchState.startDate}`;
    parameterString += `&createdAtRange.endTime=${clientVuesBatchState.endDate}`;
  }

  // Appending batchName
  if (clientVuesBatchState.batchName.isNotEmpty()) {
    parameterString += `&batchName=${clientVuesBatchState.batchName.trim()}`;
  }

  // Appending searchText
  if (clientVuesBatchState.searchText.isNotEmpty()) {
    parameterString += `&NameQueryString=${clientVuesBatchState.searchText.trim()}`;
  }

  /// Appending batchID
  if (clientVuesBatchState.canonicalId.isNotEmpty()) {
    parameterString += `&canonicalId=${clientVuesBatchState.canonicalId.trim()}`;
  }

  // Appending clientId
  if (clientVuesBatchState.clientIds.length > 0) {
    const companyIdsList = clientVuesBatchState.clientIds.join("&clientIds=");
    parameterString += `&clientIds=${companyIdsList}`;
  }

  // Appending SurveyName
  if (clientVuesBatchState.surveyName.isNotEmpty()) {
    parameterString += `&surveyName=${clientVuesBatchState.surveyName.trim()}`;
  }
  // Appending minTotalVueCount
  if (clientVuesBatchState.minTotalVueCount.isNotEmpty()) {
    const minCount = parseInt(clientVuesBatchState.minTotalVueCount.trim());
    parameterString += `&totalVueCount.startCount=${minCount}`;
  }
  // Appending maxTotalVueCount
  if (clientVuesBatchState.maxTotalVueCount.isNotEmpty()) {
    const maxCount = parseInt(clientVuesBatchState.maxTotalVueCount.trim());
    parameterString += `&totalVueCount.endCount=${maxCount}`;
  }
  // Appending maxCompletedVueCount
  if (clientVuesBatchState.minCompletedVueCount.isNotEmpty()) {
    const minCount = parseInt(clientVuesBatchState.minCompletedVueCount.trim());
    parameterString += `&completedVueCount.startCount=${minCount}`;
  }
  // Appending maxCompletedVueCount
  if (clientVuesBatchState.maxCompletedVueCount.isNotEmpty()) {
    const maxCount = parseInt(clientVuesBatchState.maxCompletedVueCount.trim());
    parameterString += `&completedVueCount.endCount=${maxCount}`;
  }
  // Appending sortColumnDisplayName
  if (clientVuesBatchState.sortColumnDisplayName.isNotEmpty()) {
    parameterString += `&sortColumnDisplayName=${clientVuesBatchState.sortColumnDisplayName}`;
  }
  // Appending sortIsAscending
  if (
    clientVuesBatchState.sortIsAscending !== null &&
    clientVuesBatchState.sortIsAscending !== undefined
  ) {
    parameterString += `&sortIsAscending=${clientVuesBatchState.sortIsAscending}`;
  }

  return parameterString;
};

export const getFileNameInUTC = () => {
  const currentDateTime = moment.utc();
  const formattedDateTime = currentDateTime.format(
    "YYYY-MM-DD_HH_mm_ss.SSSSSSSSS_Z"
  );
  return formattedDateTime;
};

export const getGridDataForVueBatch = (
  vueBatch: VueBatchDataFromServer
): VueBatchGridData => {
  return {
    batchid: vueBatch.canonicalId,
    batchcreationdate:
      vueBatch.createdAt.formatUTCNanoSecondsToString("MM/DD/YYYY"),
    batchname: vueBatch.batchName,
    totalvues: vueBatch.totalVueCount,
    completedvues: vueBatch.completedVueCount,
    surveyname: vueBatch.surveyTemplateName,
    requirements: vueBatch.clientName,
    id: vueBatch.id,
    surveyTemplateId: vueBatch.surveyTemplate,
  };
};

/// This returns the previous value searched by the user in all poppers with text fields
export const getPreviouslySearchedText = (
  clientVuesState: VuesBatchPageSummaryState,
  columnName: string
) => {
  let previouslySearchedText = "";
  switch (columnName) {
    case "batchid":
      previouslySearchedText = clientVuesState.canonicalId;
      break;
    case "batchname":
      previouslySearchedText = clientVuesState.batchName;
      break;
    case "surveyname":
      previouslySearchedText = clientVuesState.surveyName;
      break;
    default:
      break;
  }
  return previouslySearchedText;
};
