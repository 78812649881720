import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";

interface LabelProps {
  title: string;
  description: string;
}

interface Props {
  title?: string;
  radioList: LabelProps[];
  selectedValue: number;
  onSelect: (selectedIndex: number) => void;
}

const RadioButtonListWithTitle = (props: Props) => {
  const [selectedItem, setSelectedItem] = useState(props.selectedValue);

  return (
    <>
      <MDBox mb={1}>
        <MDTypography variant="button" sx={{ fontWeight: "500" }}>
          {" "}
          {props.title}
        </MDTypography>
      </MDBox>
      <FormControl sx={{ paddingLeft: "32px" }}>
        <RadioGroup aria-labelledby="" name="SelectSpreadsheetFormat:">
          {props.radioList.map((option, index) => (
            <FormControlLabel
              key={index}
              value={index}
              control={<Radio />}
              sx={{
                display: "flex !important",
                marginBottom: "8px",
                ".MuiFormControlLabel-label": {
                  fontWeight: "400",
                  lineHeight: "1.2rem",
                },
                ".MuiRadio-root": {
                  padding: "0 !important",
                  marginRight: "10px",
                },
              }}
              label={
                <span>
                  <span>
                    <b>{option.title}</b>
                  </span>
                  <span>{option.description}</span>
                </span>
              }
              checked={selectedItem === index}
              onChange={(event: React.SyntheticEvent<Element, Event>) => {
                setSelectedItem(index);
                props.onSelect(index);
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default RadioButtonListWithTitle;
