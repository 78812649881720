import {
  mdiFileExport,
  mdiRepeatVariant,
  mdiInformation,
  mdiCloseCircle,
} from "@mdi/js";
import { Grid, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import ButtonWithIcon from "pages/components/ButtonWithIcon";
import { styled } from "@mui/material/styles";

/// Styles applied to the "ButtonWithIcon" explicitly for the actionButtons in the topBar
export const StyledButtonWithIcon = styled(ButtonWithIcon)(({ theme }) => ({
  paddingLeft: "16px",
  paddingRight: "16px",
  "&:disabled": {
    color: "#C7CCD0",
    borderColor: "#C7CCD0",
  },
  svg: {
    width: "18px !important",
    height: "18px !important",
  },
}));

export enum ActionButtonType {
  export,
  reissue,
  makeUrgent,
  cancel,
}

interface Props {
  onButtonClick: (type: ActionButtonType) => void;
  shouldDisableReissueButton?: boolean;
  shouldDisableCancelButton?: boolean;
  shouldDisableMakeUrgentButton: boolean;
}

/// Custom styles applied to Tooltip component
export const tooltipStyles = {
  bgcolor: "dark.main",
  borderRadius: "2px",
  fontSize: "12px",
  padding: "5px 8px",
  textAlign: "left",
  fontWeight: "300",
  lineHeight: "16px",
  "& .MuiTooltip-arrow": {
    color: "dark.main",
  },
};

export const VueActionBar = ({
  onButtonClick,
  shouldDisableReissueButton,
  shouldDisableCancelButton,
  shouldDisableMakeUrgentButton
}: Props) => {
  return (
    <MDBox display="flex" justifyContent="space-between">
      <Grid container spacing={1}>
        {/* Export Popup */}
        <Grid item>
          <StyledButtonWithIcon
            iconPath={mdiFileExport}
            onClick={() => {
              onButtonClick(ActionButtonType.export);
            }}
          >
            Export
          </StyledButtonWithIcon>
        </Grid>
        {/* Reissue Popup */}
        <Grid item>
          <Tooltip
            title="One or more of the selected Vues are not in the Completed status"
            arrow
            disableInteractive
            placement="right"
            componentsProps={{
              tooltip: {
                sx: tooltipStyles,
              },
            }}
            disableHoverListener={!shouldDisableReissueButton}
          >
            <span>
              <StyledButtonWithIcon
                iconPath={mdiRepeatVariant}
                onClick={() => {
                  onButtonClick(ActionButtonType.reissue);
                }}
                disabled={shouldDisableReissueButton}
              >
                Reissue
              </StyledButtonWithIcon>
            </span>
          </Tooltip>
        </Grid>
        {/* Make Urgent Popup */}
        <Grid
          item
          sx={{
            svg: {
              transform: "rotate(180deg)",
            },
          }}
        >
          <Tooltip
            title="One or more of the selected Vues are not in the Open status."
            arrow
            placement="right"
            componentsProps={{
              tooltip: {
                sx: tooltipStyles,
              },
            }}
            disableHoverListener={!shouldDisableMakeUrgentButton}
          >
            <span>
              <StyledButtonWithIcon
                iconPath={mdiInformation}
                onClick={() => {
                  onButtonClick(ActionButtonType.makeUrgent);
                }}
                disabled={shouldDisableMakeUrgentButton}
              >
                Make Urgent
              </StyledButtonWithIcon>
            </span>
          </Tooltip>
        </Grid>
        {/* Cancel Vues Popup */}
        <Grid item>
          <Tooltip
            title="One or more of the selected Vues are not in the Open or In progress status"
            placement="right"
            disableHoverListener={!shouldDisableCancelButton}
            componentsProps={{
              tooltip: {
                sx: tooltipStyles,
              },
            }}
            arrow
          >
            <span>
              <StyledButtonWithIcon
                iconPath={mdiCloseCircle}
                onClick={() => {
                  onButtonClick(ActionButtonType.cancel);
                }}
                disabled={shouldDisableCancelButton}
              >
                Cancel
              </StyledButtonWithIcon>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </MDBox>
  );
};
