import {
  VueBatchAPIServices,
  WebServiceResponse,
} from "utils/services/AppUrls";
import { performGetRequest, performPostRequest } from "utils/services/NetworkHandler";

///////// VUE BATCH APIS  ////////////

export const getClientVuesBatch = async (
  parameters: string
): Promise<WebServiceResponse> => {
  const url = VueBatchAPIServices.getVueBatches + "?" + parameters;
  return await performGetRequest(url);
};

export const exportBatch = async (data: object): Promise<WebServiceResponse> => {
  const url = `${VueBatchAPIServices.exportVueBatches}`;
  return await performPostRequest(url, data);
};
