import colors from "assets/theme/base/colors"

export const gridStyle = {
    border: "0",
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
    {
        display: "none",
    },
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    ".MuiDataGrid-pinnedColumns--left, .MuiDataGrid-pinnedColumnHeaders--left": {
        backgroundColor: "white !important",
        borderRight: "1px solid #A8B8D8",
        boxShadow: "none !important"
    },
    ".MuiDataGrid-withBorderColor": {
        borderColor: "#E9EAED",
    },
    ".MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor": {
        borderBottomColor: "#A8B8D8",
    },
    ".MuiDataGrid-columnHeaderTitle, .MuiDataGrid-columnHeaderTitleContainerContent": {
        lineHeight: "46px",
        fontSize: "14px",
        color: "dark.main"
    },
    ".MuiDataGrid-columnHeaders": {
        minHeight: "46px !important",
        maxHeight: "46px !important",
        lineHeight: "46px !important",
        borderTopLeftRadius: "15px"
    },
    ".MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader": {
        height: "46px !important"
    },
    ".MuiDataGrid-cell .MuiTypography-root.MuiTypography-button": {
        backgroundImage: "none"
    },
    ".MuiDataGrid-cell .MuiDataGrid-cellContent": {
        fontSize: "14px",
        color: "dark.main",
        fontWeight: "400"
    },
    ".MuiDataGrid-row": {
        "&.Mui-selected": {
            background: "transparent"
        }
    },
    ".MuiDataGrid-virtualScroller": {
        "*": {
            scrollbarWidth: "thin",
            scrollbarColor: "#C7CCD0 auto"
        },
        "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px"
        },
        "&::-webkit-scrollbar-track": {
            background: "transparent",
            width: "10px"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#C7CCD0",
            with: "8px",
            borderRadius: "5px",
            minHeight: "100px"
        }
    },
    ".MuiDataGrid-iconButtonContainer": {
        width: "auto",
        ".MuiIconButton-root": {
            padding: "0",
            "svg": {
                marginTop: "4px",
                with: "1.45rem !important",
                height: "1.45rem !important"
            }
        }
    },
    ".MuiIconButton-root": {
        visibility: "visible",
        color: "#6E7491 !important"
    },
    ".MuiDataGrid-columnHeaderTitleContainerContent": {
        display: "flex",
        alignItems: "center",
        width: "100%",
        ".MuiIconButton-root": {
            padding: "0",
        }
    },
    ".MuiDataGrid-columnHeader": {
        "&:hover": {
            background: "#E9EAED"
        }
    }
}

export const checkBoxStyle = {
    marginRight: "21px",
    ".MuiFormControlLabel-label": {
        fontWeight: "normal",
        color: colors.inputBorderColorDark,
        fontSize: "12px",
        marginLeft: 0,
        verticalAlign: "middle",
    },
}

export const searchBarStyle = {
    maxWidth: "300px",
    width: "100%",
    input: {
        paddingTop: "10px",
        paddingBottom: "10px",
        "::placeholder": {
            color: "#6E7491",
            opacity: 1,
        },
    },
}

export const yesChipStyle = {
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "bold",
    bgcolor: "#EFD1CE",
    color: "error.main",
    height: "auto",
    lineHeight: "20px",
    paddingTop: "4px",
    paddingBottom: "4px",
}

export const noChipStyle = {
    fontSize: "14px",
    borderRadius: "4px",
    fontWeight: "regular",
    bgcolor: "transparent.main",
    color: "dark.main",
}

export const linkElementStyle = {
    textDecoration: "underline !important",
    fontSize: "14px",
    textFillColor: "unset",
    "&:link, &:visited": {
        textDecoration: "underline !important",
    },
    "&:hover": {
        textDecoration: "underline !important",
    },
}

export const datePickerStyle = {
    ".MuiDateCalendar-root": {
        maxWidth: "256px"
    },
    ".MuiDateCalendar-viewTransitionContainer": {
        padding: `0 16px`
    },
    ".MuiPickersDay-root": {
        fontWeight: "500 !important",
        fontSize: "14px !important",
        margin: "0 !important",
        width: "32px !important",
        height: "32px !important",
    },
    ".MuiPickersDay-root:not(.Mui-disabled)": {
        color: "#191919 !important",
        "&.Mui-selected": {
            backgroundColor: "#4caf50 !important",
            color: "white !important"
        }
    },
    ".MuiPickersCalendarHeader-label": {
        fontSize: "14px !important",
        fontWeight: "600 !important",
        color: "#344767 !important",
        marginRight: "0 !important"
    },
    ".MuiDayCalendar-weekDayLabel": {
        color: "#344767 !important",
        fontWeight: "normal !important"
    },
    ".MuiDayCalendar-slideTransition": {
        minHeight: "206px !important",
        height: "auto !important"
    },
    ".MuiDialogActions-root": {
        "&.MuiPickersLayout-actionBar": {
            paddingTop: "8px",
            paddingBottom: "8px",
            "& .MuiButton-text": {
                "&.MuiButton-textPrimary": {
                    border: "1px solid #c7ccff !important",
                    color: "#344767 !important"
                }
            }
        }
    },
    ".MuiYearCalendar-root": {
        width: "100% !important",
        maxHeight: "174px !important",
        "& .MuiPickersYear-yearButton": {
            fontSize: "14px !important",
            height: "24px !important",
            "&.Mui-selected": {
                backgroundColor: "#4caf50 !important",
            }
        }
    }
}

export const timePickerStyle = {
    ".MuiDialogActions-root": {
        "&.MuiPickersLayout-actionBar": {
            paddingTop: "8px",
            paddingBottom: "8px",
            "& .MuiButton-text": {
                "&.MuiButton-textPrimary": {
                    border: "1px solid #c7ccff !important",
                    color: "#344767 !important"
                }
            }
        }
    },
    ".MuiMultiSectionDigitalClock-root.MuiList-root": {
        width: "62px",
        paddingRight: "7px",
        paddingLeft: "7px",
        ".MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters": {
            minWidth: "unset !important",
            color: "#344767 !important",
            margin: "2px 0px",
            "&.Mui-selected": {
                color: "#ffffff !important",
                background: "#63b967 !important",
                "&:hover": {
                    background: "#4CAF50 !important"
                }
            }
        },
        "&::-webkit-scrollbar": {
            width: "5px",
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1" 
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#ccc",
            borderRadius: "2px",
            "&:hover": {
                background: "#aaa" 
            }
        }
    }
}