import { Grid, Link, Switch } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import DashboardTabs, {
  ProfilePageTab,
} from "pages/dashboard/home/dashboard-home/components/DashboardTabs";
import MDButton from "components/MDButton";
import { memo, useEffect, useReducer, useState } from "react";
import CustomDialogBox from "pages/components/CustomDialogBox";
import EditProfileContent from "./components/EditProfileContent";
import ContactInfo from "./components/ContactInfo";
import iVueIcon from "assets/images/ivueit_icon.png";
import instaIcon from "assets/images/instagram.svg";
import linkedinIcon from "assets/images/linkedin.svg";
import twitterIcon from "assets/images/twitter.svg";
import Portal from "pages/portal/Portal";
import { mdiAccountEdit } from "@mdi/js";
import ButtonWithIcon from "pages/components/ButtonWithIcon";
import { WebServiceStatus } from "utils/services/AppUrls";
import { downloadFile, updateProfileInfo } from "./services/ProfileServices";
import { ProfileInfo, UserInfo, ProfileKeys } from "./utils/ProfileInterfaces";
import { useAuth } from "context/AuthProvider";
import {
  UserDetailReducer,
  defaultUserDetailState,
  UserDetailsActionType,
} from "./utils/reducer";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { UpdatePasswordContent } from "./components/UpdatePasswordContent";
import TitleSection from "./components/TitleSection";
import userPlaceholder from "assets/images/user_filler.svg";

interface AccountManager {
  accountManager: string;
  accountManagerEmail: string;
  accountManagerPhoneNumber: string;
}

const defaultAccountManagerData: AccountManager = {
  accountManager: "",
  accountManagerEmail: "",
  accountManagerPhoneNumber: "",
};

const Profile = () => {
  /// Handles the visibility of "Edit Profile" alert dialog
  const [openEditProfileDialog, setOpenEditProfileDialog] = useState(false);
  /// Handles the visibility of "Edit Contact Info" alert dialog
  const [openContactInfoDialog, setOpenContactInfoDialog] = useState(false);
  /// Handles the visibility of "Update Password" alert dialog
  const [openUpdatePasswordDialog, setOpenUpdatePasswordDialog] =
    useState(false);
  /// This defines the selected tab
  const [selectedTab, setSelectedTab] = useState<ProfilePageTab>(
    ProfilePageTab.profile
  );
  const [userDetailState, dispatchUserdetailAction] = useReducer(
    UserDetailReducer,
    { ...defaultUserDetailState }
  );
  /// This handles the profileUrl
  const [profileUrl, setProfileUrl] = useState<string>(
    userDetailState.profileUrl
  );
  /// Fetching user details from local storage
  const {
    userData,
    storeUserData,
  }: { storeUserData: Function; userData: UserInfo } = useAuth();
  /// Handles the toggleability of "Send Update" switch
  const [sendUpdates, setSendUpdates] = useState<boolean>(false);
  /// State to show/hide progress indicator
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [accountManager, setAccountManager] = useState<AccountManager>(
    defaultAccountManagerData
  );

  /// Downloading profile picture
  useEffect(() => {
    const downloadProfilePic = async () => {
      const hasImageId =
        userData !== null &&
        userData.profileImgId != null &&
        userData.profileImgId.isNotEmpty();
      if (hasImageId) {
        const imageData = await downloadFile(userData.profileImgId);
        if (imageData) {
          const { mimeType, data } = imageData;
          const url = `data:${mimeType};base64,${data}`;
          setProfileUrl(url);
        } else {
          setProfileUrl(null);
        }
      } else {
        setProfileUrl(null);
      }
    };
    downloadProfilePic();
  }, [userData, userDetailState.profileUrl]);

  useEffect(() => {
    if (userData && userData.companies.length > 0) {
      const myCompany = userData.companies[0];
      setAccountManager({
        accountManager: myCompany.accountManager,
        accountManagerEmail: myCompany.accountManagerEmail,
        accountManagerPhoneNumber: myCompany.accountManagerPhoneNumber,
      });
    }
  }, [userData]);

  /// Fetching org user details from local storage
  useEffect(() => {
    const fetchLocallyStoredUser = async () => {
      if (userData !== null && userData !== undefined) {
        setSendUpdates(userData.marketingEnabled);
        dispatchUserdetailAction({
          type: UserDetailsActionType.userDetails,
          payload: {
            firstName: userData.firstName,
            lastName: userData.lastName,
            designation: userData.designation,
            profileUrl: profileUrl,
            profileImageID: userData.profileImgId,
            emailId: userData.email,
            phoneNumber: userData.phoneNumber,
            isMarketingEnabled: userData.marketingEnabled,
          },
        });
      }
    };
    fetchLocallyStoredUser();
  }, [profileUrl, userData]);

  /******** METHODS ***********/

  /// Updating marketing preferences
  const handleSendUpdates = async () => {
    setShowLoader(true);
    const updatedSendUpdates = !sendUpdates;
    const preference: ProfileInfo = {
      [ProfileKeys.isMarketingEnabled]: updatedSendUpdates,
    };
    const response = await updateProfileInfo(preference, userData.id);
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      const responseData = response.data as UserInfo;
      const isMarketingPreferenceEnabled = responseData.marketingEnabled;
      storeUserData({
        ...userData,
        marketingEnabled: isMarketingPreferenceEnabled,
      });
      setSendUpdates(isMarketingPreferenceEnabled);
    } else {
      setShowLoader(false);
      storeUserData({ ...userData });
    }
  };

  /// Handles the onClick of "Edit Contact" button
  const onClickEditContactInfo = () => {
    setOpenContactInfoDialog(true);
  };

  /// Handles the onClick of "Cancel" button in "Edit Contact" dialog box
  const handleCloseContactDialog = () => {
    setOpenContactInfoDialog(false);
  };

  /// Handles the onClick of "Update Password" button
  const onClickUpdatePassword = () => {
    setOpenUpdatePasswordDialog(true);
  };

  /// Handles the onClick of "Cancel" button in "Update Password" dialog box
  const handleCloseUpdatePasswordDialog = () => {
    setOpenUpdatePasswordDialog(false);
  };

  /// Handles the onClick of "Edit Profile" button
  const onClickEditProfile = () => {
    setOpenEditProfileDialog(true);
  };

  /// Handles the onClick of "Cancel" button in "Edit Profile" dialog box
  const handleCloseProfileDialog = () => {
    setOpenEditProfileDialog(false);
  };

  /// This handles the profile updated by the user using EditProfile option
  const getUserDetails = (
    firstName: string,
    lastName: string,
    designation: string,
    profileUrl: string,
    profileImageID: string
  ) => {
    dispatchUserdetailAction({
      type: UserDetailsActionType.editProfile,
      payload: {
        firstName: firstName,
        lastName: lastName,
        designation: designation,
        profileUrl: profileUrl,
        profileImageID: profileImageID,
        emailId: "",
        phoneNumber: "",
        isMarketingEnabled: userDetailState.isMarketingEnabled,
      },
    });
  };

  /// This handles the contact updated by the user using EditContact option
  const onSaveContactInfo = (phoneNumber: string) => {
    dispatchUserdetailAction({
      type: UserDetailsActionType.editContact,
      payload: {
        firstName: "",
        lastName: "",
        designation: "",
        profileImageID: "",
        profileUrl: null,
        emailId: userDetailState.emailId,
        phoneNumber: phoneNumber,
        isMarketingEnabled: userDetailState.isMarketingEnabled,
      },
    });
  };

  /// We can get the currently selected tab (Profile / Portal)
  const getSelectedTab = (newTabValue: ProfilePageTab) => {
    setSelectedTab(newTabValue);
  };

  const getEditContactButton = () => {
    return (
      <Grid item>
        <MDBox mt={0.5}>
          <ButtonWithIcon
            iconPath={mdiAccountEdit}
            onClick={onClickEditContactInfo}
          >
            Edit
          </ButtonWithIcon>
          <CustomDialogBox
            title={"Edit Contact Information"}
            children={
              <ContactInfo
                emailId={userDetailState.emailId}
                phoneNumber={userDetailState.phoneNumber}
                cancelButtonClick={handleCloseContactDialog}
                onSaveContactInfo={onSaveContactInfo}
              />
            }
            openDialog={openContactInfoDialog}
          />
        </MDBox>
      </Grid>
    );
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <DashboardContentLayout>
        {/* Profile image section */}
        <MDBox item xs={12} mt={3} md={6} lg={4} sx={{ ml: "auto" }} mr="30px">
          <DashboardTabs getSelectedTab={getSelectedTab} />
        </MDBox>
        <Grid
          container
          spacing={3}
          alignItems="center"
          mb={5}
          mt={-8}
          pl="16px"
        >
          {selectedTab === ProfilePageTab.profile && (
            <>
              <Grid item>
                <MDBox
                  sx={{
                    width: "74px",
                    height: "74px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {userData.profileImgId ? (
                    <MDAvatar
                      src={profileUrl ?? userPlaceholder}
                      alt="Profile Image"
                      size="xl"
                      shadow="sm"
                    />
                  ) : (
                    <MDAvatar
                      src={userPlaceholder}
                      alt="Profile Image"
                      size="xl"
                      shadow="sm"
                    />
                  )}
                </MDBox>
              </Grid>
              <Grid item>
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {userDetailState.firstName + " " + userDetailState.lastName}
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    color="dark"
                    fontWeight="regular"
                  >
                    {userDetailState.designation}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item>
                {/* Edit Profile Button */}
                <MDBox mt={-3}>
                  <ButtonWithIcon
                    iconPath={mdiAccountEdit}
                    onClick={onClickEditProfile}
                  >
                    Edit
                  </ButtonWithIcon>
                  <CustomDialogBox
                    title={"Edit Profile Information"}
                    children={
                      <EditProfileContent
                        firstName={userDetailState.firstName}
                        lastName={userDetailState.lastName}
                        designation={userDetailState.designation}
                        profileUrl={userDetailState.profileUrl}
                        profileImageID={userData.profileImgId}
                        onSaveProfileDetails={getUserDetails}
                        onCancelButtonClick={handleCloseProfileDialog}
                      />
                    }
                    openDialog={openEditProfileDialog}
                  />
                </MDBox>
              </Grid>
            </>
          )}
        </Grid>
        {selectedTab === ProfilePageTab.profile && (
          <MDBox mx={2.6}>
            {/* Contact info Header */}
            <TitleSection title="Contact Information">
              {getEditContactButton()}
            </TitleSection>
            {/* Contact info content */}
            <Grid container mb={4}>
              <Grid item>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="medium"
                      display="block"
                      mb={0.5}
                    >
                      Phone Number
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      color="dark"
                      fontWeight="regular"
                      display="block"
                    >
                      {userDetailState.phoneNumber.formatPhoneNumber()}
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={3}>
                    <MDTypography
                      variant="button"
                      fontWeight="medium"
                      display="block"
                      mb={0.5}
                    >
                      Email
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      color="dark"
                      fontWeight="regular"
                      display="block"
                    >
                      {userDetailState.emailId}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox mt={3}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={onClickUpdatePassword}
                  >
                    UPDATE PASSWORD
                  </MDButton>
                  <CustomDialogBox
                    title={"Update Password"}
                    children={
                      <UpdatePasswordContent
                        closeDialog={handleCloseUpdatePasswordDialog}
                      />
                    }
                    openDialog={openUpdatePasswordDialog}
                  />
                </MDBox>
              </Grid>
            </Grid>
            {/* Account Manager header*/}
            <TitleSection title="Account Manager" />
            {/* Account Manager content */}
            <Grid container>
              <Grid item mb={1}>
                <MDBox>
                  <MDBox mb={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="medium"
                      display="block"
                      mb={0.5}
                    >
                      Full Name
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      color="dark"
                      fontWeight="regular"
                      display="block"
                    >
                      {accountManager.accountManager.trim().isEmpty()
                        ? "Not Available"
                        : accountManager.accountManager}
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="medium"
                      display="block"
                      mb={0.5}
                    >
                      Phone Number
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      color="dark"
                      fontWeight="regular"
                      display="block"
                    >
                      {accountManager.accountManagerPhoneNumber.trim().isEmpty()
                        ? "Not Available"
                        : accountManager.accountManagerPhoneNumber.formatPhoneNumber()}
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="medium"
                      display="block"
                      mb={0.5}
                    >
                      Email
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      color="dark"
                      fontWeight="regular"
                      display="block"
                    >
                      {accountManager.accountManagerEmail.trim().isEmpty()
                        ? "Not Available"
                        : accountManager.accountManagerEmail}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
            {/* Communication Preferences section */}
            <MDBox mb={6}>
              <TitleSection title="Communication Preferences" />
              <MDTypography
                variant="button"
                fontWeight="medium"
                display="block"
                mb={0.5}
              >
                Would you like to receive marketing and product updates?
              </MDTypography>
              <MDBox display="flex" alignItems="center" mb={1}>
                <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    No
                  </MDTypography>
                  <Switch
                    checked={sendUpdates}
                    onChange={handleSendUpdates}
                    color="info"
                  />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="dark"
                >
                  Yes
                </MDTypography>
              </MDBox>
              <MDBox mb={1}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="dark"
                >
                  Follow us on social media
                </MDTypography>
              </MDBox>
              <Grid container columnSpacing={2}>
                    <Grid item>
                      <Link
                        key="ivue-site"
                        href="https://ivueit.com"
                        target="_blank"
                        rel="noreferrer"
                        sx={{ textDecoration: "none" }}
                      >
                        <img
                          src={iVueIcon}
                          alt="ivue"
                          height="29pt"
                          width="29pt"
                        />
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        key="ivue-linkedin"
                        href="https://www.linkedin.com/company/ivueit"
                        target="_blank"
                        rel="noreferrer"
                        sx={{ textDecoration: "none" }}
                      >
                        <img
                          src={linkedinIcon}
                          alt="ivue"
                          height="29pt"
                          width="29pt"
                        />
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        key="ivue-twitter"
                        href="https://twitter.com/ivueitapp"
                        target="_blank"
                        rel="noreferrer"
                        sx={{ textDecoration: "none" }}
                      >
                        <img
                          src={twitterIcon}
                          alt="ivue"
                          height="29pt"
                          width="29pt"
                        />
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        key="ivue-instagram"
                        href="https://www.instagram.com/ivueit/"
                        target="_blank"
                        rel="noreferrer"
                        sx={{ textDecoration: "none" }}
                      >
                        <img
                          src={instaIcon}
                          alt="ivue"
                          height="29pt"
                          width="29pt"
                        />
                      </Link>
                    </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        )}
        {selectedTab === ProfilePageTab.portal && <Portal />}
      </DashboardContentLayout>
    </>
  );
};

export default memo(Profile);
