import { Search } from "@mui/icons-material";
import { mdiFilterOff, mdiDelete } from "@mdi/js";
import { Divider, InputAdornment, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ButtonWithIcon from "pages/components/ButtonWithIcon";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { ModalPopUp } from "pages/components/ModalPopUp";
import { SyntheticEvent, useEffect, useState } from "react";
import { TEXTFIELD_CHANGE_DELAY } from "./../../../../../../constants";

const FilterByTextContent: React.FC<{
  searchedText: string;
  columnName: string;
  onTextChange: (text: string) => void;
  closePopper: (event: Event | React.SyntheticEvent<Element, Event>) => void;
  removeColumn: () => void;
  shouldShowRemoveColumnButton: boolean;
}> = (props) => {
  /// Holds the state of search field
  const [searchInput, setSearchInput] = useState<string>(props.searchedText);
  /// State to track the export confirmation dialog
  const [openConfirmationDialog, setOpenConfirmation] =
    useState<boolean>(false);

  // Handling the text search for the grid
  useEffect(() => {
    /// The method delays the callback for 700 millseconds
    /// It helps us to avoid frequent api calls & state updates, on text change
    const delaySearchAction = setTimeout(() => {
      props.onTextChange(searchInput.trim());
    }, TEXTFIELD_CHANGE_DELAY);
    return () => clearTimeout(delaySearchAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  /// Opens confirmation dialog
  const openConfirmation = () => {
    setOpenConfirmation(true);
  };

  /// Closes confirmation dialog
  const closeConfirmation = () => {
    setOpenConfirmation(false);
  };

  /// Handles the onClick of "YES, REMOVE" button
  const removeColumn = (event: Event | SyntheticEvent<Element, Event>) => {
    closeConfirmation();
    props.removeColumn();
    props.closePopper(event);
  };

  /// Handles the onClick of "Cancel" button in the dialog
  const handleCancelClick = (event: Event | SyntheticEvent<Element, Event>) => {
    closeConfirmation();
    props.closePopper(event);
  };

  // Generates actions for confirmation box
  const confirmationDialogActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={handleCancelClick}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={removeColumn}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        Yes, Remove
      </MDButton>,
    ];
  };

  /// Gets the confirmation dialog
  const getConfirmationDialog = () => {
    return (
      <CustomDialogBox
        title={`Remove ${props.columnName}`}
        width="454px"
        height="200px"
        openDialog={openConfirmationDialog}
      >
        <ModalPopUp
          content={`Are you sure you want to remove the ${props.columnName} column from the table?`}
          closeDialog={closeConfirmation}
          actions={confirmationDialogActions()}
        />
      </CustomDialogBox>
    );
  };

  /// Handles the onClick of "Remove Column" button in popper
  const handleRemoveColumnClick = (
    event: Event | SyntheticEvent<Element, Event>
  ) => {
    openConfirmation();
  };

  /// Handles the onChange of the search input
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = event.target.value;
    setSearchInput(input);
  };

  return (
    <>
      {openConfirmationDialog && getConfirmationDialog()}
      {!openConfirmationDialog && (
        <>
          <MDBox px={2} pt={3}>
            <MDTypography fontWeight="bold" sx={{ fontSize: "14px" }}>
              Filter By:
            </MDTypography>
            <TextField
              fullWidth
              placeholder={props.columnName}
              value={searchInput}
              InputLabelProps={{ shrink: true }}
              onChange={handleSearchInputChange}
              sx={{ py: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search fontSize="medium" sx={{ color: "#344767" }} />
                  </InputAdornment>
                ),
              }}
            />
          </MDBox>
          <Divider
            sx={{
              backgroundColor: "#A8B8D8",
              opacity: 0.5,
              height: "1x",
              backgroundImage: "none !important",
            }}
          />
          <MDBox px={3} pb={2}>
            <MDBox>
              <ButtonWithIcon
                iconPath={mdiFilterOff}
                fullWidth
                disabled={searchInput.isEmpty()}
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#1A73E7",
                  "&:disabled": {
                    color: "#C7CCD0",
                    borderColor: "#C7CCD0",
                    opacity: 3,
                  },
                  "&:hover": { color: "#1A73E7", borderColor: "#C7CCD0" },
                }}
                onClick={(
                  event: Event | React.SyntheticEvent<Element, Event>
                ) => {
                  // Sending an empty string to reset the search filters
                  setSearchInput("");
                  props.onTextChange("");
                  props.closePopper(event);
                }}
              >
                CLEAR FILTERS
              </ButtonWithIcon>
            </MDBox>
            {props.shouldShowRemoveColumnButton && (
              <MDBox pt={1}>
                <ButtonWithIcon
                  iconPath={mdiDelete}
                  fullWidth
                  onClick={handleRemoveColumnClick}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#AE1709",
                    "&:hover": { color: "#AE1709", borderColor: "#C7CCD0" },
                  }}
                >
                  REMOVE COLUMN
                </ButtonWithIcon>
              </MDBox>
            )}
          </MDBox>
        </>
      )}
    </>
  );
};

export default FilterByTextContent;
