import { Grid, Button, DialogContent, DialogActions } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "context/AuthProvider";
import { WebServiceStatus } from "utils/services/AppUrls";
import {
  deleteFile,
  updateProfileInfo,
  uploadFile,
} from "../services/ProfileServices";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { InfoOutlined } from "@mui/icons-material";
import MessageAlert from "pages/authentication/components/MessageAlert";
import imageCompression from "browser-image-compression";
import { UserInfo, ProfileInfo, ProfileKeys } from "../utils/ProfileInterfaces";
import userPlaceholder from "assets/images/user_filler.svg";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

const EditProfileContent: React.FC<{
  firstName: string;
  lastName: string;
  designation: string;
  profileUrl: string;
  profileImageID: string;
  onCancelButtonClick: () => void;
  onSaveProfileDetails: (
    firstName: string,
    lastName: string,
    designation: string,
    profileUrl: string,
    profileImageID: string
  ) => void;
}> = (props) => {
  /// Holds the profile picture
  const [profileUrl, setProfileUrl] = useState<string>(props.profileUrl);
  /// Reference for hidden input field
  const hiddenFileInputRef = useRef(null);
  /// Used to handle the firstName field
  const [firstName, setFirstName] = useState<string>(props.firstName);
  /// Used to handle the lastName field
  const [lastName, setLastName] = useState<string>(props.lastName);
  /// Used to handle the designation field
  const [designation, setDesignation] = useState<string>(props.designation);
  /// Used to handle the profile imageID
  const [imageID, setImageID] = useState<string>(props.profileImageID);
  /// Decides the enability of the button
  const [enableSaveButton, setEnableSaveButton] = useState<boolean>(false);
  /// State to show/hide progress indicator
  const [showLoader, setShowLoader] = useState<boolean>(false);
  /// Context
  const {
    storeUserData,
    userData,
  }: { storeUserData: Function; userInfo: UserInfo; userData: UserInfo } =
    useAuth();
  /// Sets the error to be shown in message alert
  const [messageAlertError, setMessageAlertError] = useState<string>("");

  /******** METHODS ***********/

  /// Handles the onClick of "Upload picture" button
  const handleUploadPictureClick = () => {
    // Opens file input box - Triggers the hidden file input field
    hiddenFileInputRef.current.click();
  };

  /// This sets the imageID empty indicating that there is no pic picture uploaded
  const setNoProfilePic = () => {
    setProfileUrl(null);
    setImageID("");
  };

  /// Handles the onClick of "Remove Picture" button
  const handleRemovePictureClick = async () => {
    setShowLoader(true);
    const imageId = userData.profileImgId;
    const hasImageId = imageId != null && imageId.isNotEmpty();
    if (hiddenFileInputRef && hiddenFileInputRef.current && hasImageId) {
      /// Case: Has imageID locally stored
      const response = await deleteFile(imageId);
      if (response.status === WebServiceStatus.success) {
        setShowLoader(false);
        hiddenFileInputRef.current.value = "";
        setNoProfilePic();
      } else {
        setShowLoader(false);
        setMessageAlertError(response.error);
      }
    } else {
      /// Case: No imageID
      setShowLoader(false);
      hiddenFileInputRef.current.value = "";
      setNoProfilePic();
    }
  };

  const handleFileUpload = (pickedFile: File) => {
    setShowLoader(true);
    const reader = new FileReader();
    reader.onloadend = async (event) => {
      const dataURL = reader.result as string;
      const extractedUrl = dataURL.split(",")[1];
      const fileName = pickedFile.name;
      const mimeType = pickedFile.type;
      const response = await uploadFile({
        filename: fileName,
        mimeType: mimeType,
        data: extractedUrl,
      });
      if (response.status === WebServiceStatus.success) {
        setProfileUrl(dataURL);
        const uploadedProfileImageId = response.data;
        setImageID(uploadedProfileImageId);
      } else {
        setMessageAlertError(response.error);
      }
      setShowLoader(false);
    };
    reader.readAsDataURL(pickedFile);
  };

  const updateButtonValidity = () => {
    if (firstName.trim() === "") {
      setEnableSaveButton(false);
    } else if (lastName.trim() === "") {
      setEnableSaveButton(false);
    } else if (
      firstName === props.firstName &&
      lastName === props.lastName &&
      designation === props.designation &&
      ((profileUrl &&
        props.profileUrl &&
        profileUrl.split(",")[1] === props.profileUrl.split(",")[1]) ||
        (!profileUrl && !props.profileUrl))
    ) {
      setEnableSaveButton(false);
    } else {
      setEnableSaveButton(true);
    }
  };

  /// Handles the onChange of "FirstName" field
  const updateFirstNameValidity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const enteredName = event.target.value;
    setFirstName(enteredName);
  };

  /// Edited user details that is send to the API
  const getUpdatedUserInfo = (): ProfileInfo => {
    return {
      [ProfileKeys.firstName]: firstName.trim(),
      [ProfileKeys.lastName]: lastName.trim(),
      [ProfileKeys.designation]: designation.trim(),
      [ProfileKeys.imageId]: imageID,
    };
  };

  /// Handles the onChange of "Upload Picture" button
  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const pickedFile = event.target.files && event.target.files[0];
    const MAX_FILE_SIZE_IN_MB = 0.78125; /// 800KB
    const FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
    if (!pickedFile.type.startsWith("image/")) {
      setMessageAlertError("Please choose a valid image file.");
      return;
    }
    if (pickedFile !== undefined) {
      setMessageAlertError("");
      /// Handles smaller image
      if (pickedFile.size <= FILE_SIZE_IN_BYTES) {
        handleFileUpload(pickedFile);
        return;
      }
      /// Handles larger image
      const options = {
        maxSizeMB: MAX_FILE_SIZE_IN_MB,
        useWebWorker: true,
        maxWidthOrHeight: 800,
      };
      try {
        const compressedFile = await imageCompression(pickedFile, options);
        handleFileUpload(compressedFile);
      } catch (error) {
        console.log("Error caught while compressing file:", error);
      }
    }
  };

  /// Handles the onClick of "Save" button
  const handleSaveButtonClick = async () => {
    setShowLoader(true);
    const editedUserInfo = getUpdatedUserInfo();
    const response = await updateProfileInfo(editedUserInfo, userData.id);
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      const responseData = response.data as UserInfo;
      const updatedInfo = {
        ...userData,
        firstName: responseData.firstName,
        lastName: responseData.lastName,
        designation: responseData.designation,
        profileImgId: responseData.profileImgId,
      };
      storeUserData(updatedInfo);
      props.onSaveProfileDetails(
        responseData.firstName,
        responseData.lastName,
        responseData.designation,
        profileUrl,
        responseData.profileImgId
      );
      props.onCancelButtonClick();
    } else {
      setShowLoader(false);
      setMessageAlertError(response.error);
    }
  };

  /// Handles the onChange of "LastName" field
  const updateLastNameValidity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const enteredName = event.target.value;
    setLastName(enteredName);
  };

  /// Handles the onChange of "Designation" field
  const updateDesignationValidity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const enteredDesignation = event.target.value;
    setDesignation(enteredDesignation);
  };


  useEffect(() => {
    updateButtonValidity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, profileUrl, designation]);

  return (
    <MDBox>
      {showLoader && <CustomIndicator />}
      <DialogContent sx={{ padding: "32px 29px !important" }}>
        <MDBox>
          {messageAlertError.isNotEmpty() && (
            <MessageAlert
              message={messageAlertError}
              type="error"
              icon={<InfoOutlined />}
            />
          )}
          <Grid container pb={3.5}>
            <Grid item xs={12} mb={1}>
              <MDTypography
                variant="bodyline2"
                fontWeight="bold"
                sx={{
                  fontSize: 14,
                }}
              >
                Profile Picture
              </MDTypography>
            </Grid>
            <Grid item>
              <MDBox />
              <MDAvatar
                src={profileUrl ? profileUrl : userPlaceholder}
                alt="Profile Image"
                sx={{ width: "143px", height: "143px" }}
              />
            </Grid>
            <Grid
              item
              xs={true}
              display="flex"
              pr="20px"
              justifyContent="end"
              alignItems="center"
            >
              <MDBox display="flex" flexDirection="column" alignItems="center">
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "secondary.light",
                    color: "primary.main",
                  }}
                  onClick={handleUploadPictureClick}
                >
                  UPLOAD PICTURE
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  ref={hiddenFileInputRef}
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
                <MDBox pt={1} />
                <Button
                  variant="text"
                  size="small"
                  sx={{
                    color: "dark.main",
                    "&:focus, &:focus:not(:hover), &:hover": {
                      color: "dark.main",
                    },
                  }}
                  onClick={handleRemovePictureClick}
                >
                  REMOVE PICTURE
                </Button>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={2}>
          <MDBox display={"flex"}>
            <MDTypography
              variant="button"
              fontWeight="bold"
              sx={{ fontSize: 14 }}
            >
              First Name
            </MDTypography>
            <RequiredFieldMarker />
          </MDBox>
          <MDInput
            placeholder="First Name"
            type="text"
            fullWidth
            onChange={updateFirstNameValidity}
            value={firstName}
            InputProps={{ maxLength: 71 }}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDBox display={"flex"}>
            <MDTypography
              variant="button"
              fontWeight="bold"
              sx={{ fontSize: 14 }}
            >
              Last Name
            </MDTypography>
            <RequiredFieldMarker />
          </MDBox>
          <MDInput
            placeholder="Last Name"
            type="text"
            fullWidth
            onChange={updateLastNameValidity}
            value={lastName}
            InputProps={{ maxLength: 71 }}
          />
        </MDBox>
        <MDBox>
          <MDTypography
            variant="button"
            fontWeight="bold"
            sx={{ fontSize: 14 }}
          >
            Job Title
          </MDTypography>
          <MDInput
            placeholder="Job Title"
            type="text"
            fullWidth
            onChange={updateDesignationValidity}
            value={designation}
            inputProps={{ maxLength: 71 }}
          />
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="outlined"
          color="info"
          size="medium"
          sx={{
            borderColor: "secondary.light",
            color: "dark.main",
            fontSize: 14,
            "&:hover": {
              backgroundColor: "white.main",
              borderColor: "secondary.light",
              color: "dark.main",
            },
            "&:focus": { color: "dark.main" },
          }}
          onClick={props.onCancelButtonClick}
        >
          CANCEL
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="medium"
          disabled={!enableSaveButton}
          onClick={handleSaveButtonClick}
        >
          SAVE
        </MDButton>
      </DialogActions>
    </MDBox>
  );
};

export default EditProfileContent;
