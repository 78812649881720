import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { mainContentStyle, contentImageStyle, contentDataImageStyle, headingStyle, contentWrapper, mainContentWrapperStyle } from "./AnalyticsStyles";
import IvueitLogo from 'assets/images/Ivueit_Analytics.svg'
import AnalyticsImg from 'assets/images/data_dashboard_with_infographics_and_statistics 1.png'
import DataImage from 'assets/images/coming_soon.png'

const AnalyticsComingSoon = () => {
    return (
    <MDBox>
        <MDBox
            px={2}
            variant='contained'
            borderRadius="lg"
            bgColor='white'
            border={"1px solid #e0e0e0"}
            position="relative"
            minHeight="100%"
            display="flex"
            flexDirection="column"
        >
            <MDBox
                variant="contained"
                borderRadius="lg"
                bgColor={colors.info.main}
                p={1.2}
                mt={2}
                mb={2}
                width="100%"
            >
                <MDTypography color='white' sx={headingStyle}>
                    Coming Soon
                </MDTypography>
            </MDBox>
            <MDBox position="relative" maxWidth="1100px" minHeight="calc(100vh - 212px)" display="flex" flexDirection="column" justifyContent="space-between">
                <MDBox sx={contentWrapper} marginLeft="64px">
                    <MDBox sx={mainContentWrapperStyle}>
                        <MDBox sx={mainContentStyle}>
                            <MDBox mb={2}>
                                <img src={IvueitLogo} alt="iVueit Logo" />
                            </MDBox>
                            <MDTypography color={colors.text.main} variant='body2' sx={{ fontWeight: '400', lineHeight: "1.4em", zIndex: 10 }}>
                                Soon we will be unveiling a new analytics platform, “iVueit Analytics”. This powerful tool leverages state-of-the-art data processing capabilities to transform your data into actionable insights, enabling client’s to make data-driven decisions with unparalleled precision. With iVueit Analytics, you can unlock the true potential of your data and drive your organization to new heights of success.
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox sx={contentImageStyle} position="absolute" left="">
                        <img src={AnalyticsImg} alt="AnalyticsImg" />
                    </MDBox>
                </MDBox>
                <MDBox sx={contentDataImageStyle}>
                    <img src={DataImage} alt="AnalyticsImg" />
                </MDBox>
            </MDBox>
        </MDBox>
    </MDBox>
    )
}

export default AnalyticsComingSoon