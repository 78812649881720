import { mdiChevronDown, mdiPlusMinusVariant } from "@mdi/js";
import Icon from "@mdi/react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { topBarButtonStyle } from "../styles/site_list_style";

export enum SiteListTopBarButtonType {
  addSite,
  bulkImport,
  manageColumn,
}

interface ActionProps {
  onButtonClick: (actionType: SiteListTopBarButtonType, event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disableManageColumnButton: boolean;
}

const SiteListTopBar = (props: ActionProps) => {
  return (
    <MDBox
      display="flex"
      ml="auto"
      pl="10px"
      flexShrink="0"
      sx={{
        ".MuiButton-root": { "& + .MuiButton-root": { marginLeft: "10px" } },
      }}
    >
      <MDButton
        variant="outlined"
        color="info"
        size="medium"
        sx={topBarButtonStyle}
        onClick={() => props.onButtonClick(SiteListTopBarButtonType.addSite)}
      >
        Add Site
      </MDButton>
      <MDButton
        variant="outlined"
        color="info"
        size="medium"
        sx={topBarButtonStyle}
        onClick={() => props.onButtonClick(SiteListTopBarButtonType.bulkImport)}
      >
        Bulk Import
      </MDButton>
      {/* Manage Column button */}
      <MDButton
        variant="contained"
        disabled={props.disableManageColumnButton}
        startIcon={<Icon path={mdiPlusMinusVariant} size={0.8} />}
        endIcon={<Icon path={mdiChevronDown} size={0.8} />}
        color="success"
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          props.onButtonClick(SiteListTopBarButtonType.manageColumn, event)
        }
        sx={{ fontSize: "14px", fontWeight: "bold" }}
      >
        COLUMN
      </MDButton>
    </MDBox>
  );
};
export default SiteListTopBar;
