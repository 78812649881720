import { useState, useEffect, useCallback } from "react";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import { WebServiceStatus } from "utils/services/AppUrls";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Info } from "@mui/icons-material";
import SkeletonLoader from "pages/my-account/manage-internal-vuer-network/SkeletonLoader";
import VuerCardLazy from "pages/my-account/manage-internal-vuer-network/VuerCardLazy";
import MDSnackbar from "components/MDSnackbar";
import {
  getClientVuerRequest,
  getClientVuers,
  vuerApprove,
  vuerReject,
  vuerRemove,
} from "pages/my-account/manage-internal-vuer-network/services/VuerServices";
import { VuesPageAPIMetaData } from "pages/dashboard/home/dashboard-home/utils/dashboard_interfaces";
import { useAuth } from "context/AuthProvider";
import { ModalPopUp } from "pages/components/ModalPopUp";
import CustomDialogBox from "pages/components/CustomDialogBox";
import VuerCard from "pages/my-account/manage-internal-vuer-network/VuerCard";
import { VuerData, VuerRequest } from "types/inter-network";
import { showMessage } from "utils/helpers/showMessage";
import colors from "assets/theme/base/colors";
import CustomizedTooltips from "pages/components/CustomTooltip";
import VuerTable from "./VuerTable";
import { DEFAULT_PAGE_SIZE } from "./../../../constants";

const ManageInternalVuerNetwork = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [toastType, setToastType] = useState<"info" | "error">("info");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [requestedVuer, setRequestedVuer] = useState<VuerRequest[]>([]);
  const [vuerList, setVuerList] = useState<VuerData[]>([]);
  const [filteredVuers, setFilteredVuers] = useState<VuerData[]>([]);
  const [selectedData, setSelectedData] = useState<VuerRequest | VuerData>();
  const [loadingVuerList, setLoadingVuerList] = useState<boolean>(true);
  const [loadingVuerRequest, setLoadingVuerRequest] = useState<boolean>(true);
  const [showCodeSnack, setShowCodeSnack] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<boolean>(false);
  const { userData } = useAuth();
  const vuerCode = userData?.companies?.filter(
    (item: VuerRequest) => item.id === userData.companyId
  )[0]?.shortcode;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleModal = (data: VuerData | VuerRequest, modalName: string) => {
    if (modalName === "remove") {
      setSelectedData(data);
      setOpenModal(!openModal);
    } else if (modalName === "reject") {
      handleReject(data.id);
    } else if (modalName === "approve") {
      handleApprove(data.id);
    }
  };

  const fetchVuerListFromServer = useCallback(async () => {
    setLoadingVuerList(true);
    const response = await getClientVuers(
      userData.companyId,
      DEFAULT_PAGE_SIZE,
      1
    );
    if (response.status === WebServiceStatus.success) {
      const {
        users,
      }: {
        meta: VuesPageAPIMetaData;
        users: VuerData[];
      } = response.data;
      setVuerList(users);
      setLoadingVuerList(false);
    } else {
      showMessage(response.error, setMessage, setToastMessage);
    }
  }, [userData]);

  const fetchVuerRequestsListFromServer = useCallback(async () => {
    setLoadingVuerRequest(true);
    const response = await getClientVuerRequest(userData.companyId);
    if (response.status === WebServiceStatus.success) {
      const {
        requests,
      }: {
        requests: VuerRequest[];
      } = response.data;
      setRequestedVuer(requests);
      setLoadingVuerRequest(false);
    } else {
      setLoadingVuerRequest(false);
      showMessage(response.error, setMessage, setToastMessage);
      setToastType("error");
    }
  }, [userData]);

  const renderFilteredVuerCards = useCallback(
    (filteredVuers: VuerData[]) => {
      return <VuerCard allVuerData={filteredVuers} handleModal={handleModal} />;
    },
    [handleModal]
  );

  const renderVuerCards = useCallback(
    (vuers: VuerData[]) => {
      return <VuerCard allVuerData={vuers} handleModal={handleModal} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vuerList, handleModal]
  );

  async function handleRemoveVuer() {
    const response = await vuerRemove({
      companyId: selectedData.orgId,
      userId: selectedData.id,
    });
    if (response.status === WebServiceStatus.success) {
      setOpenModal(false);
      fetchVuerListFromServer();
    } else {
      setOpenModal(false);
      showMessage(response.error, setMessage, setToastMessage);
      setToastType("error");
    }
  }

  async function handleApprove(id: string) {
    const response = await vuerApprove(id);
    if (response.status === WebServiceStatus.success) {
      fetchVuerListFromServer();
      fetchVuerRequestsListFromServer();
      setOpenModal(false);
    } else {
      setOpenModal(false);
      showMessage(response.error, setMessage, setToastMessage);
      setToastType("error");
    }
  }

  async function handleReject(id: string) {
    const response = await vuerReject(id);
    if (response.status === WebServiceStatus.success) {
      fetchVuerRequestsListFromServer();
      setOpenModal(false);
    } else {
      setOpenModal(false);
      showMessage(response.error, setMessage, setToastMessage);
      setToastType("error");
    }
  }

  const copyToClipBoard = () => {
    window.navigator.clipboard.writeText(vuerCode);
    setShowCodeSnack(true);
  };

  useEffect(() => {
    fetchVuerListFromServer();
    fetchVuerRequestsListFromServer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleSearch = (text: string) => {
      setSearchText(text);
      const filtered = vuerList?.filter(
        (vuer) =>
          vuer.name.toLowerCase().includes(text.toLowerCase()) ||
          vuer.email.toLowerCase().includes(text.toLowerCase()) ||
          vuer.phoneNumber.includes(text)
      );
      setFilteredVuers(filtered);
    };

    handleSearch(searchText);
  }, [searchText, vuerList]);

  const confirmationDialogActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={() => setOpenModal(false)}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={() => handleRemoveVuer()}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        YES
      </MDButton>,
    ];
  };

  return (
    <DashboardContentLayout needCardBackground={false}>
      <CustomDialogBox
        title={"Remove Vuer"}
        width="400px"
        height="250px"
        openDialog={openModal}
      >
        <ModalPopUp
          content={`Are you sure you want to remove this Vuer from your Internal Network?`}
          closeDialog={() => setOpenModal(!openModal)}
          actions={confirmationDialogActions()}
        />
      </CustomDialogBox>
      <MDBox px={2} display="flex" flexDirection={"column"}>
        <MDBox
          display="flex"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <MDBox>
            <MDTypography variant="h5">
              Pending Internal Vuer Requests
            </MDTypography>
          </MDBox>
          <MDTypography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            Join Code:{" "}
            <MDBox
              onClick={copyToClipBoard}
              sx={{
                backgroundColor: "#e4f3e5",
                color: colors.success.main,
                fontSize: "1.2em",
                fontWeight: "bold",
                px: 2,
                py: 1,
                ml: 1.5,
              }}
            >
              {vuerCode}
            </MDBox>
          </MDTypography>
          <MDSnackbar
            color="info"
            icon="done"
            title="Copied!"
            content={"Code copied to clipboard"}
            dateTime=""
            open={showCodeSnack}
            close={() => setShowCodeSnack(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          />
          <MDSnackbar
            color={toastType}
            icon="erroroutline"
            title="Notification!"
            content={message}
            dateTime=""
            open={toastMessage}
            close={() => setToastMessage(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={5000}
          />
        </MDBox>

        {/* Vuer Pending */}
        {loadingVuerRequest ? (
          <SkeletonLoader />
        ) : (
          <VuerTable
            height={
              requestedVuer.length === 0 || requestedVuer.length > 2
                ? 198
                : "auto"
            }
            headers={[
              "VUER ID",
              "NAME OF VUER",
              "PHONE NUMBER",
              "EMAIL",
              "",
              "",
            ]}
          >
            {requestedVuer.length === 0 ? (
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={"77%"}
              >
                <MDTypography variant="body2">
                  There are 0 requests awaiting review
                </MDTypography>
              </MDBox>
            ) : (
              requestedVuer.map((requester) => (
                <VuerCardLazy requester={requester} handleModal={handleModal} />
              ))
            )}
          </VuerTable>
        )}

        <MDBox display="flex" alignItems="center" mt={3} width={"100%"}>
          <MDBox
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            gap={1}
            width={"100%"}
          >
            <MDBox display="flex" alignItems="center">
              <MDTypography variant="h5">Internal Vuer Network</MDTypography>
              <CustomizedTooltips
                icon={
                  <IconButton>
                    <Info
                      sx={{
                        color: colors.dark.main,
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                }
                tooltip={
                  <Grid p={1}>
                    <MDTypography
                      variant="body2"
                      sx={{
                        color: colors.black.main,
                        textAlign: "left",
                      }}
                    >
                      Vuers can request access to your Internal Vuer Network
                      through the wallet page of the iVueit mobile app using
                      your unique join code: {vuerCode}. Once a Vuer has
                      requested, you can accept or reject their request to join
                      here. Be sure to only add Vuers to your Internal Vuer
                      Network that you know and trust.
                    </MDTypography>
                  </Grid>
                }
              />
            </MDBox>
            <MDBox display="flex" maxWidth={350} width={350} gap={2}>
              <MDInput
                fullWidth
                value={searchText}
                placeholder="Search Vuers"
                sx={{
                  "& input::placeholder": {
                    color: colors.black.main,
                  },
                }}
                InputLabelProps={{ shrink: true }}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => setSearchText(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon fontSize="medium" sx={{ color: "#344767" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="end"
              ml="auto"
            >
              <MDTypography
                variant="button"
                color="textLight"
                fontWeight="regular"
              >
                Total Internal Vuers
              </MDTypography>
              <MDTypography variant="h5">{vuerList.length}</MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        {loadingVuerList ? (
          <SkeletonLoader />
        ) : searchText ? (
          renderFilteredVuerCards(filteredVuers)
        ) : (
          renderVuerCards(vuerList)
        )}
      </MDBox>
    </DashboardContentLayout>
  );
};

export default ManageInternalVuerNetwork;
