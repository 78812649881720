import { Icon, SxProps } from "@mui/material";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import { ReactNode } from "react";

interface AlertProps {
  message: string;
  sx?: SxProps;
  type: "success" | "warning" | "error";
  icon: ReactNode;
}

interface AlertColor {
  background: string;
  foreground: string;
}

const MessageAlert = ({ message, type, icon, sx }: AlertProps) => {
  let alertColor: AlertColor = {
    background: "#63b967",
    foreground: "#ffffff",
  };

  switch (type) {
    case "success":
      alertColor = {
        background: "#ccf0ce",
        foreground: "#076f0d",
      };
      break;
    case "error":
      alertColor = {
        background: "#ffcfcf",
        foreground: "#a81609",
      };
      break;
    case "warning":
      alertColor = {
        background: "#f0f2f5",
        foreground: "#4f4f52",
      };
      break;

    default:
      break;
  }

  return (
    <MDAlert
      mt={1}
      mb={1}
      textAlign="center"
      sx={{ backgroundColor: alertColor.background, ...sx }}
    >
      <Icon fontSize="small" sx={{ color: alertColor.foreground }}>
        {icon}
      </Icon>
      <MDTypography
        variant="button"
        fontWeight="medium"
        textAlign="left"
        sx={{ color: alertColor.foreground, ml: "10px" }}
      >
        {message}
      </MDTypography>
    </MDAlert>
  );
};

export default MessageAlert;
