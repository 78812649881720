import {
  Company,
  CustomPopper,
  SurveyPreviewContent,
  TEXTFIELD_CHANGE_DELAY,
  VueSurvey,
  WebServiceStatus,
  checkBoxStyle,
  linkElementStyle,
  searchBarStyle,
  styleForUploadTemplateGrid,
  textEllipsis,
} from "@ivueit/vue-engine";
import {
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { mdiEye, mdiFilter, mdiFilterOff } from "@mdi/js";
import Icon from "@mdi/react";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowId,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import MDBox from "components/MDBox";
import { useCallback, useEffect, useReducer, useState } from "react";
import { Search } from "@mui/icons-material";
import CustomDatePicker from "pages/dashboard/components/CustomDatePicker";
import moment, { Moment } from "moment";
import ExportDialogContent, { ExportModalType } from "./ExportDialogContent";
import { GridFilter } from "pages/dashboard/home/site-lists/interfaces/interfaces";
import MDButton from "components/MDButton";
import CustomDialogBox from "pages/components/CustomDialogBox";
import {
  StyledButtonWithIcon,
  gridStyle,
} from "pages/dashboard/home/vues/vue-grid/helpers/imports";
import { FilterByDateContent } from "pages/dashboard/home/vues/vue-grid/components/FilterByDateContent";
import defaultColumnDefinition from "assets/jsons/batchGridColumns.json";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_PAGE_SIZE,
  GENERIC_ERROR_MESSAGE,
  RoutePath,
} from "../../../../constants";
import { filterDateToNanoSecondsString } from "pages/dashboard/home/vues/vue-grid/helpers/helper_methods";
import { getSurveyTemplateById } from "pages/dashboard/home/vues/services/VueServices";
import { tooltipStyles } from "pages/dashboard/home/site-lists/styles/site_list_style";
import {
  VuesBatchPageActionType,
  VuesBatchPageSummaryState,
  defaultVuesBatchPageSummaryState,
  vuesBatchPageSummaryReducer,
} from "../utils/reducer";
import {
  VueBatchDataFromServer,
  VueBatchGridData,
  VuesBatchPageAPIMetaData,
} from "../utils/interfaces";
import {
  generateVuesBatchAPIParamter,
  getGridDataForVueBatch,
  getPreviouslySearchedText,
} from "../helpers/helper_methods";
import { getClientVuesBatch } from "../services/vueBatchServices";
import { defaultMetaData } from "pages/dashboard/home/vues/vue-grid/types/constants";
import { CustomIndicator } from "pages/components/CustomIndicator";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { useAuth } from "context/AuthProvider";
import FilterByTextContent from "pages/dashboard/home/vues/vue-grid/components/FilterByTextContent";
import { FilterBySurveyTypeContent } from "pages/dashboard/home/vues/vue-grid/components/CustomFilterContent";
import FilterByRangeContent from "pages/dashboard/home/vues/vue-grid/components/FilterByRangeContent";
import { HeaderSortIconButton } from "pages/dashboard/home/vues/vue-grid/components/HeaderSortIconButton";

const BatchListDataGrid = () => {
  const dateFormat = "MM / DD / YYYY";
  // const mounted = useRef(null);
  const { getAvailablePortalsForUser } = useAuth();
  const availableCompanyList: Company[] = getAvailablePortalsForUser();
  const [loader, setLoader] = useState(false);
  const [metaValues, setMetaValues] =
    useState<VuesBatchPageAPIMetaData>(defaultMetaData);
  /// This is to avoid the unwanted useEffect invocation unless if the search text hasn't chnaged
  const [searchTextChanged, setSearchTextChanged] = useState<boolean>(false);
  /// Determines whether to select all cells or not
  const [selectAll, setSelectAll] = useState<boolean>(false);
  // Selecting all rows when the page is loading
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  /// Defines the row data
  const [filteredRowData, setFilteredRowData] = useState<VueBatchGridData[]>(
    []
  );
  /// Defines the column data
  const columnData: GridColDef[] = defaultColumnDefinition;
  /// Sort model for the grid
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  /// Defines the anchor element for the popup
  const [anchorElement, setAnchorElement] = useState<
    HTMLButtonElement | HTMLDivElement
  >(null);
  /// Defines the popup fields
  const [popperName, setPopperName] = useState<string>(null);
  const [selectedFilters, setSelectedFilters] = useState<GridFilter[]>([]);
  // Defines the search text
  const [searchText, setSearchText] = useState<string>("");
  const [showExportDialog, setShowExportDialog] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  /// To handle error
  const [error, setError] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedSurvey, setSelectedSurvey] = useState<VueSurvey>();
  const [openPreviewDialog, setOpenPreviewDialog] = useState<boolean>(false);
  const [shouldResetDate, setShouldResetDate] = useState<boolean>(false);
  /// Reference for the grid
  const gridRef = useGridApiRef();
  // hook to perform navigation
  const navigate = useNavigate();
  /// Defines the pinned columns
  const pinnedColumns = ["batchid", "batchcreationdate", "batchname"];
  /// vue batch reducer
  const [clientVueBatchState, dispatchClientsVueBatchFetchAction] = useReducer(
    vuesBatchPageSummaryReducer,
    defaultVuesBatchPageSummaryState
  );
  /// Handles the onChange of check box
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    const listOfSelectedRowIds: GridRowId[] = filteredRowData.map(
      (row) => row.id
    );
    setSelectedRows(checked ? listOfSelectedRowIds : []);
  };

  /// Handles the change in rows selection
  const handleRowSelectionChange = (
    listOfSelectedRowIds: GridRowSelectionModel
  ) => {
    setSelectedRows(listOfSelectedRowIds);
    setSelectAll(listOfSelectedRowIds.length === filteredRowData.length);
  };

  /// Handles the onClick of the "ClearAllFilters" button
  const onClearAllFilterButtonClick = () => {
    setSelectedFilters([]);
    setSortModel([]);
    // Resets date field on custom date picker
    setShouldResetDate(true);
    dispatchClientsVueBatchFetchAction({
      type: VuesBatchPageActionType.dateFilterReset,
      payload: {
        ...clientVueBatchState,
        startDate: "",
        endDate: "",
      },
    });
  };

  /// Handles the onChange of startDate and endDate
  const handleStartDateChange = async (newDate: Moment | null) => {
    const formattedEndDate =
      clientVueBatchState.endDate.formatUTCNanoSecondsToString(dateFormat);
    setShouldResetDate(false);
    setError((prev) => ({
      startDate: newDate
        ? newDate > moment(formattedEndDate) || !newDate.isValid()
          ? "Invalid start date."
          : ""
        : "",
      endDate: prev.endDate,
    }));

    dispatchClientsVueBatchFetchAction({
      type: VuesBatchPageActionType.startDateChanged,
      payload: {
        ...clientVueBatchState,
        startDate:
          newDate !== null && newDate.isValid()
            ? filterDateToNanoSecondsString(newDate)
            : "",
      },
    });
  };

  const handleEndDateChange = async (newDate: Moment | null) => {
    setShouldResetDate(false);
    const formattedStartDate =
      clientVueBatchState.startDate.formatUTCNanoSecondsToString(dateFormat);
    setError((prev) => ({
      startDate: prev.startDate,
      endDate: newDate
        ? moment(formattedStartDate) > newDate || !newDate.isValid()
          ? "Invalid end date."
          : ""
        : "",
    }));
    dispatchClientsVueBatchFetchAction({
      type: VuesBatchPageActionType.endDateChanged,
      payload: {
        ...clientVueBatchState,
        endDate:
          newDate !== null && newDate.isValid()
            ? filterDateToNanoSecondsString(newDate, true)
            : "",
      },
    });
  };

  //////////////////// GRID CUSTOMISATION ////////////////////////////////

  /// Handles the navigation to vue detail page
  const onClickLinkElement = (params: GridRenderCellParams) => {
    const { field, id } = params;
    const stateParam =
      field === "batchname"
        ? { batchID: id }
        : { surveyTemplateId: params.row.surveyTemplateId };
    navigate(RoutePath.vues, {
      state: stateParam,
    });
  };

  const onClickSurveyPreview = async (params: GridRenderCellParams) => {
    const { row } = params;
    if (row.surveyTemplateId.isEmpty()) {
      return;
    }
    setShowLoader(true);
    const response = await getSurveyTemplateById(row.surveyTemplateId);
    if (response.status === WebServiceStatus.success) {
      const { survey } = response.data;
      const surveyData = survey as VueSurvey;
      setSelectedSurvey(surveyData);
      setOpenPreviewDialog(true);
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  // Defines the custom cells in the grid
  const getCustomCells = (params: GridRenderCellParams) => {
    const { field, value } = params;

    if (field === "batchname" || field === "surveyname") {
      return (
        <MDBox className="MuiDataGrid-cellContent">
          {field === "surveyname" && (
            <IconButton
              sx={{ display: "inline-block !important" }}
              onClick={(event) => {
                event.stopPropagation();
                onClickSurveyPreview(params);
              }}
            >
              <Icon path={mdiEye} size={0.72} color="#1a73e7" />
            </IconButton>
          )}
          <Link
            sx={{ ...linkElementStyle, cursor: "pointer" }}
            onClick={() => {
              onClickLinkElement(params);
            }}
            variant="button"
            color="primary"
            fontWeight="regular"
            underline="always"
          >
            {value}
          </Link>
        </MDBox>
      );
    }
    return (
      <MDBox
        className="MuiDataGrid-cellContent"
        display="flex"
        alignItems="center"
        minWidth="0"
      >
        <MDBox sx={textEllipsis}>{value}</MDBox>
      </MDBox>
    );
  };

  // Sets the sort column values
  const setSortColumn = (name: string, isAscendingOrder: boolean) => {
    dispatchClientsVueBatchFetchAction({
      type: VuesBatchPageActionType.sortColumnChanged,
      payload: {
        ...clientVueBatchState,
        sortColumnDisplayName: name.toUpperCase(),
        sortIsAscending: isAscendingOrder,
      },
    });
  };

  /// Defines the custom headers
  const getCustomHeader = (params: GridColumnHeaderParams) => {
    const { colDef, field } = params;
    const headerName = colDef.headerName;
    const showFilterIcon = true;
    const showSortIcon = true;
    return (
      <>
        <strong>{headerName.toUpperCase()}</strong>
        {showSortIcon && (
          <HeaderSortIconButton
            onClickSort={(
              isAscending: boolean,
              newSortModel: GridSortModel
            ) => {
              setSortColumn(headerName, !isAscending);
              setSortModel(newSortModel);
            }}
            sortModel={sortModel}
            field={field}
          />
        )}
        {showFilterIcon && (
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              setAnchorElement(event.currentTarget);
              handleFilterIconClick(field);
            }}
          >
            <Icon path={mdiFilter} size={0.72} />
          </IconButton>
        )}
      </>
    );
  };

  const renderedColumns = columnData.map((data) => {
    data.renderCell = getCustomCells;
    data.renderHeader = getCustomHeader;
    return data;
  });

  const getSurveyPreviewDialog = () => {
    return selectedSurvey ? (
      <Dialog open={true} onClose={null} maxWidth="md" fullWidth>
        <SurveyPreviewContent survey={selectedSurvey} />
        <DialogActions sx={{ pt: "15px" }}>
          {getPreviewDialogActions()}
        </DialogActions>
      </Dialog>
    ) : (
      <></>
    );
  };

  const getPreviewDialogActions = () => {
    return (
      <MDButton
        variant="gradient"
        color="info"
        size="medium"
        onClick={() => {
          setOpenPreviewDialog(false);
        }}
      >
        CLOSE
      </MDButton>
    );
  };

  //////////////////// FILTER ////////////////////////////////

  /// Handles the onClick of "Filter" icon in the column header
  const handleFilterIconClick = (field: string) => {
    // Helps to show the popup. If the field is already showing, setting null will hide the popup
    setPopperName(popperName === field ? null : field);
  };

  const getFilterPopper = () => {
    var children: JSX.Element = <></>;
    switch (popperName) {
      case "batchcreationdate":
        children = getDatePickerStyledPopper();
        break;
      case "totalvues":
      case "completedvues":
        children = getRangeStyledPopper();
        break;
      case "requirements":
        children = getListStyledPopperContent();
        break;
      default:
        children = getTextBasedFilterPopperContent();
        break;
    }

    return (
      <CustomPopper
        onClickAway={(event: MouseEvent | TouchEvent) => {
          handleClosePopper(event);
        }}
        shouldOpen={true}
        anchorElement={anchorElement}
        id={popperName}
        placement={"right"}
      >
        {children}
      </CustomPopper>
    );
  };

  /// This is for "Requirements" column
  /// So setting the predefined list of portals we have as the popper content
  const getListStyledPopperContent = () => {
    var popperContent: string[] = [];
    popperContent = availableCompanyList.map((company) => company.name);
    const headerName: string = columnData.filter(
      (data) => data.field === popperName
    )[0].headerName;
    const columnName = headerName ?? "";

    const selections = selectedFilters.filter((filter) => filter.field);
    // fetching the previous selections
    const previousSelections =
      selections.length > 0 ? selections[0].filters : [];
    // Removing non empty items
    const contentSet = new Set(
      popperContent.filter((item) => item.trim().length > 0)
    );
    // Retreiving the unique items from the list
    const uniqueContents = [...new Set(contentSet)];

    return (
      <FilterBySurveyTypeContent
        customSelectorList={uniqueContents}
        removeColumn={() => {}}
        columnName={columnName}
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        onFilterChange={updateFilterChanges}
        shouldShowRemoveColumnButton={false}
        previouslySelectedOptions={previousSelections}
      />
    );
  };

  const getDatePickerStyledPopper = () => {
    const headerName: string = columnData.filter(
      (data) => data.field === popperName
    )[0].headerName;
    const columnName = headerName ?? "";
    const selectedStartDate = clientVueBatchState.startDate;
    const selectedEndDate = clientVueBatchState.endDate;
    return (
      <FilterByDateContent
        removeColumn={() => {}}
        applyDateFilter={(startDate, endDate) => {
          handleDateFilterChange(popperName, startDate, endDate);
        }}
        columnName={columnName}
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        selectedStartDate={selectedStartDate.formatUTCNanoSecondsToString(
          dateFormat
        )}
        selectedEndDate={selectedEndDate.formatUTCNanoSecondsToString(
          dateFormat
        )}
        shouldShowRemoveColumnButton={false}
      />
    );
  };

  const getTextBasedFilterPopperContent = () => {
    const headerName: string = columnData.filter(
      (data) => data.field === popperName
    )[0].headerName;
    const columnName = headerName ?? "";

    return (
      <FilterByTextContent
        removeColumn={() => {}}
        columnName={columnName}
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        onTextChange={(text) => {
          const filterObject = {
            field: popperName,
            filters: [text],
          };
          const indexOfItem = selectedFilters.findIndex(
            (item) => item.field === popperName
          );
          var updatedFilters = [...selectedFilters];
          if (text.isEmpty()) {
            // Filters on this columns has been removed
            if (indexOfItem !== -1) {
              // Item already available. Removing the item
              updatedFilters.splice(indexOfItem, 1);
            }
          } else {
            if (indexOfItem !== -1) {
              updatedFilters[indexOfItem] = filterObject;
            } else {
              updatedFilters.push(filterObject);
            }
          }
          setSelectedFilters(updatedFilters);
        }}
        shouldShowRemoveColumnButton={false}
        searchedText={getPreviouslySearchedText(
          clientVueBatchState,
          popperName
        )}
      />
    );
  };

  const getRangeStyledPopper = () => {
    const headerName: string = columnData.filter(
      (data) => data.field === popperName
    )[0].headerName;
    const columnName = headerName ?? "";
    const isTotalVueColumn = headerName === "Total Vues";
    const minRange = isTotalVueColumn
      ? clientVueBatchState.minTotalVueCount
      : clientVueBatchState.minCompletedVueCount;
    const maxRange = isTotalVueColumn
      ? clientVueBatchState.maxTotalVueCount
      : clientVueBatchState.maxCompletedVueCount;
    return (
      <FilterByRangeContent
        shouldShowRemoveColumnButton={false}
        removeColumn={() => {}}
        applyRangeFilter={(minRange, maxRange) => {
          updateRangeBasedFilterChange(minRange, maxRange);
        }}
        columnName={columnName}
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        previousMinRange={minRange}
        previousMaxRange={maxRange}
      />
    );
  };

  const updateFilterChanges = (newFilters: string[]) => {
    const filterObject: GridFilter = { field: popperName, filters: newFilters };
    const indexOfItem = selectedFilters.findIndex(
      (item) => item.field === popperName
    );
    var updatedFilters = [...selectedFilters];
    if (newFilters.length <= 0) {
      // Filters on this columns has been removed
      if (indexOfItem !== -1) {
        // Item already available. Removing the item
        updatedFilters.splice(indexOfItem, 1);
      }
    } else {
      if (indexOfItem !== -1) {
        updatedFilters[indexOfItem] = filterObject;
      } else {
        updatedFilters.push(filterObject);
      }
    }
    setSelectedFilters(updatedFilters);
  };

  const updateRangeBasedFilterChange = (minRange: string, maxRange: string) => {
    const filterObject = {
      field: popperName,
      filters: [minRange, maxRange],
    };
    const indexOfItem = selectedFilters.findIndex(
      (item) => item.field === popperName
    );
    var updatedFilters = [...selectedFilters];
    if (minRange.isEmpty() && maxRange.isEmpty()) {
      // Filters on this columns has been removed
      if (indexOfItem !== -1) {
        // Item already available. Removing the item
        updatedFilters.splice(indexOfItem, 1);
      }
    } else {
      if (indexOfItem !== -1) {
        updatedFilters[indexOfItem] = filterObject;
      } else {
        updatedFilters.push(filterObject);
      }
    }
    setSelectedFilters(updatedFilters);
  };

  // Updates the filter list with new set of filter, selected by date/time popup
  const updateDateBasedFilter = (fieldName: string, newFilters: string[]) => {
    const filterObject = {
      field: fieldName,
      filters: newFilters,
    };
    const indexOfItem = selectedFilters.findIndex(
      (item) => item.field === fieldName
    );
    var updatedFilters = [...selectedFilters];
    if (newFilters.length <= 0) {
      // Filters on this columns has been removed
      if (indexOfItem !== -1) {
        // Item already available. Removing the item
        updatedFilters.splice(indexOfItem, 1);
      }
    } else {
      if (indexOfItem !== -1) {
        updatedFilters[indexOfItem] = filterObject;
      } else {
        updatedFilters.push(filterObject);
      }
    }
    setSelectedFilters(updatedFilters);
  };

  /// Handles the date filter change
  const handleDateFilterChange = (
    fieldName: string,
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    var formattedStartDate = "";
    var formattedEndDate = "";
    var appliedFilters: string[] = [formattedStartDate, formattedEndDate];
    const isValidStartDate = startDate !== null && startDate.isValid();
    const isValidEndDate = endDate !== null && endDate.isValid();
    if (!isValidStartDate && !isValidEndDate) {
      updateDateBasedFilter(fieldName, []);
      return;
    }
    if (isValidStartDate) {
      formattedStartDate = filterDateToNanoSecondsString(startDate);
      appliedFilters[0] = formattedStartDate;
    }

    if (isValidEndDate) {
      formattedEndDate = filterDateToNanoSecondsString(endDate, true);
      appliedFilters[1] = formattedEndDate;
    }

    // Adding the date picker to the filter column
    updateFilterChanges(appliedFilters);
  };

  /// Handles the close of popper component
  const handleClosePopper = (event: Event | React.SyntheticEvent) => {
    // Helps to hide the popups
    setPopperName(null);
  };

  const canExportBatches = (): boolean => {
    if (selectedRows.length <= 0) {
      return false;
    }
    const surveyNames = filteredRowData
      .filter((row) => selectedRows.includes(row.id))
      .map((row) => row.surveyname);
    const contentSet = new Set(surveyNames);
    const uniqueSurveyNames = [...new Set(contentSet)];
    // Checks whether the selected rows has multiple survey names. Export button will be enabled if suverys are same
    return uniqueSurveyNames.length === 1;
  };

  //////////////////// COMPONENTS ////////////////////////////////

  const getTextField = () => {
    return (
      <TextField
        fullWidth
        value={searchText}
        placeholder="Search by Batch Name"
        sx={{ ...searchBarStyle, maxWidth: "275px" }}
        InputLabelProps={{ shrink: true }}
        onChange={(event) => {
          setSearchTextChanged(true);
          setSearchText(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize="medium" sx={{ color: "#344767" }} />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const getDatePickers = () => {
    return (
      <MDBox
        ml={3}
        display="flex"
        sx={{
          ".MuiFormHelperText-root": {
            position: "absolute",
            top: "calc( 100% - 3px )",
            left: "2px",
          },
        }}
      >
        <MDBox>
          <CustomDatePicker
            title="Start Date"
            errorMessage={error.startDate}
            defaultDate={null}
            onChange={handleStartDateChange}
            maximumDate={moment(
              clientVueBatchState.endDate.formatUTCNanoSecondsToString(
                dateFormat
              )
            )}
            shouldResetDate={shouldResetDate}
          />
        </MDBox>
        <MDBox ml={1}>
          <CustomDatePicker
            title="End Date"
            errorMessage={error.endDate}
            defaultDate={null}
            minimumDate={moment(
              clientVueBatchState.startDate.formatUTCNanoSecondsToString(
                dateFormat
              )
            )}
            onChange={handleEndDateChange}
            shouldResetDate={shouldResetDate}
          />
        </MDBox>
      </MDBox>
    );
  };

  const getExportButton = () => {
    const disableButton = !canExportBatches();

    return (
      <Tooltip
        title="Cannot export batches that have different surveys."
        arrow
        placement="left"
        componentsProps={{
          tooltip: {
            sx: tooltipStyles,
          },
        }}
        disableHoverListener={!disableButton}
      >
        <span>
          <MDButton
            variant="contained"
            color="info"
            onClick={() => {
              setShowExportDialog(true);
            }}
            disabled={disableButton}
          >
            Export
          </MDButton>
        </span>
      </Tooltip>
    );
  };

  const getExportDialog = () => {
    const selectedIds: string[] = selectedRows.map((item) => `${item}`);
    return (
      <CustomDialogBox
        title="Export"
        width="560px"
        openDialog={showExportDialog}
      >
        <ExportDialogContent
          closeDialog={() => {
            setShowExportDialog(false);
          }}
          type={ExportModalType.batches}
          selectedItemIds={selectedIds}
          didSelectAll={selectAll}
          batchVueState={clientVueBatchState}
        />
      </CustomDialogBox>
    );
  };

  const getClearAllFiltersButton = () => {
    return (
      <MDBox mr="auto" flexShrink="0" pr={1} ml={1}>
        <StyledButtonWithIcon
          iconPath={mdiFilterOff}
          onClick={onClearAllFilterButtonClick}
        >
          {`Clear All Filters (${selectedFilters.length})`}
        </StyledButtonWithIcon>
      </MDBox>
    );
  };

  const getFooter = () => {
    return (
      <MDBox display="flex" alignItems="center" py={2}>
        <Typography ml={2} sx={{ fontSize: "14px", fontWeight: "400" }}>
          Select one or more batches of same survey for export
        </Typography>
      </MDBox>
    );
  };

  const getNoRowsMessage = () => {
    return (
      <MDBox position="relative" top="30%">
        <Typography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          No rows available
        </Typography>
      </MDBox>
    );
  };

  /********************* GENERATING THE ROWS ****************/

  const getParameterString = useCallback(
    (isScrolling: boolean): string => {
      // Default page number will be 1 if not scrolling
      var newPageNumber = isScrolling ? metaValues.pageNumber + 1 : 1;
      var pageSize = DEFAULT_PAGE_SIZE;
      return generateVuesBatchAPIParamter(
        clientVueBatchState,
        newPageNumber,
        pageSize
      );
    },
    [clientVueBatchState, metaValues.pageNumber]
  );

  // Check for the valid start date and end date
  const isValidParameter = () => {
    const formattedStartDate =
      clientVueBatchState.startDate.formatUTCNanoSecondsToString(dateFormat);
    const formattedEndDate =
      clientVueBatchState.endDate.formatUTCNanoSecondsToString(dateFormat);
    const isValid =
      (moment(formattedStartDate)?.isValid() &&
        moment(formattedEndDate)?.isValid()) ||
      (!moment(formattedStartDate)?.isValid() &&
        !moment(formattedEndDate)?.isValid());
    return isValid;
  };

  // Get batch list from server
  const fetchVueBatchListFromServer = useCallback(
    async (isScrolling: boolean) => {
      if (isValidParameter()) {
        setLoader(true);
        const parameters = getParameterString(isScrolling);
        const response = await getClientVuesBatch(parameters);
        var gridData: VueBatchGridData[] = [];
        if (response.status === WebServiceStatus.success) {
          const {
            batches,
            meta,
          }: {
            batches: VueBatchDataFromServer[];
            meta: VuesBatchPageAPIMetaData;
          } = response.data;
          gridData = batches.map((VueBatchDataFromServer) => {
            return getGridDataForVueBatch(VueBatchDataFromServer);
          });
          setMetaValues({
            pageSize: Number(meta.pageSize),
            pageNumber: Number(meta.pageNumber),
            totalPages: Number(meta.totalPages),
            totalElements: Number(meta.totalElements),
          });
        } else {
          setSnackbarContent({
            title: "Attention!",
            message: response.error ?? GENERIC_ERROR_MESSAGE,
            isError: true,
          });
        }
        if (isScrolling) {
          setFilteredRowData((prev) => prev.concat(gridData));
        } else {
          setFilteredRowData(gridData);
        }
        setLoader(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  const handleOnRowsScrollEnd: DataGridPremiumProps["onRowsScrollEnd"] =
    async () => {
      const shouldScroll =
        filteredRowData.length < metaValues.totalElements &&
        metaValues.pageNumber < metaValues.totalPages;
      if (shouldScroll) {
        await fetchVueBatchListFromServer(true);
      }
    };

  // Search field changes
  useEffect(() => {
    if (searchTextChanged) {
      // performs filter on given field and text
      const performSearchByBatchName = (searchText: string) => {
        dispatchClientsVueBatchFetchAction({
          type: VuesBatchPageActionType.searchTextChanged,
          payload: {
            ...clientVueBatchState,
            searchText: searchText,
          },
        });
      };

      /// The method delays the callback for 700 millseconds
      const delaySearchAction = setTimeout(() => {
        performSearchByBatchName(searchText.trim());
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  // applying filters
  useEffect(() => {
    const updatePayload = () => {
      var payload: VuesBatchPageSummaryState = {
        ...defaultVuesBatchPageSummaryState,
      };
      selectedFilters.forEach((filter) => {
        const { field, filters } = filter;
        switch (field) {
          case "batchid":
            payload = {
              ...payload,
              canonicalId: filters[0],
            };
            break;
          case "batchcreationdate":
            payload = {
              ...payload,
              startDate: filters[0],
              endDate: filters[1],
            };
            break;
          case "batchname":
            payload = {
              ...payload,
              batchName: filters[0],
            };
            break;
          case "totalvues":
            payload = {
              ...payload,
              minTotalVueCount: filters[0],
              maxTotalVueCount: filters[1],
            };
            break;
          case "completedvues":
            payload = {
              ...payload,
              minCompletedVueCount: filters[0],
              maxCompletedVueCount: filters[1],
            };
            break;
          case "surveyname":
            payload = {
              ...payload,
              surveyName: filters[0],
            };
            break;
          case "requirements":
            const companyIds = availableCompanyList
              .filter((company) => filters.includes(company.name))
              .map((company) => company.id);
            payload = {
              ...payload,
              clientIds: companyIds,
            };
            break;
        }
      });
      dispatchClientsVueBatchFetchAction({
        type: VuesBatchPageActionType.filterApplied,
        payload: payload,
      });
    };
    if (selectedFilters.length > 0) {
      updatePayload();
    } else {
      // Clearing the filter.
      dispatchClientsVueBatchFetchAction({
        type: VuesBatchPageActionType.clearAllFilterClicked,
        payload: defaultVuesBatchPageSummaryState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  useEffect(() => {
    fetchVueBatchListFromServer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientVueBatchState]);

  useEffect(() => {
    if (!selectAll) {
      return;
    }
    const listOfSelectedRowIds: GridRowId[] = filteredRowData.map(
      (row) => row.id
    );
    setSelectedRows(selectAll ? listOfSelectedRowIds : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRowData]);

  return (
    <>
      {showExportDialog && getExportDialog()}
      {popperName && getFilterPopper()}
      {openPreviewDialog && getSurveyPreviewDialog()}
      <MDBox>
        {showLoader && <CustomIndicator />}
        <MDBox display="flex" alignItems="end" pl={2} pb={2.2}>
          <FormGroup sx={{ flexShrink: "0" }}>
            <FormControlLabel
              control={
                <Checkbox checked={selectAll} onChange={handleCheckboxChange} />
              }
              sx={checkBoxStyle}
              label={
                selectedRows.length > 0
                  ? `${
                      selectAll ? metaValues.totalElements : selectedRows.length
                    }/${metaValues.totalElements} Selected`
                  : ""
              }
            />
          </FormGroup>
          {getTextField()}
          {getDatePickers()}
          <MDBox display="flex" ml="auto" justifyContent="space-between">
            {selectedFilters.length > 0 && getClearAllFiltersButton()}
            {getExportButton()}
          </MDBox>
        </MDBox>
        <Card
          sx={{
            minHeight: `calc(90vh - 110px)`,
            height: `calc(90vh - 110px)`,
            justifyContent: "flex-start",
            position: "relative",
          }}
        >
          <DataGridPremium
            apiRef={gridRef}
            columns={renderedColumns}
            rows={filteredRowData}
            checkboxSelection
            disableColumnMenu
            sx={{
              ...styleForUploadTemplateGrid,
              ...gridStyle,
            }}
            density="compact"
            sortingMode="server"
            filterMode="server"
            disableRowSelectionOnClick
            keepNonExistentRowsSelected
            onRowSelectionModelChange={handleRowSelectionChange}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            rowSelectionModel={selectedRows}
            sortModel={sortModel}
            initialState={{
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...pinnedColumns],
              },
            }}
            loading={loader}
            slots={{
              footer: getFooter,
              loadingOverlay: LinearProgress,
              noRowsOverlay: getNoRowsMessage,
            }}
          />
        </Card>
        <CustomSnackbar
          snackbarContent={snackbarContent}
          onClose={() => {
            setSnackbarContent(null);
          }}
        />
      </MDBox>
    </>
  );
};
export default BatchListDataGrid;
