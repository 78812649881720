function configs(labels: any, datasets: any) {
  return {
    data: {
      labels,
      datasets: [...datasets],
    },
    options: {
      indexAxis: "y",
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          border: { dash: [5, 5], display: false },
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "#c7ccd0",
          },
          ticks: {
            display: true,
            color: "#344767",
            padding: 20,
            textTransform: "uppercase",
            font: {
              size: 16,
              weight: 400,
              family: "Roboto",
            },
          },
        },
        x: {
          border: { dash: [5, 5], display: false },
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: true,
            drawTicks: true,
            color: "#c7ccd0",
          },
          ticks: {
            display: true,
            color: "#344767",
            font: {
              size: 16,
              weight: 400,
              family: "Roboto",
            },
          },
        },
      },
    },
  };
}

export default configs;
