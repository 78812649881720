import { DEFAULT_PAGE_SIZE } from "./../../../../../../constants";

export const initialVisibilityModel = {
  clientName: false,
  completedTime: false,
  latitude: false,
  longitude: false,
  slaActual: false,
  slaTarget: false,
};

export const defaultVueStatusCount = {
  openVues: 0,
  inProgressVues: 0,
  inReviewVues: 0,
  failedVues: 0,
  completedVues: 0,
  cancelledVues: 0,
  totalVues: 0,
};

export const defaultMetaData = {
  pageSize: DEFAULT_PAGE_SIZE as number,
  pageNumber: 1,
  totalPages: 0,
  totalElements: 0,
};

/// Ids of pinned columns
export const pinnedColumnIds = ["vueId", "vueName", "siteName"];
/// Headers of pinned columns
export const pinnedColumnHeaders = ["Vue ID", "Vue Name", "Site Name"];
