// react-router-dom components
import { Link, useParams, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import "utils/helpers/extensions";

// Images
import bgImage from "assets/images/authentication-cover.jpeg";
import { InfoOutlined } from "@mui/icons-material";
import { useReducer, useState } from "react";
import CoverLayout from "layouts/CoverLayout";
import MessageAlert from "../components/MessageAlert";
import CustomPasswordInputs from "../components/CustomPasswordInputs";
import { RoutePath } from "./../../../constants";
import { requestSMSPincode } from "pages/authentication/services/AuthenticationServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { VerificationPageType } from "../sms-verification/SMSVerification";
import { CustomIndicator } from "pages/components/CustomIndicator";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

export interface SMSVerificationParams {
  phoneNumber: string,
  emailId: string,
  password: string,
  requestId: string,
  verificationCode: string
}

// Contains all types of actions to be used in the setup account page
enum SetupAccountActionType {
  setPassword = "SET_PASSWORD",
  setPhoneNumber = "SET_PHONE_NUMBER",
}

interface SetupAccountAction {
  type: SetupAccountActionType;
  payload: {
    value1: string;
    value2: string;
  };
}

interface SetupAccountState {
  emailId: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  isFormValid: boolean;
  hasError: boolean;
  error: string;
}

const defaultAccountSetupState = {
  emailId: "",
  password: "",
  confirmPassword: "",
  phoneNumber: "",
  isFormValid: false,
  hasError: false,
  error: "",
};

const setupAccountReducer = (
  state: SetupAccountState,
  action: SetupAccountAction
) => {
  const { type, payload } = action;
  switch (type) {
    case SetupAccountActionType.setPassword: {
      const password = payload.value1;
      const confirmPassword = payload.value2;
      const isFormValid =
        state.emailId.isValidEmail() &&
        password.getStrongPasswordStatus().isStrong &&
        confirmPassword === password &&
        state.phoneNumber.isValidUSPhoneNumber();
      return {
        ...state,
        password: password,
        isFormValid: isFormValid,
        confirmPassword: confirmPassword,
      };
    }
    case SetupAccountActionType.setPhoneNumber: {
      const phoneNumber = payload.value1;
      const isFormValid =
        state.emailId.isValidEmail() &&
        state.password.getStrongPasswordStatus().isStrong &&
        state.confirmPassword === state.password &&
        phoneNumber.isValidUSPhoneNumber();

      return {
        ...state,
        isFormValid: isFormValid,
        phoneNumber: phoneNumber,
      };
    }
    default:
      return state;
  }
};

function SetupAccount(): JSX.Element {
  /// Hook used for fetching the urlParams
  const { email, requestId, verificationCode } = useParams();
  /// Decoded the email extracted from the url
  const emailId = email.decodeParam();
  /// State to show/hide progress indicator
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [setupAccountState, dispatchSetupAction] = useReducer(
    setupAccountReducer,
    { ...defaultAccountSetupState, emailId: emailId }
  );
  const [hasError, setHasError] = useState<boolean>(false);
  const [error, setError] = useState<string>("Your passwords do not match");

  /// Hook for navigation
  const navigateToVerificationPage = useNavigate();

  ////// METHODS ///////

  // handles the phone number change
  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const formattedPhoneNumber = event.target.value.formatPhoneNumber();
    dispatchSetupAction({
      type: SetupAccountActionType.setPhoneNumber,
      payload: { value1: formattedPhoneNumber, value2: "" },
    });
  };

  /// Handles the continue button click and validates the inputs
  const handleUpdatePasswordClick = () => {
    if (!emailId.isValidEmail()) {
      setHasError(true);
      setError("Invalid Email");
    } else if (!setupAccountState.password.getStrongPasswordStatus().isStrong) {
      setHasError(true);
      setError("Please enter a strong password");
    } else if (setupAccountState.confirmPassword.isEmpty()) {
      setHasError(true);
      setError("Please confirm your password");
    } else if (
      setupAccountState.confirmPassword !== setupAccountState.password
    ) {
      setHasError(true);
      setError("Your passwords do not match");
    } else if (!setupAccountState.phoneNumber.isValidUSPhoneNumber()) {
      setHasError(true);
      setError("Please enter a valid phone number");
    } else {
      setHasError(false);
      proceedToNextpage();
    }
  };

  /// Getting the password and confirmPassword field values from "CustomPasswordInputs" component and storing it here locally
  const handlePasswordChange = (password: string, confirmPassword: string) => {
    dispatchSetupAction({
      type: SetupAccountActionType.setPassword,
      payload: { value1: password, value2: confirmPassword },
    });
  };

  /// Proceeds to 2FA Screen
  const proceedToNextpage = async () => {
    setShowLoader(true);
    /// Removes all non-numbers
    const parsedPhoneNumber =
      setupAccountState.phoneNumber.replaceSpecialChars();
    const params: SMSVerificationParams = {
      phoneNumber: parsedPhoneNumber,
      emailId: emailId,
      password: setupAccountState.password,
      requestId: requestId,
      verificationCode: verificationCode
    }
    const response = await requestSMSPincode(parsedPhoneNumber);
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      navigateToVerificationPage(RoutePath.smsVerification, {
        state: {
          type: VerificationPageType.not2FA,
          data: params
        },
      });
    } else {
      setShowLoader(false);
      setHasError(true);
      const error =
        response.error as string;
      setError(error);
    }
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              variant="h5"
              fontWeight="medium"
              color="white"
              mt={1}
              pb={1}
            >
              Set Up Account
            </MDTypography>
            <MDTypography
              display="block"
              variant="button"
              color="white"
              my={1}
              fontWeight="light"
            >
              Provide a password and phone number
            </MDTypography>
          </MDBox>
          {hasError && (
            <MDBox px={3}>
              <MessageAlert
                message={error}
                type="error"
                icon={<InfoOutlined />}
              />
            </MDBox>
          )}
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={3}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  sx={{ fontSize: 14 }}
                >
                  Email
                </MDTypography>
                <MDInput
                  type="email"
                  placeholder="Email"
                  fullWidth
                  value={emailId}
                  disabled
                />
              </MDBox>
              <CustomPasswordInputs
                error={error}
                onChangePasswordValue={handlePasswordChange}
              />
              <MDBox mb={3}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  sx={{ fontSize: 14 }}
                >
                  Phone Number<RequiredFieldMarker />
                </MDTypography>
                <MDInput
                  type="text"
                  placeholder="(555) 555-5555"
                  value={setupAccountState.phoneNumber}
                  fullWidth
                  onBlur={() => { }}
                  onChange={handlePhoneNumberChange}
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>

              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleUpdatePasswordClick}
                  disabled={!setupAccountState.isFormValid}
                >
                  Update Password
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text" fontWeight="regular">
                  Already have an account?{" "}
                  <MDTypography
                    component={Link}
                    to={RoutePath.root}
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </CoverLayout>
    </>
  );
}

export default SetupAccount;
