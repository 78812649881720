import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import MDBox from "components/MDBox";
import ShopIcon from "assets/images/Shop_Icon.svg";
import { IconButton } from "@mui/material";
import { buttonHoverStyle, logoConStyle } from "../styles";
import { useEffect, useRef, useState } from "react";
import imageCompression from "browser-image-compression";
import {
  downloadFile,
  uploadFile,
} from "pages/profile/services/ProfileServices";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { WebServiceStatus } from "utils/services/AppUrls";
import { MAX_FILE_SIZE_IN_MB } from "./../../../constants";
import MDAvatar from "components/MDAvatar";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";

interface Props {
  logoId: string;
  onCompleteUpload: (id: string) => void;
}

const PortalLogo = (props: Props) => {
  /// Reference for hidden input field
  const hiddenFileInputRef = useRef(null);
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  /// Downloading profile picture
  useEffect(() => {
    const downloadProfilePic = async () => {
      if (props.logoId) {
        const imageData = await downloadFile(props.logoId);
        if (imageData) {
          const { mimeType, data } = imageData;
          const url = `data:${mimeType};base64,${data}`;
          setLogoUrl(url);
        } else {
          setLogoUrl(null);
        }
      } else {
        setLogoUrl(null);
      }
    };
    downloadProfilePic();
  }, [props.logoId]);

  /// This checks whether all the criteria is satisfied by the picked file
  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const pickedFile = event.target.files && event.target.files[0];
    const FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
    if (!pickedFile.type.startsWith("image/")) {
      setSnackbarContent({
        title: "Attention!",
        message: "Please choose a valid image file.",
        isError: true,
      });
      return;
    }
    if (pickedFile !== undefined) {
      /// Handles smaller image
      if (pickedFile.size <= FILE_SIZE_IN_BYTES) {
        handleFileUpload(pickedFile);
        return;
      }
      /// Handles larger image
      const options = {
        maxSizeMB: MAX_FILE_SIZE_IN_MB,
        useWebWorker: true,
        maxWidthOrHeight: 800,
      };
      try {
        const compressedFile = await imageCompression(pickedFile, options);
        handleFileUpload(compressedFile);
      } catch (error) {
        console.log("Error caught while compressing file:", error);
      }
    }
  };

  /// Handles the file upload
  const handleFileUpload = (pickedFile: File) => {
    setShowLoader(true);
    const reader = new FileReader();
    reader.onloadend = async (event) => {
      const dataURL = reader.result as string;
      const extractedUrl = dataURL.split(",")[1];
      const fileName = pickedFile.name;
      const mimeType = pickedFile.type;
      const response = await uploadFile({
        filename: fileName,
        mimeType: mimeType,
        data: extractedUrl,
      });
      if (response.status === WebServiceStatus.success) {
        const uploadedProfileImageId = response.data;
        props.onCompleteUpload(uploadedProfileImageId);
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: response.error,
          isError: true,
        });
      }
      setShowLoader(false);
    };
    reader.readAsDataURL(pickedFile);
  };

  /// Handles the onClick of "Edit" button over the logo
  const handleEditLogo = () => {
    // Opens file input box - Triggers the hidden file input field
    hiddenFileInputRef.current.click();
  };

  return (
    <MDBox sx={logoConStyle}>
      {showLoader && <CustomIndicator />}
      <MDAvatar
        src={logoUrl ? logoUrl : ShopIcon}
        alt="Profile Image"
        size="lg"
        sx={{
          backgroundColor: "#4CAF50",
          img: logoUrl
            ? {}
            : {
                width: "auto !important",
                height: "auto !important",
              },
        }}
      />
      <MDBox sx={buttonHoverStyle}>
        <IconButton color="primary" onClick={handleEditLogo}>
          <Icon path={mdiPencil} size={0.75} />
        </IconButton>
      </MDBox>
      <input
        type="file"
        accept="image/*"
        ref={hiddenFileInputRef}
        onChange={handleFileInputChange}
        style={{ display: "none" }}
      />
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </MDBox>
  );
};
export default PortalLogo;
