import { Icon } from '@mdi/react'
import { mdiClose } from '@mdi/js';
import MDTypography from 'components/MDTypography'
import { MDBox, MDButton } from '../../vue-grid/helpers/imports'

interface Props {
    label: string;
    onClick: () => void;
    isImmutable?:boolean;
}

export const CustomDeletableChip = (props: Props) => {

    const isImmutable = props.isImmutable ?? false;

    return (

        <MDBox
            sx={{
                p: "6px 8px",
                background: "#C7CCD0",
                display: "inline-flex",
                width: "auto",
                borderRadius: "6px",
                margin: "0 10px 4px 0"
            }}>
            <MDTypography
                variant="button"
                sx={{
                    color: "#191919",
                    fontWeight: "600",
                    fontSize: "12px"
                }}>
                {props.label}
            </MDTypography>
            {!isImmutable && <MDButton
                onClick={props.onClick}
                sx={{
                    background: "transparent",
                    color: "#191919",
                    padding: "0",
                    minHeight: "unset",
                    minWidth: "unset",
                    marginLeft: "10px",
                    ":hover, :focus:not(:hover) ": {
                        background: "transparent",
                        boxShadow: "unset"
                    }
                }}>
                <Icon path={mdiClose} size={0.7} />
            </MDButton>}
        </MDBox>

    )
}
