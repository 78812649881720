import DialogContent from "@mui/material/DialogContent";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import tickImage from "assets/images/icon_tick.svg";

interface Props {
  title: string;
  description: string;
  subDescription?: string;
  closeDialog: () => void;
  buttonLabel: string;
}

export const ConfirmationDialogContent = (props: Props) => {
  return (
    <>
      <DialogContent>
        <MDBox pt={4} px={16} pb={4} display="flex" justifyContent="center">
          <MDAvatar
            size="xxl"
            bgColor="info"
            alt="Avatar"
            sx={{
              fontSize: "3.6em",
              width: "134px",
              height: "134px",
              img: {
                width: "64px",
                height: "auto",
              },
            }}
          >
            <img src={tickImage} alt="" />
          </MDAvatar>
        </MDBox>
        <MDTypography
          pb={2}
          textAlign="center"
          sx={{ color: "344767", fontSize: "22px", fontWeight: "bold" }}
        >
          {props.title}
        </MDTypography>
        <MDTypography
          pb={2}
          textAlign="center"
          sx={{ color: "344767", fontSize: "14px", fontWeight: "regular" }}
        >
          {props.description}
        </MDTypography>
        {props.subDescription && (
          <MDTypography
            pb={2}
            textAlign="center"
            sx={{ color: "344767", fontSize: "14px", fontWeight: "regular" }}
          >
            {props.subDescription}
          </MDTypography>
        )}
        <MDBox display="flex" justifyContent="center" pb={2} pt={5}>
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              alignContent: "center",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
            onClick={props.closeDialog}
          >
            {props.buttonLabel}
          </MDButton>
        </MDBox>
      </DialogContent>
    </>
  );
};
