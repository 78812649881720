import {
  SettingsPageAPIServices,
  WebServiceResponse,
} from "utils/services/AppUrls";
import {
  performGetRequest,
  performPostRequest,
} from "utils/services/NetworkHandler";

///////// VUE TAGS APIS  ////////////

export const getVueTags = async (): Promise<WebServiceResponse> => {
  const url = `${SettingsPageAPIServices.getVueTags}`;
  return await performGetRequest(url);
};

export const AddVueTag = async (
  tagName: string
): Promise<WebServiceResponse> => {
  const url = `${SettingsPageAPIServices.addVueTags}`;
  const params = {
    name: tagName,
  };
  return await performPostRequest(url, params);
};

export const DeleteVueTag = async (
  tagId: string
): Promise<WebServiceResponse> => {
  const url = `${SettingsPageAPIServices.deleteVueTags}/${tagId}`;
  return await performPostRequest(url, {});
};

export const EditVueTag = async (
  tagId: string,
  tagName: string
): Promise<WebServiceResponse> => {
  const url = `${SettingsPageAPIServices.editVueTags}/${tagId}`;
  const params = {
    name: tagName,
  };
  return await performPostRequest(url, params);
};

/// MEDIA TAGS API ///

export const getMediaTags = async (): Promise<WebServiceResponse> => {
  const url = `${SettingsPageAPIServices.getMediaTags}`;
  return await performGetRequest(url);
};

export const AddMediaTag = async (
  tagName: string
): Promise<WebServiceResponse> => {
  const url = `${SettingsPageAPIServices.addMediaTags}`;
  const params = {
    name: tagName,
  };
  return await performPostRequest(url, params);
};

export const DeleteMediaTag = async (
  tagId: string
): Promise<WebServiceResponse> => {
  const url = `${SettingsPageAPIServices.deleteMediaTags}/${tagId}`;
  return await performPostRequest(url, {});
};

export const EditMediaTag = async (
  tagId: string,
  tagName: string
): Promise<WebServiceResponse> => {
  const url = `${SettingsPageAPIServices.editMediaTags}/${tagId}`;
  const params = {
    name: tagName,
  };
  return await performPostRequest(url, params);
};

// Generates API keys
export const generateAPIKeys = async (
  companyId: string
): Promise<WebServiceResponse> => {
  return await performPostRequest(SettingsPageAPIServices.generateAPIKeys, {
    companyId: companyId,
  });
};
