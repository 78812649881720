import { SxProps } from '@mui/material/styles';
import MDBadge from 'components/MDBadge'
import { ReactNode } from 'react';

interface BadgeProps {
    content: ReactNode;
    max?: number;
    [key: string]: any;
    sx?: SxProps;
    borderRadius?: string;
    onClick?: (event: Event | React.SyntheticEvent<Element, Event>) => void;
}

export const CustomBadge = ({ content, sx, borderRadius, onClick, max, ...attributes }: BadgeProps) => {
    return (
        <MDBadge
            badgeContent={content}
            max={max} // used to disable max count settings. 
            container
            variant="contained"
            size="md"
            onClick={onClick}
            {...attributes}
            sx={{
                pr: 0.5,
                cursor: (onClick !== null && onClick !== undefined) ? "pointer" : "default",
                fontSize: "12px",
                fontWeight: "bold",
                "& .MuiBadge-badge": {
                    textTransform: "none",
                    color: '#191919',
                    backgroundColor: "#DEE2E8 !important",
                    padding: `8px 8px`,
                    borderRadius: borderRadius
                },
                ...sx
            }} />
    )
}
