import { SxProps } from "@mui/material"
import colors from "assets/theme/base/colors"

const vueHeadTooltip: SxProps = {
    '& .MuiTooltip-arrow': {
        backgroundColor: "#dadee3 !important"
    }
}

const vuerCellStyle: SxProps = {
    background: 'transparent',
    boxShadow: 'none',
    ' & .css-16b2o8n-MuiPaper-root-MuiCard-root': {
        background: 'transparent',
    },
    '& .MuiDataGrid-cell': {
        py: 2
    }
}

const vuerSingleUserStyle: SxProps = {
    minWidth: '9em',
    maxWidth: '12em',
    '@media(min-width: 75em)':{
        width: '15.66%',
        minWidth: '15.66%',
        maxWidth: '20%',
    },
}

const vuerCardLazyStyle: SxProps = {
    px: 2,
    py: 2,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    minWidth: "fit-content",
}

const tableFontStyle:SxProps = {
    fontSize: '0.7em',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    overflow:'hidden',
    width:'100%',
    color: colors.dark.main,
    fontWeight:'bold'
}

const buttonStyle:SxProps = {
    px: 0,
    py: 0,
    textTransform: 'capitalize',
    minHeight: '2em',
    fontSize: '0.7em',
}

const searchBarStyle:SxProps = {
    '& .css-171mfdn-MuiInputBase-root-MuiOutlinedInput-root':{
        backgroundColor:colors.white.main
    }
}

export {
    vueHeadTooltip,
    buttonStyle,
    vuerCellStyle,
    vuerCardLazyStyle,
    vuerSingleUserStyle,
    searchBarStyle,
    tableFontStyle
}