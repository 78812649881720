import {
  EditableCell,
  getFieldNameFromHeader,
  CellEditIconType,
  validateAddress,
} from "@ivueit/vue-engine";
import {
  GridColDef,
  GridEditInputCell,
  GridRenderEditCellParams,
  GridRowsProp,
} from "@mui/x-data-grid-premium";
import { SiteListPageSummaryState } from "./reducer";
import {
  SiteListDataFromServer,
  SiteListGridData,
} from "../interfaces/interfaces";
import {
  MAXIMUM_LENGTH_INSTRUCTIONS,
  MAXIMUM_LENGTH_SITENUMBER,
} from "./../../../../../constants";

export const renameTemplateColumnHeaders = (header: string): string => {
  switch (header) {
    case "SITENAME":
      return "SITE";
    case "SITENUMBER":
      return "SITE NUMBER";
    case "ADDRESS":
      return "ADDRESS";
    case "ADDRESSTWO":
      return "ADDRESS 2";
    case "CITY":
      return "CITY";
    case "STATE":
      return "STATE";
    case "ZIP":
      return "ZIP CODE";
    case "COUNTRY":
      return "COUNTRY";
    case "ARCHIVED":
      return "ARCHIVED";
    case "LOCATIONNOTES":
      return "INTERNAL NOTE";
    case "LATITUDE":
      return "LAT/LONG";
    case "LONGITUDE":
      return "";
    default:
      return header;
  }
};

export const siteListRequiredColumns = [
  "SiteName",
  "SiteNumber",
  "Address",
  "AddressTwo",
  "City",
  "State",
  "Zip",
  "Country",
].map((e) => e.trim().toUpperCase());

// Known headers in sitelist page, all these columns are required
export const siteListFixedHeaders = [
  "SiteName",
  "SiteNumber",
  "LocationNotes",
  "Address",
  "AddressTwo",
  "City",
  "State",
  "Zip",
  "Country",
  "Latitude",
  "Longitude",
  "Archived",
].map((e) => e.trim().toUpperCase());
// Below names are expected to be available on the site list grid
export const knownSitelistColumns = [
  "SiteName",
  "SiteNumber",
  "LocationNotes",
  "Address",
  "Address2",
  "City",
  "State",
  "Zip",
  "Country",
  "Latitude",
  "Longitude",
  "Archived",
  "site",
  "zipcode",
  "latlong",
  "id",
  "internalnote",
].map((e) => e.trim().toUpperCase());

/// Editable columns
export const knownEditableColumns: EditableCell[] = [
  { fieldName: getFieldNameFromHeader("Site") },
  { fieldName: getFieldNameFromHeader("Site Number") },
  { fieldName: getFieldNameFromHeader("Internal Note") },
  {
    fieldName: getFieldNameFromHeader("Address"),
    editIcon: CellEditIconType.map,
  },
];

/// Columns needs to be pinned
export const siteListPinnedColumns = ["Site Name", "Site", "Site Number"].map(
  (e) => getFieldNameFromHeader(e.trim())
);

// Generating default column data
const getDefaultColumnData = (): GridColDef[] => {
  const fixedColumns = siteListFixedHeaders
    .map((header) => renameTemplateColumnHeaders(header))
    .filter((header) => header)
    .map((header) => ({
      field: header === "LAT/LONG" ? "latlong" : getFieldNameFromHeader(header),
      headerName: header,
      width: header === "LAT/LONG" ? 250 : 200,
      sortable: false,
      /// Editable should be true if the item is available in the editable list
      editable: knownEditableColumns.some(
        (item) =>
          getFieldNameFromHeader(item.fieldName) ===
          getFieldNameFromHeader(header)
      ),
    }));

  // Dynamically adding site name column to the table
  var finalColumnData: GridColDef[] = [
    {
      field: getFieldNameFromHeader("Site Name"),
      headerName: "Site Name",
      width: 200,
      sortable: false,
      // Generating column value by joining site & site number
      valueGetter: (params) => {
        return `${params.row.site || ""} ${params.row.sitenumber || ""}`;
      },
    },
    ...fixedColumns,
  ];

  // Below code is used restrict the user from adding more than 100 characters to the site number
  const indexOfSiteNumber = finalColumnData.findIndex(
    (column) => column.field === "sitenumber"
  );
  if (indexOfSiteNumber !== -1) {
    // Site number column is alredy available
    const newSiteNumberColumn = {
      ...finalColumnData[indexOfSiteNumber],
      renderEditCell: (params: GridRenderEditCellParams) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            maxLength: MAXIMUM_LENGTH_SITENUMBER,
          }}
        />
      ),
    };
    finalColumnData[indexOfSiteNumber] = newSiteNumberColumn;
  }

  // Below code is used restrict the user from adding more than 100 characters to the site number
  const indexOfInternalNotes = finalColumnData.findIndex(
    (column) => column.field === "internalnote"
  );
  if (indexOfInternalNotes !== -1) {
    // Notes column is alredy available
    const newNotesColumn = {
      ...finalColumnData[indexOfInternalNotes],
      renderEditCell: (params: GridRenderEditCellParams) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            maxLength: MAXIMUM_LENGTH_INSTRUCTIONS,
          }}
        />
      ),
    };
    finalColumnData[indexOfInternalNotes] = newNotesColumn;
  }

  return finalColumnData;
};

export const defaultColumnData: GridColDef[] = getDefaultColumnData();

export const sortRowsBySiteName = (
  rowsToBeSorted: GridRowsProp
): GridRowsProp => {
  // Sorting the row data alphabetically
  const sortedRows = [...rowsToBeSorted].sort((a, b) => {
    const siteNameA = `${a.site} ${a.sitenumber}`.trim().toUpperCase();
    const siteNameB = `${b.site} ${b.sitenumber}`.trim().toUpperCase();
    return siteNameA < siteNameB ? -1 : siteNameA > siteNameB ? 1 : 0;
  });

  return sortedRows;
};

export const getIndexOfRowWithDuplicateSiteName = (
  rows: GridRowsProp,
  site: string,
  sitenumber: string
): number => {
  const duplicateRowIndex = rows.findIndex((row) => {
    /// Trimmed both site & site number in order to avoid the trailing white spaces
    const siteName =
      `${row.site.trim()} ${row.sitenumber.trim()}`.toLowerCase();
    const newSiteName = `${site.trim()} ${sitenumber.trim()}`.toLowerCase();
    return siteName === newSiteName;
  });

  return duplicateRowIndex;
};

export const generateSiteListAPIParamter = (
  siteListState: SiteListPageSummaryState,
  pageNumber: string,
  pageSize: number
): string => {
  // Pagination is NOT needed to load all data together
  var parameterString = "";
  // Appending clientId
  if (siteListState.companyId.isNotEmpty()) {
    parameterString += `&companyId=${siteListState.companyId}`;
  }
  // Appending searchText
  if (siteListState.search.isNotEmpty()) {
    parameterString += `&siteNameAndNumber=${siteListState.search}`;
  }
  // Appending siteNameAndNumber
  if (siteListState.siteNameNumber.isNotEmpty()) {
    parameterString += `&siteNameAndNumber=${siteListState.siteNameNumber}`;
  }
  // Appending site
  if (siteListState.site.isNotEmpty()) {
    parameterString += `&siteName=${siteListState.site}`;
  }
  // Appending siteNumber
  if (siteListState.siteNumber.isNotEmpty()) {
    parameterString += `&siteNumber=${siteListState.siteNumber}`;
  }
  // Appending city
  if (siteListState.city.isNotEmpty()) {
    parameterString += `&city=${siteListState.city}`;
  }
  // Appending state
  if (siteListState.state.isNotEmpty()) {
    parameterString += `&state=${siteListState.state}`;
  }
  // Appending country
  if (siteListState.country.length > 0) {
    const countryList = siteListState.country.join("&country=")
    parameterString += `&country=${countryList}`;
  }
  // Appending postal code
  if (siteListState.postalCode.isNotEmpty()) {
    parameterString += `&postalCode=${siteListState.postalCode}`;
  }
  // Appending address
  if (siteListState.address.isNotEmpty()) {
    parameterString += `&address=${siteListState.address}`;
  }
  // Appending address2
  if (siteListState.addressTwo.isNotEmpty()) {
    parameterString += `&addressTwo=${siteListState.addressTwo}`;
  }
  // Appending archive status
  if (siteListState.archive !== null && siteListState.archive !== undefined) {
    parameterString += `&archived.value=${siteListState.archive}`;
  }
  // Appending sortColumnDisplayName
  if (siteListState.sortColumnDisplayName.isNotEmpty()) {
    const columnName = siteListState.sortColumnDisplayName;
    parameterString += `&sortColumnDisplayName=${
      columnName === "Address 2" ? "Address Two" : columnName
    }`;
  }

  // Appending sortColumnDisplayName
  if (
    siteListState.sortIsAscending !== null &&
    siteListState.sortIsAscending !== undefined
  ) {
    parameterString += `&sortIsAscending=${siteListState.sortIsAscending}`;
  }
  return parameterString;
};

export const getGridDataForSiteList = (
  siteList: SiteListDataFromServer
): SiteListGridData => {
  return {
    site: siteList.siteName,
    sitenumber: siteList.siteNumber,
    address: `${siteList.streetNumber} ${siteList.routeShort}`,
    address2: siteList.addressTwo,
    city: siteList.cityShort,
    state: siteList.stateShort,
    zipcode: siteList.postalCode,
    country: siteList.countryShort,
    latlong: `${siteList.latitude}, ${siteList.longitude}`,
    archived: siteList.archived ? "Yes" : "No",
    id: siteList.id,
    internalnote: siteList.locationNotes,
    ...(siteList.meta ?? {}), // Appending custom columns to the grid data
  };
};

/// This returns the previous value searched by the user in all poppers with text fields
export const getPreviouslySearchedTextForSiteList = (
  siteListState: SiteListPageSummaryState,
  columnName: string
) => {
  let previouslySearchedText = "";
  switch (columnName) {
    case "sitename":
      previouslySearchedText = siteListState.siteNameNumber;
      break;
    case "site":
      previouslySearchedText = siteListState.site;
      break;
    case "sitenumber":
      previouslySearchedText = siteListState.siteNumber;
      break;
    case "address":
      previouslySearchedText = siteListState.address;
      break;
    case "address2":
      previouslySearchedText = siteListState.addressTwo;
      break;
    case "state":
      previouslySearchedText = siteListState.state;
      break;
    case "city":
      previouslySearchedText = siteListState.city;
      break;
    case "zipCode":
      previouslySearchedText = siteListState.postalCode;
      break;
    default:
      break;
  }
  return previouslySearchedText;
};

export const getPayloadForTextFieldChangesForSiteList = (
  siteListState: SiteListPageSummaryState,
  fieldName: string,
  newText: string
): SiteListPageSummaryState => {
  var payload = { ...siteListState };
  switch (fieldName) {
    case "sitename":
      payload = {
        ...siteListState,
        siteNameNumber: newText,
      };
      break;
    case "site":
      payload = {
        ...siteListState,
        site: newText,
      };
      break;
    case "sitenumber":
      payload = {
        ...siteListState,
        siteNumber: newText,
      };
      break;
    case "address":
      payload = {
        ...siteListState,
        address: newText,
      };
      break;
    case "address2":
      payload = {
        ...siteListState,
        addressTwo: newText,
      };
      break;
    case "state":
      payload = {
        ...siteListState,
        state: newText,
      };
      break;
    case "city":
      payload = {
        ...siteListState,
        city: newText,
      };
      break;
    case "zipcode":
      payload = {
        ...siteListState,
        postalCode: newText,
      };
      break;
    default:
      break;
  }
  return payload;
};

export const isContainEmptyAddressComponent = async (
  address: string,
  city: string,
  state: string,
  country: string,
  zipcode: string,
  latlong: string,
  performGoogleAPICheck: boolean
): Promise<boolean> => {
  if (!address || !city || !state || !zipcode || !country) {
    // returns error if any one of the field is undefined or null
    return false;
  }

  if (
    address.trim().isEmpty() ||
    city.trim().isEmpty() ||
    state.trim().isEmpty() ||
    zipcode.trim().isEmpty() ||
    country.trim().isEmpty()
  ) {
    // returns error if any one of the field is empty
    return false;
  } else if (performGoogleAPICheck) {
    return await validateAddress(
      {
        address: address,
        city: city,
        state: state,
        country: country,
        zipcode: zipcode,
        latlong: latlong,
      },
      process.env.REACT_APP_GMAP_API_KEY
    );
  } else {
    return true;
  }
};
