import MDButton from "components/MDButton";
import { ModalPopUp } from "pages/components/ModalPopUp";

interface Props {
  message: string;
  buttonName: string;
  onClose: () => void;
  onClick: () => void;
}

export const ConfirmationDialog = (props: Props) => {
  // Generates actions for confirmation box
  const confirmationDialogActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={props.onClose}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={props.onClick}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        {props.buttonName}
      </MDButton>,
    ];
  };

  return (
    <ModalPopUp
      content={props.message}
      closeDialog={props.onClose}
      actions={confirmationDialogActions()}
    />
  );
};
