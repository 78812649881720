import {
    gridStyle,
} from "@ivueit/vue-engine";
import {
    Skeleton,
} from "@mui/material";
import {
    DataGridPremium, GridColDef
} from "@mui/x-data-grid-premium";
import { vuerCellStyle } from './ManageInternalVuerNetworkStyle'
import { dataGridCellButtonStyle } from "pages/dashboard/home/site-lists/styles/site_list_style";
import MDBox from "components/MDBox";
import colors from 'assets/theme/base/colors';



const SkeletonLoader = () => {


    const columns: GridColDef[] = [
        {
            "field": "vueId",
            "headerName": "Vue ID",
            "width": 255,
            "minWidth": 255,
            "maxWidth": 365,
            "filterable": false,
            "sortable": false,
            "pinnable": false,
            "disableReorder": false,
            "hideable": false,
            renderCell: () => {
                return (
                    <Skeleton variant="text" width={"100%"} height={50} />
                );
            },
        },
        {
            "field": "nameOfVuer",
            "headerName": "Name of Vuer",
            "width": 255,
            "minWidth": 255,
            "maxWidth": 365,
            "filterable": false,
            "sortable": false,
            "pinnable": false,
            "disableReorder": false,
            "hideable": false,
            renderCell: () => {
                return (
                    <Skeleton variant="text" width={"100%"} height={50} />
                );
            },
        },
        {
            "field": "phoneNumber",
            "headerName": "Phone Number",
            "width": 255,
            "minWidth": 255,
            "maxWidth": 365,
            "filterable": false,
            "sortable": false,
            "pinnable": false,
            "disableReorder": false,
            "hideable": false,
            renderCell: () => {
                return (
                    <Skeleton variant="text" width={"100%"} height={50} />
                );
            },
        },
        {
            "field": "email",
            "headerName": "Email",
            "width": 255,
            "minWidth": 255,
            "maxWidth": 365,
            "filterable": false,
            "sortable": false,
            "pinnable": false,
            "disableReorder": false,
            "hideable": false,
            renderCell: () => {
                return (
                    <Skeleton variant="text" width={"100%"} height={50} />
                );
            },
        },
        {
            "field": "completedVues",
            "headerName": "Completed Vues",
            "width": 255,
            "minWidth": 255,
            "maxWidth": 365,
            "filterable": false,
            "sortable": false,
            "pinnable": false,
            "disableReorder": false,
            "hideable": false,
            renderCell: () => {
                return (
                    <Skeleton variant="text" width={"100%"} height={50} />
                );
            },
        },
        {
            field: 'customActions',
            headerName: '',
            width: 120,
            renderCell: () => {
                return (
                    <Skeleton variant="text" width={"100%"} height={50} />
                );
            },
        },
    ];

    const skeletons = Array.from({ length: 4 }, (_, id) => ({
        id: id + 1,
        vueId: '',
        email: '',
        nameOfVuer: "",
        phoneNumber: "",
        completedVues: "",
    }));

    return (
        <MDBox sx={{
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            backgroundColor: colors.white.main,
            borderRadius: 2,
            overflow: 'auto',
            width: '100%'
        }}>
            <DataGridPremium
                columns={columns}
                rows={
                    skeletons
                }
                hideFooter
                disableColumnMenu
                sx={{
                    ...gridStyle,
                    ...dataGridCellButtonStyle,
                    ...vuerCellStyle
                }}
                density="compact"
                disableRowSelectionOnClick
                getRowHeight={() => "auto"}
            />
        </MDBox>
    );
};
export default SkeletonLoader;
