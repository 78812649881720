// Defines the possible values for survey status
export enum SurveyStatus {
  all = 0,
  published = 1,
  archived = 2,
  draft = 3,
}
// Array lists available survey stats to be displayed on the dropdown
export const availableSurveyStatus = ["All", "Active", "Archived"];
// Fetches the survey status from the text
export const getSurveyStatusFromText = (text: string): number => {
  switch (text.toLowerCase()) {
    case "active":
      return SurveyStatus.published;
    case "archived":
      return SurveyStatus.archived;
    case "draft":
      return SurveyStatus.draft;
    default:
      return SurveyStatus.all;
  }
};
