import { Grid } from "@mui/material";
import DialogSectionTitle from "./DialogSectionTitle";
import CustomInput from "./CustomInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useReducer } from "react";
import {
  AddUserPageActionType,
  addUserPageReducer,
  defaultUserState,
} from "../utils/reducer";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import UserImageAvatar from "./UserImageAvatar";

interface Props {
  isEditing: boolean;
  user?: UserInfo;
  onChangeUserData: (newUserData: UserInfo) => void;
}

const UserInfoContent = ({ isEditing, user, onChangeUserData }: Props) => {
  const [userInfo, dispatchUserInfoUpdateAction] = useReducer(
    addUserPageReducer,
    {
      userDetail: user ?? defaultUserState.userDetail,
      contentModified: false,
    }
  );

  const { userDetail } = userInfo;

  const getFullName = () => {
    return `${userDetail.firstName.trim()} ${userDetail.lastName.trim()}`;
  };

  return (
    <>
      <DialogSectionTitle title="User Information" />
      {isEditing && (
        <Grid container spacing={3} alignItems="center" mb={5}>
          <Grid item>
            <UserImageAvatar
              imageId={userDetail.profileImgId}
              alt="Profile Image"
              size="xl"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {getFullName()}
              </MDTypography>
              <MDTypography variant="button" color="dark" fontWeight="regular">
                {userDetail.designation}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} pb={3}>
        <Grid item xs={6}>
          <CustomInput
            label="First Name"
            placeholder="First Name"
            value={userDetail.firstName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newPayload = {
                ...userInfo,
                userDetail: {
                  ...userDetail,
                  firstName: event.target.value,
                },
              };
              dispatchUserInfoUpdateAction({
                type: AddUserPageActionType.firstNameChanged,
                payload: newPayload,
              });
              onChangeUserData(newPayload.userDetail);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInput
            label="Last Name"
            placeholder="Last Name"
            value={userDetail.lastName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newPayload = {
                ...userInfo,
                userDetail: {
                  ...userDetail,
                  lastName: event.target.value,
                },
              };
              dispatchUserInfoUpdateAction({
                type: AddUserPageActionType.lastNameChanged,
                payload: newPayload,
              });
              onChangeUserData(newPayload.userDetail);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInput
            label="Email Address"
            placeholder="Email Address"
            value={userDetail.email}
            disabled={isEditing}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newPayload = {
                ...userInfo,
                userDetail: {
                  ...userDetail,
                  email: event.target.value,
                },
              };
              dispatchUserInfoUpdateAction({
                type: AddUserPageActionType.emailChanged,
                payload: newPayload,
              });
              onChangeUserData(newPayload.userDetail);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInput
            label="Job Title"
            placeholder="Job Title"
            value={userDetail.designation}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newPayload = {
                ...userInfo,
                userDetail: {
                  ...userDetail,
                  designation: event.target.value,
                },
              };
              dispatchUserInfoUpdateAction({
                type: AddUserPageActionType.designationChanged,
                payload: newPayload,
              });
              onChangeUserData(newPayload.userDetail);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserInfoContent;
