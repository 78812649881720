import { Divider } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { VueSurveyData } from "../utils/vue_detail_interface";
import { getSurveyTemplateById } from "pages/dashboard/home/vues/services/VueServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { VueSurveyContent } from "./VueSurveyContent";
import { VueSurveyContentWithoutStepState } from "./VueSurveyContentWithoutStepState";

interface Props {
  vueOverview: string;
  vueDescription: string;
  surveyTemplateId: string;
  submissionId: string;
}

export const VueSurveySection = ({
  surveyTemplateId,
  submissionId,
  vueOverview,
  vueDescription,
}: Props) => {
  const [vueSurveyData, setVueSurveyData] = useState<VueSurveyData>();

  useEffect(() => {
    const fetchSurveyTemplate = async () => {
      if (surveyTemplateId.isEmpty()) {
        return;
      }

      const response = await getSurveyTemplateById(surveyTemplateId);
      if (response.status === WebServiceStatus.success) {
        // Extracts survey and groups from the response
        const { survey, groups } = response.data;
        setVueSurveyData({ survey: survey, groups: groups });
      }
    };

    fetchSurveyTemplate();
  }, [submissionId, surveyTemplateId]);

  return (
    <>
      {vueSurveyData && (
        <MDBox sx={{ pt: "20px", px: "20px" }}>
          <MDTypography variant="h4" sx={{ fontSize: "22px", pb: "10px" }}>
            {vueSurveyData.survey.name}
          </MDTypography>
          {vueOverview && vueOverview.isNotEmpty() && (
            <MDTypography variant="h5" sx={{ fontSize: "18px", pb: "8px" }}>
              Vue Overview:&nbsp;
              <span style={{ fontWeight: "400" }}>
                <ReactMarkdown linkTarget="_blank">{vueOverview}</ReactMarkdown>
              </span>
            </MDTypography>
          )}
          {vueDescription && vueDescription.isNotEmpty() && (
            <MDTypography variant="h4" sx={{ fontSize: "18px" }}>
              Vue Specific Instruction:&nbsp;
              <span style={{ fontWeight: "400" }}>
                <ReactMarkdown linkTarget="_blank">
                  {vueDescription}
                </ReactMarkdown>
              </span>
            </MDTypography>
          )}
        </MDBox>
      )}
      <Divider
        sx={{ backgroundColor: "#c7ccd0", height: "1px", borderWidth: 1 }}
      />
      {vueSurveyData && submissionId?.isEmpty() && (
        <VueSurveyContentWithoutStepState surveyData={vueSurveyData} />
      )}
      {vueSurveyData && submissionId?.isNotEmpty() && (
        <VueSurveyContent
          key={"survey-content"}
          surveyData={vueSurveyData}
          submissionId={submissionId}
        />
      )}
    </>
  );
};
