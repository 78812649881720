/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import { Link, Breadcrumbs as MuiBreadcrumbs } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { RoutePath, breadcrumbConfig } from "../../constants";
import { useContext } from "react";
import { TabContext } from "App";

// Declaring props types for the Breadcrumbs
interface Props {
  title: string;
  route: string | string[];
  light?: boolean;
  [key: string]: any;
}

function Breadcrumbs({ icon, title, route, light }: Props): JSX.Element {
  const { tabName } = useContext(TabContext);
  const routes: string[] | any = route.slice(0, -1);
  const navigate = useNavigate();

  // Provides the links to breadcrumbs applicable
  const handleBreadCrumbNavigation = (name: string) => {
    switch (name) {
      case "vue dashboard":
        navigate(RoutePath.vueDashboard);
        break;
      case "vues":
        navigate(RoutePath.vues);
        break;
      case "site-lists":
        navigate(RoutePath.siteLists);
        break;
      case "analytics":
        navigate(RoutePath.analytics);
        break;
      case "my-account":
        navigate(RoutePath.manageUsers);
        break;
      default:
        break;
    }
  };

  /// Breadcrumb title
  const getTitle = (): string => {
    const lastRouteItem = route[route.length - 1];
    if (lastRouteItem.isNumber()) {
      const subRoute = route[route.length - 2];
      if (breadcrumbConfig[subRoute]) {
        const prefix = breadcrumbConfig[subRoute].prefix;
        return `${prefix} ${lastRouteItem}`;
      } else {
        return lastRouteItem;
      }
    } else {
      return lastRouteItem === "user" ? tabName : title.replace(/-/g, " ");
    }
  };

  const handleBreadCrumbClick = (route: string) => {
    if (route.isNumber()) {
      /// This executes when vueID in the breadcrumb is clicked
      navigate(`${RoutePath.vues}/${route}`);
      return;
    }
    handleBreadCrumbNavigation(route);
  };

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      {/* Breadcrumbs is visible only when the route length is greater than 1 */}
      {route.length > 1 && (
        <MuiBreadcrumbs
          sx={{
            "& .MuiBreadcrumbs-separator": {
              color: ({ palette: { white } }) => white.main,
            },
          }}
        >
          {routes.map((route: string) => {
            return (
              <Link
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleBreadCrumbClick(route);
                }}
                key={route}
              >
                <MDTypography
                  component="span"
                  variant="button"
                  fontWeight="light"
                  textTransform="capitalize"
                  color={"white"}
                  opacity={light ? 0.5 : 0.8}
                  sx={{ lineHeight: 0 }}
                >
                  {route.replace(/-/g, " ")}
                </MDTypography>
              </Link>
            );
          })}
          <MDTypography
            variant="button"
            fontWeight="light"
            textTransform="capitalize"
            color={"white"}
            sx={{ lineHeight: 0 }}
          >
            {title.replace(/-/g, " ")}
          </MDTypography>
        </MuiBreadcrumbs>
      )}
      <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={"white"}
        noWrap
      >
        {getTitle()}
      </MDTypography>
    </MDBox>
  );
}

// Declaring default props for Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

export default Breadcrumbs;
