import { useEffect, useState } from "react";
import {
  Coordinates,
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  PERMISSION_STATUS,
} from "./interfaces";

export const useFetchDeviceLocation = () => {
  const [location, setLocation] = useState<Coordinates | null>();

  /// Handles when permission is granted
  const onSuccess = (position: GeolocationPosition) => {
    const { coords } = position;
    const latitude = coords.latitude;
    const longitude = coords.longitude;
    setLocation({ latitude: latitude, longitude: longitude });
  };

  /// Handles when permission is declined
  const onError = (error: GeolocationPositionError) => {
    setLocation({
      latitude: DEFAULT_LATITUDE,
      longitude: DEFAULT_LONGITUDE,
    });
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (
            result.state === PERMISSION_STATUS.granted ||
            result.state === PERMISSION_STATUS.prompt
          ) {
            navigator.geolocation.getCurrentPosition(onSuccess, onError);
          } else if (result.state === PERMISSION_STATUS.denied) {
            setLocation({
              latitude: DEFAULT_LATITUDE,
              longitude: DEFAULT_LONGITUDE,
            });
          }
        })
        .catch((error) => {
          console.log("Error occurred while fetching device location:", error);
        });
    } else {
      console.log("Geolocation is not supported");
    }
  }, []);

  return { location };
};
