import { Icon } from '@mdi/react'
import { SxProps } from '@mui/material';
import { MDBox, MDButton } from '../../vue-grid/helpers/imports'
import { scrollToTopButtonStyle } from '../../styles/VueDetailStyles';

interface Props {
  iconPath: string;
  handleOnClick: () => void;
  sx?: SxProps;
}

export const ScrollToTopButton = (props: Props) => {
  return (
    <MDBox
      sx={{
        position: "fixed",
        bottom: "40px",
        right: "60px",
        zIndex: "128",
        ...props.sx,
      }} display="flex" alignItems="center" flexDirection="column">
      <MDButton
        variant="contained"
        color="info"
        sx={scrollToTopButtonStyle}
        onClick={props.handleOnClick}>
        <Icon path={props.iconPath} size={1.4} />
      </MDButton>
    </MDBox>
  )
}
