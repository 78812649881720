import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { tableRowStyle, tableCellStyle, buttonOutlineStyles } from "../styles";
import { Company, UserInfo } from "pages/profile/utils/ProfileInterfaces";
import PortalLogo from "./PortalLogo";
import { WebServiceStatus } from "utils/services/AppUrls";
import { updateCompanyDetails } from "pages/my-account/manage-users/services/userServices";
import { useAuth } from "context/AuthProvider";
import { useState } from "react";
import { CustomIndicator } from "pages/components/CustomIndicator";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";

interface Props {
  portal: Company;
  onGenerateAPIKey: () => void;
}

const PortalListItem = (props: Props) => {
  const { portal } = props;
  const { name, logoFileId, pdfLogoFileId } = portal;
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const {
    storeUserData,
    userData,
  }: { storeUserData: Function; userData: UserInfo } = useAuth();

  /// Handles the updation of company details
  const handleUpdateCompany = async (updatedCompany: Company) => {
    setShowLoader(true);
    const response = await updateCompanyDetails(updatedCompany);
    if (response.status === WebServiceStatus.success) {
      const responseData = response.data as Company;
      /// Updating the company details in the context
      const updatedUserData: UserInfo = {
        ...userData,
        companies: userData.companies.map((company) =>
          company.id === responseData.id ? responseData : company
        ),
        companyGroups: userData.companyGroups.map((group) => ({
          ...group,
          companies: group.companies.map((company) =>
            company.id === responseData.id ? responseData : company
          ),
        })),
      };
      storeUserData(updatedUserData);
    } else {
      const error = response.error;
      setSnackbarContent({
        title: "Attention!",
        message: error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const onEditPortalLogoClick = (id: string) => {
    const updatedCompany: Company = { ...portal, logoFileId: id };
    handleUpdateCompany(updatedCompany);
  };

  const onEditPdfLogoClick = (id: string) => {
    const updatedCompany: Company = { ...portal, pdfLogoFileId: id };
    handleUpdateCompany(updatedCompany);
  };

  const onGenerateAPIKey = () => {
    props.onGenerateAPIKey();
  };

  return (
    <MDBox sx={tableRowStyle}>
      {showLoader && <CustomIndicator />}
      <MDBox sx={tableCellStyle}>
        <MDTypography>{name}</MDTypography>
      </MDBox>
      <MDBox sx={tableCellStyle}>
        <PortalLogo
          logoId={logoFileId}
          onCompleteUpload={(id: string) => {
            onEditPortalLogoClick(id);
          }}
        />
      </MDBox>
      <MDBox sx={tableCellStyle}>
        <PortalLogo
          logoId={pdfLogoFileId}
          onCompleteUpload={(id: string) => {
            onEditPdfLogoClick(id);
          }}
        />
      </MDBox>
      <MDBox sx={tableCellStyle}>
        <MDButton
          variant="outlined"
          color="primary"
          sx={buttonOutlineStyles}
          onClick={onGenerateAPIKey}
        >
          GENERATE NEW API KEY
        </MDButton>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </MDBox>
  );
};
export default PortalListItem;
