import { REQUIRED_FIELD_COLOR } from "./../../constants";

const RequiredFieldMarker = () => {
  return (
    <span
      style={{
        fontSize: 14,
        color: REQUIRED_FIELD_COLOR,
        fontWeight: "bold",
        marginLeft: "2px",
      }}
    >
      *
    </span>
  );
};

export default RequiredFieldMarker;
