import { mdiVideo } from "@mdi/js";
import Icon from "@mdi/react";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { downloadPhotoSectionFile } from "../../services/VueServices";
import { PhotoFileInfo } from "../utils/vue_detail_interface";
import { WebServiceStatus } from "@ivueit/vue-engine";
import bgImage from "assets/images/logo4.png";
import { CircularProgress } from "@mui/material";

interface Props {
  thumbnailFileId: string;
  imageWidth:string;
  imageHeight:string;
  iconSize?:number;
}

const VideoThumbnail = ({ thumbnailFileId, imageWidth, imageHeight, iconSize }: Props) => {
  const [thumbnail, setThumbnail] = useState<string>(bgImage);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    /// Downloads video thumbnail
    const downloadThumbnail = async (thumbnailId: string) => {
      let imagePath;
      if (thumbnailId) {
        setShowLoader(true);
        const response = await downloadPhotoSectionFile(thumbnailId);
        if (response.status === WebServiceStatus.success) {
          const responseData = response.data as PhotoFileInfo;
          const { mimeType, data } = responseData;
          const url = `data:${mimeType};base64,${data}`;
          imagePath = url;
        } else {
          imagePath = bgImage;
        }
      } else {
        imagePath = bgImage;
      }
      setThumbnail(imagePath);
      setShowLoader(false);
    };

    downloadThumbnail(thumbnailFileId);
  }, [thumbnailFileId]);

  return (
    <MDBox minWidth={imageWidth} minHeight={imageHeight} overflow="hidden">
      {showLoader ? (
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: imageHeight
          }}
        >
          <CircularProgress size={30} color="success" />
        </MDBox>
      ) : (
        <MDBox position="relative">
          <MDBox
            position="absolute"
            zIndex="1"
            width="40px"
            height="40px"
            borderRadius="50%"
            color="#fff"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              background: "#000",
              top: "50%",
              left: "50%",
              transform: "translate( -50%, -50%)",
            }}
          >
            <Icon path={mdiVideo} size={iconSize ?? 1.2} />
          </MDBox>
          <img
            src={thumbnail}
            alt=""
            width={imageWidth}
            style={{
              height: imageHeight,
              objectFit: "cover",
              flexShrink: "0",
              transform: "scale(1.1)"
            }}
          />
        </MDBox>
      )}
    </MDBox>
  );
};

export default VideoThumbnail;
