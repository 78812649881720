export enum SurveyStepType {
  photo = "PHOTO",
  signature = "SIGNATURE",
  multiSelect = "MULTISELECT",
  measurement = "MEASUREMENT",
  text = "TEXT",
  bool = "BOOL",
  select = "SELECT",
  instructions = "INSTRUCTIONS",
  slider = "SLIDER",
  video = "VIDEO",
}

export const getSurveyStepType = (stepType: string): SurveyStepType => {
  switch (stepType.toUpperCase()) {
    case "PHOTO":
      return SurveyStepType.photo;
    case "TEXT":
      return SurveyStepType.text;
    case "BOOL":
      return SurveyStepType.bool;
    case "SIGNATURE":
      return SurveyStepType.signature;
    case "SELECT":
      return SurveyStepType.select;
    case "MULTISELECT":
      return SurveyStepType.multiSelect;
    case "INSTRUCTIONS":
      return SurveyStepType.instructions;
    case "SLIDER":
      return SurveyStepType.slider;
    case "MEASUREMENT":
      return SurveyStepType.measurement;
    case "VIDEO":
      return SurveyStepType.video;
  }
};
