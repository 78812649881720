export interface GridData {
  [x: string]: any;
  id: string;
  vueId: string;
  vueName: string;
  siteName: string;
  surveyType: string;
  status: string;
  createdDate: string;
  completedDate: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  hasEscalated: boolean;
  escalationNotes: string;
  vueTags: string[];
  photoTags: string[];
  clientName: string;
  slaTarget: string;
  slaActual: string;
  latitude: number;
  longitude: number;
  completedTime: string;
  isInternal: boolean;
  urgent: boolean;
  highQualityRequired: boolean;
  pdfFileId: string;
  pdfFileIdOrdered: string;
  pdfFileIdPhotos: string;
  pdfFileIdSurvey: string;
  jobSiteId: string;
  mapFileId: string;
  siteNameNumber: string;
}

export interface BatchItem {
  batchId: string;
  createdAt: string;
}
export interface VueExportItem {
  id: string;
  vueId: string;
  pdfFileId: string;
  pdfFileIdOrdered: string;
  pdfFileIdPhotos: string;
  pdfFileIdSurvey: string;
}
export const listStyledPoppers = [
  "surveyType",
  "status",
  "hasEscalated",
  "vueTags",
  "photoTags",
  "clientName",
  "addNewColumn",
  "country",
];
export const datePickerStyledPoppers = ["createdDate", "completedDate"];
export const timePickerStyledPoppers = ["completedTime"];
export const textFieldStyledPoppers = [
  "vueId",
  "vueName",
  "siteName",
  "address",
  "address2",
  "state",
  "city",
  "zipCode",
];
export const rangeStyledPoppers = ["slaTarget", "slaActual"];
