import { InfoOutlined } from '@mui/icons-material'
import MDAvatar from 'components/MDAvatar'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MessageAlert from 'pages/authentication/components/MessageAlert'
import { SentComponent } from 'pages/authentication/components/SentComponent'
import { useRef, useState, useEffect } from 'react'
import RequiredFieldMarker from './RequiredFieldMarker'

export const CodeVerificationComp: React.FC<{
    iconElement: JSX.Element;
    description: JSX.Element;
    messageAlertError: string;
    handleResendCodeClick: () => Promise<boolean>;
    handleCodeChange: (code: string) => void;
}> = (props) => {

    /// This variable holds the code typed by the user
    const codeInputRef = useRef<HTMLInputElement>(null);
    /// This variable holds the code typed by the user
    const [enteredCode, setEnteredCode] = useState<string>("");
    /// State to handle "Sent" component
    const [showSentComponent, setShowSentComponent] = useState(false);

    // Timer to handle the sent component - 30 seconds
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (showSentComponent) {
            timer = setTimeout(() => {
                setShowSentComponent(false);
            }, 30000);
        }
        return () => clearTimeout(timer);
    }, [showSentComponent]);

    /// Handles the onChange of verification code input field
    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedCode = event.target.value.formatVerificationCode();
        setEnteredCode(formattedCode);
        const isValid = formattedCode.isValidCode();
        isValid && props.handleCodeChange(formattedCode);
    };

    const handleResendCodeClick = async () => {
        const value = await props.handleResendCodeClick();
        setShowSentComponent(value);
    }

    return (
        <>
            <MDBox pt={4} px={16} pb={2} display="flex" justifyContent="center">
                <MDAvatar
                    size="xxl"
                    bgColor="info"
                    alt="Avatar"
                    sx={{ fontSize: "3.6em", width: "142px", height: "142px" }}>
                    {props.iconElement}
                </MDAvatar>
            </MDBox>
            <MDBox px={3}>
                <MDBox mt={2}>
                    <MDTypography
                        textAlign="center"
                        variant="h4"
                        fontWeight="medium"
                        color="black">
                        Enter Your Verification Code<RequiredFieldMarker />
                    </MDTypography>
                </MDBox>
                {props.messageAlertError.isNotEmpty() &&
                    <MessageAlert
                        message={props.messageAlertError}
                        type="error"
                        icon={<InfoOutlined />} />}
                <MDBox mt={2}>
                    <MDTypography
                        textAlign="center"
                        display="block"
                        variant="button"
                        color="dark"
                        fontWeight="regular">{props.description}
                    </MDTypography>
                    <MDBox mt={3}>
                        <MDTypography
                            variant="button"
                            fontWeight="bold"
                            sx={{ fontSize: 14 }}>
                            Verification Code
                        </MDTypography>
                        <MDInput
                            value={enteredCode}
                            placeholder="Code"
                            fullWidth
                            onChange={handleCodeChange}
                            inputRef={codeInputRef}
                            InputLabelProps={{ shrink: true }}
                        />
                    </MDBox>
                    <MDBox mb={3} pt={1} display="flex">
                        <MDBox pr={2}>
                            <MDButton
                                variant="text"
                                color="primary"
                                disableRipple
                                sx={{
                                    textTransform: "none",
                                    textAlign: "left",
                                    padding: "0 !important",
                                    fontWeight: "normal",
                                    fontSize: "14px",
                                    textDecoration: "underline",
                                }}
                                onClick={handleResendCodeClick}>
                                Resend Verification Code
                            </MDButton>
                        </MDBox>
                        {showSentComponent && <SentComponent />}
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    )
}
