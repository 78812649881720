import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import HistoryLineChart from "../charts/HistoryLineChart";
import StatusPieChart, { VueStatus } from "../charts/StatusPieChart";
import TypesOfVueBarChart from "../charts/TypesOfVueBarChart";
import {
  CompletedVueCountType,
  VueStatusCount,
} from "../utils/dashboard_interfaces";
import { memo } from "react";

export interface GraphProps {
  selectedCompanyIds: string[];
  totalVueCount: number;
  vueStatusCount: VueStatusCount;
  completedVueCountByType: CompletedVueCountType[];
  onPieChartClick: (status: VueStatus) => void;
  onTotalCountClick: () => void;
  onBarChartClick: (surveyType: string, status: string) => void;
}

const VueGraphs = memo(
  ({
    selectedCompanyIds,
    totalVueCount,
    vueStatusCount,
    completedVueCountByType,
    onTotalCountClick,
    onPieChartClick,
    onBarChartClick,
  }: GraphProps) => {
    return (
      <MDBox pb={1}>
        {/* Vue Status Chart and History Line chart */}
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <StatusPieChart
                totalVueCount={totalVueCount}
                vueStatusCount={vueStatusCount}
                onClick={onPieChartClick}
                onTotalCountClick={onTotalCountClick}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <HistoryLineChart selectedCompanyIds={selectedCompanyIds} />
            </Grid>
          </Grid>
        </MDBox>
        {/* Horizontal Bar Chart */}
        <MDBox mb={3}>
          <TypesOfVueBarChart
            completedVueCountByType={completedVueCountByType}
            onBarChartClick={onBarChartClick}
          />
        </MDBox>
      </MDBox>
    );
  }
);

export default VueGraphs;
