import Icon from "@mdi/react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Autocomplete,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { MDBox } from "../../vues/vue-grid/helpers/imports";
import { mdiAlertCircle, mdiMapMarkerCheck } from "@mdi/js";
import { useEffect, useReducer, useState } from "react";
import GlobalStyles from "@mui/material/GlobalStyles";
import {
  AddSiteActionType,
  AddSiteReducer,
  defaultAddSiteState,
} from "../helpers/reducer";
import { AddSiteState, SiteAddressProp } from "../helpers/constants";
import { CustomMapMarker } from "../../vues/vue_detail/components/googlemap/CustomMapMarker";
import {
  cancelButtonStyle,
  inputFieldStyle,
  saveButtonStyle,
} from "../styles/site_list_style";
import { CustomMapComponent } from "../../vues/vue_detail/components/googlemap/CustomMapComponent";
import {
  LatLng,
  TEXTFIELD_CHANGE_DELAY,
  autoCompleteStyle,
  getFieldNameFromHeader,
} from "@ivueit/vue-engine";
import {
  MAXIMUM_LENGTH_INSTRUCTIONS,
  MAXIMUM_LENGTH_SITENUMBER,
} from "../../../../../constants";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";
import { OverlayViewF, OverlayView } from "@react-google-maps/api";

interface Props {
  listOfCustomColumns: string[];
  getAddSiteData: (props: AddSiteState) => void;
  onCloseDialog: () => void;
}

const globalMapStyle = {
  '.gm-style > div[aria-label="Map"] > div:nth-child(2) > div': {
    top: "calc( 50% - 48px ) !important",
    left: "50% !important",
    transform: "translate(-50%, -50%) !important;",
    width: "24px !important",
  },
};

export const AddSiteDialogContent = (props: Props) => {
  const [error, setError] = useState<string>("");
  const [showMarker, setShowMarker] = useState<boolean>(false);
  const [predictions, setPredictions] = useState<string[]>([]);
  const [hasMapDragged, setHasMapDragged] = useState(false);
  const [coords, setCoords] = useState<LatLng>();
  const [predictionSelected, setPredictionSelected] = useState<boolean>(false);
  const [addSiteState, dispatchAddSiteReducer] = useReducer(
    AddSiteReducer,
    defaultAddSiteState
  );

  const handleSaveClick = () => {
    const addressTrimmed = addSiteState.address.split(",")[0];
    props.getAddSiteData({ ...addSiteState, address: addressTrimmed });
    props.onCloseDialog();
  };

  const fetchAddress = async (input: string) => {
    /// Removing space & comma from the input
    const splitInput = input
      .split(/[,\s]+/)
      .map((component) => component.trim());
    const isCoordinates =
      splitInput.length <= 2 &&
      splitInput.every((component) => !isNaN(parseFloat(component)));
    /// Executes when the input is coordinates
    if (isCoordinates) {
      if (splitInput.length === 2) {
        const [latitude, longitude] = splitInput;
        const newViewport = {
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
        };
        const result = await convertCoordsToPlace(
          newViewport.latitude,
          newViewport.longitude
        );
        if (!result) {
          setError("Not a valid coordinate");
          setShowMarker(false);
          return;
        }
        setShowMarker(false);
        const location = result.geometry.location;
        const addressComponents = result.address_components;
        const formattedAddress: string = result.formatted_address;
        setPredictions([formattedAddress]);
        if (predictionSelected) {
          const components = getAddressComponents(
            addressComponents,
            location,
            formattedAddress
          );
          if (!components) {
            setShowMarker(false);
            return;
          }
          setShowMarker(true);
          setCoords(newViewport);
        }
      } else {
        setError("Not a valid coordinate");
        setShowMarker(false);
      }
    }
    /// Executes when the input is an address
    else {
      try {
        /// Fetching and setting the predictions list
        const predictionsList: string[] = await getPredictionsList(input);
        if (predictionsList.length === 0) {
          setShowMarker(false);
          return;
        }
        setPredictions(predictionsList);
        /// Checking if any of the prediction is selected
        /// if yes, then fetching the details of the prediction, then setting the location
        if (predictionSelected) {
          const result = await convertPlaceToCoords(input);
          if (!result) {
            setShowMarker(false);
            return;
          }
          const location = result.geometry.location;
          const addressComponents = result.address_components;
          const formattedAddress = result.formatted_address;
          const newViewport = {
            latitude: location.lat(),
            longitude: location.lng(),
          };
          const components = getAddressComponents(
            addressComponents,
            location,
            formattedAddress
          );
          if (!components) {
            setShowMarker(false);
            return;
          }
          setShowMarker(true);
          setCoords(newViewport);
        } else {
          console.log("No prediction selected");
        }
      } catch (error) {
        setShowMarker(false);
        console.error("Error occurred while geocoding:", error);
        throw error;
      }
    }
  };

  /// To get the main address and not the full address, to fill the "Address" column in the grid
  const getMainAddress = (mainAddressComponents: string[]) => {
    try {
      const mainAddress = mainAddressComponents
        .filter((component) => component)
        .join(", ");
      return mainAddress;
    } catch (error) {
      console.log("Error occurred while fetching the main address:", error);
    }
  };

  /// Fetching the address components - address, city, state, country, zipcode
  const getAddressComponents = (
    googleResponsesList: any,
    location: any,
    address: string
  ): SiteAddressProp => {
    let cityValue,
      stateValue,
      zipcodeValue,
      countryValue,
      errorMessage,
      streetNumberValue,
      routeValue;
    for (let item of googleResponsesList) {
      if (item.types.includes("locality")) {
        cityValue = item.long_name;
      } else if (item.types.includes("administrative_area_level_1")) {
        stateValue = item.short_name;
      } else if (item.types.includes("postal_code")) {
        zipcodeValue = item.short_name;
      } else if (item.types.includes("country")) {
        countryValue = item.short_name;
      } else if (item.types.includes("street_number")) {
        streetNumberValue = item.long_name;
      } else if (item.types.includes("route")) {
        routeValue = item.long_name;
      }
    }

    if (!cityValue) {
      errorMessage = "City is not valid.";
    } else if (!stateValue) {
      errorMessage = "State is not valid.";
    } else if (!zipcodeValue) {
      errorMessage = "Zipcode is not valid.";
    } else if (!countryValue) {
      errorMessage = "Country is not valid.";
    } else if (!streetNumberValue) {
      errorMessage = "Street number is not valid.";
    } else if (!routeValue) {
      errorMessage = "Route is not valid.";
    }
    setError(errorMessage ? errorMessage : "");

    dispatchAddSiteReducer({
      type: AddSiteActionType.addSiteTextChange,
      payload: {
        ...addSiteState,
        address: address,
        city: cityValue,
        state: stateValue,
        country: countryValue,
        zipCode: zipcodeValue,
        latLng: `${location.lat()}, ${location.lng()}`,
      },
    });
    if (
      stateValue &&
      cityValue &&
      zipcodeValue &&
      countryValue &&
      streetNumberValue &&
      routeValue
    ) {
      const mainAddress = getMainAddress([streetNumberValue, routeValue]);
      const addressComponents: SiteAddressProp = {
        address: mainAddress,
        state: stateValue,
        city: cityValue,
        zipCode: zipcodeValue,
        country: countryValue,
        latLng: {
          latitude: location.lat(),
          longitude: location.lng(),
        },
      };
      return addressComponents;
    } else {
      console.log("Error occurred while parsing the address components");
      return null;
    }
  };

  const handleMapDrag = (result: any | null) => {
    if (result) {
      setHasMapDragged(true);
      getAddressComponents(
        result.address_components,
        result.geometry.location,
        result.formatted_address
      );
    }
    setShowMarker(true);
  };

  const resetValues = () => {
    setShowMarker(false);
    setHasMapDragged(false);
    setPredictionSelected(false);
    setPredictions([]);
    setError("");
  };

  /// Fetching the predictions list - Using Autocomplete Service
  const getPredictionsList = (input: string): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      const googleMapObject = window.google.maps;
      if (googleMapObject) {
        const placesService = new googleMapObject.places.AutocompleteService();
        placesService.getPlacePredictions(
          { input: input },
          (predictions: any[], status: string) => {
            if (status === "OK" && predictions) {
              const listOfPredictions: string[] = predictions.map(
                (prediction) => {
                  return prediction.description;
                }
              );
              resolve(listOfPredictions);
            } else {
              resolve([]);
            }
          }
        );
      } else {
        reject(null);
      }
    });
  };

  /// Place to coordinates conversion --> Geocoding --> Using Geocode Services
  const convertPlaceToCoords = (placeDescription: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const googleMapObject = window.google.maps;
      if (googleMapObject) {
        const geocoderService = new googleMapObject.Geocoder();
        geocoderService.geocode(
          { address: placeDescription },
          (results: any[], status: string) => {
            if (status === "OK" && results.length > 0) {
              const result = results[0];
              resolve(result);
            } else {
              resolve(null);
            }
          }
        );
      } else {
        reject(null);
      }
    });
  };

  /// Coordinates to place conversion --> Reverse Geocoding --> Using Geocode Services
  const convertCoordsToPlace = (lat: number, lng: number): Promise<any> => {
    return new Promise((resolve, reject) => {
      const googleMapObject = window.google.maps;
      if (googleMapObject) {
        const geocoderService = new googleMapObject.Geocoder();
        const latLngService = new googleMapObject.LatLng(lat, lng);
        geocoderService.geocode(
          { location: latLngService },
          (results: any[], status: string) => {
            if (status === "OK" && results.length > 0) {
              const result = results[0];
              resolve(result);
            } else {
              resolve(null);
            }
          }
        );
      } else {
        reject(null);
      }
    });
  };

  useEffect(() => {
    /// This gets invoked only when user types anything in the input field
    if (addSiteState.address && !hasMapDragged) {
      /// The method delays the api call for 0.7 ms - to reduce frequent API call when state change happens
      const delayAPIInvocation = setTimeout(() => {
        fetchAddress(addSiteState.address);
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delayAPIInvocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSiteState.address]);

  return (
    <>
      <GlobalStyles styles={globalMapStyle} />
      <DialogContent>
        <Grid container p="0 10px" spacing={3} mt={0.2}>
          <Grid item xs={5} sx={{ paddingRight: "15px" }}>
            <MDBox
              sx={{
                maxHeight: "472px",
                overflowY: "auto",
                paddingRight: "10px",
              }}
            >
              <MDBox mb={2}>
                <MDBox display={"flex"}>
                  <MDTypography sx={inputFieldStyle}>Site</MDTypography>
                  <RequiredFieldMarker />
                </MDBox>
                <MDInput
                  name={"site"}
                  value={addSiteState.site}
                  fullWidth
                  placeholder="Enter a Site (eg XYZ Company)"
                  InputLabelProps={{ shrink: true }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    dispatchAddSiteReducer({
                      type: AddSiteActionType.addSiteTextChange,
                      payload: {
                        ...addSiteState,
                        site: value,
                      },
                    });
                  }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDBox display={"flex"}>
                  <MDTypography sx={inputFieldStyle}>Site Number</MDTypography>
                  <RequiredFieldMarker />
                </MDBox>
                <MDInput
                  name={"sitenumber"}
                  value={addSiteState.sitenumber}
                  fullWidth
                  placeholder="Enter a Site Number"
                  InputLabelProps={{ shrink: true }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    dispatchAddSiteReducer({
                      type: AddSiteActionType.addSiteTextChange,
                      payload: {
                        ...addSiteState,
                        sitenumber: value,
                      },
                    });
                  }}
                  inputProps={{
                    maxLength: MAXIMUM_LENGTH_SITENUMBER,
                  }}
                />
              </MDBox>
              <MDBox>
                <MDBox display={"flex"}>
                  <MDTypography sx={inputFieldStyle}>Site Address</MDTypography>
                  <RequiredFieldMarker />
                </MDBox>
                <Autocomplete
                  filterOptions={(x) => x}
                  noOptionsText="No locations"
                  options={predictions}
                  value={addSiteState.address}
                  sx={autoCompleteStyle}
                  onChange={(event, value) => {
                    setPredictionSelected(true);
                    dispatchAddSiteReducer({
                      type: AddSiteActionType.addSiteTextChange,
                      payload: {
                        ...addSiteState,
                        address: value,
                      },
                    });
                  }}
                  onInputChange={(event, newInputValue) => {
                    if (!newInputValue) {
                      resetValues();
                    }
                    dispatchAddSiteReducer({
                      type: AddSiteActionType.addSiteTextChange,
                      payload: {
                        ...addSiteState,
                        address: newInputValue,
                      },
                    });
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <MDInput
                      {...params}
                      name={"address"}
                      error={error}
                      placeholder="Search Address"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment:
                          addSiteState.address && !error && showMarker ? (
                            <Icon
                              path={mdiMapMarkerCheck}
                              size={1}
                              color="#4CAF50"
                            />
                          ) : null,
                      }}
                      fullWidth
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item sx={{ display: "flex", width: 44 }}>
                            <LocationOnIcon sx={{ color: "text.secondary" }} />
                          </Grid>
                          <Grid
                            item
                            sx={{
                              width: "calc(100% - 44px)",
                              wordWrap: "break-word",
                            }}
                          >
                            <MDTypography
                              sx={{ fontWeight: 400, fontSize: 14 }}
                            >
                              {option}
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
                {error && (
                  <MDTypography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#AE1709",
                    }}
                  >
                    {error}
                  </MDTypography>
                )}
              </MDBox>
              <MDBox mt={2}>
                <MDTypography sx={inputFieldStyle}>Address 2</MDTypography>
                <MDInput
                  value={addSiteState.address2}
                  fullWidth
                  placeholder="Unit/Suite No"
                  InputLabelProps={{ shrink: true }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    dispatchAddSiteReducer({
                      type: AddSiteActionType.addSiteTextChange,
                      payload: {
                        ...addSiteState,
                        address2: value,
                      },
                    });
                  }}
                />
              </MDBox>
              <MDBox mt={2}>
                <MDTypography sx={inputFieldStyle}>
                  Add Internal Note
                </MDTypography>
                <MDInput
                  value={addSiteState.instruction}
                  fullWidth
                  placeholder="Enter your text"
                  multiline
                  rows={7}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    dispatchAddSiteReducer({
                      type: AddSiteActionType.addSiteTextChange,
                      payload: {
                        ...addSiteState,
                        instruction: value,
                      },
                    });
                  }}
                  inputProps={{
                    maxLength: MAXIMUM_LENGTH_INSTRUCTIONS,
                  }}
                />
              </MDBox>
              {props.listOfCustomColumns.map((columnName, index) => {
                const customColumnKeyName = getFieldNameFromHeader(columnName);
                const formattedColumnName = columnName.capitalizeWords();
                return (
                  <MDBox key={index} my={2}>
                    <MDTypography sx={inputFieldStyle}>
                      {formattedColumnName}
                    </MDTypography>
                    <MDInput
                      name={columnName}
                      fullWidth
                      placeholder={`Enter a ${formattedColumnName}`}
                      InputLabelProps={{ shrink: true }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        dispatchAddSiteReducer({
                          type: AddSiteActionType.addSiteTextChange,
                          payload: {
                            ...addSiteState,
                            [customColumnKeyName]: event.target.value,
                          },
                        });
                      }}
                    />
                  </MDBox>
                );
              })}
            </MDBox>
          </Grid>
          <Grid item xs={7}>
            <>
              <MDBox
                height="420px"
                border="1px solid #000000"
                position="relative"
              >
                <CustomMapComponent
                  showMarker={showMarker}
                  handleMapDrag={handleMapDrag}
                  coordinates={coords}
                  markerComponents={
                    showMarker && coords
                      ? [
                          <OverlayViewF
                            key={"marker"}
                            position={{
                              lat: coords.latitude,
                              lng: coords.longitude,
                            }}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                          >
                            <CustomMapMarker
                              lat={coords.latitude}
                              lng={coords.longitude}
                              color="#4CAF50"
                            />
                          </OverlayViewF>,
                        ]
                      : [<></>]
                  }
                />
              </MDBox>
              <MDBox
                display="flex"
                mt={2}
                justifyContent="center"
                alignItems="center"
                sx={{ svg: { transform: "rotate(180deg)" } }}
              >
                <Icon path={mdiAlertCircle} size={0.8} />
                <MDTypography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    pl: "10px",
                    color: "#344767",
                  }}
                >
                  Drag to adjust location
                </MDTypography>
              </MDBox>
            </>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid #a8b8d8" }}>
        <Button
          variant="outlined"
          color="info"
          size="small"
          sx={cancelButtonStyle}
          onClick={props.onCloseDialog}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          color="info"
          size="small"
          disabled={
            !(
              addSiteState.site &&
              addSiteState.sitenumber &&
              addSiteState.address &&
              showMarker &&
              !error
            )
          }
          sx={saveButtonStyle}
          onClick={handleSaveClick}
        >
          SAVE
        </Button>
      </DialogActions>
    </>
  );
};
