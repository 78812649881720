import DashboardContentLayout from "layouts/DashboardContentLayout";
import BatchListDataGrid from "./components/BatchListDataGrid";
const Batches = () => {
  return (
    <DashboardContentLayout needCardBackground={false}>
      <BatchListDataGrid />
    </DashboardContentLayout>
  );
};
export default Batches;
