import { Aod, InfoOutlined } from "@mui/icons-material";
import { DialogActions, DialogContent } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useAuth } from "context/AuthProvider";
import MessageAlert from "pages/authentication/components/MessageAlert";
import {
  requestSMSPincode,
  verifySMSPincodeAndPhone,
} from "pages/authentication/services/AuthenticationServices";
import { CodeVerificationComp } from "pages/components/CodeVerificationComp";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { useState } from "react";
import { WebServiceStatus } from "utils/services/AppUrls";
import { UserInfo } from "../utils/ProfileInterfaces";
import {
  revertPhoneNumber,
  updatePhoneNumber,
} from "../services/ProfileServices";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

const ContactInfo: React.FC<{
  phoneNumber: string;
  emailId: string;
  cancelButtonClick: () => void;
  onSaveContactInfo: (phoneNumber: string) => void;
}> = (props) => {
  /// State to handle the phone number
  const [phone, setPhone] = useState<string>(props.phoneNumber);
  /// Decides whether there is any error or not
  /// State to handle the open/close of codeVerification dialog box
  const [openVerificationDialog, setOpenVerificationDialog] =
    useState<boolean>(false);
  /// This defines whether the "Send Email" button should be enabled or not based on whether the code is valid or not
  const [isValidCode, setValidCode] = useState<boolean>(false);
  /// State to hold the code
  const [code, setCode] = useState<string>("");
  /// Current logged in user
  const {
    email,
    userData,
    storeUserData,
  }: { email: string; storeUserData: Function; userData: UserInfo } = useAuth();
  /// State to show/hide progress indicator
  const [showLoader, setShowLoader] = useState<boolean>(false);
  /// Sets the error to be shown in message alert in edit contact dialog
  const [messageAlertError, setMessageAlertError] = useState<string>("");
  /// Sets the error to be shown in message alert in 2FA dialog
  const [codeVerifyAlertError, setCodeVerifyAlertError] = useState<string>("");
  const [apiInProgress, setApiInProgress] = useState<boolean>(false);
  /******** METHODS ***********/

  /// Opens confirmation dialog
  const openDialog = () => {
    setOpenVerificationDialog(true);
  };

  /// Closes confirmation dialog
  const closeDialog = () => {
    setOpenVerificationDialog(false);
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhoneNumber = event.target.value.formatPhoneNumber();
    setPhone(formattedPhoneNumber);
    isValidForm(formattedPhoneNumber);
  };

  const isValidForm = (phoneNumber: string): boolean => {
    if (phoneNumber.isEmpty()) {
      return false;
    } else if (!phoneNumber.isValidUSPhoneNumber()) {
      return false;
    } else {
      return true;
    }
  };

  const handleSaveButtonClick = async () => {
    setShowLoader(true);
    if (isValidForm(phone)) {
      setApiInProgress(true);
      const response = await requestSMSPincode(phone.replaceSpecialChars());
      setApiInProgress(false);
      if (response.status === WebServiceStatus.success) {
        setShowLoader(false);
        openDialog();
      } else {
        setShowLoader(false);
        const error = response.error as string;
        setMessageAlertError(error);
      }
    }
  };

  /// Gets the confirmation dialog
  const getCodeVerificationDialog = () => {
    return (
      <CustomDialogBox
        title="Code Verification"
        openDialog={openVerificationDialog}
      >
        {getDialogContent()}
      </CustomDialogBox>
    );
  };

  const handleSubmitButtonClick = async () => {
    setShowLoader(true);
    const parsedPhoneNumber = phone.replaceSpecialChars();
    setApiInProgress(true);
    const response = await verifySMSPincodeAndPhone({
      phoneNumber: parsedPhoneNumber,
      email: email,
      verificationCode: code,
    });
    setApiInProgress(false);
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      setApiInProgress(true);
      const response = await updatePhoneNumber(parsedPhoneNumber);
      setApiInProgress(false);
      if (response.status === WebServiceStatus.success) {
        setShowLoader(false);
        storeUserData({ ...userData, phoneNumber: parsedPhoneNumber });
        props.onSaveContactInfo(phone);
        props.cancelButtonClick();
      } else {
        setShowLoader(true);
        setCodeVerifyAlertError(response.error);
        setApiInProgress(true);
        const responseData = await revertPhoneNumber();
        setApiInProgress(false);
        if (responseData.status === WebServiceStatus.success) {
          setShowLoader(false);
          storeUserData({ ...userData });
        } else {
          setShowLoader(false);
          setCodeVerifyAlertError(responseData.error);
        }
      }
    } else {
      setShowLoader(false);
      setCodeVerifyAlertError(response.error);
    }
  };

  // Generates actions for confirmation box
  const codeVerificationDialogActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={closeDialog}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
        disabled={apiInProgress}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        disabled={!isValidCode || apiInProgress}
        onClick={handleSubmitButtonClick}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        SUBMIT
      </MDButton>,
    ];
  };

  const handleCodeChange = (enteredCode: string) => {
    setCode(enteredCode);
    setValidCode(true);
  };

  const handleResendCodeClick = async (): Promise<boolean> => {
    setShowLoader(true);
    const parsedPhoneNumber = phone.replaceSpecialChars();
    setApiInProgress(true);
    const response = await requestSMSPincode(parsedPhoneNumber);
    setApiInProgress(false);
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      return true;
    } else {
      setShowLoader(false);
      setCodeVerifyAlertError(response.error);
      return false;
    }
  };

  const getDialogContent = () => {
    const lastDigits = phone.replaceSpecialChars().getLastDigits();
    return (
      <>
        <CodeVerificationComp
          iconElement={<Aod fontSize="inherit" />}
          description={
            <span>
              A 4 digit verification code was sent to your phone number ending
              in <b>{lastDigits}</b>. Please enter it below.
            </span>
          }
          handleResendCodeClick={handleResendCodeClick}
          handleCodeChange={handleCodeChange}
          messageAlertError={codeVerifyAlertError}
        />
        <DialogActions sx={{ pb: 4 }}>
          {codeVerificationDialogActions()}
        </DialogActions>
      </>
    );
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      {openVerificationDialog && getCodeVerificationDialog()}
      {!openVerificationDialog && (
        <MDBox>
          <DialogContent sx={{ padding: "32px 29px !important" }}>
            {messageAlertError.isNotEmpty() && (
              <MessageAlert
                message={messageAlertError}
                type="error"
                icon={<InfoOutlined />}
              />
            )}
            <MDBox mb={2}>
              <MDBox display={"flex"}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  sx={{ fontSize: 14 }}
                >
                  Phone Number
                </MDTypography>
                <RequiredFieldMarker />
              </MDBox>
              <MDInput
                placeholder="(555) 555-5555"
                type="tel"
                fullWidth
                onChange={handleChangePhone}
                value={phone.formatPhoneNumber()}
              />
            </MDBox>
            <MDBox>
              <MDTypography
                variant="button"
                fontWeight="bold"
                sx={{ fontSize: 14 }}
              >
                Email Address
              </MDTypography>
              <MDInput
                placeholder="Email"
                type="text"
                fullWidth
                value={props.emailId}
                disabled={true}
              />
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="outlined"
              color="info"
              size="medium"
              sx={{
                borderColor: "secondary.light",
                color: "dark.main",
                fontSize: 14,
                "&:hover": {
                  backgroundColor: "white.main",
                  borderColor: "secondary.light",
                  color: "dark.main",
                },
                "&:focus": { color: "dark.main" },
              }}
              onClick={props.cancelButtonClick}
              disabled={apiInProgress}
            >
              CANCEL
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              size="medium"
              onClick={handleSaveButtonClick}
              disabled={!phone.isValidUSPhoneNumber() || apiInProgress}
            >
              SAVE
            </MDButton>
          </DialogActions>
        </MDBox>
      )}
    </>
  );
};

export default ContactInfo;
