// @mui material components
import Card from "@mui/material/Card";
import React, { useState, useRef, useEffect } from "react";
// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// Authentication layout components
import CoverLayout from "layouts/CoverLayout";
// Image
import bgImage from "assets/images/authentication-cover.jpeg";
import { Aod, Mail } from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useAuth } from "context/AuthProvider";
import { RoutePath } from "../../../constants";
import {
  getAuthTokenForClientPortal,
  handleChangePassword,
  requestEmailPincode,
  requestSMSPincode,
  requestSMSPincodeWithEmail,
  verifySMSPincodeAndPhone,
} from "pages/authentication/services/AuthenticationServices";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { ModalPopUp } from "pages/components/ModalPopUp";
import { WebServiceStatus } from "utils/services/AppUrls";
import { SentComponent } from "../components/SentComponent";
import { SMSVerificationParams } from "../setup-account/SetupAccount";
import { secondFactorAuthParams as SecondFactorAuthParams, CodeType } from "../two-factor/TwoFactorAuthentication";
import { getOrgUser } from "pages/profile/services/ProfileServices";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { CustomIndicator } from "pages/components/CustomIndicator";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

export enum VerificationPageType {
  is2FA, not2FA
}

function SMSVerification(): JSX.Element {
  /// Handles the open and close of alert
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  /// State to handle "Sent" component
  const [showSentComponent, setShowSentComponent] = useState(false);
  const [hasError, setHasError] = useState(false);
  /// State to show/hide progress indicator
  const [showLoader, setShowLoader] = useState<boolean>(false);
  /// Used to fetch the parameters passed while navigation
  const location = useLocation();
  /// Hook for navigation
  const navigate = useNavigate();
  /// This values defines that the account setup is successful
  const { setAccountSetupStatus, email, storeAuthToken, storeUserData, logout } = useAuth();
  /// This variable holds the code typed by the user
  const codeInputRef = useRef<HTMLInputElement>(null);
  /// This variable holds the code typed by the user
  const [enteredCode, setEnteredCode] = useState<string>("");
  /// This defines whether the "Send Email" button should be enabled or not based on whether the code is valid or not
  const [isValidCode, setValidCode] = useState<boolean>(false);
  const type = location.state.type as VerificationPageType;
  const is2FA = type === VerificationPageType.is2FA;
  const not2FA = type === VerificationPageType.not2FA;
  const getParamsBasedOnType = () => {
    switch (type) {
      case VerificationPageType.not2FA:
        const not2FAParams = location.state.data as SMSVerificationParams;
        return {
          phoneNumber: not2FAParams.phoneNumber as string,
          emailId: not2FAParams.emailId as string,
          password: not2FAParams.password as string,
          requestId: not2FAParams.requestId as string,
          verificationCode: not2FAParams.verificationCode as string,
        }
      case VerificationPageType.is2FA:
        const is2FAParams = location.state.data as SecondFactorAuthParams;
        return {
          email: is2FAParams.emailId as string,
          auditId: is2FAParams.auditId as string,
          recipient: is2FAParams.recipient as string,
          codeType: is2FAParams.codeType as CodeType,
        }
      default:
        break;
    }
  }
  const params = getParamsBasedOnType();
  const id = params.auditId != null && params.auditId;
  const [auditId, setAuditId] = useState<string>(id);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showSentComponent) {
      // Timer to handle the sent component - 30 seconds
      timer = setTimeout(() => {
        setShowSentComponent(false);
      }, 30000);
    }
    return () => clearTimeout(timer);
  }, [showSentComponent]);

  /******** METHODS ***********/

  const handleAlertDialogClose = () => {
    setOpenAlertDialog(false);
    setEnteredCode('')
  };

  /// Handles the onChange of verification code input field
  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedCode = event.target.value.formatVerificationCode();
    setEnteredCode(formattedCode);
    const isValid = formattedCode.isValidCode();
    setValidCode(isValid);
  };

  /// This handles the case which the user navigates to the page after SignIn for 2FA
  /// Verifies the code entered and invokes getAuthTokenForClientPortal API 
  /// On success, navigates the user to the dashboard
  const handleSecondFactorAuthentication = async () => {
    setShowLoader(true);
    const emailId = email as string;
    const code = codeInputRef.current.value?.trim() ?? "";
    const isValid = code.isValidCode();
    try {
      if (isValid) {
        const response = await getAuthTokenForClientPortal({
          email: emailId,
          requestId: auditId,
          verificationCode: code,
        });
        if (response.status === WebServiceStatus.success) {
          setShowLoader(false);
          /// Token is stored locally
          const authToken = response.data as string;
          storeAuthToken(authToken);
          storeOrgUser();
        } else {
          setShowLoader(false);
          const error = response.error as string;
          setOpenAlertDialog(true);
          setHasError(true);
          setAlertMessage(error);
        }
      }
    } catch (error) {
      setShowLoader(false);
    }
  }

  /// Fetches the user details
  const storeOrgUser = async () => {
    setShowLoader(true);
    const response = await getOrgUser();
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      const responseData = response.data as UserInfo;
      /// Storing the user data locally
      storeUserData(responseData);
      navigate(RoutePath.vueDashboard, {
        replace: true,
      });
    } else {
      setShowLoader(false);
      await logout;
    }
  };

  /// This handles the case which the user navigates to the page for setting up their account
  const handleAccountSetupVerification = async () => {
    setShowLoader(true);
    const code = codeInputRef.current.value?.trim() ?? "";
    const isValid = code.isValidCode();
    const params = getParamsBasedOnType() as SMSVerificationParams;
    try {
      if (isValid) {
        const response = await verifySMSPincodeAndPhone({
          phoneNumber: params.phoneNumber,
          email: params.emailId,
          verificationCode: code,
        });
        if (response.status === WebServiceStatus.success) {
          setShowLoader(false);
          const setPasswordParameters = {
            email: params.emailId,
            password: params.password,
            requestId: params.requestId,
            verificationCode: params.verificationCode,
          }
          const response = await handleChangePassword(setPasswordParameters);
          setShowLoader(true);
          if (response.status === WebServiceStatus.success) {
            setShowLoader(false);
            setAccountSetupStatus(true);
          } else {
            setShowLoader(false);
            setHasError(true);
            setOpenAlertDialog(true);
            const error = response.error ?? "Unable to set your password.";
            setHasError(true);
            setAlertMessage(error);
          }
        } else {
          setShowLoader(false);
          const error = response.error as string;
          setOpenAlertDialog(true);
          setHasError(true);
          setAlertMessage(error);
        }
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  /// Handles the onClick of "Submit" button
  const handleSubmitButtonClick = async () => {
    switch (type) {
      case VerificationPageType.is2FA:
        await handleSecondFactorAuthentication();
        break;
      case VerificationPageType.not2FA:
        await handleAccountSetupVerification();
        break;
      default:
        break;
    }
  }

  /// Handles the onClick of "Previous" button
  const handlePreviousButtonClick = () => {
    /// Pops to previous screen
    navigate(-1);
  }

  /// Handles resend code when the user navigates to the page after SignIn for 2FA
  const resendCodeFor2FA = async () => {
    setShowLoader(true);
    const params = getParamsBasedOnType() as SecondFactorAuthParams;
    const type = params.codeType;
    const emailId = email as string;
    const response =
      type === CodeType.mobile
        ? await requestSMSPincodeWithEmail(emailId)
        : await requestEmailPincode(emailId);
    if (response.status === WebServiceStatus.success) {
      setShowLoader(false);
      const responseData = response.data;
      const id = responseData.auditId as string;
      setAuditId(id);
      setShowSentComponent(true);
    } else {
      setShowLoader(false);
      const error = response.error as string;
      setOpenAlertDialog(true);
      setHasError(true);
      setShowSentComponent(false);
      setAlertMessage(error);
    }
  }

  /// Handles resend code when the user navigates to the page for setting up their account
  const resendCodeWithout2FA = async () => {
    setShowLoader(true);
    const params = getParamsBasedOnType() as SMSVerificationParams;
    const phoneNumber = params.phoneNumber;
    if (phoneNumber !== null && phoneNumber.isNotEmpty()) {
      const response = await requestSMSPincode(phoneNumber as string);
      if (response.status === WebServiceStatus.success) {
        setShowLoader(false);
        setShowSentComponent(true);
      } else {
        setShowLoader(false);
        const error = response.error as string;
        setOpenAlertDialog(true);
        setHasError(true);
        setShowSentComponent(false);
        setAlertMessage(error);
      }
    } else {
      setShowLoader(false);
      const error = "Invalid phone number.";
      setOpenAlertDialog(true);
      setHasError(true);
      setAlertMessage(error);
    }
  }

  /// Handles the onClick of "Resend Verification Code" link
  const handleResendCodeClick = async () => {
    switch (type) {
      case VerificationPageType.is2FA:
        await resendCodeFor2FA();
        setEnteredCode('')
        break;
      case VerificationPageType.not2FA:
        await resendCodeWithout2FA();
        setEnteredCode('')
        break;
      default:
        break;
    }
  };

  /// This returns an alert
  const showAlert = () => {
    return <CustomDialogBox
      title={hasError ? "Error" : "Success"}
      children={
        <ModalPopUp
          content={alertMessage}
          closeDialog={handleAlertDialogClose}
        />}
      openDialog={openAlertDialog}
    />
  }

  /// To get the desription text shown 
  const getDescriptionLabel = () => {
    const parameters = getParamsBasedOnType();
    const phoneNumber = parameters.phoneNumber != null && parameters.phoneNumber.getLastDigits();
    switch (type) {
      case VerificationPageType.is2FA:
        const type = parameters.codeType as CodeType;
        const text = (type === CodeType.email) ? "email address" : "phone number";
        return <span>A 4 digit verification code was sent to your {text}. Please enter it below.</span>
      default:
        return <span>A 4 digit verification code was sent to your phone number ending in <b>{phoneNumber}</b>. Please enter it below.</span>;
    }
  }

  /// To get the icon inside Avatar
  const getIconElement = () => {
    const parameters = getParamsBasedOnType();
    switch (type) {
      case VerificationPageType.is2FA:
        const type = parameters.codeType as CodeType;
        const iconElement = type === CodeType.email ? <Mail fontSize="inherit" /> : <Aod fontSize="inherit" />;
        return iconElement
      default:
        return <Aod fontSize="inherit" />;
    }
  }

  return (
    <>
      {openAlertDialog && showAlert()}
      {showLoader && <CustomIndicator />}
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox pt={4} px={16} pb={2} display="flex" justifyContent="center">
            <MDAvatar
              size="xxl"
              bgColor="info"
              alt="Avatar"
              sx={{ fontSize: "3.6em", width: "142px", height: "142px" }}>
              {getIconElement()}
            </MDAvatar>
          </MDBox>
          <MDBox pb={3} px={3}>
            <MDBox mt={2}>
              <MDTypography
                textAlign="center"
                variant="h4"
                fontWeight="medium"
                color="black">
                Enter Your Verification Code
              </MDTypography>
            </MDBox>
            <MDBox mt={2}>
              <MDTypography
                textAlign="center"
                display="block"
                variant="button"
                color="dark"
                fontWeight="regular">{getDescriptionLabel()}
              </MDTypography>
              <MDBox mt={5}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  sx={{ fontSize: 14 }}>
                  Verification Code<RequiredFieldMarker />
                </MDTypography>
                <MDInput
                  value={enteredCode}
                  placeholder="Code"
                  fullWidth
                  onChange={handleCodeChange}
                  inputRef={codeInputRef}
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>
              <MDBox mb={4} pt={1} display="flex">
                <MDBox pr={2}>
                  <MDButton
                    variant="text"
                    color="primary"
                    disableRipple
                    sx={{
                      textTransform: "none",
                      textAlign: "left",
                      padding: "0 !important",
                      fontWeight: "normal",
                      fontSize: "14px",
                      textDecoration: "underline",
                    }}
                    onClick={handleResendCodeClick}>
                    Resend Verification Code
                  </MDButton>
                </MDBox>
                {showSentComponent && <SentComponent />}
              </MDBox>
              <MDButton
                variant="gradient"
                color="info"
                fontSize="14px"
                fontWeight="bold"
                sx={{
                  "&:hover, &:disabled, &:enabled": {
                    fontSize: "14px",
                    fontWeight: "bold"
                  },
                }}
                fullWidth
                disabled={!isValidCode}
                onClick={handleSubmitButtonClick}>
                SUBMIT
              </MDButton>
              {is2FA && <MDBox pt={2}>
                <MDButton
                  variant="outlined"
                  sx={{
                    borderColor: "#c7ccd0",
                    color: "#344767",
                    fontSize: "14px",
                    fontWeight: "bold",
                    "&:hover": {
                      borderColor: "#c7ccd0",
                      color: "#344767",
                    }
                  }}
                  fullWidth
                  onClick={handlePreviousButtonClick} >
                  PREVIOUS
                </MDButton>
              </MDBox>}
            </MDBox>
          </MDBox>
          {not2FA && <MDBox mb={2} textAlign="center">
            <MDTypography variant="button" color="text" fontWeight="regular">
              Already have an account?&nbsp;
              <MDTypography
                component={Link}
                to={RoutePath.root}
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
                mb={3}>
                Sign In
              </MDTypography>
            </MDTypography>
          </MDBox>}
        </Card>
      </CoverLayout>
    </>
  );
}

export default SMSVerification;