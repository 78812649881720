export const topBarButtonStyle = {
  borderColor: "secondary.light",
  color: "dark.main",
  fontSize: 14,
  "&:hover": {
    backgroundColor: "white.main",
    borderColor: "secondary.light",
    color: "dark.main",
  },
  "&:focus": { color: "dark.main" },
};

export const menuItemStyle = {
  padding: `6px 5px`,
  width: "280px",
  ":focus": {
    backgroundColor: "unset",
  },
  "&.Mui-selected": {
    backgroundColor: "unset !important",
    ":hover, :focus": {
      backgroundColor: "unset !important",
    },
  },
};

/// Custom styles applied to Tooltip component
export const tooltipStyles = {
  bgcolor: "dark.main",
  borderRadius: "2px",
  fontSize: "12px",
  padding: "5px 8px",
  textAlign: "left",
  fontWeight: "300",
  lineHeight: "16px",
  "& .MuiTooltip-arrow": {
    color: "dark.main",
  },
};

/// Styles related to "Add Site" dialog
export const inputFieldStyle = {
  fontSize: "14px",
  fontWeight: "500",
};

export const cancelButtonStyle = {
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "500",
  padding: "11px 16px",
  borderColor: "secondary.light",
  color: "dark.main",
  "&:hover": {
    backgroundColor: "white.main",
    borderColor: "secondary.light",
  },
};

export const saveButtonStyle = {
  fontSize: "14px",
  fontWeight: "500",
  cursor: "pointer",
  padding: "11px 16px",
  color: "white.main",
  backgroundColor: "success.main",
  "&:hover": { backgroundColor: "success.main" },
};

export const dataGridCellButtonStyle = {
  ".MuiDataGrid-cell": {
    fontSize: "14px",
    fontWeight: "400",
    ".MuiIconButton-root": {
      display: "none",
    },
    "&:hover": {
      ".MuiIconButton-root": {
        display: "inline-flex",
      },
    },
  },
};
