import axios, { AxiosError } from "axios";
import { getAuthenticationHeader } from "pages/authentication/services/AuthenticationServices";
import {
  UserProfileAPIServices,
  WebServiceResponse,
  WebServiceStatus,
} from "utils/services/AppUrls";
import { downloadPhotoFile } from "utils/services/NetworkHandler";
import { DownloadFile, ProfileInfo } from "../utils/ProfileInterfaces";
import { GENERIC_ERROR_MESSAGE } from "../../../constants";

/// This is the type of all the edited user details that is to be send as params
export type REQUEST_TYPE = { [key: string]: { value: any } };

/// Fetch user details
export const getOrgUser = async (): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "get",
      url: UserProfileAPIServices.getOrgUser,
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: responseData.message ?? GENERIC_ERROR_MESSAGE,
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// Updates profile info
export const updateProfileInfo = async (
  updateUserInfo: ProfileInfo,
  userId: string
): Promise<WebServiceResponse> => {
  try {
    const editedInfos = Object.entries(updateUserInfo)
      .filter(([key, value]) => value !== null && value !== undefined)
      .reduce((info: REQUEST_TYPE, [key, value]) => {
        info[key] = { value: value };
        return info;
      }, {});
    const params = {
      ...editedInfos,
    };
    const response = await axios({
      method: "post",
      url: UserProfileAPIServices.updateMyOrgUser,
      data: params,
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data.id) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: responseData.message ?? GENERIC_ERROR_MESSAGE,
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// Updates user's phone number
export const updatePhoneNumber = async (
  phoneNumber: string
): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: UserProfileAPIServices.updatePhoneNumber,
      data: {
        phoneNumber: phoneNumber,
      },
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data.value) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data.value,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: responseData.message ?? GENERIC_ERROR_MESSAGE,
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// Updates user's phone number
export const revertPhoneNumber = async (): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: UserProfileAPIServices.revertPhoneNumber,
      data: {},
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data.value) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data.value,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: responseData.message ?? GENERIC_ERROR_MESSAGE,
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// Downloads the image
export const downloadFile = async (imageId: string) => {
  try {
    const response = await downloadPhotoFile(
      `${UserProfileAPIServices.downloadFile}/${imageId}`
    );
    if (response.status === WebServiceStatus.success) {
      const responseData = response.data as DownloadFile;
      return responseData;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

/// Removes the profile picture
export const deleteFile = async (
  imageId: string
): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: `${UserProfileAPIServices.deleteFile}/${imageId}`,
      data: {},
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.success,
        data: data,
      };
      return responseData;
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: responseData.message ?? GENERIC_ERROR_MESSAGE,
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// Upload profile picture
export const uploadFile = async (uploadPictureParams: {
  filename: string;
  mimeType: string;
  data: string;
}): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: UserProfileAPIServices.uploadFile,
      data: uploadPictureParams,
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data.assignedId) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data.assignedId,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      const responseData = axiosError.response.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: responseData.message ?? GENERIC_ERROR_MESSAGE,
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// This changes the password
export const handleUpdatePassword = async (updatePasswordParameters: {
  oldPassword: string;
  newPassword: string;
}): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "post",
      url: UserProfileAPIServices.updatePassword,
      data: updatePasswordParameters,
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.success,
        data: data,
      };
      return responseData;
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: responseData.message ?? GENERIC_ERROR_MESSAGE,
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};

/// This returns the pre-authenticated phone number
export const getVerifiedPhoneNumber = async (): Promise<WebServiceResponse> => {
  try {
    const response = await axios({
      method: "get",
      url: UserProfileAPIServices.getVerifiedPhoneNumber,
      headers: getAuthenticationHeader(),
    });
    const { data, status } = response;
    if (status === 200) {
      if (data) {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.success,
          data: data,
        };
        return responseData;
      } else {
        const responseData: WebServiceResponse = {
          status: WebServiceStatus.error,
          error: GENERIC_ERROR_MESSAGE,
        };
        return responseData;
      }
    } else {
      const responseData: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return responseData;
    }
  } catch (error) {
    if ((error as AxiosError).response) {
      const responseData = (error as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: responseData.message ?? GENERIC_ERROR_MESSAGE,
      };
      return result;
    } else {
      const result: WebServiceResponse = {
        status: WebServiceStatus.error,
        error: GENERIC_ERROR_MESSAGE,
      };
      return result;
    }
  }
};
