import { VueSurvey } from "@ivueit/vue-engine";
import {
  CreateVueAPIServices,
  WebServiceResponse,
} from "utils/services/AppUrls";
import {
  performGetRequest,
  performPostRequest,
} from "utils/services/NetworkHandler";

/// Get Survey templates
export const getSurveyTemplates = async (
  id: string,
  searchText: string,
  statusOf: number
): Promise<WebServiceResponse> => {
  // Appends company id details
  const companyIdData = id ? `&companyIDs=${id}` : "";
  const searchContent = searchText ? `&name=${searchText}` : "";
  const url = `${CreateVueAPIServices.getSurveyTemplates}?sortByRecentBatchUsage=true${companyIdData}${searchContent}&statusOf=${statusOf}`;
  return await performGetRequest(url);
};

// Archive/Unarchive selected survey
export const updateSurveyTemplate = async (
  template: VueSurvey,
  selectedSurveyId: string
): Promise<WebServiceResponse> => {
  const url = `${CreateVueAPIServices.updateSurveyTemplate}/${selectedSurveyId}`;
  return await performPostRequest(url, template);
};
