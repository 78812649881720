import { mdiFilterOff, mdiDelete } from "@mdi/js";
import { Divider } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import moment, { Moment } from "moment";
import ButtonWithIcon from "pages/components/ButtonWithIcon";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { ModalPopUp } from "pages/components/ModalPopUp";
import CustomDatePicker from "pages/dashboard/components/CustomDatePicker";
import { useState } from "react";

export const FilterByDateContent: React.FC<{
  selectedStartDate: string;
  selectedEndDate: string;
  columnName: string;
  closePopper: (event: Event | React.SyntheticEvent<Element, Event>) => void;
  removeColumn: () => void;
  applyDateFilter: (startDate: Moment | null, endDate: Moment | null) => void;
  shouldShowRemoveColumnButton: boolean;
}> = (props) => {
  /// Date Format
  const dateFormat = "MM / DD / YYYY";

  /// Checks whether the dates are valid or not
  const isValidEndDate =
    props.selectedEndDate &&
    moment(props.selectedEndDate, dateFormat).isValid();
  const isValidStartDate =
    props.selectedStartDate &&
    moment(props.selectedStartDate, dateFormat).isValid();
  const defaultEndDate = isValidEndDate
    ? moment(props.selectedEndDate, dateFormat)
    : null;
  const defaultStartDate = isValidStartDate
    ? moment(props.selectedStartDate, dateFormat)
    : null;

  /// To handle error
  const [error, setError] = useState({
    START_DATE: "",
    END_DATE: "",
  });

  /// This holds the start date
  const [startDate, setStartDate] = useState<Moment | null>(defaultStartDate);
  /// This holds the end date
  const [endDate, setEndDate] = useState<Moment | null>(defaultEndDate);
  /// Handles the open/close of DatePicker
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
  /// State to track the export confirmation dialog
  const [openConfirmationDialog, setOpenConfirmation] =
    useState<boolean>(false);

  /// Handles the change in start date - Based on this the minimum end date is set
  const handleStartDateChange = (newDate: Moment | null) => {
    setError((prev) => ({
      START_DATE:
        newDate && endDate
          ? newDate > endDate || !newDate.isValid()
            ? "Invalid start date."
            : ""
          : endDate && !newDate
          ? "Start date must not be empty."
          : "",
      END_DATE: !endDate && !newDate ? "" : prev.END_DATE,
    }));
    setStartDate(newDate ? moment(newDate) : null);
    if (newDate && endDate && newDate.isValid() && endDate.isValid()) {
      props.applyDateFilter(newDate ?? moment(newDate), endDate);
    } else if (newDate === null && endDate === null) {
      props.applyDateFilter(null, null);
    }
  };

  /// Handles the change in end date - Based on this the maximum start date is set
  const handleEndDateChange = (newDate: Moment | null) => {
    setError((prev) => ({
      START_DATE: !startDate && !newDate ? "" : prev.START_DATE,
      END_DATE:
        newDate && startDate
          ? startDate > newDate || !newDate.isValid()
            ? "Invalid end date."
            : ""
          : startDate && !newDate
          ? "End date must not be empty."
          : "",
    }));
    setEndDate(newDate ? moment(newDate) : null);
    if (newDate && startDate && newDate.isValid() && startDate.isValid()) {
      props.applyDateFilter(startDate, newDate ?? moment(newDate));
    } else if (newDate === null && startDate === null) {
      props.applyDateFilter(null, null);
    }
  };

  /// Opens confirmation dialog
  const openConfirmation = () => {
    setOpenConfirmation((prevState) => !prevState);
  };

  /// Closes confirmation dialog
  const closeConfirmation = () => {
    setOpenConfirmation(false);
  };

  /// Handles the onClick of "YES, REMOVE" button
  const removeColumn = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    closeConfirmation();
    props.removeColumn();
    props.closePopper(event);
  };

  /// Handles the onClick of "Cancel" button in the dialog
  const handleCancelClick = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    closeConfirmation();
    props.closePopper(event);
  };

  // Generates actions for confirmation box
  const confirmationDialogActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={handleCancelClick}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={removeColumn}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        Yes, Remove
      </MDButton>,
    ];
  };

  /// Gets the confirmation dialog
  const getConfirmationDialog = () => {
    return (
      <CustomDialogBox
        title={`Remove ${props.columnName}`}
        width="454px"
        height="200px"
        openDialog={openConfirmationDialog}
      >
        <ModalPopUp
          content={`Are you sure you want to remove the ${props.columnName} column from the table?`}
          closeDialog={closeConfirmation}
          actions={confirmationDialogActions()}
        />
      </CustomDialogBox>
    );
  };

  /// Handles the onClick of "Remove Column" button in popper
  const handleRemoveColumnClick = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    openConfirmation();
  };

  return (
    <>
      {openConfirmationDialog && getConfirmationDialog()}
      {!openConfirmationDialog && (
        <MDBox>
          <MDBox px={2.5} pt={2}>
            <MDTypography fontWeight="bold" sx={{ fontSize: "14px" }}>
              Filter By Date
            </MDTypography>
            <MDBox pt={2} pb={openStartDatePicker ? 42 : 2}>
              <CustomDatePicker
                minWidth={55}
                title="Start Date"
                defaultDate={defaultStartDate}
                onChange={handleStartDateChange}
                maximumDate={endDate}
                errorMessage={error.START_DATE}
                openDialog={openStartDatePicker}
                onOpen={() => {
                  setOpenStartDatePicker(!openStartDatePicker);
                }}
                onClose={() => {
                  setOpenStartDatePicker(false);
                }}
              />
            </MDBox>
            <MDBox pb={openEndDatePicker ? 40 : 0}>
              <CustomDatePicker
                minWidth={55}
                title="End Date"
                errorMessage={error.END_DATE}
                defaultDate={defaultEndDate}
                minimumDate={startDate}
                onChange={handleEndDateChange}
                openDialog={openEndDatePicker}
                onOpen={() => {
                  setOpenEndDatePicker(!openEndDatePicker);
                }}
                onClose={() => {
                  setOpenEndDatePicker(false);
                }}
              />
            </MDBox>
          </MDBox>
          <Divider
            sx={{
              backgroundColor: "#A8B8D8",
              height: "1x",
              opacity: 0.5,
              backgroundImage: "none !important",
            }}
          />
          <MDBox px={2} pb={2}>
            <MDBox>
              <ButtonWithIcon
                iconPath={mdiFilterOff}
                fullWidth
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#1A73E7",
                  "&:disabled": { color: "#c7ccd0", borderColor: "#c7ccd0" },
                  "&:hover": { color: "#1A73E7", borderColor: "#c7ccd0" },
                }}
                onClick={(
                  event: Event | React.SyntheticEvent<Element, Event>
                ) => {
                  props.applyDateFilter(null, null);
                  props.closePopper(event);
                }}
              >
                CLEAR FILTERS
              </ButtonWithIcon>
            </MDBox>
            {props.shouldShowRemoveColumnButton && (
              <MDBox pt={1}>
                <ButtonWithIcon
                  iconPath={mdiDelete}
                  fullWidth
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#AE1709",
                    "&:hover": { color: "#AE1709", borderColor: "#c7ccd0" },
                  }}
                  onClick={handleRemoveColumnClick}
                >
                  REMOVE COLUMN
                </ButtonWithIcon>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      )}
    </>
  );
};
