import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface Props {
  title: string;
  fontSize?: string;
}

const DialogSectionTitle = (props: Props) => {
  return (
    <MDBox display="flex" alignItems="center" mb={2}>
      <MDTypography variant="h6" fontSize={props.fontSize}>{props.title}</MDTypography>
      <MDBox
        flexGrow="1"
        ml={2}
        sx={{ height: "1px", background: "#3447671a" }}
      ></MDBox>
    </MDBox>
  );
};

export default DialogSectionTitle;
