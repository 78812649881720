export const tableConStyle = {
  display: "flex",
  flexFlow: "column nowrap",
  backgroundCcolor: "white",
};
export const tableRowHeadStyle = {
  display: "flex",
  flexFlow: "row nowrap",
  width: "100%",
  borderBottom: "1px solid #a8b8d8",
  textTransform: "uppercase",
  "p.MuiTypography-root": {
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },
};

export const tableHeadCellStyle = {
  display: "flex",
  flex: "1",
  py: "12px",
  justifyContent: "start",
  alignItems: "center",
  "&:first-child": {
    paddingLeft: "36px"
  }
};

export const tableRowStyle = {
  display: "flex",
  flexFlow: "row nowrap",
  width: "100%",
  borderBottom: "1px solid #e9eaed",
  textTransform: "uppercase",
  "p.MuiTypography-root": {
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
};
export const tableCellStyle = {
  display: "flex",
  flex: "1",
  py: "12px",
  justifyContent: "start",
  alignItems: "center",
  "&:first-child": {
    paddingLeft: "36px"
  },
  "p.MuiTypography-root": {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    color: "#6e7491",
  },
};

export const buttonOutlineStyles = {
  minHeight: "unset",
  fontSize: "14px",
  whiteSpace: "nowrap",
  borderColor: "#cccccc",
};

export const logoConStyle = {
  width: "60px",
  height: "60px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

export const buttonHoverStyle = {
  position: "absolute",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
  background: "rgba(255, 255, 255, 0.65)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: "0",
  transition: "all 0.25s ease",
  ":hover": {
    opacity: "1",
  },
};

export const tagItemStyle = {
  display: "flex",
  alignItems: "center",
  flexBasis: '33.33%',
  flexShrink: '0',
  padding: '0px 30px 30px 30px',
  ":nth-child(3n + 1), :nth-child(3n + 0)": {
      background: "#F8F8F8",
    },
  ":nth-child(1), :nth-child(2), :nth-child(3)": {
    paddingTop: "30px"
  },
  ".MuiBadge-badge": {
    background: "#C7CCD0 !important",
    fontSize: "16px",
    color: "#191919",
  },
};

export const listSyles = {
  flex: "1",
  padding: "0px",
  display: 'flex',
  flexWrap: 'wrap'
};

export const tagListContainerStyle = {
  maxHeight: '320px',
  overflowY: 'auto',
  marginBottom: '70px'
}
