import { List } from "@mui/material";
import MDBox from "components/MDBox";
import PortalListItem from "./PortalListItem";

import MDTypography from "components/MDTypography";
import {
  tableConStyle,
  tableRowHeadStyle,
  tableHeadCellStyle,
} from "../styles";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import { useAuth } from "context/AuthProvider";

interface Props {
  onGenerateAPIKey: (companyId: string) => void;
}

const PortalListComponent = (props: Props) => {
  const { getAvailablePortalsForUser } = useAuth();
  const companiesList: Company[] = getAvailablePortalsForUser();

  return (
    <>
      <MDBox sx={tableConStyle}>
        <MDBox sx={tableRowHeadStyle}>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>PORTALS</MDTypography>
          </MDBox>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>PORTAL LOGO</MDTypography>
          </MDBox>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>PDF LOGO</MDTypography>
          </MDBox>
          <MDBox sx={tableHeadCellStyle}>
            <MDTypography>API KEYS</MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <List sx={tableConStyle}>
        {companiesList.map((portal, index) => (
          <PortalListItem
            key={index}
            portal={portal}
            onGenerateAPIKey={() => {
              props.onGenerateAPIKey(portal.id);
            }}
          />
        ))}
      </List>
      <MDBox display="flex" flexDirection="column" flexGrow="1"></MDBox>
    </>
  );
};
export default PortalListComponent;
