// Contains all types of actions to be used in the Vue Batch page
export enum VuesBatchPageActionType {
  sortColumnChanged = "SORT_COLUMN_CHANGED",
  dateFilterReset = "DATE_FILTER_RESET",
  startDateChanged = "START_DATE_CHANGED",
  endDateChanged = "END_DATE_CHANGED",
  searchTextChanged = "SEARCH_TEXT_CHANGED",
  filterApplied = "FILTER_APPLIED",
  clearAllFilterClicked = "CLEAR_ALL_FILTER_CLICKED",
}

export interface VuesBatchPageSummaryState {
  startDate?: string;
  endDate?: string;
  clientIds: string[];
  searchText: string;
  batchName: string;
  canonicalId: string;
  surveyName: string;
  minCompletedVueCount: string;
  maxCompletedVueCount: string;
  minTotalVueCount: string;
  maxTotalVueCount: string;
  sortColumnDisplayName: string;
  sortIsAscending?: boolean;
}

export const defaultVuesBatchPageSummaryState = {
  clientIds: [] as string[],
  searchText: "",
  batchName: "",
  canonicalId: "",
  surveyName: "",
  minCompletedVueCount: "",
  maxCompletedVueCount: "",
  minTotalVueCount: "",
  maxTotalVueCount: "",
  sortColumnDisplayName: "",
  sortIsAscending: null as boolean,
  startDate: "",
  endDate: "",
};

// Vue Batch Summary Reducer
export interface VuesBatchPageAction {
  type: VuesBatchPageActionType;
  payload: VuesBatchPageSummaryState;
}

export const vuesBatchPageSummaryReducer = (
  state: VuesBatchPageSummaryState,
  action: VuesBatchPageAction
) => {
  const { type, payload } = action;
  switch (type) {
    case VuesBatchPageActionType.sortColumnChanged:
      return {
        ...state,
        sortColumnDisplayName: payload.sortColumnDisplayName,
        sortIsAscending: payload.sortIsAscending,
      };
    case VuesBatchPageActionType.dateFilterReset:
      return {
        ...state,
        startDate: payload.startDate,
        endDate: payload.endDate,
      };
    case VuesBatchPageActionType.startDateChanged:
      return {
        ...state,
        startDate: payload.startDate,
      };
    case VuesBatchPageActionType.endDateChanged:
      return {
        ...state,
        endDate: payload.endDate,
      };
    case VuesBatchPageActionType.searchTextChanged:
      return {
        ...state,
        searchText: payload.searchText,
      };
    case VuesBatchPageActionType.filterApplied:
      return { ...state, ...payload };
    case VuesBatchPageActionType.clearAllFilterClicked:
      return defaultVuesBatchPageSummaryState;
    default:
      return state;
  }
};
