import { mdiArrowCollapse } from "@mdi/js";
import { Card, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useVueProvider } from "pages/dashboard/home/vues/context/VueProvider";
import { useEffect, useState } from "react";
import { mapFullViewStyle } from "../../styles/VueDetailStyles";
import { VueMediaSection } from "./VueMediaSection";
import { CustomMapMarker } from "./googlemap/CustomMapMarker";
import { IndividualVue, PhotoFileInfo } from "../utils/vue_detail_interface";
import { PlaceholderImage } from "./PlaceholderImage";
import { PlaceholderComponent } from "pages/dashboard/home/dashboard-home/components/PlaceholderComponent";
import { LatLng } from "@ivueit/vue-engine";
import { CustomMapComponent } from "./googlemap/CustomMapComponent";
import { OverlayViewF, OverlayView } from "@react-google-maps/api";

interface Props {
  toggleButtonClick: () => void;
  vueDetail: IndividualVue;
}

/// To control the keyboard events while interacting with the map
enum KeyboardEvents {
  arrowLeft = "ArrowLeft",
  arrowRight = "ArrowRight",
  keydown = "keydown",
}

export const MapFullViewDialogContent = (props: Props) => {
  const { vue, photos } = props.vueDetail;
  const { latitude, longitude, canonicalId } = vue;
  const lastPhotoIndex = photos.length - 1;
  const { latestSelectedVueImage, storeLatestSelectedVueImage } =
    useVueProvider();
  /// State to hold the photo
  const [photo, setPhoto] = useState<PhotoFileInfo>();
  /// State to handle the marker and image index
  const [hoveredMarkerId, setHoveredMarkerId] = useState<string>("");
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number>();
  /// Default coordinates for the map
  const [coords, setCoords] = useState<LatLng | null>({
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    if (!latestSelectedVueImage) {
      setCoords({
        latitude: latitude,
        longitude: longitude,
      });
    } else {
      /// Checking whether there is any previously selected photo / locally stored
      /// If so, then checks whether the vue is same & then setting the marker ID, coords
      /// So that, map will render with that coords in the center
      const key = Object.keys(latestSelectedVueImage)[0];
      const locallyStoredImage: PhotoFileInfo = latestSelectedVueImage[key];
      if (locallyStoredImage && key === canonicalId) {
        setPhoto(locallyStoredImage);
        setHoveredMarkerId(locallyStoredImage.id);
        setCoords({
          latitude: locallyStoredImage.location.latitude,
          longitude: locallyStoredImage.location.longitude,
        });
        const index = photos.findIndex(
          (photo) => locallyStoredImage.id === photo.id
        );
        setSelectedPhotoIndex(index);
      } else {
        setCoords({
          latitude: latitude,
          longitude: longitude,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vueDetail]);

  /// To handle keyboard events
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      setHoveredMarkerId(null);
      let index = selectedPhotoIndex;
      if (event.key === KeyboardEvents.arrowLeft) {
        index =
          selectedPhotoIndex === 0 ? lastPhotoIndex : selectedPhotoIndex - 1;
      } else if (event.key === KeyboardEvents.arrowRight) {
        index =
          selectedPhotoIndex === lastPhotoIndex ? 0 : selectedPhotoIndex + 1;
      }
      /// Updating the thumbnail index and so the photo
      setSelectedPhotoIndex(index);
      const photo = photos[index];
      setPhoto(photo);
      storeLatestSelectedVueImage(canonicalId, photo);
    };
    document.addEventListener(KeyboardEvents.keydown, handleKeyDown);
    return () => {
      document.removeEventListener(KeyboardEvents.keydown, handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhotoIndex]);

  /// Handles the onClick of the selected image and marker
  const storeSelectedMarkerAndImage = (index: number, photo: PhotoFileInfo) => {
    setPhoto(photo);
    storeLatestSelectedVueImage(canonicalId, photo);
    setSelectedPhotoIndex(index);
    setHoveredMarkerId(photo.id);
  };

  const getListOfMarkers = () => {
    return [
      <OverlayViewF
        key={"vueLocation"}
        position={{
          lat: latitude,
          lng: longitude,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <CustomMapMarker
          lat={latitude}
          lng={longitude}
          onHover={() => {}}
          color="#57FF6F"
        />
      </OverlayViewF>,
      ...photos.map((photo, index) => {
        const { location, escalated, id, serialNumber } = photo;
        const { latitude, longitude } = location;
        const hasDefinedLocation =
          location && location.latitude && location.longitude;
        const isActiveMarker =
          hoveredMarkerId === id || selectedPhotoIndex === index;
        return hasDefinedLocation ? (
          <OverlayViewF
            key={index}
            position={{
              lat: latitude,
              lng: longitude,
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <CustomMapMarker
              lat={latitude}
              lng={longitude}
              onHover={() => {
                storeSelectedMarkerAndImage(index, photo);
              }}
              color={
                escalated ? "#AE1709" : isActiveMarker ? "#4CAF50" : "white"
              }
              textColor={escalated ? "#FFFFFF" : "#000000"}
              content={serialNumber}
            />
          </OverlayViewF>
        ) : (
          <></>
        );
      }),
    ];
  };

  return (
    <>
      <MDBox
        height="100%"
        display="flex"
        padding="10px"
        sx={{ minHeight: "92vh", maxHeight: "calc(100vh - 68px)" }}
      >
        <MDBox
          sx={{
            flexShrink: "0",
            minWidth: "0",
            flexGrow: "0",
            flexBasis: "574px",
            border: "1px solid #C7CCD0",
            marginRight: "15px",
            padding: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {photo ? (
            <Card
              sx={{
                p: "0px",
                minHeight: "439px",
                boxShadow: "none",
                flexShrink: "0",
                img: {
                  maxHeight: "300px",
                  objectFit: "contain",
                },
              }}
            >
              <VueMediaSection photoFileInfo={photo} />
            </Card>
          ) : (
            <PlaceholderImage />
          )}
          <Divider
            sx={{
              backgroundColor: "#c7ccd0",
              height: "1x",
              opacity: "0.5",
              backgroundImage: "none !important",
              margin: "0.7rem 0",
            }}
          />
          <MDTypography variant="h6" sx={{ mb: "10px" }}>
            Available Images
          </MDTypography>
          {photos.length !== 0 ? (
            <MDBox
              sx={{ display: "flex", overflowY: "auto", ...mapFullViewStyle }}
            >
              <Grid container spacing={1}>
                {photos.map((photo, index) => (
                  <Grid item xs={4} key={index}>
                    <MDBox
                      sx={{
                        border:
                          selectedPhotoIndex === index
                            ? "3px solid green"
                            : photo.escalated
                            ? "3px solid red"
                            : "3px solid transparent",
                        borderRadius: "5px",
                        width: "100%",
                        height: "88px",
                        overflow: "hidden",
                        cursor: "pointer",
                        flexShrink: "0",
                        marginRight: "15px",
                      }}
                      onClick={() => {
                        storeSelectedMarkerAndImage(index, photo);
                      }}
                    >
                      <img
                        src={`data:${photo.mimeType};base64,${photo.data}`}
                        alt="Pic"
                        style={{
                          filter:
                            (photo.adminHide || photo.clientHide) &&
                            "blur(12px)",
                          width: "100%",
                          height: "88px",
                          objectFit: "contain",
                          borderRadius: "3px",
                        }}
                      />
                    </MDBox>
                  </Grid>
                ))}
              </Grid>
            </MDBox>
          ) : (
            <PlaceholderComponent label={"No images available"} />
          )}
        </MDBox>
        <MDBox flexGrow="1">
          <MDBox height="100%">
            <CustomMapComponent
              onlyUpdateViewPortOnDrag
              onClickToggleButton={props.toggleButtonClick}
              fullViewIconPath={mdiArrowCollapse}
              iconSize={1.4}
              handleMapDrag={() => {}}
              coordinates={coords}
              markerComponents={getListOfMarkers()}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};
