import MDBox from "components/MDBox";
import {
  SurveyStep,
  VueSurveyData,
} from "../utils/vue_detail_interface";
import { SurveySummary } from "@ivueit/vue-engine";

interface Props {
  surveyData: VueSurveyData;
}

export const VueSurveyContentWithoutStepState = ({ surveyData }: Props) => {
  const { survey } = surveyData;
  const { steps } = survey;

  const getSurveyContents = () => {
    const allSteps = steps ?? [];

    if (allSteps.length <= 0) {
      return <></>;
    }
    var elementArray: JSX.Element[] = [];
    allSteps.forEach((step) => {
      const nonGroupElement = getSurveySummary(step);
      elementArray.push(nonGroupElement);
    });
    return (
      <MDBox sx={{ pt: "10px", pb: "20px", px: "20px" }}>{elementArray}</MDBox>
    );
  };

  const getSurveySummary = (step: SurveyStep) => {
    const serialNumber: number = parseInt(step.index) + 1;
    return (
      <SurveySummary
        header={`${serialNumber}. ${step.description.replaceAll('"', "")}`}
        body={<></>}
      />
    );
  };

  return getSurveyContents();
};
