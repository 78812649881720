import { AddSiteState } from "./constants";

export enum AddSiteActionType {
  addSiteTextChange = "ADD_SITE_TEXT_CHANGE",
}

interface AddSiteAction {
  type: AddSiteActionType;
  payload: AddSiteState;
}

export const defaultAddSiteState = {
  site: "",
  sitenumber: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  latLng: "",
  instruction: "",
};

export const AddSiteReducer = (state: AddSiteState, action: AddSiteAction) => {
  const { type, payload } = action;
  switch (type) {
    case AddSiteActionType.addSiteTextChange: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

// Contains all types of actions to be used in the Sitelist page
export enum SiteListPageActionType {
  listBasedFilterApplied = "LIST_BASED_FILTER_APPLIED",
  clearAllFilterClicked = "CLEAR_ALL_FILTER_CLICKED",
  searchTextChanged = "SEARCH_TEXT_CHANGED",
  sortColumnChanged = "SORT_COLUMN_CHANGED",
  textBasedFilterApplied = "TEXT_BASED_FILTER_APPLIED",
}

export interface SiteListPageSummaryState {
  archive: boolean;
  city: string;
  state: string;
  postalCode: string;
  companyId: string;
  search: string;
  siteNameNumber: string;
  site: string;
  siteNumber: string;
  sortColumnDisplayName: string;
  sortIsAscending?: boolean;
  address: string;
  addressTwo: string;
  country: string[];
}

export const defaultSiteListPageSummaryState = {
  archive: null as boolean,
  city: "",
  state: "",
  postalCode: "",
  companyId: "",
  search: "",
  siteNameNumber: "",
  site: "",
  siteNumber: "",
  address: "",
  addressTwo: "",
  sortColumnDisplayName: "",
  country:[] as string[]
};

// Dashboard Summary Reducer
export interface SiteListPageAction {
  type: SiteListPageActionType;
  payload: SiteListPageSummaryState;
}

export const siteListPageSummaryReducer = (
  state: SiteListPageSummaryState,
  action: SiteListPageAction
) => {
  const { type, payload } = action;
  switch (type) {
    case SiteListPageActionType.listBasedFilterApplied:
      return {
        ...state,
        city: payload.city,
        archive: payload.archive,
        state: payload.state,
        postalCode: payload.postalCode,
        siteNameNumber: payload.siteNameNumber,
        site: payload.site,
        siteNumber: payload.siteNumber,
        address: payload.address,
        addressTwo: payload.addressTwo,
        country:payload.country
      };
    case SiteListPageActionType.searchTextChanged:
      return {
        ...state,
        search: payload.search,
      };
    case SiteListPageActionType.clearAllFilterClicked:
      return { ...payload };
    case SiteListPageActionType.sortColumnChanged:
      return {
        ...state,
        sortColumnDisplayName: payload.sortColumnDisplayName,
        sortIsAscending: payload.sortIsAscending,
      };
    case SiteListPageActionType.textBasedFilterApplied:
      return {
        ...state,
        city: payload.city,
        archive: payload.archive,
        state: payload.state,
        postalCode: payload.postalCode,
        siteNameNumber: payload.siteNameNumber,
        site: payload.site,
        siteNumber: payload.siteNumber,
        address: payload.address,
        addressTwo: payload.addressTwo,
      };
    default:
      return state;
  }
};
