import {
  CustomPopper,
  HeaderSortIconButton,
  TEXTFIELD_CHANGE_DELAY,
  gridStyle,
  searchBarStyle,
  textEllipsis,
} from "@ivueit/vue-engine";
import {
  Card,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { mdiAccountEdit, mdiFilter, mdiFilterOff, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import {
  DataGridPremium,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import MDBox from "components/MDBox";
import { useCallback, useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import moment, { Moment } from "moment";
import { GridFilter } from "pages/dashboard/home/site-lists/interfaces/interfaces";
import { FilterBySurveyTypeContent } from "pages/dashboard/home/vues/vue-grid/components/CustomFilterContent";
import MDButton from "components/MDButton";
import {
  CustomDialogBox,
  StyledButtonWithIcon,
} from "pages/dashboard/home/vues/vue-grid/helpers/imports";
import { FilterByDateContent } from "pages/dashboard/home/vues/vue-grid/components/FilterByDateContent";
import defaultColumnDefinition from "assets/jsons/manageUsersColumns.json";
import {
  convertDateToNanoSecondsString,
  convertDateToNanoSecondsStringForEndDate,
} from "pages/dashboard/home/vues/vue-grid/helpers/helper_methods";
import MDTypography from "components/MDTypography";
import AddUserDialogContent from "./AddUserDialogContent";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { dataGridCellButtonStyle } from "pages/dashboard/home/site-lists/styles/site_list_style";
import {
  CompanyRoleResult,
  PaginationMetaData,
  UserFromAPI,
  UserGridData,
  UserRoles,
  defaultPaginationMetaData,
  generateGridDataFromResponse,
} from "../interfaces/interfaces";
import { ConfirmationDialog } from "pages/dashboard/home/site-lists/components/ConfirmationDialog";
import { WebServiceStatus } from "utils/services/AppUrls";
import {
  activateDeactivateUserWithEmail,
  createClientOrgUser,
  getAllUsersFromMyCompany,
  getCompanyRolesByEmail,
  getOrgUserByEmail,
  rollbackEmailWhiteListing,
  rollbackOrgUserCreation,
  sendNewUserEmailInvite,
  updateOrgUserById,
  whiteListEmailAndUpdateRoles,
} from "../services/userServices";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import UserImageAvatar from "./UserImageAvatar";
import { useAuth } from "context/AuthProvider";
import { alphanumericSort } from "utils/helpers/extensions";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { tooltipStyles } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";

// Style for inactive cells
const inactiveCell = {
  opacity: "0.50",
  PointerEvent: "none",
};

enum UserCreationStep {
  emailWhiteListing,
  invitationSending,
}

const ManageUserGrid = () => {
  /// Fetching user details from local storage
  const { userData }: { userData: UserInfo } = useAuth();
  /// Defines the row data
  const [nonFilteredRowData, setNonFilteredRowData] = useState<GridRowsProp>(
    []
  );
  /// Defines the row data
  const [filteredRowData, setFilteredRowData] = useState<GridRowsProp>([]);
  /// Defines the column data
  const columnData: GridColDef[] = defaultColumnDefinition.map((column) => {
    const { field } = column;
    var newDef: GridColDef = { ...column };
    if (field === "users") {
      newDef = {
        ...column,
        sortComparator: (user1: UserInfo, user2: UserInfo) => {
          return alphanumericSort(
            `${user1.firstName} ${user1.lastName}`,
            `${user2.firstName} ${user2.lastName}`
          );
        },
      };
    }
    return newDef;
  });
  /// Sort model for the grid
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  /// Defines the anchor element for the popup
  const [anchorElement, setAnchorElement] = useState<
    HTMLButtonElement | HTMLDivElement
  >(null);
  /// Defines the popup fields
  const [popperName, setPopperName] = useState<string>(null);
  /// Selected filters
  const [selectedFilters, setSelectedFilters] = useState<GridFilter[]>([]);
  // Defines the search text
  const [searchText, setSearchText] = useState<string>("");
  /// Reference for the grid
  const gridRef = useGridApiRef();
  /// Defines the pinned columns
  const pinnedColumns = ["users"];
  /// Total number of active users
  const [totalActiveUsers, setTotalActiveUsers] = useState<number>(0);
  // Whether to display new user dialog
  const [showAddNewUserDialog, setShowAddNewUserDialog] = useState(false);
  const [cellInEdit, setCellInEdit] = useState<null | GridRenderCellParams>(
    null
  );
  const [loader, setLoader] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [metaValues, setMetaValues] = useState<PaginationMetaData>(
    defaultPaginationMetaData
  );
  const [showCustomIndicator, setShowCustomIndicator] = useState(false);
  const [enableAddUser, setEnableAddUser] = useState(false);

  //////////////////// METHODS ////////////////////////////////

  /// Handles the onClick of the "ClearAllFilters" button
  const onClearAllFilterButtonClick = () => {
    setSelectedFilters([]);
    setFilteredRowData(nonFilteredRowData);
  };

  /// Handles the active/inactive feature
  const getActiveInactiveConfirmation = () => {
    if (!cellInEdit) {
      return <></>;
    }

    const { value } = cellInEdit;

    var message = "Are you sure you want to change the status to Active?";
    if (value) {
      message = "Are you sure you want to change the status to Inactive?";
    }
    return (
      <CustomDialogBox
        title={"Change Status"}
        width="450px"
        openDialog={showConfirmation}
      >
        <ConfirmationDialog
          message={message}
          buttonName={"Yes"}
          onClose={() => {
            setShowConfirmation(false);
            // Resetting the editing cell
            setCellInEdit(null);
          }}
          onClick={() => {
            handleActionClick(!value);
          }}
        />
      </CustomDialogBox>
    );
  };

  const handleActionClick = async (newActiveState: boolean) => {
    var newRowData = [...nonFilteredRowData];
    const rowIndex = newRowData.findIndex(
      (row) => row.id === cellInEdit.row.id
    );
    if (rowIndex !== -1) {
      const emailId = newRowData[rowIndex].users?.email;
      if (emailId && emailId.trim().isNotEmpty()) {
        setShowCustomIndicator(true);
        const response = await activateDeactivateUserWithEmail(
          emailId,
          newActiveState
        );
        setShowCustomIndicator(false);
        if (response.status === WebServiceStatus.error) {
          const error = response.error ?? "Error: Unable to update user status";
          setSnackbarContent({
            title: "Attention!",
            message: error,
            isError: true,
          });
        }
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: "Invalid email",
          isError: true,
        });
      }
    }
    // Hiding the confirm dialog on API SUCCESS
    setShowConfirmation(false);
    setCellInEdit(null);
    fetchAllUsers();
  };

  //////////////////// GRID CUSTOMISATION ////////////////////////////////

  const onClickEditUser = (params: GridRenderCellParams) => {
    setCellInEdit(params);
    setShowAddNewUserDialog(true);
  };

  const onClickActiveInactive = (params: GridRenderCellParams) => {
    setCellInEdit(params);
    setShowConfirmation(true);
  };

  const getUserCell = (params: GridRenderCellParams) => {
    const { value, row } = params;

    const user: UserInfo = value;
    const name = `${user.firstName} ${user.lastName}`;
    const designation = user.designation;
    const email = user.email;
    const profileImage = user.profileImgId;
    const isAdminUser = row.isAdmin ?? false;
    const isActiveUser = row.active_inactive ?? false;
    return (
      <Grid
        container
        columnSpacing={3}
        alignItems="center"
        flexWrap={"nowrap"}
        pt={1.2}
        pb={0.8}
        sx={!isActiveUser && inactiveCell}
      >
        <Grid item pl="6px">
          <UserImageAvatar imageId={profileImage} size="sm" shadow="sm" />
        </Grid>
        <Grid item>
          <MDBox
            height="100%"
            display="flex"
            flexDirection={"column"}
            mt={0.5}
            lineHeight={1}
          >
            <MDTypography variant="h6" fontWeight="regular">
              {name}
            </MDTypography>
            <MDBox>
              <MDTypography variant="button" color="dark" fontWeight="light">
                {email}
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant="button" color="dark" fontWeight="light">
                {designation}
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display={"flex"} alignItems={"center"}>
          {isAdminUser && <CustomBadge content="Admin" borderRadius="5px" />}
          {isActiveUser && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onClickEditUser(params);
              }}
            >
              <Icon path={mdiAccountEdit} size={1} color="#1a73e7" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    );
  };

  const getActiveStatusCell = (params: GridRenderCellParams) => {
    const isActive = params.value;
    const { email } = userData;
    const userEmail = params.row?.users?.email ?? "";
    const disableButton =
      email.trim().toLowerCase() === userEmail.trim().toLowerCase();
    return (
      <MDBox alignContent="center">
        <Tooltip
          title="Cannot change your own status."
          arrow
          placement="left"
          componentsProps={{
            tooltip: {
              sx: tooltipStyles,
            },
          }}
          disableHoverListener={!disableButton}
        >
          <span>
            <MDButton
              variant="text"
              color="primary"
              sx={{
                ml: "10px",
                p: 0,
                minHeight: "unset",
                fontSize: "14px",
              }}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                onClickActiveInactive(params);
              }}
              disabled={disableButton}
            >
              {isActive ? "ACTIVE" : "INACTIVE"}
            </MDButton>
          </span>
        </Tooltip>
      </MDBox>
    );
  };
  const getManagePortalCell = (params: GridRenderCellParams) => {
    const { value } = params;
    return (
      <MDBox
        className="MuiDataGrid-cellContent"
        display="flex"
        alignItems="center"
        minWidth="0"
        sx={!params.row.active_inactive && inactiveCell}
      >
        <MDBox sx={textEllipsis}>{value}</MDBox>
        {params.row.active_inactive && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onClickEditUser(params);
            }}
          >
            <Icon path={mdiPencil} size={0.72} color="#1a73e7" />
          </IconButton>
        )}
      </MDBox>
    );
  };

  // Defines the custom cells in the grid
  const getCustomCells = (params: GridRenderCellParams) => {
    const { field, value } = params;
    var cellContent = value;

    switch (field) {
      case "users":
        return getUserCell(params);
      case "active_inactive":
        return getActiveStatusCell(params);
      case "manageportals":
        return getManagePortalCell(params);
      case "createddate":
      case "lastsignin":
        cellContent =
          value.length > 0
            ? value.formatUTCNanoSecondsToString("MM/DD/YYYY")
            : "-";
        break;
      default:
        break;
    }

    return (
      <MDBox
        className="MuiDataGrid-cellContent"
        display="flex"
        alignItems="center"
        minWidth="0"
        sx={!params.row.active_inactive && inactiveCell}
      >
        <MDBox sx={textEllipsis}>{cellContent}</MDBox>
      </MDBox>
    );
  };

  /// Defines the custom headers
  const getCustomHeader = (params: GridColumnHeaderParams) => {
    const { colDef, field } = params;
    const headerName = colDef.headerName;
    var iconButtons: JSX.Element = <></>;

    const showFilterIcon = true;
    const showSortIcon = ![].includes(field);
    return (
      <MDBox>
        {
          <>
            <strong>{headerName.toUpperCase()}</strong>
            {showSortIcon && (
              <HeaderSortIconButton
                onClickSort={(
                  isAscending: boolean,
                  newSortModel: GridSortModel
                ) => {
                  setSortModel(newSortModel);
                }}
                sortModel={sortModel}
                field={field}
              />
            )}
            {showFilterIcon && (
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setAnchorElement(event.currentTarget);
                  handleFilterIconClick(field);
                }}
              >
                <Icon path={mdiFilter} size={0.72} />
              </IconButton>
            )}
          </>
        }
        {iconButtons}
      </MDBox>
    );
  };

  const renderedColumns = columnData.map((data) => {
    data.renderCell = getCustomCells;
    data.renderHeader = getCustomHeader;
    return data;
  });

  const getDefaultRoles = (): CompanyRoleResult => {
    // Getting current user's email
    const { email } = userData;
    const currentUser = nonFilteredRowData.find(
      (row) => row.users.email === email
    );
    if (currentUser) {
      const currentUserCompanies = currentUser.roles as CompanyRoleResult;
      const newCompanyRoles = currentUserCompanies.companyRoles.map(
        (currentRole) => {
          return { ...currentRole, role: UserRoles.user };
        }
      );
      const defaultRoles: CompanyRoleResult = {
        email: "",
        companyRoles: newCompanyRoles,
      };
      return defaultRoles;
    }
    return null;
  };

  const getAddNewUserDialog = () => {
    const isEditing = cellInEdit !== null;
    const userInEdit = cellInEdit?.row.users ?? null;
    const roles = cellInEdit?.row.roles ?? getDefaultRoles();
    const isAdmin = cellInEdit?.row.isAdmin ?? false;
    return (
      <CustomDialogBox
        title={isEditing ? "Edit User" : "Add New User"}
        width="844px"
        openDialog={showAddNewUserDialog}
      >
        <AddUserDialogContent
          user={userInEdit}
          roles={roles}
          isAdmin={isAdmin}
          closeDialog={() => {
            setShowAddNewUserDialog(false);
            setCellInEdit(null);
            fetchAllUsers();
          }}
          isEditing={isEditing}
          onSave={async (newUserData: UserGridData) => {
            setShowCustomIndicator(true);
            // Saves the user data
            const success = await saveUserData(newUserData);
            if (success) {
              setShowAddNewUserDialog(!success); // Hides dialog only on success
              setCellInEdit(null);
              fetchAllUsers();
            }
            setShowCustomIndicator(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  const saveUserData = async (newUserData: UserGridData): Promise<boolean> => {
    const { users, roles } = newUserData;
    // Current user's company ID
    const { companyId, companyGroups } = userData;
    var newlyCreatedUserId = "";
    if (cellInEdit && users.id) {
      // Existing user data
      const updateResponse = await updateOrgUserById(users);
      if (updateResponse.status === WebServiceStatus.error) {
        setSnackbarContent({
          title: "Attention!",
          message: updateResponse.error,
          isError: true,
        });
        return false;
      }
    } else {
      // Finding company group id
      const companyGroupId =
        companyGroups.length > 0 ? companyGroups[0].id : "";
      // creating new user
      const createResponse = await createClientOrgUser(
        companyId,
        companyGroupId,
        users
      );
      if (createResponse.status === WebServiceStatus.error) {
        setSnackbarContent({
          title: "Attention!",
          message: createResponse.error,
          isError: true,
        });
        return false;
      } else {
        // Updating user id for newly created data
        const { id } = createResponse.data;
        newlyCreatedUserId = id ?? "";
      }
    }
    // Filtering out no access Roles
    var finalRole: CompanyRoleResult = { ...roles };
    finalRole.companyRoles = roles.companyRoles.filter(
      (companyRole) => companyRole.role !== UserRoles.noAccess
    );

    const whiteListResponse = await whiteListEmailAndUpdateRoles(finalRole);
    if (whiteListResponse.status === WebServiceStatus.error) {
      setSnackbarContent({
        title: "Attention!",
        message: whiteListResponse.error,
        isError: true,
      });
      if (newlyCreatedUserId.isNotEmpty()) {
        await revertNewUserCreation(
          users.email,
          newlyCreatedUserId,
          companyId,
          UserCreationStep.emailWhiteListing
        );
      }
      return false;
    }
    if (users.id.isEmpty()) {
      // Sending email invite for new user
      const inviteResponse = await sendNewUserEmailInvite(users.email);
      if (inviteResponse.status === WebServiceStatus.error) {
        setSnackbarContent({
          title: "Attention!",
          message: inviteResponse.error,
          isError: true,
        });
        await revertNewUserCreation(
          users.email,
          newlyCreatedUserId,
          companyId,
          UserCreationStep.invitationSending
        );
        return false;
      }
    }
    return true;
  };

  // Reverts the new user creation process
  const revertNewUserCreation = async (
    emailId: string,
    userId: string,
    companyId: string,
    failedStep: UserCreationStep
  ) => {
    switch (failedStep) {
      // @ts-ignore
      case UserCreationStep.invitationSending:
        const emailRollback = await rollbackEmailWhiteListing(emailId);
        if (emailRollback.status === WebServiceStatus.error) {
          console.error(emailRollback.error);
        }
      // Fallthrough the step to rollbacks user creation as well
      // eslint-disable-next-line
      case UserCreationStep.emailWhiteListing:
        // Reverts the user creation
        const userRollback = await rollbackOrgUserCreation(userId, companyId);
        if (userRollback.status === WebServiceStatus.error) {
          console.error(userRollback.error);
        }
        break;
      default:
        break;
    }
  };

  //////////////////// FILTER ////////////////////////////////

  /// Handles the onClick of "Filter" icon in the column header
  const handleFilterIconClick = (field: string) => {
    // Helps to show the popup. If the field is already showing, setting null will hide the popup
    setPopperName(popperName === field ? null : field);
  };

  const getFilterPopper = () => {
    var children: JSX.Element = <></>;
    switch (popperName) {
      case "createddate":
      case "lastsignin":
        children = getDatePickerStyledPopper();
        break;
      default:
        // Filtering data and sending it dynamically
        children = getListStyledPopperContent();
        break;
    }

    return (
      <CustomPopper
        onClickAway={(event: MouseEvent | TouchEvent) => {
          handleClosePopper(event);
        }}
        shouldOpen={true}
        anchorElement={anchorElement}
        id={popperName}
        placement={"right"}
      >
        {children}
      </CustomPopper>
    );
  };

  const getDatePickerStyledPopper = () => {
    const headerName: string = columnData.filter(
      (data) => data.field === popperName
    )[0].headerName;
    const columnName = headerName ?? "";
    var selectedStartDate = "";
    var selectedEndDate = "";
    const selections = selectedFilters.filter(
      (filter) => filter.field === popperName
    );
    // fetching the previous selections
    const previousSelections =
      selections.length > 0 ? selections[0].filters : [];
    if (previousSelections.length === 2) {
      selectedStartDate = previousSelections[0];
      selectedEndDate = previousSelections[1];
    }

    const dateFormat = "MM / DD / YYYY";
    return (
      <FilterByDateContent
        removeColumn={() => {}}
        applyDateFilter={(startDate, endDate) => {
          handleDateFilterChange(startDate, endDate);
        }}
        columnName={columnName}
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        selectedStartDate={selectedStartDate.formatUTCNanoSecondsToString(
          dateFormat
        )}
        selectedEndDate={selectedEndDate.formatUTCNanoSecondsToString(
          dateFormat
        )}
        shouldShowRemoveColumnButton={false}
      />
    );
  };

  const getListStyledPopperContent = () => {
    var popperContent: any[] = [];
    popperContent = nonFilteredRowData.map((row) => row[popperName]);
    switch (popperName) {
      case "users":
        popperContent = popperContent.map(
          (item) => `${item.firstName} ${item.lastName}`
        );
        break;
      case "active_inactive":
        popperContent = ["Active", "Inactive"];
        break;
      default:
        popperContent = nonFilteredRowData.map((row) =>
          row[popperName].toString()
        );
        break;
    }

    const headerName: string = columnData.filter(
      (data) => data.field === popperName
    )[0].headerName;
    const columnName = headerName ?? "";

    const selections = selectedFilters.filter((filter) => filter.field);
    // fetching the previous selections
    const previousSelections =
      selections.length > 0 ? selections[0].filters : [];
    // Removing non empty items

    const contentSet = popperContent
      .filter((item) => item !== null && item.trim().isNotEmpty())
      .sort();

    // Retreiving the unique items from the list
    const uniqueContents = [...new Set(contentSet)];

    return (
      <FilterBySurveyTypeContent
        customSelectorList={uniqueContents}
        removeColumn={() => {}}
        columnName={columnName}
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        onFilterChange={updateFilterChanges}
        shouldShowRemoveColumnButton={false}
        previouslySelectedOptions={previousSelections}
      />
    );
  };

  const updateFilterChanges = (newFilters: string[]) => {
    const filterObject: GridFilter = { field: popperName, filters: newFilters };
    const indexOfItem = selectedFilters.findIndex(
      (item) => item.field === popperName
    );
    var updatedFilters = [...selectedFilters];
    if (newFilters.length <= 0) {
      // Filters on this columns has been removed
      if (indexOfItem !== -1) {
        // Item already available. Removing the item
        updatedFilters.splice(indexOfItem, 1);
      }
    } else {
      if (indexOfItem !== -1) {
        updatedFilters[indexOfItem] = filterObject;
      } else {
        updatedFilters.push(filterObject);
      }
    }
    setSelectedFilters(updatedFilters);
  };

  /// Handles the close of popper component
  const handleClosePopper = (event: Event | React.SyntheticEvent) => {
    // Helps to hide the popups
    setPopperName(null);
  };

  /// Handles the date filter change
  const handleDateFilterChange = (
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    var formattedStartDate = "";
    var formattedEndDate = "";
    var appliedFilters: string[] = [];
    if (startDate !== null && startDate.isValid()) {
      formattedStartDate = convertDateToNanoSecondsString(startDate);
      appliedFilters.push(formattedStartDate);
    }

    if (endDate !== null && endDate.isValid()) {
      formattedEndDate = convertDateToNanoSecondsStringForEndDate(endDate);
      appliedFilters.push(formattedEndDate);
    }
    // Adding the date picker to the filter column
    updateFilterChanges(appliedFilters);
  };

  //////////////////// COMPONENTS ////////////////////////////////

  const getClearAllFiltersButton = () => {
    return (
      <MDBox mr="auto" flexShrink="0" pr={1}>
        <StyledButtonWithIcon
          iconPath={mdiFilterOff}
          onClick={onClearAllFilterButtonClick}
        >
          {`Clear All Filters (${selectedFilters.length})`}
        </StyledButtonWithIcon>
      </MDBox>
    );
  };

  const getNoRowsMessage = () => {
    return (
      <MDBox position="relative" top="30%">
        <Typography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          No rows available
        </Typography>
      </MDBox>
    );
  };

  // Fetches user roles and updates the grid
  const fetchCompanyRolesByEmail = useCallback(
    async (userSummaryList: UserFromAPI[], userDetailList: UserInfo[]) => {
      const userRoleList: CompanyRoleResult[] = [];
      const userRoleResponse = await Promise.all(
        userSummaryList.map((user) => getCompanyRolesByEmail(user.email.trim()))
      );
      userRoleResponse.forEach((roleResponse) => {
        if (roleResponse.status === WebServiceStatus.success) {
          const userRole = roleResponse.data as CompanyRoleResult;
          userRoleList.push(userRole);
          if (userRoleList.length === userSummaryList.length) {
            updateGridData(userSummaryList, userDetailList, userRoleList);
          }
        } else {
          console.error("Some data couldn't be found");
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateGridData = (
    userSummaryList: UserFromAPI[],
    userDetailList: UserInfo[],
    userRoleList: CompanyRoleResult[]
  ) => {
    const gridData = generateGridDataFromResponse(
      userSummaryList,
      userDetailList,
      userRoleList
    );

    const { email } = userData;

    // 1. Current User first
    // 2. all admins
    // 3. all active users listed alphabetically.
    // 4. all inactive users listed alphabetically.
    const sortedData = gridData.sort((a, b) => {
      // Current user comes first
      if (a.users.email === email) return -1;
      if (b.users.email === email) return 1;

      // Then, sort by admins
      if (a.isAdmin && !b.isAdmin) return -1;
      if (!a.isAdmin && b.isAdmin) return 1;

      // Then, sort by active users alphabetically
      if (a.active_inactive && !b.active_inactive) return -1;
      if (!a.active_inactive && b.active_inactive) return 1;

      // Finally, sort by inactive users alphabetically
      return alphanumericSort(
        `${a.users.firstName} ${a.users.lastName}`,
        `${b.users.firstName} ${b.users.lastName}`
      );
    });

    var newRowData = [];
    for (let rowIndex = 0; rowIndex < sortedData.length; rowIndex++) {
      newRowData.push({ id: rowIndex, ...sortedData[rowIndex] });
    }

    const activeUsers = sortedData.filter(
      (data) => data.active_inactive
    ).length;

    setFilteredRowData(newRowData);
    setNonFilteredRowData(newRowData);
    setTotalActiveUsers(activeUsers);
  };

  const fetchAllUsers = useCallback(async () => {
    setLoader(true);
    const response = await getAllUsersFromMyCompany(metaValues.pageNumber);
    if (response.status === WebServiceStatus.success) {
      const { meta, users } = response.data;
      if (meta) {
        setMetaValues({
          pageSize: Number(meta.pageSize),
          pageNumber: Number(meta.pageNumber),
          totalPages: Number(meta.totalPages),
          totalElements: Number(meta.totalElements),
        });
      }
      if (users) {
        var finalUserList: UserInfo[] = [];
        const userList = users as UserFromAPI[];
        const userDetailResponses = await Promise.all(
          userList.map((user) => getOrgUserByEmail(user.email))
        );
        userDetailResponses.forEach((userResponse, index) => {
          if (response.status === WebServiceStatus.success) {
            const user = userResponse.data as UserInfo;
            if (user) {
              // Updating the user list only when the user object is created
              finalUserList.push(user);
            }
            if (finalUserList.length === userList.length) {
              // When all requests returns success values, fetching the compnay roles for each by the email
              fetchCompanyRolesByEmail(userList, finalUserList);
            } else if (index === userDetailResponses.length - 1) {
              // Following code is added to fix CP-1188
              // When tried to fetch the company roles by email, some requests failed.
              // Ignoring the failed users and loading the page
              const newUserList = userList.filter((user) => {
                // Checking whether the users are available in the newly generated list
                return finalUserList.some(
                  (userInfo) =>
                    userInfo.email.trim().toLowerCase() ===
                    user.email.trim().toLowerCase()
                );
              });
              fetchCompanyRolesByEmail(newUserList, finalUserList);
            }
          }
        });
        setLoader(false);
      }
      setEnableAddUser(true);
    } else {
      setLoader(false);
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
  }, [fetchCompanyRolesByEmail, metaValues.pageNumber]);

  // Search field changes
  useEffect(() => {
    // performs filter on given field and text
    const performSearchUser = (searchText: string) => {
      var rowDataToFilter = [...nonFilteredRowData];
      if (searchText.trim().isNotEmpty()) {
        rowDataToFilter = rowDataToFilter.filter((row) => {
          let name = `${row.users.firstName} ${row.users.lastName}`;
          return name
            .trim()
            .toLowerCase()
            .includes(searchText?.trim().toLowerCase());
        });
      }

      if (selectedFilters.length > 0) {
        rowDataToFilter = applyFiltersOnGrid(rowDataToFilter, selectedFilters);
      }

      setFilteredRowData(moveCurrentUserToTop(rowDataToFilter));
    };

    /// The method delays the callback for 700 millseconds
    const delaySearchAction = setTimeout(() => {
      performSearchUser(searchText.trim());
    }, TEXTFIELD_CHANGE_DELAY);
    return () => clearTimeout(delaySearchAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonFilteredRowData, searchText, selectedFilters]);

  // move current user to top
  const moveCurrentUserToTop = (array: GridValidRowModel[]) => {
    const { email } = userData;
    const index = array.findIndex(
      (item) => item.roles && item.roles.email === email
    );

    if (index !== -1) {
      const [selectedObject] = array.splice(index, 1);
      array.unshift(selectedObject);
    }

    return array;
  };

  // applying filters
  useEffect(() => {
    if (selectedFilters.length <= 0) {
      setFilteredRowData(nonFilteredRowData);
      return;
    }

    var rowDataToFilter = [...nonFilteredRowData];
    if (searchText.trim().isNotEmpty()) {
      // If search is on, applies filter on filtered data
      rowDataToFilter = [...filteredRowData];
    }

    rowDataToFilter = applyFiltersOnGrid(rowDataToFilter, selectedFilters);

    setFilteredRowData(rowDataToFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  useEffect(() => {
    fetchAllUsers();
  }, [metaValues.pageNumber, fetchAllUsers]);

  const applyFiltersOnGrid = (
    rowDataToFilters: GridRowsProp,
    allFilters: GridFilter[]
  ) => {
    var resultData = [...rowDataToFilters];
    for (var index = 0; index < allFilters.length; index++) {
      const { field, filters } = allFilters[index];

      switch (field) {
        case "users":
          resultData = resultData.filter((row) => {
            const gridData = row as UserGridData;
            const searchText =
              `${gridData.users.firstName.trim()} ${gridData.users.lastName.trim()}`.trim();
            return filters.some(
              (value) => value.trim().toLowerCase() === searchText.toLowerCase()
            );
          });
          break;
        case "active_inactive":
          resultData = resultData.filter((row) => {
            const gridData = row as UserGridData;
            const searchText = gridData.active_inactive ? "Active" : "Inactive";
            return filters.some(
              (value) => value.trim().toLowerCase() === searchText.toLowerCase()
            );
          });
          break;
        case "manageportals":
          resultData = resultData.filter((row) => {
            const gridData = row as UserGridData;
            const searchText = gridData.manageportals.toString();
            return filters.some(
              (value) => value.trim().toLowerCase() === searchText.toLowerCase()
            );
          });
          break;
        case "createddate":
          if (filters.length === 2) {
            const dateFormat = "MM / DD / YYYY";
            const startDate = moment(
              filters[0].formatUTCNanoSecondsToString(dateFormat)
            );
            const endDate = moment(
              filters[1].formatUTCNanoSecondsToString(dateFormat)
            );
            resultData = resultData.filter((row) => {
              const gridData = row as UserGridData;
              const searchCriteria = moment(
                gridData.createddate.formatUTCNanoSecondsToString(dateFormat)
              );
              return searchCriteria.isBetween(
                startDate,
                endDate,
                undefined,
                "[]"
              );
            });
          }
          break;
        case "lastsignin":
          if (filters.length === 2) {
            const dateFormat = "MM / DD / YYYY";
            const startDate = moment(
              filters[0].formatUTCNanoSecondsToString(dateFormat)
            ).startOf("day");
            const endDate = moment(
              filters[1].formatUTCNanoSecondsToString(dateFormat)
            ).endOf("day");
            resultData = resultData.filter((row) => {
              const gridData = row as UserGridData;
              const searchCriteria = moment(
                gridData.lastsignin.formatUTCNanoSecondsToString(dateFormat)
              );
              return searchCriteria.isBetween(
                startDate,
                endDate,
                undefined,
                "[]"
              );
            });
          }
          break;
        default:
          break;
      }
    }
    return resultData;
  };

  return (
    <>
      {popperName && getFilterPopper()}
      {showAddNewUserDialog && getAddNewUserDialog()}
      {showConfirmation && getActiveInactiveConfirmation()}
      {showCustomIndicator && <CustomIndicator />}
      <MDBox>
        <MDBox display="flex" alignItems="end" pl={2} pb={2.2}>
          <MDBox pr={2} display={"flex"} flexDirection={"column"}>
            <MDTypography
              variant="caption"
              fontWeight="regular"
              sx={{ color: "#6E7491", fontSize: "14px" }}
            >
              Total Active Users
            </MDTypography>
            <MDTypography variant="h5">{totalActiveUsers}</MDTypography>
          </MDBox>
          <TextField
            fullWidth
            value={searchText}
            placeholder="Search Users"
            sx={searchBarStyle}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => setSearchText(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search fontSize="medium" sx={{ color: "#344767" }} />
                </InputAdornment>
              ),
            }}
          />
          <MDBox display="flex" ml="auto" justifyContent="space-between">
            {selectedFilters.length > 0 && getClearAllFiltersButton()}
            <MDButton
              variant="contained"
              color="info"
              onClick={() => {
                setShowAddNewUserDialog(true);
              }}
              disabled={!enableAddUser}
            >
              ADD NEW USER
            </MDButton>
          </MDBox>
        </MDBox>
        <Card
          sx={{
            minHeight: `calc(90vh - 122px)`,
            height: `calc(90vh - 122px)`,
            justifyContent: "flex-start",
            position: "relative",
          }}
        >
          <DataGridPremium
            apiRef={gridRef}
            columns={renderedColumns}
            rows={filteredRowData}
            hideFooter
            disableColumnMenu
            sx={{
              ...gridStyle,
              ...dataGridCellButtonStyle,
            }}
            density="compact"
            disableRowSelectionOnClick
            sortModel={sortModel}
            loading={loader}
            rowHeight={140}
            initialState={{
              pinnedColumns: {
                left: pinnedColumns,
              },
            }}
            slots={{
              loadingOverlay: LinearProgress,
              noRowsOverlay: getNoRowsMessage,
            }}
          />
        </Card>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </>
  );
};
export default ManageUserGrid;
