import { Grid } from "@mui/material";
import EscalatedVueItem from "./EscalatedVueItem";
import { DashboardVue } from "pages/dashboard/home/dashboard-home/utils/dashboard_interfaces";

const EscalatedVueList = ({
  latestEscalatedVues,
}: {
  latestEscalatedVues: DashboardVue[];
}) => {
  return (
    <Grid container spacing={3}>
      {latestEscalatedVues.map((vueItem) => {
        return (
          <EscalatedVueItem
            key={vueItem.submissionId}
            dashboardVue={vueItem}
            shouldDownloadMapFromId={false}
          />
        );
      })}
    </Grid>
  );
};

export default EscalatedVueList;
