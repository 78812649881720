export enum VueStatusFilter {
  vueStatusAll = 0,
  vueStatusOpen = 1,
  vueStatusInProgress = 2,
  vueStatusInReview = 3,
  vueStatusComplete = 4,
  vueStatusCancelled = 5,
  vueStatusFailed = 6,
}

// ENUMS IN RESPONSE

export enum VueSubmissionStatus {
  submissionOpen = 0,
  submissionInProgress = 1,
  submissionInReview = 2,
  submissionComplete = 3,
  submissionCancelled = 4,
  submissionFailed = 5,
}

export enum ImmutableVueTags {
  urgent = "Urgent",
  highQuality = "High Quality",
  internal = "Internal",
  escalated = "Escalated",
}

export const immutableVueTagList = [
  ImmutableVueTags.urgent,
  ImmutableVueTags.highQuality,
  ImmutableVueTags.internal,
  ImmutableVueTags.escalated,
];

///////////////////// HELPER METHODS - VUE STATUS /////////////////////

// Generates Vue status name to display in the application
export const getVueStatus = (status: string): string => {
  switch (status.toUpperCase()) {
    case "SUBMISSION_OPEN":
      return "OPEN";
    case "SUBMISSION_IN_PROGRESS":
      return "IN PROGRESS";
    case "SUBMISSION_IN_REVIEW":
      return "IN REVIEW";
    case "SUBMISSION_COMPLETE":
      return "COMPLETED";
    case "SUBMISSION_CANCELLED":
      return "CANCELLED";
    case "SUBMISSION_FAILED":
      return "FAILED";
    default:
      return "";
  }
};

// Generates the Vue status filter value to send through the API
export const getVueStatusFilterValue = (status: string): number => {
  switch (status.toUpperCase()) {
    case "VUE_STATUS_ALL":
    case "ALL":
      return 0;
    case "VUE_STATUS_OPEN":
    case "OPEN":
      return 1;
    case "VUE_STATUS_IN_PROGRESS":
    case "IN PROGRESS":
      return 2;
    case "VUE_STATUS_IN_REVIEW":
    case "IN REVIEW":
      return 3;
    case "VUE_STATUS_COMPLETE":
    case "COMPLETED":
      return 4;
    case "VUE_STATUS_CANCELLED":
    case "CANCELLED":
      return 5;
    case "VUE_STATUS_FAILED":
    case "FAILED":
      return 6;
    default:
      return 0;
  }
};

export const getVueStatusFilterOptions = (): string[] => {
  return [
    "ALL",
    "OPEN",
    "IN PROGRESS",
    "IN REVIEW",
    "COMPLETED",
    "CANCELLED",
    // "FAILED", Removing Failed status from List CP-819
  ];
};

export const getStatusStringValue = (number: number): string => {
  const surveyTypes: { [key: number]: string } = {
    0: "ALL",
    1: "OPEN",
    2: "IN PROGRESS",
    3: "IN REVIEW",
    4: "COMPLETED",
    5: "CANCELLED",
    6: "FAILED",
  };

  return surveyTypes[number] || "";
};

export const getVueStatusFilterForExportAPI = (type: number): string => {
  switch (type) {
    case 1:
      return "VUE_STATUS_OPEN";
    case 2:
      return "VUE_STATUS_IN_PROGRESS";
    case 3:
      return "VUE_STATUS_IN_REVIEW";
    case 4:
      return "VUE_STATUS_COMPLETE";
    case 5:
      return "VUE_STATUS_CANCELLED";
    case 6:
      return "VUE_STATUS_FAILED";
    default:
      return "VUE_STATUS_ALL";
  }
};

///////////////////// HELPER METHODS - SURVEY TYPE /////////////////////

// Generates the survey type filter value to send through the API
export const getSurveyTypeFilterValue = (name: string): number => {
  switch (name.toUpperCase()) {
    case "DAY":
      return 1;
    case "NIGHT":
      return 2;
    case "INTERIOR":
      return 3;
    case "INTERIOR EXTERIOR":
      return 4;
    case "RESIDENTIAL":
      return 5;
    case "RESIDENTIAL INTERIOR":
      return 6;
    case "RESIDENTIAL EXTERIOR":
      return 7;
    case "LANDSCAPE":
      return 8;
    case "SNOW":
      return 9;
    case "PARKING LOT":
      return 10;
    case "EXTERIOR":
      return 11;
    case "JANITORIAL":
      return 12;
    case "LIGHTING":
      return 13;
    case "SIGNAGE":
      return 14;
    case "DISASTER":
      return 15;
    case "VACANT":
      return 16;
    case "FLOORING":
      return 17;
    case "MEASUREMENT":
      return 18;
    case "SCHEDULED":
      return 19;
    default:
      return 0;
  }
};

// Used to fetch the survey type value with id
// Used in the popup to check with previously selected items
export const getSurveyTypeStringValue = (number: number): string => {
  const surveyTypes: { [key: number]: string } = {
    0: "ALL",
    1: "DAY",
    2: "NIGHT",
    3: "INTERIOR",
    4: "INTERIOR EXTERIOR",
    5: "RESIDENTIAL",
    6: "RESIDENTIAL INTERIOR",
    7: "RESIDENTIAL EXTERIOR",
    8: "LANDSCAPE",
    9: "SNOW",
    10: "PARKING LOT",
    11: "EXTERIOR",
    12: "JANITORIAL",
    13: "LIGHTING",
    14: "SIGNAGE",
    15: "DISASTER",
    16: "VACANT",
    17: "FLOORING",
    18: "MEASUREMENT",
    19: "SCHEDULED",
  };

  return surveyTypes[number] || "";
};

// Options to be displayed on the filter popup
export const getSurveyTypeFilterOptions = (): string[] => {
  return [
    "ALL",
    "DAY",
    "NIGHT",
    "RESIDENTIAL",
    "INTERIOR",
    "INTERIOR EXTERIOR",
    "RESIDENTIAL INTERIOR",
    "RESIDENTIAL EXTERIOR",
    "LANDSCAPE",
    "SNOW",
    "PARKING LOT",
    "EXTERIOR",
    "JANITORIAL",
    "LIGHTING",
    "SIGNAGE",
    "DISASTER",
    "VACANT",
    "FLOORING",
    "MEASUREMENT",
    "SCHEDULED",
  ];
};
