import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { mdiCheck } from "@mdi/js";
import { useState } from "react";
import iVueitLogo from "assets/images/logo7.png";
import MDButton from "components/MDButton";
import Icon from "@mdi/react";
import { deEscalateButtonStyle } from "../../styles/VueDetailStyles";
import { PhotoFileInfo, VideoFileInfo } from "../utils/vue_detail_interface";
import VideoThumbnail from "./VideoThumbnail";

interface Props {
  escalatedPhoto?: PhotoFileInfo;
  escalatedVideo?: VideoFileInfo;
  deEscalateButtonClick?: (shouldDeEscalate: boolean) => void;
  isStepTypeVideo?: boolean;
}

export const EscalationSummary = (props: Props) => {
  const {
    escalatedPhoto,
    escalatedVideo,
    deEscalateButtonClick,
    isStepTypeVideo,
  } = props;
  const escalationNotes = isStepTypeVideo
    ? escalatedVideo.escalationNotes
    : escalatedPhoto.escalationNotes;
  const adminEscalated = isStepTypeVideo
    ? escalatedVideo.adminEscalated
    : escalatedPhoto.adminEscalated;
  const [deEscalatePhoto, setDeEscalatePhoto] = useState<boolean>(false);

  const handleDeEscalatePhotoClick = () => {
    const value = !deEscalatePhoto;
    setDeEscalatePhoto(value);
    deEscalateButtonClick(value);
  };

  const getImageComponent = () => {
    const { mimeType, data, adminHide, clientHide } = escalatedPhoto;
    return (
      <img
        src={`data:${mimeType};base64,${data}`}
        alt=""
        style={{
          filter: (adminHide || clientHide) && "blur(12px)",
          width: "106px",
          height: "70px",
          objectFit: "contain",
          flexShrink: "0",
        }}
      />
    );
  };

  return (
    <MDBox>
      <MDBox display="flex">
        {isStepTypeVideo ? (
          <VideoThumbnail
            thumbnailFileId={escalatedVideo.thumbnailFileId}
            imageWidth={"106px"}
            imageHeight={"70px"}
          />
        ) : (
          getImageComponent()
        )}
        {escalationNotes && (
          <MDBox sx={{ ml: "15px", flexGrow: "1" }}>
            <MDTypography
              fontSize="14px"
              sx={{ fontWeight: "700", lineHeight: "14px" }}
            >
              Reason For Escalation
            </MDTypography>
            <MDTypography
              fontSize="14px"
              sx={{ lineHeight: "16px", mt: "8px" }}
            >
              {escalationNotes}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="end"
        justifyContent="end"
      >
        {adminEscalated ? (
          <img src={iVueitLogo} alt="iVueit Logo" width="auto" height="40px" />
        ) : deEscalateButtonClick ? (
          <MDButton
            variant="outlined"
            color={deEscalatePhoto ? "info" : "primary"}
            size="medium"
            sx={{
              ...deEscalateButtonStyle,
              background: deEscalatePhoto ? "#ebf6eb" : null,
              border: deEscalatePhoto ? "none" : null,
              "&:focus": {
                boxShadow: "none !important",
                background: deEscalatePhoto ? "#ebf6eb !important" : null,
              },
            }}
            onClick={handleDeEscalatePhotoClick}
          >
            {deEscalatePhoto ? (
              <span style={{ marginRight: "5px" }}>
                <Icon path={mdiCheck} size={1} />
              </span>
            ) : null}
            {`DE-ESCALATE ${isStepTypeVideo ? "VIDEO" : "PHOTO"}`}
          </MDButton>
        ) : null}
      </MDBox>
    </MDBox>
  );
};
