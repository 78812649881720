import { RoutePath } from "../../constants";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";

interface Props {
  children: React.ReactElement;
}

export const ProtectedRoute: React.FC<Props> = ({ children }) => {
  // // Get user from context
  const { userData } :{userData:UserInfo} = useAuth();
  // // if no user.token redirect to login path
  if (!userData) {
    return <Navigate to={RoutePath.root} replace />;
  }

  return children ? children : <Outlet />;
};