import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { TimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { Moment } from "moment";
import moment from "moment";
import { GlobalStyles } from "@mui/material";
import { timePickerStyle } from "../home/vues/styles/VueStyles";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

interface Props {
  isRequiredField?: boolean;
  title: string;
  [key: string]: any;
  defaultTime: Moment;
  onChange?: (selectedTime: Moment) => void;
  errorMessage?: string;
  openDialog?: boolean;
  shouldOpenDialog?: () => void;
  shouldCloseDialog?: () => void;
  minWidth?: number;
}

function CustomTimePicker({
  isRequiredField,
  title,
  defaultTime,
  minTime,
  maxTime,
  onChange,
  openDialog,
  errorMessage,
  shouldOpenDialog,
  shouldCloseDialog,
  minWidth,
  ...rest
}: Props): JSX.Element {
  /// Time format for the picker
  const dateFormat = "HH:mm a";

  /// This holds the date selected by the user
  const [value, setValue] = useState<Moment | null>(defaultTime);

  /// Handles the change in datePicker
  const handleTimePickerChange = (newTime: Moment) => {
    const selectedTime = moment(newTime, dateFormat);
    setValue(selectedTime);
    onChange(selectedTime);
  };

  return (
    // Global styles
    <MDBox>
      <MDBox lineHeight={1} mb={0.5}>
        <MDTypography
          variant="caption"
          color="dark"
          sx={{ fontWeight: "500", fontSize: "14px" }}
        >
          {title}
        </MDTypography>
        {isRequiredField && <RequiredFieldMarker />}
      </MDBox>
      <MDBox
        display="flex"
        sx={{
          ".MuiFormControl-root": {
            flexGrow: 1,
          },
        }}
      >
        {/* Global Styles for time picker */}
        <GlobalStyles styles={timePickerStyle} />
        <TimePicker
          defaultValue={defaultTime}
          value={value}
          onChange={handleTimePickerChange}
          sx={{
            ".MuiSvgIcon-root": {
              color: "#344767",
              minWidth: minWidth,
            },
            ".MuiFormHelperText-root": {
              color: "#AE1709",
              fontWeight: 400,
              ml: 0,
            },
          }}
          {...rest}
          open={openDialog}
          onOpen={shouldOpenDialog}
          onClose={shouldCloseDialog}
          slotProps={{
            // Action props for the date picker
            actionBar: {
              actions: ["clear"],
            },
            field: {
              readOnly: true,
            },
            textField: {
              error: false,
              helperText: errorMessage,
            },
          }}
        />
      </MDBox>
    </MDBox>
  );
}

export default CustomTimePicker;
