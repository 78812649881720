import { mdiContentCopy } from "@mdi/js";
import Icon from "@mdi/react";
import { DialogActions, DialogContent, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { generateAPIKeys } from "../services/settingsService";
import { WebServiceStatus } from "utils/services/AppUrls";
import { GENERIC_ERROR_MESSAGE } from "./../../../constants";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";

interface Props {
  companyId: string;
  closeDialog: () => void;
}

interface APIKeys {
  apiKey: string;
  secret: string;
}

const GenerateAPIKeyDialog = (props: Props) => {
  const [apiKeys, setApiKeys] = useState<APIKeys>(null);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);

  useEffect(() => {
    const generateCredentials = async (companyId: string) => {
      const response = await generateAPIKeys(companyId);
      if (response.status === WebServiceStatus.success) {
        const data = response.data as APIKeys;
        if (data) {
          setApiKeys(data);
        } else {
          setSnackbarContent({
            title: "Attention!",
            message: GENERIC_ERROR_MESSAGE,
            isError: true,
          });
        }
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
    };

    if (props.companyId?.isNotEmpty()) {
      generateCredentials(props.companyId);
    }
  }, [props.companyId]);

  return (
    <MDBox>
      <DialogContent sx={{ padding: "32px 24px !important" }}>
        <MDTypography variant="h6" mb={3.6} sx={{ lineHeight: "22px" }}>
          Success! Store these securely, this is the only time you will ever see
          these credentials. These credentials cannot be recovered, but you can
          generate new keys anytime.
        </MDTypography>
        <MDBox display="flex" alignItems="center" mb={1}>
          <MDTypography variant="button" fontWeight="medium" mr={1}>
            Key:
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular">
            {apiKeys?.apiKey ?? "Generating keys..."}
          </MDTypography>
          {apiKeys && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                navigator.clipboard.writeText(apiKeys.apiKey);
                setSnackbarContent({
                  title: "Copied!",
                  message: "Copied to clipboard",
                  isError: false,
                });
              }}
            >
              <Icon path={mdiContentCopy} size={0.72} color="#1a73e7" />
            </IconButton>
          )}
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <MDTypography variant="button" fontWeight="medium" mr={1}>
            Secret:
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular">
            {apiKeys?.secret ?? "Generating keys..."}
          </MDTypography>
          {apiKeys && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                navigator.clipboard.writeText(apiKeys.secret);
                setSnackbarContent({
                  title: "Copied!",
                  message: "Copied to clipboard",
                  isError: false,
                });
              }}
            >
              <Icon path={mdiContentCopy} size={0.72} color="#1a73e7" />
            </IconButton>
          )}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          color="info"
          size="medium"
          onClick={props.closeDialog}
        >
          CLOSE
        </MDButton>
      </DialogActions>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </MDBox>
  );
};

export default GenerateAPIKeyDialog;
