import MDBox from 'components/MDBox'
import PhotoRoundedIcon from '@mui/icons-material/PhotoRounded';
import MDTypography from 'components/MDTypography';

export const PlaceholderImage = () => {
    return (
        <MDBox
            height="500px"
            borderRadius="5px"
            alignItems="center"
            justifyContent="center"
            display="flex"
            backgroundColor="#E9EAED !important">
            <MDBox alignItems="center" display="flex" flexDirection="column">
                <PhotoRoundedIcon
                    sx={{
                        "&.MuiSvgIcon-fontSizeInherit": {
                            fontSize: "150px !important",
                        },
                        color: '#999999'
                    }} />
                <MDTypography sx={{ color: "#999999", fontSize: "18px", fontWeight: "500" }}>
                    Select a location on the map to view the image.
                </MDTypography>
            </MDBox>
        </MDBox>
    )
}
