import { useMemo, ReactNode, useCallback } from "react";

// react-chartjs-2 components

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// PieChart configurations
import configs from "layouts/Charts/PieChart/configs";
import { Pie } from "react-chartjs-2";

type onPieChartClick = (index: number) => void;

// Declaring props types for PieChart
interface Props {
  icon?: {
    color?:
      | "open"
      | "inprogress"
      | "inreview"
      | "pastdue"
      | "completed"
      | "previousyear"
      | "presentyear"
      | string;
    component: ReactNode;
  };
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  chart: {
    labels: string[];
    datasets: {
      label: string;
      backgroundColors: string[];
      data: number[];
    };
  };
  onClick?: onPieChartClick;
  [key: string]: any;
}

function PieChart({
  icon,
  title,
  description,
  height,
  chart,
  onClick,
}: Props): JSX.Element {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  // Handles the click on bar chart
  const handlePieChartClick = useCallback(
    (element: [any], event: any) => {
      /// Handling the click on the data point
      if (element.length > 0) {
        const clickedElement = element[0];
        const { index } = clickedElement;
        if (onClick) {
          // Sending the selected data to the parent
          onClick(index);
        }
      }
    },
    [onClick]
  );
  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height} sx={{ cursor: "pointer" }}>
            <Pie
              data={data}
              options={options}
              getElementAtEvent={handlePieChartClick}
            />
          </MDBox>
        ),
        [data, handlePieChartClick, height, options]
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Declaring default props for PieChart
PieChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

export default PieChart;
