import MDTypography from "components/MDTypography";

interface Props {
  label: string;
  fontSize?: string;
}

export const PlaceholderComponent = (props: Props) => {
  return (
    <MDTypography
      fontWeight="400"
      fontSize={props.fontSize ?? "24px"}
      sx={{
        color: "#939393",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.label}
    </MDTypography>
  );
};
