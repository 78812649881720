import {
  EditableCell,
  TemplatePlaceholder,
  downloadFileToDevice,
  getFieldNameFromHeader,
} from "@ivueit/vue-engine";
import MDTypography from "components/MDTypography";
import { useRef, useState } from "react";
import { mdiInformation } from "@mdi/js";
import Icon from "@mdi/react";
import { MDBox } from "../../vues/vue-grid/helpers/imports";
import importListImage from "../../../../../assets/images/importSitesList.png";
import downloadListImage from "../../../../../assets/images/downloadSitesList.png";
import { read, utils } from "xlsx";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid-premium";
import { DialogContent, DialogActions, Button } from "@mui/material";
import { BulkImportProps } from "../helpers/constants";
import {
  knownEditableColumns,
  renameTemplateColumnHeaders,
  siteListFixedHeaders,
  siteListRequiredColumns,
} from "../helpers/helpers";
import { downloadFile } from "pages/profile/services/ProfileServices";
import { CustomIndicator } from "pages/components/CustomIndicator";
import BulkImportPreviewGrid from "./BulkImportPreviewGrid";

const contentStyle = {
  fontSize: "18px",
  fontWeight: "400",
  color: "#191919",
};

const instructionContentStyle = {
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "400",
  color: "#344767",
  maxWidth: "780px",
  marginLeft: "12px"
};

interface Props {
  // Defines the original data available on the server
  originalSiteList: GridRowsProp;
  onImportSuccess: () => void;
  onCloseDialog: () => void;
}

enum BulkImportState {
  uploadFile,
  processFileContent,
}

export const BulkImportDialogContent = (props: Props) => {
  /// Error Message
  const [importProps, setImportProps] = useState<BulkImportProps>();
  const [error, setError] = useState<string>("");
  const [dialogState, setDialogState] = useState<BulkImportState>(
    BulkImportState.uploadFile
  );
  /// Reference for the input element
  const inputRef = useRef(null);
  /// Picked File
  const [pickedFile, setPickedFile] = useState<File>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const handleUploadFile = () => {
    setError("");
    inputRef.current.click();
  };

  const handleDownloadFile = async () => {
    const fileId = process.env.REACT_APP_SITE_LIST_TEMPLATE;
    setShowLoader(true);
    const fileData = await downloadFile(fileId);
    setShowLoader(false);
    const { mimeType, data } = fileData;
    const url = `data:${mimeType};base64,${data}`;
    downloadFileToDevice(url, fileData.filename, fileData.mimeType);
  };

  /// Importing the contents from excel to the grid
  const importDataFromExcel = async (file: File) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      /// Reads & fetches the workBook
      const workBook = read(event.target.result);
      /// Retrieve all sheet names available in the workbook
      const sheets = workBook.SheetNames;
      if (sheets.length) {
        /// Select the data of the first sheet in the workbook
        const workSheet = workBook.Sheets[sheets[0]];
        /// Converts a worksheet object to an array of JSON objects. Considering the first row as column header
        const rowsFromExcel: string[][] = utils.sheet_to_json(workSheet, {
          header: 1,
          blankrows: false,
        });
        if (rowsFromExcel.length <= 1) {
          // Uploaded file does not contains any rows, displaying error
          setError(
            `Oh no! Something went wrong. Please make sure your file has at least 1 row(s).`
          );
          return;
        } else {
          /// Filtering the currently available headers in the excel
          const allAvailableHeaders = rowsFromExcel[0].map((e) =>
            e.trim().toUpperCase().replaceAll("*", "")
          );

          const hasDuplicateHeaders =
            allAvailableHeaders.filter(
              (value, index, arr) => arr.indexOf(value) !== index
            ).length > 0;

          if (hasDuplicateHeaders) {
            setError(
              `Oh no! Duplicate columns found. Please update your file and re-upload to proceed.`
            );
            return;
          }

          /// Checking if all the fixed headers are available
          const hasAllFixedHeaders = siteListRequiredColumns.every(
            (knownHeader) =>
              allAvailableHeaders.some((header) => header === knownHeader)
          );
          const fieldNamesOfAvailableHeaders = allAvailableHeaders.map(
            (header) => getFieldNameFromHeader(header)
          );
          // Generate site names for each row
          const listOfSiteNames = rowsFromExcel.map((row) => {
            const siteIndex = fieldNamesOfAvailableHeaders.indexOf(
              getFieldNameFromHeader("SiteName")
            );
            const siteNumberIndex = fieldNamesOfAvailableHeaders.indexOf(
              getFieldNameFromHeader("SiteNumber")
            );
            const site = (row[siteIndex] ?? "").toString().trim();
            const siteNumber = (row[siteNumberIndex] ?? "").toString().trim();

            return `${site} ${siteNumber}`.trim();
          });

          var duplicateSiteNameRows: string[] = [];
          // Check for duplicates
          listOfSiteNames.map((siteName, index) => {
            const indexOfSecondItem = listOfSiteNames.indexOf(
              siteName.toString().trim()
            );
            if (indexOfSecondItem !== index) {
              // Adjusting index to match the excel row number
              duplicateSiteNameRows.push(
                `${index + 1} & ${indexOfSecondItem + 1}`
              );
              return true;
            }
            return false;
          });
          const hasDuplicates = duplicateSiteNameRows.length > 0;

          var rowsForAddressValidation = [...rowsFromExcel];
          // Removes the header from the list
          rowsForAddressValidation.shift();
          var indexOfInvalidRows: number[] = [];
          rowsForAddressValidation.map((excelRow, index) => {
            const addressIndex = fieldNamesOfAvailableHeaders.indexOf(
              getFieldNameFromHeader("Address")
            );
            const cityIndex = fieldNamesOfAvailableHeaders.indexOf(
              getFieldNameFromHeader("City")
            );

            const stateIndex = fieldNamesOfAvailableHeaders.indexOf(
              getFieldNameFromHeader("State")
            );
            const countryIndex = fieldNamesOfAvailableHeaders.indexOf(
              getFieldNameFromHeader("Country")
            );
            const zipCodeIndex = fieldNamesOfAvailableHeaders.indexOf(
              getFieldNameFromHeader("Zip")
            );
            const address = (excelRow[addressIndex] ?? "").toString().trim();
            const city = (excelRow[cityIndex] ?? "").toString().trim();
            const state = (excelRow[stateIndex] ?? "").toString().trim();
            const country = (excelRow[countryIndex] ?? "").toString().trim();
            const zipcode = (excelRow[zipCodeIndex] ?? "").toString().trim();
            const isInvalid =
              address.isEmpty() ||
              city.isEmpty() ||
              state.isEmpty() ||
              country.isEmpty() ||
              zipcode.isEmpty();
            if (isInvalid) {
              // Adding 2 to adjust with table header and row index
              indexOfInvalidRows.push(index + 2);
            }
            return isInvalid;
          });

          if (indexOfInvalidRows.length > 0) {
            setError(
              `Rows (${indexOfInvalidRows.join(
                ", "
              )}) having empty address values. Please update your file and re-upload to proceed.`
            );
          } else if (rowsFromExcel.length <= 1) {
            // Assuming rows having only header
            setError(
              "Invalid data. Please update your file and re-upload to proceed."
            );
          } else if (hasAllFixedHeaders && !hasDuplicates) {
            setPickedFile(file);

            /// Filtering the custom headers from the available headers
            const customHeaders = allAvailableHeaders.filter(
              (header) => !siteListFixedHeaders.includes(header)
            );
            const editableHeaderList: EditableCell[] = customHeaders.map(
              (header) => {
                return { fieldName: getFieldNameFromHeader(header) };
              }
            );
            const editableColumnList: EditableCell[] = [
              ...knownEditableColumns,
              ...editableHeaderList,
            ].map((cell) => {
              return {
                ...cell,
                fieldName: getFieldNameFromHeader(cell.fieldName),
              };
            });

            const renamedHeadersAsPerTemplate = siteListFixedHeaders.map(
              (header) => {
                return renameTemplateColumnHeaders(header);
              }
            );
            /// Generating the column headers by combining both fixed & custom headers
            const columnHeaders: GridColDef[] = [
              ...renamedHeadersAsPerTemplate,
              ...customHeaders,
            ].map((header) => ({
              field: getFieldNameFromHeader(header),
              headerName: header,
              width: 150,
              sortable: false,
              /// Editable should be true if the item is available in the editable list
              editable: editableColumnList.some(
                (item) =>
                  getFieldNameFromHeader(item.fieldName) ===
                  getFieldNameFromHeader(header)
              ),
              hideable: false,
            }));
            /// Removed the header row from the parsed data
            rowsFromExcel.shift();

            /// Generates the rowData corresponding to the column headers
            const rowData: GridRowsProp = rowsFromExcel.map((row, rowIndex) => {
              let rowObject: { [key: string]: string } = {};
              /// Looping over each headers in the columnHeaders, finding the index in the total headers available, fetching the rowdata
              /// This allows us to get the correct excel rowData corresponding to each columnHeader
              columnHeaders.forEach((column) => {
                let fieldName = getFieldNameFromHeader(column.headerName);
                let index = allAvailableHeaders
                  .map((header) => renameTemplateColumnHeaders(header))
                  .indexOf(column.headerName);
                rowObject[fieldName] = (row[index] ?? "").toString().trim();
              });
              return { id: rowIndex, ...rowObject };
            });
            // Dynamically adding site name column to the table
            const finalColumnData: GridColDef[] = [
              {
                field: getFieldNameFromHeader("SiteName"),
                headerName: "Site Name",
                width: 200,
                sortable: false,
                // Generating column value by joining site & site number
                valueGetter: (params) => {
                  return `${params.row.site || ""} ${
                    params.row.sitenumber || ""
                  }`;
                },
              },
              ...columnHeaders,
            ];
            const gridProps: BulkImportProps = {
              rows: rowData,
              columns: finalColumnData,
              editableColumns: editableColumnList,
              editableHeaders: editableHeaderList,
            };
            setImportProps(gridProps);
            setDialogState(BulkImportState.processFileContent);
          } else {
            setError(
              hasDuplicates
                ? `Duplicate Site Name found in rows (${duplicateSiteNameRows.join(
                    ", "
                  )}). Please update your file and re-upload to proceed.`
                : `Oh no! Something went wrong. Please try again (Make sure your upload template columns match the original site list upload template columns).`
            );
          }
        }
      }
    };
    reader.readAsArrayBuffer(file);
    reader.onerror = () => {
      setError(
        `Oh no! Something went wrong. Please try again (Make sure your upload template columns match the original site list upload template columns).`
      );
    };
  };

  /// Handles the file selection
  const handleFileUploadChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const xlsxFileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const csvFileType = "text/csv";
    const file = event.target.files && event.target.files[0];
    if (file.type !== xlsxFileType && file.type !== csvFileType) {
      setError("File type not supported.");
      return;
    }
    importDataFromExcel(file);
    event.target.value = "";
  };

  /// Handles file deletion & re-opens the file explorer
  const handleDeleteFile = () => {
    setPickedFile(null);
  };

  const getUploadTemplateContent = () => {
    return (
      <>
        <DialogContent>
          <MDBox minHeight="400px">
            <MDBox textAlign="center" mt={3.2} mb={4}>
              <MDTypography variant="h6" sx={{ ...contentStyle }}>
                To get started, import an existing .XLS file or download a blank
                template
              </MDTypography>
            </MDBox>
            <MDBox display="flex" mb={1}>
              <TemplatePlaceholder
                title={"Upload Existing Site List Creation List"}
                filename={pickedFile?.name}
                errorMessage={error}
                buttonName={"IMPORT FILE"}
                imagePath={importListImage}
                onDeleteClick={handleDeleteFile}
                onClick={handleUploadFile}
              />
              <TemplatePlaceholder
                title={"Download Blank Site List Template"}
                buttonName={"DOWNLOAD TEMPLATE"}
                imagePath={downloadListImage}
                onClick={handleDownloadFile}
              />
              <input
                type="file"
                accept=".xlsx,.csv"
                ref={inputRef}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleFileUploadChange(event);
                }}
                style={{ display: "none" }}
              />
            </MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid #a8b8d8" }}>
          <MDBox display="flex" pl={2} mb={0.6} flexGrow="1" >
            <MDBox display="flex" pb="10px">      
              <MDTypography variant="h5" sx={{ fontSize: "14px", pr: "5px" }}>
                Instructions
              </MDTypography>
              <Icon path={mdiInformation} size={0.72} />
            </MDBox>
            <MDTypography sx={instructionContentStyle}>
              Please utilize the provided template to upload one or more
              locations to be added to your site list. Ensure all fields marked
              with an asterisk(*) are filled out. You have the option to use
              latitude and longitude instead of specifying the address, city,
              state, and zip code. Additionally, feel free to include any custom
              metadata fields pertinent to the location(s)
            </MDTypography>
          </MDBox>
          <MDBox>
            <Button
              variant="outlined"
              color="info"
              size="small"
              sx={{
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: "500",
                padding: "11px 16px",
                borderColor: "secondary.light",
                color: "dark.main",
                "&:hover": {
                  backgroundColor: "white.main",
                  borderColor: "secondary.light",
                },
              }}
              onClick={props.onCloseDialog}
            >
              CANCEL
            </Button>
          </MDBox>
        </DialogActions>
      </>
    );
  };

  const getImportPreviewContent = () => {
    if (!importProps) {
      return <>Preview not available. Please choose a different file</>;
    }
    return (
      <BulkImportPreviewGrid
        rowsFromExcel={importProps.rows}
        columnsFromExcel={importProps.columns}
        editableHeaders={importProps.editableHeaders}
        onCloseDialog={props.onCloseDialog}
        originalSiteList={props.originalSiteList}
        onImportSuccess={props.onImportSuccess}
      />
    );
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      {dialogState === BulkImportState.uploadFile
        ? getUploadTemplateContent()
        : getImportPreviewContent()}
    </>
  );
};
