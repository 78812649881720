import { Vue } from "pages/dashboard/home/dashboard-home/utils/dashboard_interfaces";

export enum PhotoFileKeys {
  adminHide = "adminHide",
  escalated = "escalated",
  escalationNotes = "escalationNotes",
  rotation = "rotation",
  clientHide = "clientHide",
}

/// REQUEST PARAMS FOR UPDATE PROFILE
export interface EditPhotoInfo {
  [PhotoFileKeys.adminHide]?: boolean;
  [PhotoFileKeys.escalated]?: boolean;
  [PhotoFileKeys.escalationNotes]?: string;
  [PhotoFileKeys.rotation]?: string;
  [PhotoFileKeys.clientHide]?: string;
}

export interface IndividualVue {
  vue: Vue;
  submissionId: string;
  status: string;
  vuerCompletedAt: string;
  companyId: string;
  companyName: string;
  lastUpdatedAt: string;
  photos?: PhotoFileInfo[];
  videos?: VideoFileInfo[];
}

export const individualVueState: IndividualVue = {
  vue: {
    id: "",
    canonicalId: "",
    title: "",
    description: "",
    notes: "",
    latitude: 0,
    longitude: 0,
    typeOf: "",
    slaTarget: "",
    tz: "",
    createdAt: "",
    vueTags: [],
    mediaTags: [],
    escalated: false,
    escalationNotes: "",
    slaActual: "",
    adminWroteEscalationNotes: false,
    streetAddress: "",
    city: "",
    stateProvinceAbbreviation: "",
    zipPostalCode: "",
    zipSuffixCode: "",
    countryAbbreviation: "",
    pdfFileId: "",
    pdfFileIdSurvey: "",
    pdfFileIdPhotos: "",
    pdfFileIdOrdered: "",
    cost: "",
    isInternal: false,
    surveyName: "",
    surveyTemplateId: "",
    selectedDays: '{"selectedDays": []}',
    startsAt: "",
    endsAt: "",
    startsAtDst: "",
    endsAtDst: "",
    urgent: false,
    highQualityRequired: false,
    siteName: "",
    siteNumber: "",
    siteNameNumber: "",
    batchID: "",
    batchName: "",
    backgroundCheckRequired: false,
    arRequired: false,
    includePhotoTimestamp: false,
    canUseReferral: false,
    jobSiteId: "",
    mapFileId: "",
    fileAttachmentIDs: [],
  },
  submissionId: "",
  status: "",
  vuerCompletedAt: "",
  companyId: "",
  companyName: "",
  lastUpdatedAt: "",
  photos: [],
  videos: [],
};

/// VUE SURVEY

export interface VueSurveyData {
  survey: VueSurvey;
  groups: SurveyGroupWithStep[];
}

export interface VueSurvey {
  id: string;
  typeOf: string;
  canonicalId: string;
  name: string;
  description: string;
  note: string;
  steps: SurveyStep[];
  published: boolean;
  archived: boolean;
  autoCostPerVue?: string;
  autoPayoutPerVue?: string;
  autoSlaHours?: {
    value: string;
  };
  companyId: any;
}

export interface SurveyStep {
  id: string;
  description: string;
  stepType: string;
  params: any;
  validators: any;
  index: string;
  sliderMin: number;
  sliderMax: number;
  sliderSegments: number;
  minSelections: number;
  maxSelections: number;
  options: string;
  groupId: string;
  instances: SurveyStepState[];
}

export interface SurveyGroups {
  id: string;
  groupTitle: string;
  minInstances: number;
  maxInstances: number;
}

export interface SurveyGroupWithStep extends SurveyGroups {
  steps: SurveyStep[];
}

export interface SurveyStepState {
  id: string;
  stepId: string;
  submissionId: string;
  value: string;
  updatedFromLocation: { latitude: number; longitude: number };
  lastUpdated: string;
  escalated: boolean;
  escalationNotes: string;
  groupInstanceIndex: number;
  visible: boolean;
}

export interface PhotoFileInfo {
  id: string;
  canonicalId: string;
  filename: string;
  mimeType: string;
  data?: string;
  uploadedAt: string;
  fileSize: string;
  capturedAt: string;
  escalated: boolean;
  adminEscalated: boolean;
  escalationNotes: string;
  userFlagged: boolean;
  userNotes: string;
  rotation: string;
  address: string;
  location: {
    latitude: number;
    longitude: number;
  };
  adminHide: boolean;
  serialNumber: string;
  clientHide: boolean;
  appliedMediaTags: string[];
  photoTagNames?: string[];
  stepStateId? : string;
}

export interface VideoFileInfo {
  id: string;
  canonicalId: string;
  filename: string;
  mimeType: string;
  fileSize: string;
  durationInMilliseconds: string;
  thumbnailFileId: string;
  videoHeight: number;
  videoWidth: number;
  capturedAt: string;
  escalated: boolean;
  adminEscalated: boolean;
  escalationNotes: string;
  address: string;
  location: {
    latitude: number;
    longitude: number;
  };
  adminHide: boolean;
  clientHide: boolean;
  uploadedAt: string;
  serialNumber: string;
  appliedMediaTags: string[];
  stepStateId? : string;
}
