import { Dialog, DialogTitle, SxProps } from "@mui/material";
import "utils/helpers/extensions";
import { Theme } from "@mui/material/styles";
import { ReactNode } from "react";

interface DialogBoxProps {
  sx?: SxProps;
  title?: string;
  children: ReactNode;
  openDialog: boolean;
  width?: string;
  height?: string;
  [key: string]: any;
}

const CustomDialogBox = ({
  title,
  children,
  openDialog,
  width,
  height,
  sx,
  ...attributes
}: DialogBoxProps) => {
  return (
    <Dialog
      open={openDialog}
      onClose={null}
      fullWidth
      maxWidth="xs"
      sx={{
        ".MuiDialog-paper": {
          maxWidth: width != null ? width : null,
          maxHeight: height != null ? height : null,
        },
        ...sx,
      }}
      {...attributes}
    >
      <DialogTitle
        sx={{
          fontSize: 19,
          paddingLeft: "20px",
          paddingRight: "20px",
          fontWeight: "normal",
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }: Theme) =>
            `${linearGradient(
              rgba(gradients.iVue.main, 1),
              rgba(gradients.iVue.state, 1),
              180
            )}`,
        }}
      >
        <span style={{ color: "white" }}>{title}</span>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default CustomDialogBox;
