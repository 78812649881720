import { InfoOutlined } from "@mui/icons-material";
import { DialogActions, DialogContent } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MessageAlert from "pages/authentication/components/MessageAlert";
import { useEffect, useState } from "react";
import { TagItem } from "../interfaces/interfaces";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

interface Props {
  tagName: string;
  availableTags: TagItem[];
  onClickCancel: () => void;
  onClickSave: (tag: string) => void;
}
const AddTagDialogComponent = (props: Props) => {
  const [tagName, setTagName] = useState(props.tagName ?? "");
  const [errorMessage, setErrorMessage] = useState("");
  const [isInvalidTag, setIsInvalidTag] = useState(false);

  useEffect(() => {
    const isTagAlreadyAvailable = () => {
      const index = props.availableTags.findIndex(
        (value) => value.name.trim().toLowerCase() === tagName.trim().toLowerCase()
      );
      const isExisting =
        index !== -1 &&
        // Ensuring not to display an error when the editing tag is same.
        tagName.trim().toLowerCase() !== props.tagName.trim().toLowerCase();
      setErrorMessage(isExisting ? "This tag name already exists" : "");
      return isExisting;
    };

    const isExisting = isTagAlreadyAvailable();
    const isInvalid = tagName.trim().isEmpty() || isExisting;
    setIsInvalidTag(isInvalid);
  }, [props.availableTags, props.tagName, tagName]);

  return (
    <MDBox>
      <DialogContent sx={{ padding: "10 !important" }}>
        <MDBox display={"flex"}>
          <MDTypography
            variant="h6"
            mb={2}
            sx={{ fontWeight: "500", color: "#000000", textAlign: "left" }}
          >
            Enter a unique Tag Name
          </MDTypography>
          <RequiredFieldMarker />
        </MDBox>
        <MDInput
          fullWidth
          value={tagName}
          placeholder="Add Name"
          InputLabelProps={{ shrink: true }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setTagName(event.target.value)
          }
        />
        {errorMessage.isNotEmpty() && (
          <MessageAlert
            message={errorMessage}
            type={"error"}
            icon={<InfoOutlined />}
          />
        )}
      </DialogContent>
      <MDBox>
        <DialogActions>
          <MDButton
            variant="outlined"
            color="secondary"
            size="small"
            onClick={props.onClickCancel}
          >
            CANCEL
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={() => {
              props.onClickSave(tagName.trim());
            }}
            disabled={isInvalidTag}
          >
            SAVE
          </MDButton>
        </DialogActions>
      </MDBox>
    </MDBox>
  );
};
export default AddTagDialogComponent;
