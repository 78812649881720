import { FC } from "react";
import { VuerData, VuerRequest } from 'types/inter-network';
import VuerTable from "./VuerTable";
import VuerTableRow from "./VuerTableRow";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


interface VuerCardProps {
    handleModal: (data: VuerData | VuerRequest, modalName: string) => void,
    allVuerData?: VuerData[]
}



const VuerCard: FC<VuerCardProps> = ({ handleModal, allVuerData }) => {

    return (
        <>
            <VuerTable
            headers={['VUER ID', 'NAME OF VUER', 'PHONE NUMBER', 'EMAIL', 'COMPLETED VUES', '']}
            >
                {
                    allVuerData.length === 0 ? <MDBox
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        height={'77%'}
                    >
                        <MDTypography
                            variant="body2" sx={{pt: "50px", pb: "50px"}}>
                            No Internal Vuers
                        </MDTypography>
                    </MDBox> :
                        allVuerData.map((vuer) => (
                            <VuerTableRow vuerData={vuer} handleModal={handleModal} />
                        ))}
            </VuerTable>
        </>
    );
};
export default VuerCard;
