import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

interface ITooltip {
  tooltip: React.ReactNode;
  icon: any;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#dadee3",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadee3",
  },
}));

const CustomizedTooltips: React.FC<ITooltip> = ({ icon, tooltip }) => {
  return (
    <HtmlTooltip title={tooltip} placement="right">
      {icon}
    </HtmlTooltip>
  );
};

export default CustomizedTooltips;
