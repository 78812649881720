/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import PageLayout from "layouts/PageLayout";
import bgImage from "assets/images/logo4.png";

// Declaring props types for CoverLayout
interface Props {
  coverHeight?: string;
  image: string;
  children: ReactNode;
}

function CoverLayout({ coverHeight, image, children }: Props): JSX.Element {
  return (
    <PageLayout>
      <MDBox
        width="calc(100% - 2rem)"
        minHeight={coverHeight}
        borderRadius="xl"
        position="relative"
        mx={2}
        my={2}
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }: Theme) =>
            `${linearGradient(
              rgba(gradients.iVue.main, 1),
              rgba(gradients.iVue.state, 1),
              180
            )}`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}>
        <MDBox
          px={1}
          width="100%"
          mx="auto"
          position="absolute"
          minHeight={coverHeight}
          maxHeight={coverHeight}
          display="flex"
          alignItems={{ xs: "top", sm: "top", md: "center", lg: "center", xl: "center" }}
          justifyContent="center"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <img src={bgImage} alt="iVueit Logo" width="100%" />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox mt={{ xs: -20, lg: -18 }} px={1} width="calc(100%-2rem)" mx="auto">
        <Grid container spacing={1} justifyContent="center">
          <Grid item sx={{ width: "100%", maxWidth: "408px", padding: "0" }}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

// Declaring default props for CoverLayout
CoverLayout.defaultProps = {
  coverHeight: "35vh",
};

export default CoverLayout;
