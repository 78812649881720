// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadgeDot from "components/MDBadgeDot";
import PieChart from "layouts/Charts/PieChart";
import colors from "assets/theme/base/colors";
import { VueStatusCount } from "../utils/dashboard_interfaces";
import { memo } from "react";
import { Link } from "@mui/material";

type ChartColor =
  | "open"
  | "inprogress"
  | "inreview"
  | "pastdue"
  | "completed"
  | "previousyear"
  | "presentyear"
  | string;

export type VueStatus =
  | "Open"
  | "In Progress"
  | "In Review"
  | "Past Due"
  | "Completed";

interface Types {
  labels: VueStatus[];
  datasets: {
    label: string;
    backgroundColors: ChartColor[];
    data: number[];
  };
}

interface PieChartProps {
  totalVueCount: number;
  vueStatusCount: VueStatusCount;
  onClick: (status: VueStatus) => void;
  onTotalCountClick: () => void;
}

const { iVueChartColors } = colors;

const StatusPieChart = memo(
  ({
    totalVueCount,
    vueStatusCount,
    onClick,
    onTotalCountClick,
  }: PieChartProps) => {
    const chartData: Types = {
      labels: ["Open", "In Progress", "In Review", "Past Due", "Completed"],
      datasets: {
        label: "Status of Vues Selected",
        backgroundColors: [
          "open",
          "inprogress",
          "inreview",
          "pastdue",
          "completed",
        ],
        data: [
          vueStatusCount.openVues,
          vueStatusCount.inProgressVues,
          vueStatusCount.inReviewVues,
          vueStatusCount.failedVues,
          vueStatusCount.completedVues,
        ],
      },
    };
    const { labels, datasets } = chartData;
    const { backgroundColors, data } = datasets;

    // Finding some of data which is displayed in the chart.
    // This has to be updated when you add new status to the chart as well
    const getTotalItemsOnChart = (): number => {
      return (
        Number(vueStatusCount.openVues) +
        Number(vueStatusCount.inProgressVues) +
        Number(vueStatusCount.inReviewVues) +
        Number(vueStatusCount.failedVues) +
        Number(vueStatusCount.completedVues)
      );
    };

    const onPieChartClick = (index: number) => {
      const selectedPie = chartData.labels[index];
      onClick(selectedPie);
    };

    const getChartLabels = () => {
      const badges = labels.map((label, index) => {
        const background = backgroundColors[index];
        return (
          <MDBadgeDot
            key={label}
            color={
              iVueChartColors[background]
                ? iVueChartColors[background]
                : background
            }
            size="sm"
            badgeContent={`${label} - ${data[index]}`}
            onClick={() => onPieChartClick(index)} // clicking on data point
          />
        );
      });

      return badges;
    };

    return (
      <Card sx={{ height: "100%" }}>
        {/* Chart Caption */}
        <MDBox display="flex" justifyContent="space-between" pt={2} px={2}>
          <MDTypography variant="h6">Status of Vues Selected</MDTypography>
          <Link onClick={onTotalCountClick}>
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="end"
              sx={{ cursor: "pointer" }}
            >
              <MDTypography
                variant="button"
                sx={{
                  fontSize: "14px",
                  fontWeight: "regular",
                  color: "#6E7491",
                }}
              >
                Total Vues
              </MDTypography>
              <MDTypography variant="h3" fontWeight="regular">
                {getTotalItemsOnChart()}
              </MDTypography>
            </MDBox>
          </Link>
        </MDBox>
        <MDBox alignItems="center">
          <PieChart
            chart={chartData}
            height="12.5rem"
            onClick={onPieChartClick}
          />
        </MDBox>
        <MDBox
          pt={2}
          pb={2}
          px={2}
          display="flex"
          flexWrap="wrap"
          mt="auto"
          alignItems="center"
          justifyContent="center"
        >
          {getChartLabels()}
        </MDBox>
      </Card>
    );
  }
);

export default StatusPieChart;
