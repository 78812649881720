import MDTypography from "components/MDTypography";
import { MDBox } from "pages/dashboard/home/vues/vue-grid/helpers/imports";

const VueDescription = ({ title, value }: { title: string, value: string }) => {
  return (
    <MDBox flexShrink="0" flexBasis="auto" flexGrow="1" marginLeft="-15px" >
      <MDBox paddingLeft="15px" paddingRight="15px" marginBottom="8px">
        <MDTypography
          variant="h6"
          fontWeight="bold"
          color="dark"
          sx={{ letterSpacing: "0" }}
        >
          {title.toUpperCase()}
        </MDTypography>
        <MDTypography
          variant="subtitle2"
          color="dark"
          sx={{ fontSize: "14px", fontWeight: "400", maxWidth: "100px" }}
        >
          {value}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default VueDescription;