import {
  CompletedVueCountType,
  DashboardVue,
  VueStatusCount,
} from "./dashboard_interfaces";

// Contains all types of actions to be used in the dashboard page
export enum DashboardActionType {
  setSummaryFigures = "SET_SUMMARY_FIGURES",
  setDashboardVues = "SET_DASHBOARD_VUES",
}

// Dashboard Summary Reducer
export interface DashboardSummaryAction {
  type: DashboardActionType;
  payload: DashboardSummaryState;
}

export interface DashboardSummaryState {
  slaCompletionPercentage: number;
  under24HourCompletionPercentage: number;
  escalatedVueCount: number;
}

export const defaultDashboardSummaryState = {
  slaCompletionPercentage: 0,
  under24HourCompletionPercentage: 0,
  escalatedVueCount: 0,
};

export const dashboardSummaryReducer = (
  state: DashboardSummaryState,
  action: DashboardSummaryAction
) => {
  const { type, payload } = action;
  switch (type) {
    case DashboardActionType.setSummaryFigures: {
      return {
        ...state,
        slaCompletionPercentage: payload.slaCompletionPercentage,
        under24HourCompletionPercentage:
          payload.under24HourCompletionPercentage,
        escalatedVueCount: payload.escalatedVueCount,
      };
    }
    default:
      return state;
  }
};

// Dashboard Vues Reducer
export interface DashboardVuesAction {
  type: DashboardActionType;
  payload: {
    vueStatusCounts: VueStatusCount;
    totalVueCount: number;
    completedVueCountByType: CompletedVueCountType[];
    latestEscalatedAndCompletedVues: DashboardVue[];
    latestCompletedVues: DashboardVue[];
  };
}

export interface DashboardVuesState {
  vueStatusCounts: VueStatusCount;
  totalVueCount: number;
  completedVueCountByType: CompletedVueCountType[];
  latestEscalatedAndCompletedVues: DashboardVue[];
  latestCompletedVues: DashboardVue[];
}

export const defaultDashboardVuesState = {
  vueStatusCounts: {
    openVues: 0,
    inProgressVues: 0,
    inReviewVues: 0,
    failedVues: 0,
    completedVues: 0,
    cancelledVues: 0,
    totalVues: 0,
  },
  totalVueCount: 0,
  completedVueCountByType: [] as CompletedVueCountType[],
  latestEscalatedAndCompletedVues: [] as DashboardVue[],
  latestCompletedVues: [] as DashboardVue[],
};

export const dashboardVuesReducer = (
  state: DashboardVuesState,
  action: DashboardVuesAction
) => {
  const { type, payload } = action;
  switch (type) {
    case DashboardActionType.setDashboardVues: {
      return {
        ...state,
        vueStatusCounts: payload.vueStatusCounts,
        totalVueCount: payload.totalVueCount,
        completedVueCountByType: payload.completedVueCountByType,
        latestEscalatedAndCompletedVues:
          payload.latestEscalatedAndCompletedVues,
        latestCompletedVues: payload.latestCompletedVues,
      };
    }
    default:
      return state;
  }
};
