// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// react-router-dom components
import { Link, useLocation } from "react-router-dom";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// Authentication layout components
import CoverLayout from "layouts/CoverLayout";
// Image
import bgImage from "assets/images/authentication-cover.jpeg";
import { Mail } from "@mui/icons-material";
import { RoutePath } from "./../../../constants";


function CheckYourInbox(): JSX.Element {

    /// useLocation returns the current location object
    /// Can retrieve the email entered by the user using the state of this location
    const userEmail = useLocation().state?.email ?? "";

    return (
        <CoverLayout image={bgImage}>
            <Card>
                <MDBox pt={4} px={15} pb={2} display="flex" justifyContent="center">
                    <MDAvatar size="xxl" bgColor="info" alt="Avatar" sx={{ fontSize: "3.6em", width: "142px", height: "142px" }}>
                        <Icon fontSize="inherit"><Mail /></Icon>
                    </MDAvatar>
                </MDBox>
                <MDBox pb={3} px={3}>
                    <MDBox mt={2}>
                        <MDTypography textAlign="center" variant="h4" fontWeight="medium" color="black">
                            Check Your Inbox
                        </MDTypography>
                    </MDBox>
                    <MDBox mt={2}>
                        <MDTypography textAlign="center" display="block" variant="button" color="dark" fontWeight="regular" >
                            If <MDTypography variant="button" fontWeight="medium" display="inline">{userEmail}</MDTypography> is associated with an iVueit account, you will receive an email on instructions to reset your account password.
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox mb={2} textAlign="center">
                    <MDTypography variant="button" textAlign="center" color="text" fontWeight="regular">Already have an account?&nbsp;
                        <MDTypography
                            component={Link}
                            to={RoutePath.root}
                            variant="button"
                            color="primary"
                            fontWeight="medium"
                            textGradient
                            mb={3}
                        >
                            Sign In
                        </MDTypography>
                    </MDTypography>
                </MDBox>
            </Card>
        </CoverLayout >
    );
}

export default CheckYourInbox;
