import DashboardContentLayout from "layouts/DashboardContentLayout";
import { useCallback, useEffect, useRef, useState } from "react";
import MDBox from "components/MDBox";
import { Card, Link, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {
  mdiArrowUp,
  mdiFileCode,
  mdiPencil,
  mdiTag,
  mdiAlert,
  mdiRepeatVariant,
  mdiAttachment,
} from "@mdi/js";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import {
  StyledButtonWithIcon,
  tooltipStyles,
} from "../vue-grid/components/VueActionBar";
import VueDescription from "./components/VueDescription";
import { VueMapAndMediaSection } from "./components/VueMapAndMediaSection";
import { VueSurveySection } from "./components/VueSurveySection";
import { useNavigate, useParams } from "react-router-dom";
import { linkElementStyle } from "../styles/VueStyles";
import { ScrollToTopButton } from "./components/ScrollToTopButton";
import { TagVueDialogContent } from "./components/TagVueDialogContent";
import { GENERIC_ERROR_MESSAGE, RoutePath } from "../../../../../constants";
import {
  escalatedButtonStyle,
  metaDataDialogStyle,
} from "../styles/VueDetailStyles";
import {
  fetchIndividualVueWithCanonicalID,
  reissueVues,
} from "pages/dashboard/home/vues/services/VueServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { IndividualVue } from "./utils/vue_detail_interface";
import { CustomIndicator } from "pages/components/CustomIndicator";
import {
  ImmutableVueTags,
  getVueStatus,
} from "pages/dashboard/home/vues/vue-grid/utils/enums";
import { getDay } from "./helpers/helper_methods";
import { DeEscalateDialogContent } from "./components/DeEscalateDialogContent";
import { useVueProvider } from "../context/VueProvider";
import { IndividualVueActionType } from "./utils/reducer";
import ExportDialogContent, {
  ExportModalType,
} from "pages/dashboard/batches/components/ExportDialogContent";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { ModalPopUp } from "pages/components/ModalPopUp";
import { BatchItem, VueExportItem } from "../vue-grid/types/types";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { getExporVueItemFromVue } from "../vue-grid/helpers/action_bar_dialogs";
import { alphanumericSort } from "utils/helpers/extensions";
import EditVueDetailsDialogContent, {
  VueDetail,
} from "./components/EditVueDetailsDialogContent";
import { ViewAttachmentsDialogContent } from "./components/ViewAttachmentsDialogContent";
import { TagItem } from "pages/settings/interfaces/interfaces";
import { getMediaTags } from "pages/settings/services/settingsService";

const VueDetails = () => {
  /// To get canonical ID from the URL
  const params = useParams();
  const { canonicalId } = params;
  /// Loader
  const [showLoader, setShowLoader] = useState(false);
  /// For scrolling
  const containerRef = useRef<HTMLDivElement>(null);
  /// Handles the visibility of "Scroll To Top" button
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);
  /// State to handle the tag vue dialog
  const [showTagVueDialog, setShowTagVueDialog] = useState<boolean>(false);
  /// State to handle the export vue dialog
  const [showExportVueDialog, setShowExportVueDialog] =
    useState<boolean>(false);
  /// State to handle the de-escalate vue dialog
  const [showDeEscalateVueDialog, setShowDeEscalateVueDialog] =
    useState<boolean>(false);
  /// State to handle the reissue vue dialog
  const [showReissueVueDialog, setShowReissueVueDialog] =
    useState<boolean>(false);
  /// State to handle the "View Metadata" dialog
  const [showMetadataDialog, setShowMetadataDialog] = useState<boolean>(false);
  /// State to handle the "View Attachments" dialog
  const [showAttachmentsDialog, setShowAttachmentsDialog] =
    useState<boolean>(false);
  /// Edit Dialog
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  /// Context value
  const {
    individualVue,
    dispatchIndividualVueAction,
    storeCompanyMediaTags,
  }: {
    individualVue: IndividualVue;
    dispatchIndividualVueAction: Function;
    storeCompanyMediaTags: Function;
  } = useVueProvider();
  const [vueTagsWithImmmutableTags, setVueTagsWithImmmutableTags] = useState<
    string[]
  >([]);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const navigateFromDetails = useNavigate();

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const handleScroll = () => {
        setShowScrollToTopButton(container.scrollTop > 0);
      };
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    /// Get tags with Immutable tags
    const getTagsWithImmutableTags = () => {
      var immutableTags = getImmutableTagsList();
      const availableTags = [...immutableTags, ...vueTags].sort((a, b) =>
        alphanumericSort(a, b)
      );
      setVueTagsWithImmmutableTags(availableTags);
    };
    getTagsWithImmutableTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualVue]);

  const fetchVueDetail = async () => {
    setShowLoader(true);
    const response = await fetchIndividualVueWithCanonicalID(canonicalId);
    if (response.status === WebServiceStatus.success) {
      const vueDetail = response.data as IndividualVue;
      dispatchIndividualVueAction({
        type: IndividualVueActionType.loadVueData,
        payload: vueDetail,
      });
    } else {
      setSnackbarContent({
        title: "Attention!!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  useEffect(() => {
    fetchVueDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canonicalId]);

  const {
    id,
    description,
    notes,
    siteNameNumber,
    title,
    streetAddress,
    addressTwo,
    typeOf,
    cost,
    slaActual,
    slaTarget,
    selectedDays,
    startsAt,
    endsAt,
    createdAt,
    expiresAt,
    isInternal,
    meta,
    batchName,
    batchID,
    surveyName,
    surveyTemplateId,
    vueTags,
    city,
    stateProvinceAbbreviation,
    zipPostalCode,
    countryAbbreviation,
    urgent,
    highQualityRequired,
    jobSiteId,
    fileAttachmentIDs,
    escalated,
  } = individualVue.vue;

  const isCompletedVue =
    getVueStatus(individualVue.status).toUpperCase() === "COMPLETED";
  const isOpenOrInProgressVue =
    getVueStatus(individualVue.status).toUpperCase() === "IN PROGRESS" ||
    getVueStatus(individualVue.status).toUpperCase() === "OPEN";

  const hasEscalatedPhotos = () => {
    const adminEscalatedPhotos = individualVue.photos.filter(
      (photo) => photo.escalated && photo.adminEscalated
    );
    const clientEscalatedPhotos = individualVue.photos.filter(
      (photo) => photo.escalated && !photo.adminEscalated
    );
    const adminEscalatedVideos = individualVue.videos.filter(
      (video) => video.escalated && video.adminEscalated
    );
    const clientEscalatedVideos = individualVue.videos.filter(
      (video) => video.escalated && !video.adminEscalated
    );
    return (
      escalated ||
      adminEscalatedPhotos.length > 0 ||
      clientEscalatedPhotos.length > 0 ||
      adminEscalatedVideos.length > 0 ||
      clientEscalatedVideos.length > 0
    );
  };

  const getAddress = () => {
    const addressComponents = [
      streetAddress,
      addressTwo,
      city,
      stateProvinceAbbreviation,
      zipPostalCode,
      countryAbbreviation,
    ];
    // Filter out undefined or empty components
    const validComponents = addressComponents.filter(
      (component) => component && component.trim() !== ""
    );
    // Construct the address string by joining valid components with commas
    const fullAddress = validComponents.join(", ");
    // If there are valid components, use the constructed address; otherwise, set it to an empty string
    return validComponents.length > 0 ? fullAddress : "";
  };

  const getImmutableTagsList = () => {
    var tagsUpdated = [];
    if (isInternal) tagsUpdated.push(ImmutableVueTags.internal);
    if (urgent) tagsUpdated.push(ImmutableVueTags.urgent);
    if (highQualityRequired) tagsUpdated.push(ImmutableVueTags.highQuality);
    if (hasEscalatedPhotos()) tagsUpdated.push(ImmutableVueTags.escalated);
    return tagsUpdated;
  };

  const getFormattedDateTime = (time: string) => {
    return (
      time?.formatUTCNanoSecondsToString("MM/DD/YYYY - hh:mm A", true) ?? ""
    );
  };

  const getSelectedDays = () => {
    const selectedDaysArray = JSON.parse(selectedDays).selectedDays as number[];
    const allDaysArray = [0, 1, 2, 3, 4, 5, 6];
    const areAllDaysSelected = allDaysArray.every((day) =>
      selectedDaysArray.includes(day)
    );
    return areAllDaysSelected
      ? "All"
      : selectedDaysArray.map((value) => getDay(value)).join(", ");
  };

  const handleScrollToTopButtonClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  /// Handles the onClick of "TagVue" button
  const handleTagVueButtonClick = () => {
    setShowTagVueDialog(true);
  };

  /// Handles the onClick of "ExportVue" button
  const handleExportVueButtonClick = () => {
    setShowExportVueDialog(true);
  };

  /// Handles the onClick of "View Metadata" button
  const handleViewMetadataButtonClick = () => {
    setShowMetadataDialog(true);
  };

  /// Handles the onClick of "View Attachments" button
  const handleViewAttachmentsButtonClick = () => {
    setShowAttachmentsDialog(true);
  };

  /// This returns the Tags for the vue
  const getTags = (tagsList: string[]) => {
    return (
      <>
        {tagsList.slice(0, 3).map((tag: string, index: number) => (
          <CustomBadge
            key={index}
            content={tag}
            borderRadius="8px"
            sx={{
              ".MuiBadge-badge": {
                background: "#c7ccd0 !important",
                padding: "12px 8px",
                minWidth: "50px",
              },
            }}
          />
        ))}
        {tagsList.length > 3 && (
          <Tooltip
            title={tagsList.slice(3).map((tag: string, index: number) => {
              return (
                <span key={index}>
                  <b key={tag}>
                    {tag}
                    <br />
                  </b>
                </span>
              );
            })}
            arrow
            placement="right"
            componentsProps={{
              tooltip: {
                sx: tooltipStyles,
              },
            }}
            disableHoverListener={false}
          >
            <span>
              <MDTypography
                sx={{
                  pl: 0.8,
                  fontSize: "14px",
                  fontWeight: "regular",
                }}
              >
                +{tagsList.length - 3} more
              </MDTypography>
            </span>
          </Tooltip>
        )}
      </>
    );
  };

  /// Tag vue dialo
  const getTagVueDialog = () => {
    return (
      <CustomDialogBox
        title="Tag Vue"
        width="440px"
        openDialog={showTagVueDialog}
      >
        <TagVueDialogContent
          vueId={id}
          previouslyAddedTags={vueTagsWithImmmutableTags.map((tag) => tag)}
          cancelButtonClick={() => {
            setShowTagVueDialog(false);
          }}
          saveButtonClick={(selectedTagsList: string[]) => {
            dispatchIndividualVueAction({
              type: IndividualVueActionType.editVueTags,
              payload: {
                ...individualVue,
                vue: {
                  vueTags: selectedTagsList,
                },
              },
            });
            setShowTagVueDialog(false);
          }}
          immutableTags={getImmutableTagsList()}
        />
      </CustomDialogBox>
    );
  };

  const getViewMetadataDialog = () => {
    return (
      <CustomDialogBox title="Metadata" openDialog={showMetadataDialog}>
        <MDBox sx={metaDataDialogStyle}>
          <ModalPopUp
            content={JSON.stringify(meta)}
            closeDialog={() => {
              setShowMetadataDialog(false);
            }}
          />
        </MDBox>
      </CustomDialogBox>
    );
  };

  const getViewAttachmentsDialog = () => {
    return (
      <CustomDialogBox
        width="500px"
        title="View Attachments"
        openDialog={showAttachmentsDialog}
        sx={{
          ".MuiDialogContent-root": {
            padding: "36px 46px",
            borderBottom: "1px solid rgb(168, 184, 216)",
          },
        }}
      >
        <ViewAttachmentsDialogContent
          closeDialog={() => setShowAttachmentsDialog(false)}
          listOfFileIds={fileAttachmentIDs}
        />
      </CustomDialogBox>
    );
  };

  /// Export vue dialog
  const getExportVueDialog = () => {
    const selectedIds: string[] = [id];
    const vueItem: VueExportItem = getExporVueItemFromVue(individualVue.vue);
    const selectedVues: VueExportItem[] = [vueItem];
    return (
      <CustomDialogBox
        title="Export"
        width="560px"
        openDialog={showExportVueDialog}
      >
        <ExportDialogContent
          closeDialog={() => setShowExportVueDialog(false)}
          type={ExportModalType.vues}
          hasNonCompletedVues={!isCompletedVue}
          selectedItemIds={selectedIds}
          selectedVues={selectedVues}
        />
      </CustomDialogBox>
    );
  };

  const getDeEscalateDialog = () => {
    return (
      <CustomDialogBox
        title="Escalated Photos"
        width="670px"
        openDialog={showDeEscalateVueDialog}
      >
        <DeEscalateDialogContent
          closeDialog={() => {
            setShowDeEscalateVueDialog(false);
          }}
        />
      </CustomDialogBox>
    );
  };

  const getReissueDialog = () => {
    return (
      <CustomDialogBox title="Reissue" openDialog={showReissueVueDialog}>
        <ModalPopUp
          content={`Are you sure you want to reissue this Vue?`}
          actions={getReIssueActions()}
        />
      </CustomDialogBox>
    );
  };

  const reissueVue = async () => {
    setShowLoader(true);
    const response = await reissueVues([id]);
    if (response.status === WebServiceStatus.success) {
      const batchesList = response.data.batches as BatchItem[];
      if (batchesList.length !== 0) {
        setSnackbarContent({
          title: "Vue Reissued",
          message: "Vue reissued successfully.",
          isError: false,
        });
      } else {
        setSnackbarContent({
          title: "Vue Reissue Failed",
          message: "Vues cannot be reissued.",
          isError: true,
        });
      }
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setShowLoader(false);
    setShowReissueVueDialog(false);
  };

  const getReIssueActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={() => {
          setShowReissueVueDialog(false);
        }}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={() => {
          reissueVue();
        }}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        REISSUE
      </MDButton>,
    ];
  };

  const onClickLinkElement = (path: string, params?: any) => {
    navigateFromDetails(path, {
      state: params,
    });
  };

  const getEditVueDetailsDialog = () => {
    const vueDetail: VueDetail = {
      id: id,
      address: `${streetAddress}, ${city}, ${stateProvinceAbbreviation} ${zipPostalCode}, ${countryAbbreviation}`,
      addressTwo: addressTwo,
      notes: notes,
      startsAt: startsAt.parsedTimeFromBackend(false),
      endsAt: endsAt.parsedTimeFromBackend(false),
    };
    return (
      <CustomDialogBox
        title="Edit Vue Details"
        width="1204px"
        openDialog={showEditDialog}
      >
        <EditVueDetailsDialogContent
          closeDialog={() => {
            setShowEditDialog(false);
          }}
          onUpdateSuccess={async () => {
            await fetchVueDetail();
          }}
          vueDetail={vueDetail}
        />
      </CustomDialogBox>
    );
  };

  const getSiteNameNumber = () => {
    if (siteNameNumber?.isNotEmpty()) {
      // Checking job site ID to avoid navigation to site detail page when jobsite id is not available
      if (jobSiteId.isNotEmpty()) {
        return (
          <Link
            sx={{
              ...linkElementStyle,
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
            }}
            color="primary"
            variant="h5"
            underline="always"
            onClick={() => {
              onClickLinkElement(
                `${RoutePath.vues}/${canonicalId}/site-details`,
                {
                  jobSiteID: jobSiteId,
                  siteName: siteNameNumber,
                }
              );
            }}
            pr={1}
          >
            {siteNameNumber}
          </Link>
        );
      } else {
        // Prevents navigation when job site id is not available
        return (
          <MDTypography variant="h5" color="black" pr={1}>
            {siteNameNumber}
          </MDTypography>
        );
      }
    }
    return <></>;
  };

  /// Fetching Photo tag names from the list of IDs
  const fetchCompanyMediaTags = useCallback(async () => {
    const response = await getMediaTags();
    if (response.status === WebServiceStatus.success) {
      const tagListForMyCompany = response.data.tags as TagItem[];
      storeCompanyMediaTags(tagListForMyCompany);
    } else {
      storeCompanyMediaTags([]);
    }
  }, [storeCompanyMediaTags]);

  useEffect(() => {
    fetchCompanyMediaTags();
  }, [fetchCompanyMediaTags]);

  return (
    <DashboardContentLayout
      needCardBackground={false}
      containerRef={containerRef}
    >
      {showTagVueDialog && getTagVueDialog()}
      {showReissueVueDialog && getReissueDialog()}
      {showExportVueDialog && getExportVueDialog()}
      {showMetadataDialog && getViewMetadataDialog()}
      {showDeEscalateVueDialog && getDeEscalateDialog()}
      {showAttachmentsDialog && getViewAttachmentsDialog()}
      {showEditDialog && getEditVueDetailsDialog()}
      {showLoader && <CustomIndicator />}
      {/* Top section fixed >> */}
      <MDBox
        display="flex"
        position="sticky"
        top="0px"
        zIndex="132"
        sx={{ backgroundColor: "#f0f2f5" }}
      >
        {/* Left column */}
        <MDBox flexGrow="1" pl={2} pb={1.4}>
          <MDBox display="flex" alignItems="center" mb={0.8}>
            {getSiteNameNumber()}
            <MDTypography variant="h5" color="black">
              {title}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center" mb={0.6}>
            <MDTypography variant="h6" color="#344767">
              Address:
            </MDTypography>
            <MDTypography
              variant="h6"
              color="#344767"
              pl={1}
              sx={{ fontWeight: "500" }}
            >
              {getAddress()}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItems="center">
            <MDTypography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#344767" }}
            >
              Survey Name:
            </MDTypography>
            <Link
              sx={{ ...linkElementStyle, fontWeight: "500", cursor: "pointer" }}
              onClick={() => {
                onClickLinkElement(RoutePath.vues, {
                  surveyTemplateId: surveyTemplateId,
                });
              }}
              variant="h6"
              color="primary"
              underline="always"
              pl={1}
            >
              {surveyName}
            </Link>
          </MDBox>
          {batchName && (
            <MDBox display="flex" alignItems="center" mt={0.6}>
              <MDTypography
                variant="h6"
                fontWeight="bold"
                sx={{ color: "#344767" }}
              >
                Batch Name:
              </MDTypography>
              <Link
                sx={{
                  ...linkElementStyle,
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => {
                  onClickLinkElement(RoutePath.vues, {
                    batchID: batchID,
                  });
                }}
                variant="h6"
                color="primary"
                underline="always"
                pl={1}
              >
                {batchName}
              </Link>
            </MDBox>
          )}
          <MDBox display="flex" alignItems="center" mt={2}>
            {vueTagsWithImmmutableTags.length > 0 && (
              <MDBox display="flex" alignItems="center">
                <MDTypography
                  variant="h6"
                  color="dark"
                  sx={{ fontWeight: "600", marginRight: "6px" }}
                >
                  Vue Tags:
                </MDTypography>
              </MDBox>
            )}
            {getTags(vueTagsWithImmmutableTags)}
          </MDBox>
        </MDBox>
        {/* Right column */}
        <MDBox
          flexShrink="0"
          display="flex"
          flexDirection="column"
          alignItems="stretch"
        >
          <MDBox display="flex" flexDirection="column" alignItems="stretch">
            <MDButton
              variant="contained"
              color="info"
              onClick={handleExportVueButtonClick}
              sx={{ fontSize: "14px", fontWeight: "bold" }}
            >
              EXPORT
            </MDButton>
            <MDBox display="flex" mt="12px">
              {isOpenOrInProgressVue && (
                <MDBox display="flex" flex="1">
                  <StyledButtonWithIcon
                    iconPath={mdiPencil}
                    sx={{ mr: "12px", width: "100%", whiteSpace: "nowrap" }}
                    onClick={() => {
                      setShowEditDialog(true);
                    }}
                  >
                    EDIT
                  </StyledButtonWithIcon>
                </MDBox>
              )}
              {isCompletedVue && (
                <MDBox display="flex" flex="1">
                  <StyledButtonWithIcon
                    iconPath={mdiRepeatVariant}
                    sx={{ mr: "12px", width: "100%", whiteSpace: "nowrap" }}
                    onClick={() => {
                      setShowReissueVueDialog(true);
                    }}
                    disabled={!isCompletedVue}
                  >
                    Reissue
                  </StyledButtonWithIcon>
                </MDBox>
              )}
              {hasEscalatedPhotos() && (
                <MDBox display="flex" flex="1">
                  <StyledButtonWithIcon
                    iconPath={mdiAlert}
                    sx={{
                      ...escalatedButtonStyle,
                      mr: "12px",
                      width: "100%",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      setShowDeEscalateVueDialog(true);
                    }}
                    disabled={
                      !(
                        individualVue.photos.length > 0 ||
                        individualVue.videos.length > 0
                      )
                    }
                  >
                    ESCALATED
                  </StyledButtonWithIcon>
                </MDBox>
              )}
              <MDBox display="flex" flex="1">
                <StyledButtonWithIcon
                  iconPath={mdiTag}
                  onClick={handleTagVueButtonClick}
                  sx={{ width: "100%", whiteSpace: "nowrap" }}
                >
                  TAG VUE
                </StyledButtonWithIcon>
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox display="flex" mt={1.6} mb={1}>
            <MDBox display="flex" flex="1">
              <StyledButtonWithIcon
                iconPath={mdiFileCode}
                onClick={handleViewMetadataButtonClick}
                sx={{ width: "100%", whiteSpace: "nowrap" }}
              >
                VIEW METADATA
              </StyledButtonWithIcon>
            </MDBox>
            {fileAttachmentIDs.length !== 0 && (
              <MDBox ml={1.6} display="flex" flex="1">
                <StyledButtonWithIcon
                  iconPath={mdiAttachment}
                  onClick={handleViewAttachmentsButtonClick}
                  sx={{ width: "100%", whiteSpace: "nowrap" }}
                >
                  VIEW ATTACHMENTS
                </StyledButtonWithIcon>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
      {/* Top section fixed << */}
      <MDBox display="flex" alignItems="left" pb={1.4}>
        <Card sx={{ width: "100%" }}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="start"
            flexWrap="wrap"
            px={2}
            py={2}
          >
            <VueDescription title="Vue ID" value={`#${canonicalId}`} />
            <VueDescription
              title="Status"
              value={getVueStatus(individualVue.status).capitalizeWords()}
            />
            <VueDescription title="Category" value={typeOf.capitalizeWords()} />
            <VueDescription
              title="Completed At"
              value={getFormattedDateTime(individualVue.vuerCompletedAt)}
            />
            <VueDescription title="Cost" value={`$${cost}`} />
            <VueDescription
              title="Age/SLA"
              value={`${slaActual}/${slaTarget}`}
            />
            <VueDescription
              title="Publish At"
              value={getFormattedDateTime(createdAt)}
            />
            <VueDescription title="Days Available" value={getSelectedDays()} />
            <VueDescription
              title="Times Available"
              value={`${startsAt.parsedTimeFromBackend(
                true
              )} - ${endsAt.parsedTimeFromBackend(true)}`}
            />
            <VueDescription
              title="Expires"
              value={
                (expiresAt && getFormattedDateTime(expiresAt.value)) ?? "N/A"
              }
            />
            <VueDescription
              title="Internal"
              value={isInternal ? "YES" : "NO"}
            />
          </MDBox>
        </Card>
      </MDBox>
      {/* Map and Photo Section */}
      <Card sx={{ p: "20px", mt: "10px" }}>
        <VueMapAndMediaSection vueDetail={individualVue} />
      </Card>
      {/* Survey Section */}
      <Card sx={{ mt: "20px" }}>
        <VueSurveySection
          surveyTemplateId={surveyTemplateId}
          submissionId={individualVue.submissionId}
          vueOverview={description}
          vueDescription={notes}
        />
      </Card>
      {/* ScrollToTop */}
      {showScrollToTopButton && (
        <ScrollToTopButton
          iconPath={mdiArrowUp}
          handleOnClick={handleScrollToTopButtonClick}
        />
      )}
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </DashboardContentLayout>
  );
};

export default VueDetails;
