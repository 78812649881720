import MDTypography from 'components/MDTypography'
import React from 'react';
import { MDBox } from '../../vue-grid/helpers/imports'

interface Props {
    element: JSX.Element | JSX.Element[];
    groupName: string;
}

export const GroupedQAComponent = (props: Props) => {

    /// Generates answer component based on the answer props
    const getGroupedComponent = () => {
        const hasMultipleAnswers = Array.isArray(props.element);
        if (hasMultipleAnswers) {
            return (props.element as JSX.Element[]).map((element, index) =>
            (<React.Fragment key={index}>
                {element}
            </React.Fragment>))
        } else {
            return props.element;
        }
    }

    return (
        <MDBox
            sx={{
                backgroundColor: "#F0F5F0",
                pt: "8.5px", pb: "25px",
                borderRadius: "5px",
                marginBottom: "30px",
                ".MuiButton-root": {
                    paddingLeft: "11px !important",
                    paddingRight: "12px !important"
                }
            }}>
            <MDTypography
                sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#191919 !important",
                    paddingLeft: "14px"
                }}>{props.groupName}</MDTypography>
            <MDBox p="22px">{getGroupedComponent()}</MDBox>
            <MDTypography
                sx={{
                    paddingLeft: "14px",
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#191919 !important",
                }}>{`End ${props.groupName}`}</MDTypography>
        </MDBox>
    )
}
