import { Grid, Divider, Chip } from "@mui/material";
import MDTypography from "components/MDTypography";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { DashboardVue } from "../utils/dashboard_interfaces";
import { linkElementStyle } from "../../vues/styles/VueStyles";
import { RoutePath } from "../../../../../constants";

const CompletedVueItem = ({ vueItem }: { vueItem: DashboardVue }) => {
  const navigate = useNavigate();
  const { vue } = vueItem;

  const getCompletedAtValue = (): string => {
    const dateFormat = "MM/DD/YYYY-hh:mm A";
    return vueItem.vuerCompletedAt.formatUTCNanoSecondsToString(dateFormat);
  };

  /// Handles the navigation to vue detail page
  const navigateToVueDetail = () => {
    const id = vue.canonicalId;
    navigate(`${RoutePath.vues}/${id}`);
  };

  return (
    <>
      <Grid container display="flex" px={2} spacing={4}>
        <Grid item xs={3}>
          <Link
            sx={{ ...linkElementStyle, cursor: "pointer" }}
            onClick={navigateToVueDetail}
            variant="button"
            color="primary"
            fontWeight="regular"
            underline="always"
            ml={1}
          >
            {vue.title}
          </Link>
        </Grid>
        <Grid item xs={2}>
          <MDTypography
            variant="button"
            sx={{ fontSize: "14px" }}
            fontWeight="regular"
          >
            {`#${vue.canonicalId}`}
          </MDTypography>
        </Grid>
        <Grid item xs={3}>
          <MDTypography
            variant="button"
            sx={{ fontSize: "14px" }}
            fontWeight="regular"
          >
            {getCompletedAtValue()}
          </MDTypography>
        </Grid>
        <Grid item xs={2}>
          {vue.escalated ? (
            <Chip
              label="YES"
              sx={{
                borderRadius: 3,
                fontSize: "14px",
                fontWeight: "bold",
                bgcolor: "#EFD1CE",
                color: "error.main",
              }}
            />
          ) : (
            <Chip
              label="NO"
              sx={{
                fontSize: "14px",
                fontWeight: "regular",
                bgcolor: "white.main",
                color: "dark.main",
              }}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {parseInt(vue.slaActual, 10) >= parseInt(vue.slaTarget, 10) ? (
            <Chip
              label={vue.slaActual + "/" + vue.slaTarget.toString() + "Hrs"}
              sx={{
                borderRadius: 3,
                fontSize: "14px",
                fontWeight: "bold",
                bgcolor: "#EFD1CE",
                color: "error.main",
              }}
            />
          ) : (
            <Chip
              label={vue.slaActual + "/" + vue.slaTarget.toString() + "Hrs"}
              sx={{
                borderRadius: 3,
                fontSize: "14px",
                fontWeight: "bold",
                bgcolor: "#E4F3E5",
                color: "success.main",
                opacity: 5,
              }}
            />
          )}
        </Grid>
      </Grid>
      <Divider
        sx={{ backgroundColor: "#c7ccd0", height: "1pt", borderWidth: 1 }}
      />
    </>
  );
};

export default CompletedVueItem;
