import MDAvatar from "components/MDAvatar";
import userPlaceholder from "assets/images/user_filler.svg";
import { useEffect, useState } from "react";
import { downloadFile } from "pages/profile/services/ProfileServices";

interface Props {
  imageId: string;
  [key: string]: any;
}

const UserImageAvatar = ({imageId, ...attr}:Props) => {
  const [profileUrl, setProfileUrl] = useState("");

  /// Downloading profile picture
  useEffect(() => {
    const downloadProfilePic = async () => {
      if (imageId) {
        const imageData = await downloadFile(imageId);
        if (imageData) {
          const { mimeType, data } = imageData;
          const url = `data:${mimeType};base64,${data}`;
          setProfileUrl(url);
        } else {
          setProfileUrl(null);
        }
      } else {
        setProfileUrl(null);
      }
    };
    downloadProfilePic();
  }, [imageId]);
  
  return (
    <MDAvatar
      src={profileUrl?.isNotEmpty() ? profileUrl : userPlaceholder}
      alt="User Image"
      {...attr}
    />
  );
};

export default UserImageAvatar;