import {
    SiteListAPIServices,
    WebServiceResponse,
} from "utils/services/AppUrls";
import { performGetRequest, performPostRequest } from "utils/services/NetworkHandler";

///////// SITE LIST APIS  ////////////

export const getSiteList = async (
    parameters: string
): Promise<WebServiceResponse> => {
    const url = SiteListAPIServices.getSiteList + "?" + parameters;
    return await performGetRequest(url);
};

export const addSiteList = async (data: object): Promise<WebServiceResponse> => {
    const url = `${SiteListAPIServices.addSite}`;
    return await performPostRequest(url, data);
};

export const editSiteList = async (data: object): Promise<WebServiceResponse> => {
    const url = `${SiteListAPIServices.editSite}`;
    return await performPostRequest(url, data);
};

export const bulkImportSiteList = async (data: object): Promise<WebServiceResponse> => {
    const url = `${SiteListAPIServices.bulkImportSiteList}`;
    return await performPostRequest(url, data);
};

export const exportSiteList = async (data: object): Promise<WebServiceResponse> => {
    const url = `${SiteListAPIServices.exportSiteList}`;
    return await performPostRequest(url, data);
};

export const bulkArchiveSiteList = async (data: object): Promise<WebServiceResponse> => {
    const url = `${SiteListAPIServices.bulkArchiveSiteList}`;
    return await performPostRequest(url, data);
};