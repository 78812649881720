import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { VueMapSection } from "./VueMapSection";
import { useVueProvider } from "pages/dashboard/home/vues/context/VueProvider";
import { PlaceholderImage } from "./PlaceholderImage";
import { VueMediaSection } from "./VueMediaSection";
import { IndividualVue, PhotoFileInfo } from "../utils/vue_detail_interface";
import { getVueStatus } from "../../vue-grid/utils/enums";

export interface Props {
  vueDetail: IndividualVue;
}

export const VueMapAndMediaSection = ({ vueDetail }: Props) => {
  /// To get the latest stored vue image detail from local storage
  const { latestSelectedVueImage } = useVueProvider();
  const vueStatus = getVueStatus(vueDetail.status);
  /// State to maintain the photo section according to the marker hover
  const [currentPhotoInfo, setCurrentPhotoInfo] =
    useState<PhotoFileInfo | null>();

  useEffect(() => {
    if (latestSelectedVueImage && vueStatus.toUpperCase() !== "IN REVIEW") {
      /// Checking whether there is any previously selected photo / locally stored
      const key = Object.keys(latestSelectedVueImage)[0];
      const locallyStoredImage: PhotoFileInfo = latestSelectedVueImage[key];
      /// If so, then checks whether the vue is same & then setting the marker ID, coords
      /// So that, map will render with that coords in the center
      if (locallyStoredImage && key === vueDetail.vue.canonicalId) {
        setCurrentPhotoInfo(locallyStoredImage);
      }
    }
  }, [latestSelectedVueImage, vueDetail, vueStatus]);

  return (
    <Grid container spacing={2.49}>
      <Grid item xs={6}>
        <VueMapSection vue={vueDetail} />
      </Grid>
      <Grid item xs={6}>
        {currentPhotoInfo ? (
          <VueMediaSection photoFileInfo={currentPhotoInfo} />
        ) : (
          <PlaceholderImage />
        )}
      </Grid>
    </Grid>
  );
};
