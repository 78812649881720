import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import ProfileSectionDivider from "./ProfileSectionDivider";

interface Props {
  title: string;
  children?:JSX.Element;
}

const TitleSection = ({ children, title }: Props) => {
  return (
    <Grid container spacing={3} alignItems="center" mb={2}>
      <Grid item>
        <MDTypography variant="h6" fontWeight="medium">
          {title}
        </MDTypography>
      </Grid>
      {children}
      <ProfileSectionDivider />
    </Grid>
  );
};

export default TitleSection;
