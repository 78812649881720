import { Grid, Divider, CircularProgress } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import NamedAvatar from "pages/components/NamedAvatar";
import { downloadFile } from "pages/profile/services/ProfileServices";
import { useEffect, useState } from "react";

const PortalItem: React.FC<{
  name: string;
  role: string;
  profileImageId?: string;
}> = (props) => {
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const downloadPortalLogo = async () => {
      setIsLoading(true)
      const hasImageId =
        props.profileImageId !== null && props.profileImageId.isNotEmpty();
      if (hasImageId) {
        const imageData = await downloadFile(props.profileImageId);
        if (imageData) {
          const { mimeType, data } = imageData;
          const url = `data:${mimeType};base64,${data}`;
          setProfileImage(url);
        } else {
          setProfileImage(null);
        }
        setIsLoading(false)
      }
    };
    downloadPortalLogo();
  }, [props.profileImageId]);

  return (
    <>
      <Grid
        container
        display="flex"
        justifyContent="start"
        alignItems="center"
        ml={3}
      >
        <Grid item xs={5}>
          <Grid
            container
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            <Grid item>
              {props.profileImageId ? (
                isLoading ? (
                  <CircularProgress size={30} color="success" />
                ) : profileImage ? (
                  <MDAvatar src={profileImage} alt="Profile Image" size="md" />
                ) : (
                  <MDAvatar>
                    <NamedAvatar name={props.name} />
                  </MDAvatar>
                ))
                : (
                  <MDAvatar>
                    <NamedAvatar name={props.name} />
                  </MDAvatar>
                )}
            </Grid>
            <Grid item pl={2}>
              <MDTypography
                variant="button"
                sx={{
                  fontSize: 16,
                  color: "secondary.main",
                  fontWeight: "bold",
                }}
              >
                {props.name}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={"auto"}>
          <MDTypography
            variant="button"
            sx={{
              fontSize: 16,
              color: "secondary.main",
              fontWeight: "regular",
            }}
          >
            {props.role}
          </MDTypography>
        </Grid>
      </Grid>
      <Grid item xs={true}>
        <Divider
          sx={{
            backgroundColor: "#c7ccd0",
            height: "1x",
            opacity: "0.5",
            backgroundImage: "none !important",
            margin: "0.7rem 0",
          }}
        />
      </Grid>
    </>
  );
};

export default PortalItem;
