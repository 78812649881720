import DashboardContentLayout from "layouts/DashboardContentLayout";
import ManageUserGrid from "./components/ManageUserGrid";

const ManageUsers = () => {
  return <DashboardContentLayout needCardBackground={false}>
    <ManageUserGrid/>
  </DashboardContentLayout>;
};

export default ManageUsers;
