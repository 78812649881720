import MDTypography from "components/MDTypography";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import ComingSoon from "pages/components/ComingSoon";

const Support = () => {
  return (
    <DashboardContentLayout needCardBackground={false}>
      <ComingSoon />
      {/* TODO: REMOVE THE VERSION NUMBER ONCE IT'S READY FOR RELEASE */}
      <MDTypography
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: 300,
          fontSize: "16px",
        }}
      >{`Beta ${process.env.REACT_APP_VERSION}`}</MDTypography>
    </DashboardContentLayout>
  );
};

export default Support;
