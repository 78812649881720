export enum ProfileKeys {
  designation = "designation",
  firstName = "firstName",
  lastName = "lastName",
  imageId = "profileImgId",
  isMarketingEnabled = "marketingEnabled",
  orgUserId = "orgUserId",
}

/// REQUEST PARAMS FOR UPDATE PROFILE
export interface ProfileInfo {
  [ProfileKeys.firstName]?: string;
  [ProfileKeys.lastName]?: string;
  [ProfileKeys.designation]?: string;
  [ProfileKeys.imageId]?: string;
  [ProfileKeys.isMarketingEnabled]?: boolean;
}

/// USER DETAILS
export interface UserInfo {
  id: string;
  canonicalId: string;
  email: string;
  createdAt: string;
  companyId: string;
  companies: Company[];
  companyGroups: CompanyGroup[];
  valid: boolean;
  phoneNumber: string;
  profileImgId: string;
  designation: string;
  firstName: string;
  lastName: string;
  twoFormRequired: boolean;
  marketingEnabled: boolean;
}

export interface Company {
  id: string;
  canonicalId: string;
  name: string;
  logoFileId: string;
  createdAt: string;
  shortcode: string;
  primaryEmail?: string;
  address?: string;
  billingEmail?: string;
  archived?: boolean;
  accountManager?: string;
  role?: string;
  accountManagerEmail?: string;
  accountManagerPhoneNumber?: string;
  pdfLogoFileId: string;
  pricingVisible: boolean;
}

export interface CompanyGroup {
  id: string;
  name: string;
  description: string;
  valid: boolean;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  companies: Company[];
}

export interface DownloadFile {
  filename: string;
  mimeType: string;
  data: string;
  uploadedAt: string;
  metadata: any;
}
