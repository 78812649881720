import MDBox from "components/MDBox";

export const CustomMapMarker = ({
  lat,
  lng,
  content,
  color,
  onHover,
  textColor,
}: {
  lat: number;
  lng: number;
  content?: string;
  color: string;
  onHover?: () => void;
  textColor?: string;
}) => {
  return (
    <MDBox
      onMouseOver={() => {
        if (onHover) {
          onHover();
        }
      }}
      sx={{
        transform: "translate(-12px, -30px)",
        borderRadius: "50%",
        background: color,
        width: "24px",
        height: "24px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#000000",
        zIndex: 4,
        "&::after": {
          position: "absolute",
          content: "''",
          width: "0px",
          height: "0px",
          bottom: "-26px",
          left: "0px",
          border: "12px solid transparent",
          borderTop: `24px solid ${color}`,
          zIndex: -1,
        },
      }}
    >
      {content === null || content === undefined ? (
        <span
          style={{
            height: "15px",
            width: "15px",
            backgroundColor: "#ffffff",
            borderRadius: "50%",
            display: "inline-block",
          }}
        />
      ) : (
        <span
          style={{
            color: textColor ?? "#000000",
            lineHeight: "12px",
            whiteSpace: "nowrap",
            fontSize: "10px",
          }}
        >
          {content}
        </span>
      )}
    </MDBox>
  );
};
