import { mdiArrowUpThin, mdiArrowDownThin } from "@mdi/js";
import { IconButton } from "@mui/material";
import { Icon } from "@mdi/react";
import { GridSortModel } from "@mui/x-data-grid-premium";

interface Props {
  onClickSort: (isAscending: boolean, newSortModel: GridSortModel) => void;
  sortModel: GridSortModel;
  field: string;
}

export const HeaderSortIconButton = (props: Props) => {
  const isAsc =
    props.sortModel[0]?.field === props.field &&
    props.sortModel[0]?.sort === "asc";
  var sortIcon = (
    <Icon path={isAsc ? mdiArrowUpThin : mdiArrowDownThin} size={1} />
  );
  if (props.sortModel.length <= 0 || props.sortModel[0].field !== props.field) {
    sortIcon = <Icon path={mdiArrowUpThin} color="#d3d3d3" size={1} />;
  }
  const newSortModel: GridSortModel = isAsc
    ? [{ field: props.field, sort: "desc" }]
    : [{ field: props.field, sort: "asc" }];

  return (
    <IconButton
      size="small"
      onClick={(event) => {
        event.stopPropagation();
        props.onClickSort(isAsc, newSortModel);
      }}
    >
      {sortIcon}
    </IconButton>
  );
};
