type SetShowToastMessage = (value: boolean) => void;
type SetToastMessage = (value: string) => void;

function showMessage(errorMessage: string, setMessage: SetToastMessage, setToastMessage: SetShowToastMessage) {
    setMessage(errorMessage);
    setToastMessage(true);
}

export {
    showMessage
}