import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";
import { imageEditorStyles } from "./styles";
import { useEffect, useRef, useState } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import {
  dialogCancelButtonStyle,
  dialogSaveButtonStyle,
} from "pages/dashboard/home/vues/styles/VueDetailStyles";
import { PhotoFileInfo } from "../../utils/vue_detail_interface";
import "tui-color-picker/dist/tui-color-picker.css";
interface Props {
  photoFileInfo: PhotoFileInfo;
  cancelButtonClick: () => void;
  saveButtonClick: (imageDate: string) => void;
}

const CustomImageEditor = (props: Props) => {
  const { photoFileInfo } = props;

  const imageEditorRef = useRef<ImageEditor>();
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const cancelButtonClick = () => {
    props.cancelButtonClick();
  };

  const handleSaveButtonClick = () => {
    const editor = imageEditorRef.current.getInstance();
    const image = editor.toDataURL({ format: "jpeg", quality: 0 });
    props.saveButtonClick(image);
  };

  useEffect(() => {
    const editor = imageEditorRef.current.getInstance();
    editor.on("undoStackChanged", (length: number) => {
      // Enabling save button when there are some changes made
      setEnableSaveButton(length > 0);
    });
  }, []);

  return (
    <>
      <ImageEditor
        ref={imageEditorRef}
        includeUI={{
          theme: imageEditorStyles,
          uiSize: {
            width: "auto",
            height: "88vh",
          },
          menuBarPosition: "left",
          loadImage: {
            path: `data:${photoFileInfo.mimeType};base64,${photoFileInfo.data}`,
            name: photoFileInfo.filename,
          },
        }}
        cssMaxHeight={700}
        cssMaxWidth={700}
        /// Used to customize the user selected portion
        selectionStyle={{
          cornerSize: 10,
          rotatingPointOffset: 0,
          cornerColor: "#ffffff",
          borderColor: "#53AE55",
        }}
        usageStatistics={false}
      />
      <MDBox px={2} py={2} display="flex" justifyContent="center">
        <MDButton
          variant="outlined"
          sx={dialogCancelButtonStyle}
          size="small"
          onClick={cancelButtonClick}
        >
          CANCEL
        </MDButton>
        <MDButton
          variant="gradient"
          color="success"
          disabled={!enableSaveButton}
          sx={dialogSaveButtonStyle}
          size="small"
          onClick={handleSaveButtonClick}
        >
          SAVE
        </MDButton>
      </MDBox>
    </>
  );
};

export default CustomImageEditor;
