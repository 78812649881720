import Card from "@mui/material/Card";
/// Hooks
import React, { useState, useRef } from "react";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { InfoOutlined } from "@mui/icons-material";
// Images
import bgImage from "assets/images/authentication-cover.jpeg";
import "utils/helpers/extensions";
import CoverLayout from "layouts/CoverLayout";
import MessageAlert from "../components/MessageAlert";
import { RoutePath } from "./../../../constants";
import { requestEmailToResetPassword } from "pages/authentication/services/AuthenticationServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { CustomIndicator } from "pages/components/CustomIndicator";
import RequiredFieldMarker from "pages/components/RequiredFieldMarker";

function ResetPassword(): JSX.Element {
  /// This handles the navigation to another page
  const navigateToCheckYourInboxPage = useNavigate();
  /// This variable holds the email typed by the user
  const emailInputRef = useRef<HTMLInputElement>(null);
  /// This state decides whether errorComponent is active or not
  const [hasError, setHasError] = useState<boolean>(false);
  /// This state handles the message shown in errorComponent
  const [error, setError] = useState<string>("");
  /// This defines whether the "Send Email" button should be enabled or not
  const [isDisabled, setDisability] = useState<boolean>(true);
  /// State to show/hide progress indicator
  const [showLoader, setShowLoader] = useState<boolean>(false);

  /****** METHODS *******/

  /// This handles the onChange of email field
  const handleEmailFieldChange = () => {
    const emailId = emailInputRef.current.value.trim();
    if (!emailId.isValidEmail()) {
      setDisability(true);
    } else {
      setHasError(false);
      setDisability(false);
    }
  };

  /// This handles the onClick of "Send Email" button
  const handleSendEmailButtonClick = async () => {
    setShowLoader(true);
    const email = emailInputRef.current.value.trim();
    if (email.isValidEmail()) {
      const response = await requestEmailToResetPassword(email);
      if (response.status === WebServiceStatus.success) {
        setShowLoader(false);
        navigateToCheckYourInboxPage(RoutePath.checkInbox, {
          replace: true,
          /// Passing the entered valid email to "ResetCompletion" page
          state: {
            email: emailInputRef.current.value.trim(),
          },
        });
      } else {
        setShowLoader(false);
        setHasError(true);
        const error = response.error ?? "Invalid Email.";
        setError(error);
      }
    }
  };

  return (
    <>
      {showLoader && <CustomIndicator />}
      <CoverLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              variant="h5"
              fontWeight="medium"
              color="white"
              mt={1}
              pb={1}
            >
              Reset Password
            </MDTypography>
            <MDTypography
              display="block"
              variant="button"
              color="white"
              my={1}
              fontWeight="light"
            >
              Enter your email to reset your password
            </MDTypography>
          </MDBox>
          {hasError && (
            <MDBox px={3}>
              <MessageAlert message={error} type="error" icon={<InfoOutlined />} />
            </MDBox>
          )}
          <MDBox pt={hasError ? 0 : 4} pb={2} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={4}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  sx={{ fontSize: 14 }} >Email<RequiredFieldMarker />
                </MDTypography>
                <MDInput
                  type="email"
                  placeholder="Email Address"
                  fullWidth
                  inputRef={emailInputRef}
                  onChange={handleEmailFieldChange}
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>
              <MDBox mt={3} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  disabled={isDisabled}
                  onClick={handleSendEmailButtonClick}
                >
                  SEND EMAIL
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox mb={3} textAlign="center">
            <MDTypography variant="button" color="text" fontWeight="regular">
              Already have an account?&nbsp;
              <MDTypography
                component={Link}
                to={RoutePath.root}
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
                mb={2}
              >
                Sign In
              </MDTypography>
            </MDTypography>
          </MDBox>
        </Card>
      </CoverLayout>
    </>
  );
}

export default ResetPassword;
