import { DialogContent, DialogContentText, DialogActions } from "@mui/material";
import MDButton from "components/MDButton";

interface Props {
  content: string | JSX.Element;
  actions?: JSX.Element[];
  closeDialog?: () => void;
}

export const ModalPopUp = ({ actions, content, closeDialog }: Props) => {
  // Generates the actions for modal poup
  const generateActions = () => {
    if (actions === null || actions === undefined) {
      return getDefaultCloseButton();
    } else if (actions.length <= 0) {
      return getDefaultCloseButton();
    } else {
      return actions;
    }
  };

  // Generates the default close button
  const getDefaultCloseButton = () => {
    return (
      <MDButton
        variant="gradient"
        color="info"
        size="medium"
        onClick={closeDialog}
      >
        CLOSE
      </MDButton>
    );
  };

  /// Styling for dialog text
  const dialogTextStyle = {
    color: "#344767 !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    pt: 2,
    pb: 2,
  };

  return (
    <>
      <DialogContent>
        <DialogContentText sx={dialogTextStyle}>{content}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          ".MuiButton-root": {
            fontWeight: "500",
          },
        }}
      >
        {generateActions()}
      </DialogActions>
    </>
  );
};
