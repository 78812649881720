import { CustomIndicator } from "pages/components/CustomIndicator";
import { RoutePath } from "../../constants";
import { useAuth } from "context/AuthProvider";
import { UserRoles } from "pages/my-account/manage-users/interfaces/interfaces";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { useNavigate } from "react-router-dom";
import { hasMinimumRequiredRole } from "utils/helpers/common";

interface Props {
  children: JSX.Element;
  requiredRole: UserRoles;
}

export const RestrictedRoute = ({
  children,
  requiredRole,
}: Props): JSX.Element => {
  const { userData, userRole }: { userData: UserInfo; userRole: UserRoles } =
    useAuth();
  const navigate = useNavigate();
  const hasAccess = hasMinimumRequiredRole({ userRole, requiredRole });
  if (!userData) {
    navigate(RoutePath.root);
  } else if (!userData.companyId) {
    navigate(RoutePath.vueDashboard);
  } else if (!hasAccess) {
    navigate(RoutePath.vueDashboard);
  }
  return hasAccess ? children : <CustomIndicator />;
};