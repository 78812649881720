import {
  CellEditIconType,
  EditableCell,
  checkBoxStyle,
  textEllipsis,
  gridStyle,
  cellEditIconsStyle,
  hasBasicData,
  UpdateAddressDialogContent,
  LatLng,
  SiteAddressProp,
  styleForUploadTemplateGrid,
} from "@ivueit/vue-engine";
import { mdiMapMarker, mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellEditStartParams,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowId,
  GridRowSelectionModel,
  GridRowsProp,
  GridValidRowModel,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { dataGridCellButtonStyle } from "../styles/site_list_style";
import { ConfirmationDialog } from "./ConfirmationDialog";
import {
  getIndexOfRowWithDuplicateSiteName as getRowIndexWithDuplicateSiteName,
  isContainEmptyAddressComponent,
  knownEditableColumns,
  siteListPinnedColumns,
  sortRowsBySiteName,
} from "../helpers/helpers";
import { GENERIC_ERROR_MESSAGE } from "../../../../../constants";
import { bulkImportSiteList } from "../services/siteListServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { useAuth } from "context/AuthProvider";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import MessageAlert from "pages/authentication/components/MessageAlert";
import { MDButton } from "../../vues/vue-grid/helpers/imports";

// Defines the button types used within the screen
enum EditButtonType {
  edit,
  map,
}

const IMPORT_QUEUE_LIMIT = 300;

interface PreviewGridProps {
  // Rows from excel
  rowsFromExcel: GridRowsProp;
  columnsFromExcel: GridColDef[];
  editableHeaders: EditableCell[];
  onImportSuccess: () => void;
  onCloseDialog: () => void;
  // Defines the original data available on the server
  originalSiteList: GridRowsProp;
}

const BulkImportPreviewGrid = ({
  rowsFromExcel,
  columnsFromExcel,
  editableHeaders,
  onCloseDialog,
  onImportSuccess,
  originalSiteList,
}: PreviewGridProps) => {
  // Sorting the rows by site name, when loading the table for the first time
  const allRows = sortRowsBySiteName(rowsFromExcel);

  const { userData }: { userData: UserInfo } = useAuth();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  /// Defines the validated row data
  const [validatedRowData, setValidatedRowData] =
    useState<GridRowsProp>(allRows);
  /// Defines the invalid row data
  const [invalidRowData, setInvalidRowData] = useState<GridRowsProp>([]);
  const [editableColumns] = useState<EditableCell[]>(knownEditableColumns);
  /// Determines whether to select all cells or not
  const [selectAll, setSelectAll] = useState<boolean>(true);
  // Selecting all rows when the page is loading
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>(
    allRows.map((row) => row.id)
  );
  // Defines the cell param which is in editing
  const [cellInEdit, setCellInEdit] = useState<null | GridRenderCellParams>(
    null
  );
  const [showMapDialog, setShowMapDialog] = useState(false);
  /// State to save the bulk import data
  const [bulkImportData, setBulkImportData] = useState<GridRowsProp | null>(
    null
  );

  const [isReadyToImport, setIsReadyToImport] = useState(false);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  // Set updated row
  const [updatedRowData, setUpdatedRowData] = useState<any | null>(null);
  // hook to perform navigation
  /// Reference for the grid
  const gridRef = useGridApiRef();

  /// Checks for duplicate site names and inititates the upload
  const checkForDuplicateRowsForBulkUpload = (selectedRows: GridRowsProp) => {
    // Generate site names for original rows
    const siteNameListFromOriginal = originalSiteList.map((row) => {
      const site = (row.site ?? "").toString().trim();
      const siteNumber = (row.sitenumber ?? "").toString().trim();
      return `${site} ${siteNumber}`.trim().toLowerCase();
    });
    const siteNameListFromExcel = selectedRows.map((row) => {
      const site = (row.site ?? "").toString().trim();
      const siteNumber = (row.sitenumber ?? "").toString().trim();
      return `${site} ${siteNumber}`.trim().toLowerCase();
    });
    // Checking for duplicate site names
    const hasDuplicates = siteNameListFromOriginal.some((siteName) => {
      return siteNameListFromExcel.indexOf(siteName) !== -1;
    });
    if (!hasDuplicates) {
      bulkImportSiteListUpdate(selectedRows, editableHeaders);
    } else {
      setBulkImportData(selectedRows);
    }
  };

  // Sorts invalid rows to top and then removes the selections of invalid rows
  const sortRowsByValidityAndUpdateSelection = (rowData: GridRowsProp) => {
    var rowDataToSort = [...rowData];
    // Sorting the grid data source based on address & basic validations
    rowDataToSort.sort((row1, row2) => {
      const isRow1Valid = row1.addressValidity && row1.basicValidity;
      const isRow2Valid = row2.addressValidity && row2.basicValidity;
      return isRow1Valid - isRow2Valid;
    });
    // Updates the grid data source with sorted row data
    setValidatedRowData(rowDataToSort);
    // Filtering valid row IDs
    const validRowIds = rowDataToSort
      .filter((row) => {
        if (!selectedRows.includes(row.id)) {
          // Ignoring the row, if it is NOT selected already
          return false;
        }
        // *** Below code applicable only for currently selected rows *** //

        // Considering the cases where the validation is NOT done yet, returns the original data
        if (
          row.addressValidity === undefined ||
          row.basicValidity === undefined
        ) {
          return true;
        } else {
          return row.addressValidity && row.basicValidity;
        }
      })
      .map((row) => row.id);
    // Updating row selections manually
    handleRowSelectionChange(validRowIds);
  };

  const getMetaFields = (site: any, editableHeader: EditableCell[]) => {
    let customObject: { [key: string]: string } = {};
    editableHeader.forEach((item) => {
      customObject[item.fieldName] = site[item.fieldName];
    });
    return customObject;
  };

  const bulkImportSiteListUpdate = async (
    rowsFromExcel: GridRowsProp,
    editableHeaders: EditableCell[]
  ) => {
    const jobSites = rowsFromExcel.map((site) => {
      const duplicateRowIndex = getRowIndexWithDuplicateSiteName(
        originalSiteList,
        site.site,
        site.sitenumber
      );
      const metaFields =
        editableHeaders.length > 0 ? getMetaFields(site, editableHeaders) : {};
      const siteData = {
        siteName: site.site,
        siteNumber: site.sitenumber,
        address: site.address,
        addressTwo: site.address2,
        city: site.city,
        stateOrProvinceTwoCharacterCode: site.state,
        zipOrPostalCode: `${site.zipcode}`,
        archived: site.archived.toString().toLowerCase() === "yes",
        notes: site.internalnote,
        meta: metaFields,
      };

      if (duplicateRowIndex !== -1) {
        const duplicateItem = originalSiteList[duplicateRowIndex];
        // Appending the site id
        return {
          ...siteData,
          id: duplicateItem.id,
        };
      }
      return siteData;
    });
    bulkImportSiteListToServer(userData.companyId, jobSites);
  };

  const sliceJobSitesIntoChunks = (sites: any[]) => {
    var chunks: any[] = [];
    for (var index = 0; index < sites.length; index += IMPORT_QUEUE_LIMIT) {
      chunks.push(sites.slice(index, index + IMPORT_QUEUE_LIMIT));
    }
    return chunks;
  };

  const bulkImportSiteListToServer = async (
    companyId: string,
    jobSites: any[]
  ) => {
    const chunksArray = sliceJobSitesIntoChunks(jobSites);
    setShowLoader(true);
    const responses = await Promise.all(
      chunksArray.map(async (sites) => {
        const params = {
          companyId: companyId,
          jobSites: sites,
        };
        return await bulkImportSiteList(params);
      })
    );

    const failedResponses = responses.filter(
      (response) => response.status !== WebServiceStatus.success
    );
    if (failedResponses.length === 0) {
      // Imported successfully
      onImportSuccess();
    } else {
      // Atleast one of the request set is failed
      const errorMessage = failedResponses[0].error ?? GENERIC_ERROR_MESSAGE;
      setSnackbarContent({
        title: "Import failed for some sites",
        message: errorMessage,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  // Opens the map dialog
  const getMapDialogBox = () => {
    if (!cellInEdit) {
      setShowMapDialog(false);
      return <></>;
    }

    const { id } = cellInEdit;
    const rowIndex = validatedRowData.findIndex((value) => value.id === id);
    if (rowIndex === -1) {
      return <></>;
    }
    var currentRow = { ...validatedRowData[rowIndex] };
    const { site, address, address2, city, state, zip } = currentRow;

    const isValidAddress = currentRow.addressValidity ?? true;
    const siteAddress = `${address ?? ""} ${city ?? ""} ${state ?? ""} ${
      zip ?? ""
    }`;
    var newRows = [...validatedRowData];
    newRows.splice(rowIndex, 1);
    return (
      <CustomDialogBox title="Update Address" width="1204px" openDialog={true}>
        <UpdateAddressDialogContent
          isValidAddress={isValidAddress}
          closeDialog={() => {
            setShowMapDialog(false);
          }}
          disableLocationName={false}
          siteAddress={siteAddress}
          suiteNo={address2}
          siteName={site}
          updatedSiteDetails={async (
            coordinates?: LatLng,
            addressComp?: SiteAddressProp,
            siteName?: string,
            suiteNo?: string
          ) => {
            currentRow.address2 = suiteNo;
            currentRow.coordinates = coordinates;
            currentRow.site = siteName;
            currentRow.address = addressComp.address;
            currentRow.city = addressComp.city;
            currentRow.state = addressComp.state;
            currentRow.zipcode = addressComp.zipCode;
            setUpdatedRowData(currentRow);
            setCellInEdit(null);
          }}
        />
      </CustomDialogBox>
    );
  };

  const getBulkImportReplaceSiteConfirmation = () => {
    return (
      <CustomDialogBox
        title="Duplicate Site Found"
        width="450px"
        openDialog={true}
      >
        <ConfirmationDialog
          message="Duplicate site name found. Please note the old data shall be overwritten by the new data. Do you wish to continue?"
          buttonName="Yes, merge"
          onClose={() => {
            setBulkImportData(null);
          }}
          onClick={() => {
            bulkImportSiteListUpdate(bulkImportData, editableHeaders);
            setBulkImportData(null);
          }}
        />
      </CustomDialogBox>
    );
  };

  const getIconType = (
    cellData: EditableCell,
    params: GridRenderCellParams
  ) => {
    // Checks whether the column needs to be edited
    const iconType = cellData?.editIcon ?? CellEditIconType.edit;
    return iconType;
  };

  const getIconsFromCellType = (
    type: CellEditIconType,
    onClick: (buttonType: EditButtonType) => void
  ): JSX.Element => {
    var icon: JSX.Element;
    switch (type) {
      case CellEditIconType.map:
        icon = (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onClick(EditButtonType.map);
            }}
          >
            <Icon path={mdiMapMarker} size={0.72} />
          </IconButton>
        );
        break;
      default:
        icon = (
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              onClick(EditButtonType.edit);
            }}
          >
            <Icon path={mdiPencil} size={0.72} />
          </IconButton>
        );
        break;
    }
    return icon;
  };

  const editButtonClicked = (
    buttonType: EditButtonType,
    cellParams: GridRenderCellParams
  ) => {
    const { id, field } = cellParams;
    switch (buttonType) {
      case EditButtonType.map:
        setShowMapDialog(true);
        break;
      default:
        gridRef.current.startCellEditMode({ id: id, field: field });
        break;
    }
  };

  /// Defines the custom headers
  const getCustomHeader = (params: GridColumnHeaderParams) => {
    const { colDef } = params;
    const headerName = colDef.headerName;
    return (
      <MDBox>
        <strong>{headerName.toUpperCase()}</strong>
      </MDBox>
    );
  };

  // Defines the custom cells in the grid
  const getCustomCells = (params: GridRenderCellParams) => {
    const { field, value } = params;

    var iconButtons: JSX.Element = <></>;
    // Checks whether the cells are editable or not
    if (editableColumns && editableColumns.length > 0) {
      var iconType = CellEditIconType.edit;

      const cellData = editableColumns.find(
        (cell) => cell.fieldName.toLowerCase() === field.toLowerCase()
      );
      if (cellData) {
        // Checks whether the column needs to be edited
        iconType = getIconType(cellData, params);
        iconButtons = getIconsFromCellType(iconType, (buttonType) => {
          setCellInEdit(params);
          editButtonClicked(buttonType, params);
        });
      }
    }

    return (
      <MDBox
        className="MuiDataGrid-cellContent"
        display="flex"
        alignItems="center"
        minWidth="0"
      >
        <MDBox sx={textEllipsis}>{value}</MDBox>
        <MDBox sx={cellEditIconsStyle}>{iconButtons}</MDBox>
      </MDBox>
    );
  };

  const renderedColumns = columnsFromExcel.map((data) => {
    data.renderCell = getCustomCells;
    data.renderHeader = getCustomHeader;
    return data;
  });

  const getRowClassName = (params: GridRowClassNameParams<any>): string => {
    const isInvalidRow = invalidRowData.some((row) => row.id === params.row.id);
    return isInvalidRow ? "invalid-grid-row" : "";
  };

  /// Handles the onChange of check box
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    const listOfSelectedRowIds: GridRowId[] = validatedRowData.map(
      (row) => row.id
    );
    setSelectedRows(checked ? listOfSelectedRowIds : []);
  };

  /// Handles the change in rows selection
  const handleRowSelectionChange = (
    listOfSelectedRowIds: GridRowSelectionModel
  ) => {
    setSelectedRows(listOfSelectedRowIds);
    setSelectAll(listOfSelectedRowIds.length === validatedRowData.length);
  };

  const onProcessRowUpdate = async (
    updatedRow: any,
    originalRow: any
  ): Promise<any> => {
    const rowIndex = validatedRowData.findIndex(
      (row) => row.id === updatedRow.id
    );
    if (rowIndex !== -1) {
      setUpdatedRowData(updatedRow);
    }
    // For invalid cases
    return originalRow;
  };

  const getNoRowsMessage = () => {
    return (
      <MDBox position="relative" top="30%">
        <Typography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          No rows available
        </Typography>
      </MDBox>
    );
  };

  const validateRow = async (
    rowToBeValidated: GridValidRowModel
  ): Promise<boolean> => {
    const { address, city, state, country, zipcode, latlong } = rowToBeValidated;
    return await isContainEmptyAddressComponent(
      address,
      city,
      state,
      country,
      zipcode,
      latlong,
      true
    );
  };

  useEffect(() => {
    const editRow = async (updatedRow: any) => {
      const rowIndex = validatedRowData.findIndex(
        (value) => value.id === updatedRow.id
      );
      if (rowIndex === -1) {
        return;
      }
      const newRows = [...validatedRowData];
      newRows.splice(rowIndex, 1); // Removing the row to check for other duplicates
      const duplicateRowIndex = getRowIndexWithDuplicateSiteName(
        newRows,
        updatedRow.site,
        updatedRow.sitenumber
      );
      if (duplicateRowIndex === -1) {
        // Validating the row
        const addressValidity = await validateRow(updatedRow);
        const basicValidity = hasBasicData(updatedRow);
        // Updating validity status
        const finalRowData = {
          ...updatedRow,
          addressValidity: addressValidity,
          basicValidity: basicValidity,
        };
        // Updating selection if it was previously invalid and not selected
        if (
          updatedRow.addressValidity !== addressValidity ||
          updatedRow.basicValidity !== basicValidity
        ) {
          var invalidRows = [...invalidRowData];
          var newlySelectedRows = [...selectedRows];
          if (addressValidity && basicValidity) {
            // Removing row from invalid row data
            invalidRows = invalidRowData.filter(
              (row) => row.id !== finalRowData.id
            );
            // Selecting row, if it is NOT selected yet
            if (!newlySelectedRows.includes(finalRowData.id)) {
              newlySelectedRows = [...selectedRows, finalRowData.id];
            }
          } else {
            // Adding to invalid rows
            if (!invalidRowData.some((row) => row.id === finalRowData.id)) {
              invalidRows.push(finalRowData);
            }
            // Removing selection since the row is invalid
            newlySelectedRows = selectedRows.filter((id) => finalRowData.id !== id);
          }
          // Updating row selection
          handleRowSelectionChange(newlySelectedRows);
          setInvalidRowData(invalidRows);
        }
        var rowsToBeUpdated = [...validatedRowData];
        rowsToBeUpdated[rowIndex] = finalRowData;
        // Updating selection
        setValidatedRowData(rowsToBeUpdated);
        setUpdatedRowData(null);
        setShowLoader(false);
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: "Duplicate site name found.",
          isError: true,
        });
      }
    };

    if (updatedRowData !== null) {
      editRow(updatedRowData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedRowData]);

  // Use effect to validate the rows
  useEffect(() => {
    const validateAllRows = async () => {
      var newlyValidated: any[] = [];
      var invalidRows: any[] = [];
      setShowLoader(true);
      const responses: boolean[] = await Promise.all(
        allRows.map((currentRow) => validateRow(currentRow))
      );
      setShowLoader(false);
      responses.forEach((validAddress, index) => {
        const currentRow = allRows[index];
        const basicValidity = hasBasicData(currentRow);
        const rowItem = {
          ...currentRow,
          addressValidity: validAddress,
          basicValidity: basicValidity,
        };
        newlyValidated[index] = rowItem;
        if (!validAddress || !basicValidity) {
          // Adding invalid rows
          invalidRows.push(rowItem);
        }
        if (newlyValidated.length === allRows.length) {
          sortRowsByValidityAndUpdateSelection(newlyValidated);
          setInvalidRowData(invalidRows);
        }
      });
    };
    if (allRows.length === 0) {
      setValidatedRowData([]);
    } else {
      validateAllRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsFromExcel]);

  useEffect(() => {
    if (invalidRowData.length <= 0) {
      setErrorMessage("");
      return;
    }

    var errorMessage = "";
    const basicValidations = invalidRowData.filter((row) => !row.basicValidity);
    const addressValidation = invalidRowData.filter(
      (row) => !row.addressValidity
    );

    if (basicValidations.length > 0 && addressValidation.length > 0) {
      errorMessage = `${addressValidation.length} addresses and ${basicValidations.length} site/site numbers could not be validated. Please update the highlighted rows if you’d like them to be included.`;
    } else if (basicValidations.length > 0) {
      errorMessage = `${basicValidations.length} site/site number could not be validated. Please update the highlighted site/site numbers if you’d like them to be included.`;
    } else if (addressValidation.length > 0) {
      errorMessage = `${addressValidation.length} addresses could not be validated. Please update the highlighted addresses if you’d like them to be included.`;
    }

    setErrorMessage(errorMessage);
  }, [invalidRowData]);

  // Handles the row selection
  useEffect(() => {
    if (!selectAll) {
      return;
    }
    const newSelectedRows = validatedRowData.map((row) => row.id);
    setSelectedRows(selectAll ? newSelectedRows : []);
  }, [selectAll, validatedRowData]);

  // Updating row selections and validity to the parent
  useEffect(() => {
    const containsInvalidRows =
      invalidRowData.filter((row) => selectedRows.includes(row.id)).length > 0;
    setIsReadyToImport(!containsInvalidRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  // Generates the error message to be displayed on top of the page
  const getErrorMessage = (): JSX.Element => {
    return (
      <MDBox
        ml="auto"
        sx={{ "& > .MuiBox-root": { marginTop: "0", marginBottom: "0" } }}
      >
        <MessageAlert
          message={errorMessage}
          type={"error"}
          icon={<ErrorOutlineOutlined />}
          sx={{ display: "inline-flex" }}
        />
      </MDBox>
    );
  };

  return (
    <>
      <DialogContent>
        <>
          {bulkImportData !== null && getBulkImportReplaceSiteConfirmation()}
          {showMapDialog && getMapDialogBox()}
          <MDBox mt={2}>
            {showLoader && <CustomIndicator />}
            <MDBox display="flex" alignItems="start" pl={2} pb={1.4}>
              <FormGroup sx={{ flexShrink: "0" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAll}
                      onChange={handleCheckboxChange}
                    />
                  }
                  sx={checkBoxStyle}
                  label={
                    selectedRows.length > 0
                      ? `${
                          selectAll
                            ? allRows.length
                            : selectedRows.length
                        }/${allRows.length} selected`
                      : ""
                  }
                />
              </FormGroup>
              {errorMessage.length > 0 && getErrorMessage()}
            </MDBox>
            <MDBox
              sx={{
                minHeight: `calc(80vh - 190px)`,
                height: `calc(85vh - 190px)`,
                justifyContent: "flex-start",
                position: "relative",
              }}
            >
              <DataGridPremium
                apiRef={gridRef}
                columns={renderedColumns}
                rows={validatedRowData}
                checkboxSelection
                disableColumnMenu
                sx={{
                  ...dataGridCellButtonStyle,
                  ...gridStyle,
                  ...styleForUploadTemplateGrid,
                }}
                hideFooter
                density="compact"
                disableRowSelectionOnClick
                onRowSelectionModelChange={handleRowSelectionChange}
                rowSelectionModel={selectedRows}
                initialState={{
                  pinnedColumns: {
                    left: [
                      GRID_CHECKBOX_SELECTION_COL_DEF.field,
                      ...siteListPinnedColumns,
                    ],
                  },
                }}
                slots={{
                  loadingOverlay: LinearProgress,
                  noRowsOverlay: getNoRowsMessage,
                }}
                onCellEditStart={(
                  params: GridCellEditStartParams,
                  event: MuiEvent
                ) => {
                  // Preventing other edit options like double click/
                  event.defaultMuiPrevented = true;
                }}
                processRowUpdate={onProcessRowUpdate}
                onProcessRowUpdateError={(error) => {
                  console.log(error);
                }}
                getRowClassName={getRowClassName}
              />
            </MDBox>
          </MDBox>
          <CustomSnackbar
            snackbarContent={snackbarContent}
            onClose={() => {
              setSnackbarContent(null);
            }}
          />
        </>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid #a8b8d8" }}>
        <Button
          variant="outlined"
          color="info"
          size="small"
          sx={{
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: "500",
            padding: "11px 16px",
            borderColor: "secondary.light",
            color: "dark.main",
            "&:hover": {
              backgroundColor: "white.main",
              borderColor: "secondary.light",
            },
          }}
          onClick={onCloseDialog}
        >
          CANCEL
        </Button>
        <MDButton
          variant="contained"
          color="info"
          onClick={() => {
            // Filtering the selected rows
            const selectedRowData = validatedRowData.filter((row) =>
              selectedRows.includes(row.id)
            );
            checkForDuplicateRowsForBulkUpload(selectedRowData);
          }}
          disabled={selectedRows.length <= 0 || !isReadyToImport}
        >
          IMPORT
        </MDButton>
      </DialogActions>
    </>
  );
};

export default BulkImportPreviewGrid;
