import { Card, CircularProgress, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { RoutePath } from "../../../../../constants";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import bgImage from "assets/images/map.png";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { getVueStatus } from "pages/dashboard/home/vues/vue-grid/utils/enums";
import { DashboardVue } from "../utils/dashboard_interfaces";
import { downloadFile } from "pages/profile/services/ProfileServices";
import { useCallback, useEffect, useState } from "react";
import { loaderStyle } from "../../vues/styles/VueDetailStyles";
import { downloadPhotoSectionFile } from "../../vues/services/VueServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { PhotoFileInfo } from "../../vues/vue_detail/utils/vue_detail_interface";

const EscalatedVueItem = ({
  dashboardVue,
  shouldDownloadMapFromId,
}: {
  dashboardVue: DashboardVue;
  shouldDownloadMapFromId: boolean;
}) => {
  const navigate = useNavigate();
  const { vue, vuerCompletedAt } = dashboardVue;
  const [imageURL, setImageUrl] = useState<string>("");
  const API_KEY = process.env.REACT_APP_GMAP_API_KEY;
  const MAP_SIZE = "350x200";
  const ZOOM_LEVEL = "19";

  // Formatting the address fields, skipping the empty items
  const getVueAddress = (): string => {
    var address = vue.streetAddress.isNotEmpty()
      ? `${vue.streetAddress}, `
      : "";
    address += vue.city.isNotEmpty() ? `${vue.city}, ` : "";
    address += vue.stateProvinceAbbreviation.isNotEmpty()
      ? `${vue.stateProvinceAbbreviation} `
      : "";
    address += vue.zipPostalCode.isNotEmpty() ? `${vue.zipPostalCode}, ` : "";
    address += vue.countryAbbreviation.isNotEmpty()
      ? `${vue.countryAbbreviation}, `
      : "";
    return address;
  };

  /// Handles the navigation to vue detail page
  const navigateToVueDetail = () => {
    const id = vue.canonicalId;
    navigate(`${RoutePath.vues}/${id}`);
  };

  /// Returns the static map URL
  const getStaticMapURL = useCallback((): string => {
    const { latitude, longitude } = vue;
    const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&maptype=satellite&zoom=${ZOOM_LEVEL}&size=${MAP_SIZE}&markers=color:0x4caf50%7C${latitude},${longitude}&key=${API_KEY}`;
    return mapUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vue]);

  /// In SiteDetail screen, it will show the map using the mapFileID.
  /// In Dashboard screen, it will show the last escalated photo of that Vue.
  /// In the case if the image is not available, the static map will be shown.
  const downloadImage = useCallback(async () => {
    let sourcePath: string;
    if (shouldDownloadMapFromId) {
      if (vue.mapFileId) {
        const fileData = await downloadFile(vue.mapFileId);
        if (fileData) {
          const { mimeType, data } = fileData;
          const url = `data:${mimeType};base64,${data}`;
          sourcePath = url;
        } else {
          sourcePath = getStaticMapURL();
        }
      } else {
        sourcePath = getStaticMapURL();
      }
    } else {
      if (dashboardVue.latestEscalatedPhotoId) {
        const response = await downloadPhotoSectionFile(
          dashboardVue.latestEscalatedPhotoId
        );
        if (response.status === WebServiceStatus.success) {
          const responseData = response.data as PhotoFileInfo;
          const { mimeType, data } = responseData;
          const url = `data:${mimeType};base64,${data}`;
          sourcePath = url;
        } else {
          sourcePath = getStaticMapURL();
        }
      } else {
        sourcePath = getStaticMapURL();
      }
    }
    setImageUrl(sourcePath);
  }, [
    dashboardVue.latestEscalatedPhotoId,
    getStaticMapURL,
    shouldDownloadMapFromId,
    vue.mapFileId,
  ]);

  useEffect(() => {
    downloadImage();
  }, [downloadImage]);

  return (
    <Grid
      item
      mt={3}
      xs={12}
      md={6}
      lg={4}
      display="flex"
      sx={{
        cursor: "pointer",
        a: {
          display: "flex",
        },
      }}
    >
      <Link onClick={navigateToVueDetail}>
        <Card
          /// This property is added inorder to make the card jump on hover
          sx={{
            position: "relative",
            "&:hover .card-header": {
              transform: "translate3d(0, -15px, 0)",
            },
            ...(vue.escalated && shouldDownloadMapFromId
              ? {
                  "&:after": {
                    display: "block",
                    content: '""',
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    border: "2px solid #AE1709",
                    borderRadius: "8px",
                  },
                }
              : {}),
          }}
        >
          <MDBox
            position="relative"
            borderRadius="lg"
            mt={-3}
            mx={2}
            className="card-header"
            zIndex={2}
          >
            {imageURL ? (
              <MDBox
                component="img"
                src={imageURL}
                alt={vue.title}
                borderRadius="lg"
                width="100%"
                height="100%"
                position="relative"
                zIndex={1}
                sx={{
                  height: "186px",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            ) : (
              <CircularProgress color="success" sx={loaderStyle} />
            )}
            <MDBox
              borderRadius="lg"
              width="100%"
              height="100%"
              position="absolute"
              left={0}
              top="0"
              sx={{
                backgroundImage: !imageURL && `url(${bgImage})`,
                transform: "scale(0.94)",
                filter: "blur(12px)",
                backgroundSize: "cover",
              }}
            />
          </MDBox>
          <MDBox textAlign="center" pt={3} px={3} flexGrow={1}>
            <MDTypography fontWeight="medium" fontSize="20px">
              {vue.title}
            </MDTypography>
            <MDTypography
              variant="button"
              color="text"
              sx={{ mt: 1.5, mb: 1 }}
              fontWeight="regular"
              fontSize="16px"
            >
              {getVueAddress()}
            </MDTypography>
            <MDTypography variant="h6" mt={0.6} fontWeight="regular">
              Completed At:{" "}
              {vuerCompletedAt.formatUTCNanoSecondsToString("MM/DD/YYYY")}
            </MDTypography>
          </MDBox>
          <Divider sx={{ backgroundColor: "secondary.main", height: "1pt" }} />
          <MDBox pb={2} display="flex" justifyContent="center">
            <MDBox>
              <CustomBadge content={`#${vue.canonicalId}`} borderRadius="8px" />
            </MDBox>
            <MDBox px={2}>
              <CustomBadge
                content={getVueStatus(dashboardVue.status).capitalizeWords()}
                borderRadius="8px"
              />
            </MDBox>
            <MDBox>
              <CustomBadge
                content={vue.typeOf.capitalizeWords()}
                borderRadius="8px"
              />
            </MDBox>
          </MDBox>
        </Card>
      </Link>
    </Grid>
  );
};

export default EscalatedVueItem;
