import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { VUE_EXPORT_LIMIT } from "./../../../../constants";
import { tooltipStyles } from "pages/dashboard/home/site-lists/styles/site_list_style";

interface Props {
  imgPath: string;
  title: string;
  titleHead: string;
  isExportEnabled: boolean;
  errorMessage: string;
  exportButtonClick: () => void;
  exportItemCount?: number;
}

const ExportDialogSectionTitle = (props: Props) => {
  return (
    <MDBox>
      <MDBox display="flex" alignItems="center">
        <img src={props.imgPath} alt="" />
        <MDBox ml={1}>
          <MDTypography variant="button" sx={{ fontWeight: "500" }}>
            {" "}
            {props.titleHead}
          </MDTypography>
          <MDTypography variant="button" sx={{ fontWeight: "400" }}>
            {" "}
            - {props.title}
          </MDTypography>
        </MDBox>
        <MDBox ml="auto">
          <Tooltip
            title={`Cannot export more than ${VUE_EXPORT_LIMIT} Vues at a time.`}
            arrow
            placement="left"
            componentsProps={{
              tooltip: {
                sx: tooltipStyles,
              },
            }}
            disableHoverListener={
              props.exportItemCount < VUE_EXPORT_LIMIT ||
              props.titleHead === "Data"
            }
          >
            <span>
              <MDButton
                variant="contained"
                color="success"
                sx={{ fontSize: "14px", fontWeight: "500" }}
                onClick={props.exportButtonClick}
                disabled={!props.isExportEnabled}
              >
                Export
              </MDButton>
            </span>
          </Tooltip>
        </MDBox>
      </MDBox>
      {!props.errorMessage.isEmpty() && (
        <MDTypography
          display="flex"
          justifyContent="flex-end"
          py={0}
          pr={2}
          sx={{
            color: "#AE1709",
            fontSize: "12px",
            fontWeight: "regular",
            padding: "0",
            right: "24px",
            bottom: "6px",
          }}
        >
          <span>{props.errorMessage}</span>
        </MDTypography>
      )}
    </MDBox>
  );
};

export default ExportDialogSectionTitle;
