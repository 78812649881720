import { CardMedia, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { downloadFile } from "pages/profile/services/ProfileServices";

export const VueSignature = ({photoId}:{photoId:string}) => {
  const [signatureImage, setSignatureImage] = useState<string>();
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    const fetchSignatureImage = async () => {
      setLoader(true);
      const imageData = await downloadFile(photoId);
      const { mimeType, data } = imageData;
      const url = `data:${mimeType};base64,${data}`;
      setSignatureImage(url);
      setLoader(false);
    };
    fetchSignatureImage();
  }, [photoId]);

  return loader ? (
    <CircularProgress />
  ) : (
    <CardMedia
      component="img"
      height="380px"
      width="auto"
      image={signatureImage}
      alt="Signature"
    />
  );
};
