import {
  CustomDropdown,
  PlaceholderComponent,
  SurveyListItem,
  SurveyPreviewContent,
  TEXTFIELD_CHANGE_DELAY,
  VueSurvey,
  searchBarStyle,
} from "@ivueit/vue-engine";
import { Search } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  InputAdornment,
  List,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { useCallback, useEffect, useState } from "react";
import { getSurveyTemplateById } from "../vues/services/VueServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { ConfirmationDialog } from "../site-lists/components/ConfirmationDialog";
import { getSurveyTemplates, updateSurveyTemplate } from "./services/services";
import { availableSurveyStatus, getSurveyStatusFromText } from "./enums/enums";
import { useLocation } from "react-router-dom";
import { useAuth } from "context/AuthProvider";
import { hasMinimumRequiredRole } from "utils/helpers/common";
import { UserRoles } from "pages/my-account/manage-users/interfaces/interfaces";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";

interface ConfirmDialogOptions {
  visible: boolean;
}

const Surveys = () => {
  const [surveyStatus, setSurveyStatus] = useState<string>("Active");
  const [surveyList, setSurveyList] = useState<VueSurvey[]>([]);
  const [selectedSurvey, setSelectedSurvey] = useState<VueSurvey>();
  const [showPreviewDialog, setShowPreviewDialog] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState("");
  const [showConfirmation, setShowConfirmation] =
    useState<ConfirmDialogOptions>({
      visible: false,
    });
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  // Defines the search text
  const [searchText, setSearchText] = useState<string>("");
  const location = useLocation();
  const { userRole, userData } = useAuth();
  // Set companyId
  const companyId = userData.companyId;

  const getPreviewDialog = () => {
    return (
      <Dialog open={true} onClose={null} maxWidth="md" fullWidth>
        <SurveyPreviewContent survey={selectedSurvey} />
        <DialogActions sx={{ pt: "15px" }}>
          {getPreviewDialogActions()}
        </DialogActions>
      </Dialog>
    );
  };

  const getPreviewDialogActions = () => {
    return (
      <MDButton
        variant="gradient"
        color="info"
        size="medium"
        onClick={() => {
          setShowPreviewDialog(false);
        }}
      >
        CLOSE
      </MDButton>
    );
  };

  const getTextField = () => {
    return (
      <TextField
        fullWidth
        value={searchText}
        placeholder="Search Surveys"
        sx={[searchBarStyle, { marginLeft: "20px" }]}
        InputLabelProps={{ shrink: true }}
        onChange={(event) => setSearchText(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search fontSize="medium" sx={{ color: "#344767" }} />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const handleDropDownClick = (newValue: string) => {
    setSurveyStatus(newValue);
  };

  /// Handles the on click of "Preview" button
  const handlePreviewButtonClick = async (surveyId: string) => {
    setShowLoader(true);
    if (surveyId.isEmpty()) {
      setShowLoader(false);
      return;
    }
    const response = await getSurveyTemplateById(surveyId);
    if (response.status === WebServiceStatus.success) {
      const { survey } = response.data;
      const surveyData = survey as VueSurvey;
      setSelectedSurvey(surveyData);
      setShowPreviewDialog(true);
    } else {
      console.log("Error: Unable to display the preview");
    }
    setShowLoader(false);
  };

  const getArchiveConfirmationDialog = () => {
    var title = "Archive Survey";
    var message = "Are you sure you want to archive this survey?";
    var buttonName = "ARCHIVE";
    if (selectedSurvey.archived) {
      title = "Unarchive Survey";
      message = "Are you sure you want to unarchive this survey?";
      buttonName = "UNARCHIVE";
    }
    return (
      <CustomDialogBox
        title={title}
        width="450px"
        openDialog={showConfirmation.visible}
      >
        <ConfirmationDialog
          message={message}
          buttonName={buttonName}
          onClose={() => {
            setShowConfirmation({ visible: false });
          }}
          onClick={() => {
            updateSurveyTemplateToServer();
          }}
        />
      </CustomDialogBox>
    );
  };

  //Update Survey template
  const updateSurveyTemplateToServer = async () => {
    setShowLoader(true);
    const params: VueSurvey = {
      ...selectedSurvey,
      archived: !selectedSurvey.archived,
    };
    const response = await updateSurveyTemplate(params, selectedSurvey.id);
    if (response.status === WebServiceStatus.success) {
      getSurveyList();
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
    setShowConfirmation({ visible: false });
  };

  const getSurveyList = useCallback(async () => {
    // Fetching the survey status from the text
    const statusOf = getSurveyStatusFromText(surveyStatus);
    setShowLoader(true);
    const response = await getSurveyTemplates(
      companyId,
      searchText.trim(),
      statusOf
    );
    if (response.status === WebServiceStatus.success) {
      const { surveys } = response.data;
      const surveyList = surveys as VueSurvey[];
      setError(
        surveyList.length === 0 ? "No surveys available for this client" : ""
      );
      setSurveyList(surveyList);
    } else {
      const error = response.error;
      setError(error);
    }
    setShowLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, surveyStatus]);

  useEffect(() => {
    /// The method delays the callback for 700 millseconds
    const delaySearchAction = setTimeout(() => {
      getSurveyList();
    }, TEXTFIELD_CHANGE_DELAY);
    return () => clearTimeout(delaySearchAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    getSurveyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyStatus]);

  useEffect(() => {
    if (location.state) {
      const { templateId } = location.state;
      if (templateId) {
        handlePreviewButtonClick(templateId);
      }
    }
  }, [location.state]);

  return (
    <DashboardContentLayout>
      {showPreviewDialog && getPreviewDialog()}
      {showConfirmation.visible && getArchiveConfirmationDialog()}
      {showLoader && <CustomIndicator />}
      <MDBox
        display="flex"
        alignItems="center"
        width="100%"
        mb={0.8}
        pt={3}
        pb={2}
        px={2}
      >
        <MDTypography variant="h5" sx={{ color: "#344767" }}>
          Frequently Used Surveys
        </MDTypography>
        {getTextField()}
        <MDBox display="flex" align="centre" ml="auto" width="200px">
          <CustomDropdown
            title={"Survey Status"}
            selectedItem={surveyStatus}
            availableValues={availableSurveyStatus}
            onChange={handleDropDownClick}
            prefixTitle={true}
          />
        </MDBox>
      </MDBox>
      <MDBox display="flex" flexDirection="column" flexGrow="1" px={2} py={2}>
        <List>
          {surveyList.sort().map((survey, index) => {
            const { typeOf, name, description, archived, autoSlaHours, id } =
              survey;
            const requiredRole = UserRoles.admin;
            // Showing cost only for admins
            const isAdminUser =
              userRole && hasMinimumRequiredRole({ userRole, requiredRole });
            const standardCost = isAdminUser
              ? survey.autoCostPerVue &&
                "$" + survey.autoCostPerVue.value.toString()
              : "";
            const urgentCost = isAdminUser
              ? survey.autoUrgentCost &&
                "$" + survey.autoUrgentCost.value.toString()
              : "";
            return (
              <SurveyListItem
                key={index}
                surveyName={name}
                category={typeOf}
                vueOverview={description}
                sla={autoSlaHours && autoSlaHours.value}
                isSelected={false}
                onClickPreview={() => {
                  handlePreviewButtonClick(id);
                }}
                standardCost={standardCost}
                urgentCost={urgentCost}
                showArchiveButton={isAdminUser}
                isArchived={archived}
                onClickArchive={() => {
                  setSelectedSurvey(survey);
                  setShowConfirmation({
                    visible: true,
                  });
                }}
              />
            );
          })}
        </List>
        {error && (
          <MDBox margin="auto">
            <PlaceholderComponent label={error} />
          </MDBox>
        )}
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </DashboardContentLayout>
  );
};

export default Surveys;
