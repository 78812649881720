import { Company, VueEngine } from "@ivueit/vue-engine";
import { useAuth } from "context/AuthProvider";
import { RoutePath } from "../../../../constants";
import DashboardContentLayout from "layouts/DashboardContentLayout";
import { useLocation, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { useCallback, useEffect, useState } from "react";
import history from "../../../../history_routing";
import MDButton from "components/MDButton";
import CustomDialogBox from "pages/components/CustomDialogBox";
import { ModalPopUp } from "pages/components/ModalPopUp";

const CreateVues = () => {
  const navigate = useNavigate();
  const { getAvailablePortalsForUser } = useAuth();
  const availableCompanyList: Company[] = getAvailablePortalsForUser();
  const { pathname } = useLocation();
  const [triggerExit, setTriggerExit] = useState({
    allowNavigation: false,
    pathname: "",
  });
  const [openConfirmationDialog, setOpenConfirmation] =
    useState<boolean>(false);
  const { userData }: { userData: UserInfo } = useAuth();
  const parentCompanyId = userData.companyId;

  const handleGoToIntendedPage = useCallback(
    (location: any) => history.push(location),
    []
  );

  useEffect(() => {
    window.addEventListener("beforeunload", (event: any) => {});

    return () => {
      window.removeEventListener("beforeunload", (event: any) => {});
    };
  }, []);

  useEffect(() => {
    if (triggerExit.allowNavigation) {
      handleGoToIntendedPage(triggerExit.pathname);
    }
    const unblock = history.block(({ location }: { location: any }) => {
      const targetLocation = location.pathname;
      if (targetLocation !== RoutePath.createVues) {
        setOpenConfirmation(true);
      }
      setTriggerExit((prev) => ({
        ...prev,
        pathname: targetLocation,
      }));
      return triggerExit.allowNavigation;
    });
    return () => {
      unblock();
    };
  }, [
    handleGoToIntendedPage,
    pathname,
    triggerExit.allowNavigation,
    triggerExit.pathname,
  ]);

  const confirmationDialogActions = () => {
    return [
      <MDButton
        key={1}
        variant="outlined"
        color="dark"
        size="medium"
        onClick={() => {
          setOpenConfirmation(false);
        }}
        sx={{
          borderColor: "#C7CCD0",
          padding: "11px 16px",
          fontSize: "14px",
          fontWeight: "bold",
          "&:hover": { borderColor: "#C7CCD0" },
        }}
      >
        CANCEL
      </MDButton>,
      <MDButton
        key={2}
        variant="gradient"
        color="info"
        size="medium"
        onClick={() => {
          setTriggerExit((prev) => ({
            ...prev,
            allowNavigation: true,
          }));
          setOpenConfirmation(false);
        }}
        sx={{ padding: "11px 16px", fontSize: "14px", fontWeight: "bold" }}
      >
        Yes
      </MDButton>,
    ];
  };

  /// Gets the confirmation dialog
  const getConfirmationDialog = () => {
    return (
      <CustomDialogBox
        title={`Cancel Vue creation`}
        width="454px"
        openDialog={openConfirmationDialog}
      >
        <ModalPopUp
          content={`Are you sure you want to exit the Vue creation process? Your progress will not be saved.`}
          closeDialog={() => {
            setOpenConfirmation(false);
          }}
          actions={confirmationDialogActions()}
        />
      </CustomDialogBox>
    );
  };

  return (
    <DashboardContentLayout needCardBackground={false}>
      {openConfirmationDialog && getConfirmationDialog()}
      <MDBox
        sx={{
          "& .MuiBox-root.css-11ptvp6": {
            mr: "0.6em",
          },
          "& .MuiBox-root.css-t4s0ii button": {
            padding: "0.8em 1.2em",
          },
        }}
      >
        <VueEngine
          parentCompanyId={parentCompanyId}
          availableCompanyList={availableCompanyList}
          apiKey={process.env.REACT_APP_GMAP_API_KEY}
          fileId={process.env.REACT_APP_VUE_UPLOAD_TEMPLATE}
          onVueCreate={() => {
            setTriggerExit((prev) => ({
              pathname: RoutePath.vues,
              allowNavigation: true,
            }));
          }}
          navigateToSiteList={() => {
            navigate(RoutePath.siteLists);
          }}
        />
      </MDBox>
    </DashboardContentLayout>
  );
};

export default CreateVues;
